import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { Animated, TextInput, TouchableHighlight, Text, ScrollView } from 'react-native-web';
import retStyles from '../../styles/MainStyleSheet';
import { isEmpty } from '@firebase/util';
import LeaderboardPart from '../parts/LeaderboardPart';
import { useTheme } from 'react-native-paper';



const CustomLeaderboards = ({CustomLeaderboards, active, events, competitors, teams, compDets, scores, forViewer, permissionLevel, setCreateCustomLeaderboardTrigger, setCustomboard }) =>{
    const [customCompetitorLists, setCustomCompetitorLists] = useState({});

    useEffect(async()=>{
        let obj = {}
        for(let leaderboard of CustomLeaderboards){
            let rowData = [];
            if(leaderboard.data){
                if(leaderboard.data.type == 'Team'){
                    rowData = teams
                }else if(leaderboard.data.type == 'Competitor'){
                    rowData = competitors
                }

                for(let f of leaderboard.data.filters){
                    if(f.field == 'NumMembers'){
                        switch(f.queryType){
                            case 'equals':
                                rowData = rowData.filter(x=> x.data.TeamMembers.length == f.queryString)
                                break;
                            case '!equals':
                                rowData = rowData.filter(x=> x.data.TeamMembers.length != f.queryString)
                                break;
                            case 'larger':
                                rowData = rowData.filter(x=> x.data.TeamMembers.length > f.queryString);
                                break;
                            case 'smaller':
                                rowData = rowData.filter(x=> x.data.TeamMembers.length < f.queryString);
                                break;
                            case 'larger_equals':
                                rowData = rowData.filter(x=> x.data.TeamMembers.length >= f.queryString);
                                break;
                            case 'smaller_equals':
                                rowData = rowData.filter(x=> x.data.TeamMembers.length <= f.queryString);
                                break;
                        }
                    }else{
                        if(f.isCustom){
                            switch(f.queryType){

                                case 'equals':
                                    rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] == f.queryString){return(true)}}else{return(false)}})
                                    break;
                                case '!equals':
                                    rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] != f.queryString){return(true)}}else{return(false)}})
                                    break;
                                case 'contains':
                                    rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field].includes(f.queryString)){return(true)}}else{return(false)}})
                                    break;
                                case '!contains':
                                    rowData = rowData.filter(x=> {if(x.data.customAttributes){if(!x.data.customAttributes[f.field].includes(f.queryString)){return(true)}}else{return(false)}})
                                    break;
                                case 'larger':
                                    rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] > f.queryString){return(true)}}else{return(false)}})
                                    break;
                                case 'smaller':
                                    rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] < f.queryString){return(true)}}else{return(false)}})
                                    break;
                                case 'larger_equals':
                                    rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] >= f.queryString){return(true)}}else{return(false)}})
                                    break;
                                case 'smaller_equals':
                                    rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] <= f.queryString){return(true)}}else{return(false)}})
                                    break;
                            }
                        }else{
                            switch(f.queryType){
                                case 'equals':
                                    rowData = rowData.filter(x=> x.data[f.field] == f.queryString)
                                    break;
                                case '!equals':
                                    rowData = rowData.filter(x=> x.data[f.field] != f.queryString)
                                    break;
                                case 'contains':
                                    rowData = rowData.filter(x => x.data[f.field].includes(f.queryString))
                                    break;
                                case '!contains':
                                    rowData = rowData.filter(x => !x.data[f.field].includes(f.queryString))
                                    break;
                                case 'larger':
                                    rowData = rowData.filter(x=> x.data[f.field] > f.queryString);
                                    break;
                                case 'smaller':
                                    rowData = rowData.filter(x=> x.data[f.field] < f.queryString);
                                    break;
                                case 'larger_equals':
                                    rowData = rowData.filter(x=> x.data[f.field] >= f.queryString);
                                    break;
                                case 'smaller_equals':
                                    rowData = rowData.filter(x=> x.data[f.field] <= f.queryString);
                                    break;
                            }
                        }
                    }
                }

            }
            obj[leaderboard.id] = rowData;
        }

        setCustomCompetitorLists(obj)
    },[CustomLeaderboards, competitors, teams])

    if(active){
        if(CustomLeaderboards){
            return(
                CustomLeaderboards.map((cLB)=>{
                    return(
                        <LeaderboardPart
                            key={cLB.id}
                            name={cLB.data.name}
                            events={events.sort((a,b)=>a.data.EventNo-b.data.EventNo)}
                            participants={(customCompetitorLists[cLB.id]) ? customCompetitorLists[cLB.id] : []}
                            competitorList={competitors}
                            teamList={teams}
                            compDets={compDets}
                            scores={scores}
                            custom={true}
                            permissionLevel={permissionLevel}
                            setCreateCustomLeaderboardTrigger={setCreateCustomLeaderboardTrigger}
                            setCustomboard={setCustomboard}
                            customID={cLB.id}
                            forViewer={false}
                            isTeamBoard={(cLB.data.type == 'Team') ? true:false}
                        />
                    )
                })
            )
        }
    }
    return(null)
}

export default CustomLeaderboards;