import React, { useContext, useEffect, useState } from 'react';
import { Text, View, TextInput, Image } from 'react-native';
import { getDoc, doc, collection, getDocs, deleteDoc, setDoc, updateDoc, query, onSnapshot, where, addDoc } from 'firebase/firestore';
import { CheckBox, Picker, ScrollView, TouchableOpacity } from 'react-native-web';

import * as ImagePicker from 'expo-image-picker';


import Popup from '../../../parts/Popup';
import AppButton from '../../../parts/AppButton';
import Header from '../../../parts/Header';
import AlertPopup from '../../../parts/AlertPopup';

import { Ionicons } from '@expo/vector-icons';

import retButtonStyles from '../../../../styles/ButtonStyleSheet';
import retStyles from '../../../../styles/MainStyleSheet';
import { useTheme } from 'react-native-paper';
import { db } from '../../../../fbManage';
import {validateColour} from '../../../../styles/colourManagement';
import { AppContext } from '../../../../AppContextProvider';

function ThemeScreen({theme, setSaveButton, userDets, setLoading}) {
    if(!theme){
        theme= useTheme()
    }

    const {gVpWidth} = useContext(AppContext)

    const styles = retStyles();
    const ButtonStyles = retButtonStyles();
    const [initial, setInitial] = useState(true)

    const [previewTheme, setPreviewTheme] = useState(theme)
    const [mainColour, setMainColourInternal] = useState('')
    const [headerBackground,setHeaderBackground] = useState('')
    const [compNameColour, setCompNameColour] =useState('')
    const [customImage, setCustomImage] = useState('');
    const [cusImageMode, setCusImageMode] = useState('stretch')

    useEffect(()=>{
        let isMounted = true
        if(isMounted){
            if(initial){
                setMainColourInternal(theme.colors.mainColour)
                setHeaderBackground(theme.colors.headerBackground)
                setCompNameColour(theme.colors.compNameColour)
                setCustomImage(theme.customImage)
                setCusImageMode(theme.customImageMode)
                setInitial(false)
            }
        }
        return()=>{isMounted = false}

    },[])

    useEffect(()=>{
        setPreviewTheme({
            ...theme,
            colors:{
                ...theme.colors,
                mainColour:mainColour,
                headerBackground:headerBackground,
                compNameColour:compNameColour
            },
            customImage:customImage,
            customImageMode:cusImageMode
        })
        setSaveButton(
            <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw', marginRight:'2vw'}}>
                <AppButton
                    textStyle={ButtonStyles.appButtonText}
                    containerStyle = {ButtonStyles.appButtonContainer}
                    title="Save Theme"
                    onPress={()=>saveTheme()}
                />
            </View>
        )
    }, [mainColour, headerBackground, compNameColour, customImage, cusImageMode])

    const saveTheme = () =>{
        setLoading(true)
        let userObj = {
            Settings:{
                ...userDets.data.Settings,
                Theme:{
                    ...userDets.data.Settings.Theme,
                    colors:{
                        mainColour:mainColour,
                        headerBackground:headerBackground,
                        compNameColour:compNameColour,
                    },
                    customImage:customImage,
                    customImageMode:cusImageMode,
                }
            }
        }

        updateDoc(doc(db, "Users", userDets.id), userObj).then(()=>{
            setLoading(false)
        })
    }

    const changeMain = async(e) =>{
        let colour = await validateColour(e)
        if(colour){
            setMainColourInternal(colour)
        }
    }

    const changeHeaderBackground = async(e)=>{
        let colour = await validateColour(e)
        if(colour){
            setHeaderBackground(colour)
        }
    }

    const changeCompNameColour = async(e)=>{
        let colour = await validateColour(e)
        if(colour){
            setCompNameColour(colour)
        }
    }

    const changeImage = async() => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            setCustomImage(result.uri);
        }
    }


    return (
        <View style={{ width:'100%',paddingLeft:'2vw',paddingRight:'2vw'}}>
            <Text style={[styles.H1, {color:theme.colors.mainColour}]}>Theme Settings</Text>
            <View style={{marginTop:'0.5vh'}}>
                <View style={gVpWidth > 600 ? {flexDirection:'row',justifyContent:'space-between'} : null}>
                    <View style={gVpWidth <= 600 ? {flexDirection:'row', justifyContent:'space-between'}:null}>
                        <Text style={{marginBottom:'0.5vw'}}>Main Colour:</Text>
                        <View style={{flexDirection:'row', marginBottom:'1vh'}}>
                            <TextInput
                                style={styles.inputBox}
                                onChangeText={(e)=>changeMain(e)}
                                placeholder={mainColour}
                            />
                            <View
                                style={{marginLeft:'1vw',width:'20px', height:'20px', backgroundColor:mainColour, borderRadius:'5px'}}
                            />
                        </View>
                    </View>
                    <View style={gVpWidth <= 600 ? {flexDirection:'row', justifyContent:'space-between'}:null}>
                        <Text style={{marginBottom:'0.5vw'}}>Competition Title Colour:</Text>
                        <View style={{flexDirection:'row', marginBottom:'1vh'}}>
                            <TextInput
                                style={styles.inputBox}
                                onChangeText={(e)=>changeCompNameColour(e)}
                                placeholder={compNameColour}
                            />
                            <View
                                style={{marginLeft:'1vw',width:'20px', height:'20px', backgroundColor:compNameColour, borderRadius:'5px'}}
                            >

                            </View>
                        </View>
                    </View>
                    <View style={gVpWidth <= 600 ? {flexDirection:'row', justifyContent:'space-between'}:null}>
                        <Text style={{marginBottom:'0.5vw'}}>Header Background Colour:</Text>
                        <View style={{flexDirection:'row', marginBottom:'1vh'}}>
                            <TextInput
                                style={styles.inputBox}
                                onChangeText={(e)=>changeHeaderBackground(e)}
                                placeholder={headerBackground}
                            />
                            <View
                                style={{marginLeft:'1vw',width:'20px', height:'20px', backgroundColor:headerBackground, borderRadius:'5px'}}
                            >

                            </View>
                        </View>
                    </View>
                    <View>
                        <Text>Custom Logo:</Text>
                        {customImage == '' ?
                            <View style={{width:'20vw', marginBottom:'4vh'}}>
                                <View style={{marginTop:'0.5vh', minWidth:'10vw'}}>
                                    <AppButton
                                        title={"Select"}
                                        containerStyle={ButtonStyles.appButtonContainer}
                                        textStyle={ButtonStyles.appButtonText}
                                        onPress={()=>changeImage()}
                                    />
                                </View>
                            </View>
                        :
                            <View style={{flexDirection:'row', marginBottom:'3.5vh'}}>
                                <View style={{marginBottom:'0.5vh', marginTop:'0.5vh', minWidth:'10vw'}}>
                                    <AppButton
                                        title={"Remove"}
                                        containerStyle={ButtonStyles.appButtonContainer}
                                        textStyle={ButtonStyles.appButtonText}
                                        onPress={()=>setCustomImage('')}
                                    />
                                </View>

                                <Picker
                                    selectedValue={cusImageMode}
                                    onValueChange={(itemValue) => setCusImageMode(itemValue)}
                                    style={{borderRadius:'5px', marginLeft:'1vw', minWidth:'9vw'}}
                                >
                                    <Picker.Item label="Center" value="center"/>
                                    <Picker.Item label="Contain" value="contain"/>
                                    <Picker.Item label="Cover" value="cover"/>
                                    <Picker.Item label="Repeat" value="repeat"/>
                                    <Picker.Item label="Stretch" value="stretch"/>

                                </Picker>

                            </View>
                        }
                    </View>
                </View>
                {/**Preview */}
                <Text style={{fontWeight:'bold', marginTop:'1.5vh', marginBottom:'1vw', marginLeft:'1vw'}}>Preview:</Text>
                <View style={{width:'98%', height:'7.8vh', marginRight:'1%', marginLeft:'1%'}}>
                    <Header
                        preview={true}
                        name={"Preview"}
                        theme={previewTheme}
                        buttons={
                            <View style={{flexDirection:'row'}}>
                                <View style={{justifyContent:'center', alignItems:'center'}}>
                                    <TouchableOpacity>
                                    <Ionicons name="settings" size={24} color={previewTheme.colors.mainColour} />
                                    </TouchableOpacity>
                                </View>
                                <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                    <AppButton
                                        title={"Create"}
                                        containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:previewTheme.colors.mainColour}]}
                                        textStyle={ ButtonStyles.appButtonText}
                                    />
                                </View>
                                <View style={{ height:'5vh', minWidth:'10vw', marginLeft:'2vw'}}>
                                    <AppButton
                                        title={"Logout"}
                                        containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:previewTheme.colors.mainColour}]}
                                        textStyle={ ButtonStyles.appButtonText}
                                    />
                                </View>
                            </View>
                        }
                    />
                </View>
            </View>
        </View>
    );

}

export default ThemeScreen;