import React, { createRef, useContext, useEffect, useState } from 'react';
import { CheckBox, Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import * as ImagePicker from 'expo-image-picker';

import { Text, View, TextInput, Image } from 'react-native';

import { Ionicons } from '@expo/vector-icons';

import { AppContext } from "../../../../../AppContextProvider";
import { useTheme } from 'react-native-paper';

import retButtonStyles from '../../../../../styles/ButtonStyleSheet';
import retStyles from '../../../../../styles/MainStyleSheet';
import LoadingModal from '../../../../parts/LoadingModal';
import Popup from '../../../../parts/Popup';
import AppButton from '../../../../parts/AppButton';
import AlertPopup from '../../../../parts/AlertPopup';
import Header from '../../../../parts/Header';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../fbManage';
import { validateColour } from '../../../../../styles/colourManagement';
import { deleteOrg } from '../../../../../data';

function OrganisationMenu ({theme, orgDets, userOrgDets,setSaveButton, setSelectedOrg}){
    const styles = retStyles(theme);
    const ButtonStyles = retButtonStyles(theme);

    const {gVpWidth} = useContext(AppContext)

    const [loading, setLoading] = useState(false);
    const [orgName, setOrgName] = useState('');
    const [alertPopupTrigger, setAlertPopupTrigger] = useState('');
    const [confDelTrigger, setConfDelTrigger] = useState(false);
    const [delCountdown, setDelCountdown] = useState(0)

    useEffect(()=>{
        setSaveButton(
            <View style={{ height:'5vh', marginLeft:'2vw', marginRight:'2vw'}}>
                <AppButton
                    title="Save Org Details"
                    onPress={()=>saveOrganisationDetails()}
                />
            </View>
        )

    }, [orgName])

    const saveOrganisationDetails = async () =>{
        let name2Save = orgName.trim();
        if(name2Save.toLowerCase() == 'new' || name2Save.toLowerCase() == 'personal'){
            setAlertPopupTrigger({title:'Organisation Name', text:`${name2Save} is a reserved word and therefor cannot be used as a name`})
        }else if(name2Save == ''){
            name2Save = orgDets.data.name
        }else{
            setLoading(true)
            await updateDoc(doc(db, "Organisations", orgDets.id),{
                name: name2Save,
            })
            setLoading(false)
        }
    }

    const deleteOrganisation = async () => {
        if(delCountdown <=0){
            setConfDelTrigger(false)
            setLoading(true)
                let res = await deleteOrg({orgID: orgDets.id});
                console.log(res)
                if(res.data.code == 200){
                    setSelectedOrg('')
                }else if (res.data.code == 213){
                    setAlertPopupTrigger({title:"Licences!", text:"Please cancel all associated licences before deleting the organisation."})
                    setLoading(false)
                }else{
                    setAlertPopupTrigger({title:"Oh No!", text:"Something went wrong, please try again!"});
                    setLoading(false)
                }
        }
    }

    useEffect(()=>{
        if(confDelTrigger){
            if(delCountdown >0){
                setTimeout(()=>{
                    if(delCountdown > 0){
                        setDelCountdown(delCountdown-1)
                    }
                },1000)
            }
        }
    }, [delCountdown])

    useEffect(()=>{
        if(confDelTrigger){
            setDelCountdown(5)
        }
    }, [confDelTrigger])

    return(
        <View style={{width:'100%'}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                <Text style={styles.H2}>Organisation</Text>
            </View>
            <View style={{marginTop:'2.5vh', paddingLeft:'10vw'}}>
                <View style={{flexDirection:'row'}}>
                    <Text style={{minWidth:'5vw', fontWeight:'bold', textAlign:'left'}}>Name:</Text>
                    <TextInput style={[styles.inputBox,{borderColor:'black', borderWidth:1, marginLeft:'0.5vw', minWidth:'15vw'}]}
                        placeholder={orgDets.data.name}
                        value={orgName}
                        onChangeText={(e)=>setOrgName(e)}
                    />
                </View>
                {userOrgDets?.role == 0 ?
                    <View style={{minWidth:'20vw', flexDirection:'row', justifyContent:'center', marginTop:'2vh'}}>
                        <View style={{minWidth:'200px', width:'15vw', height:'4vh'}}>
                            <AppButton
                                title={"Delete Organisation"}
                                onPress={()=>{setConfDelTrigger(true)}}
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle = {[ButtonStyles.appButtonContainer]}
                            />
                        </View>
                    </View>
                :null}

            </View>
            <LoadingModal
                trigger={loading}
                theme={theme}
            />
            <AlertPopup
                trigger={(alertPopupTrigger != '')}
                theme={theme}
                setTrigger={setAlertPopupTrigger}
                title={alertPopupTrigger.title}
                alertText={alertPopupTrigger.text}
            />
            <Popup
                trigger={confDelTrigger}
                innerStyle={{
                    padding:10,
                    width:(gVpWidth > 600 ) ? '40%' : "90%",
                    height:'auto',
                    backgroundColor:'white',
                    borderRadius:20,
                    borderColor:theme.colors.mainColour,
                    borderWidth:2
                }}
                content={
                    <View>
                        <View style={{width:'100%', alignItems:'center'}}>
                            <Text style={styles.H1}>Delete {orgDets.data.name}</Text>
                        </View>
                        <View style={{width:'100%', justifyContent:'center', alignItems:'center', marginVertical:'1vh'}}>
                           <Text>Are you sure you want to delete this organisation?</Text>
                           <Text>This <b>CANNOT</b> be undone.</Text>
                           <Text>You will lose any data associated with this organisation!</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'1vh'}}>
                            <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    textStyle={ButtonStyles.appButtonText}
                                    onPress={() => setConfDelTrigger(false)}
                                    title={'Cancel'}
                                />
                            </View>
                            <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    textStyle={ButtonStyles.appButtonText}
                                    onPress={() => {if(delCountdown <= 0){deleteOrganisation()}}}
                                    title={(delCountdown > 0) ? `${delCountdown}` : "Delete"}
                                />
                            </View>
                        </View>
                    </View>
                }
            />
        </View>
    )
}

export default OrganisationMenu;