import React, { useContext, useEffect, useState } from 'react';
import {View, Text, Image, Dimensions} from "react-native";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

import { useTheme } from 'react-native-paper';
import retStyles from '../../styles/MainStyleSheet';
import { Modal, ScrollView, TouchableOpacity } from 'react-native-web';
import { Entypo } from '@expo/vector-icons';
import Popup from './Popup';
import AppButton from './AppButton';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import DynamicText from './DynamicText';
import { AppContext } from '../../AppContextProvider';


const Header = ({buttons, name, theme, preview}) =>{
    if(!theme){
        theme = useTheme();
    }
    const styles = retStyles(theme)
    const ButtonStyles = retButtonStyles(theme)

    const {gVpWidth, gVpHeight} = useContext(AppContext)

    const [fontSize, setFontSize] = useState((gVpWidth/350)*50)

    const scale = (headerheight) =>{
        setFontSize((gVpWidth/373)*57/2)

        setHeaderHeight(headerheight)
    }

    const [smallFontSize, setSmallFontSize] = useState(20)

    const [menuTrigger, setMenuTrigger] = useState(false)

    const [headerHeight, setHeaderHeight] = useState(10)
    const VH3 = (gVpHeight/100)*3
    const VH10 = (gVpHeight/100)*10

    return(
        <View style={[styles.header, (preview)?{width:'100%', height:headerHeight, marginBottom:0}:null]}
            onLayout={(e) => {
                const { width } = e.nativeEvent.layout;
                let ratio = gVpWidth/width;
                let height = Math.floor(VH10/ratio)
                scale(height)
            }}
        >
            <View style={gVpWidth > 600 ? {flex:1, width:'33%', justifyContent:'space-between'} : {flex: 2, width:'75%', height:'100%', justifyContent:'space-between'}}>
            <View style={{flex:2}}>
                {theme.customImage == '' ?
                    <DynamicText
                        verticalCenter={true}
                        originalWidth={373}
                        originalSize={57}
                        originalHeight={70}
                    style={{
                        textAlign:'left',
                        //textAlignVertical:'center',
                        //justifyContent:'center',
                        //alignItems:'center',
                        //alignContent:'center',
                        fontStyle:'normal',
                        fontFamily: 'BRUSH',
                        paddingLeft:'2vw',
                        height:headerHeight,
                        color:theme.colors.mainColour,
                    }}

                    >SKOREBRD</DynamicText>
                :
                <View>
                    <Image
                        resizeMode={theme.customImageMode}
                        style={{height:'7vh', width:'14vw',maxHeight:'7vh', maxWidth:'14vw'}}
                        source={{
                            uri: theme.customImage
                        }}
                    />
                    <View style={[{flexDirection:'row', height:'25%', maxHeight:'25%', marginBottom:'5%', alignItems:'center', marginLeft:'15%', maxWidth:'100%'}, gVpWidth > 600 ? {marginLeft:'7vw'}:null]}>
                        <DynamicText
                            containerStyle={{height:"100%"}}
                            originalSize={16}
                            minFontSize={(gVpHeight < 400) ? 8 : gVpWidth < 600 ? 10 : 13}
                            originalWidth={'7vw'}
                            originalHeight={'2vh'}
                            style={{height:'2vh', maxWidth:'50%', marginLeft:'0',  color:theme.colors.mainColour, textAlignVertical:'center'}}
                        >
                            {"Powered By "}
                        </DynamicText>
                        <DynamicText
                            containerStyle={{height:"100%"}}
                            originalSize={18}
                            originalWidth={'7vw'}
                            originalHeight={'2vh'}
                            minFontSize={(gVpHeight < 400) ? 9 : gVpWidth < 600 ? 13 : 16}
                            style={{height:'2vh', marginLeft:'0',  color:theme.colors.mainColour, fontFamily:'BRUSH', textAlignVertical:'center'}}
                        >
                        SKOREBRD
                        </DynamicText>
                    </View>
                    {/* <View style={{flexDirection:'row', marginBottom:'1vh'}}>
                        <Text style={{marginLeft:'7vw', color:theme.colors.mainColour, fontSize:20}} adjustsFontSizeToFit={true} numberOfLines={1}>Powered By </Text>
                        <Text style={[{marginLeft:'0', color:theme.colors.mainColour, fontFamily:'BRUSH', fontSize:20}, (gVpWidth > 600 ? {marginTop:'0.25vh'}: null)]} adjustsFontSizeToFit={true} numberOfLines={1}>SKOREBRD</Text>
                    </View> */}
                </View>
                }
            </View>
            </View>

            <View style={{flexDirection:'row', flex:1, maxWidth:'33%'}}>
                <View style={{justifyContent:'center', width:'100%'}}>
                    <DynamicText
                        originalSize={30}
                        originalWidth={'33vw'}
                        originalHeight={"10vh"}
                        verticalCenter={true}
                        minFontSize={11}
                        style={{fontWeight:'bold', height:headerHeight, color:theme.colors.compNameColour, textAlignVertical:'center', alignItems:'center', justifyContent:'center'}}
                    >
                        {name}
                    </DynamicText>
                </View>
            </View>
            <View style={{maxWidth:'33%', minWidth:'33%',flex:1,  marginRight:'2%', justifyContent:'flex-end', alignItems:'flex-end', maxHeight:'100%'}}>
                {gVpWidth > 600 ?
                    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}
                        contentContainerStyle={{flexDirection:'row', justifyContent:'flex-end', marginRight:0, paddingRight:0}}
                    >
                        <View style={{flexDirection:'row',alignItems:'center', height:'100%', justifyContent:'flex-end', paddingRight:'1vw'}}>
                            {buttons}
                        </View>
                    </ScrollView>

                :
                    <View style={{justifyContent:'flex-end', flexDirection:'row', alignItems:'center', height:'100%'}}>
                        <TouchableOpacity
                            onPress={() => setMenuTrigger(true)}
                        >
                            <Entypo name="menu" size={"6vh"} color={theme.colors.mainColour} />
                        </TouchableOpacity>
                    </View>
                }
            </View>
            <Popup
                innerStyle={
                    {
                        maxWidth:(0.8*375),
                    }
                }
                trigger={menuTrigger}
                content={
                    <View style={{alignItems:'center'}}>
                        <Text style={styles.H1}>Menu</Text>
                        {buttons}
                        <View style={{minWidth:'10vw', height:'5vh', marginTop:'1vh'}}>
                            <AppButton
                                containerStyle={ButtonStyles.appButtonContainer}
                                textStyle={[ButtonStyles.appButtonText]}
                                onPress={() => setMenuTrigger(false)}
                                title={'Close'}
                            />
                        </View>

                    </View>
                }
            />
        </View>
    )

}

export default Header;