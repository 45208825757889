import React, { useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, Button, Dimensions, Linking } from 'react-native';
import { Animated, TextInput, TouchableHighlight } from 'react-native-web';

//Firebase
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

//Custom Parts
import AppButton from '../parts/AppButton';
import Popup from '../parts/Popup'
//Custom Style sheets
import retButtonStyles from '../../styles/ButtonStyleSheet';
import { Modal, useTheme } from 'react-native-paper';
import retStyles from '../../styles/MainStyleSheet';
import LoadingModal from '../parts/LoadingModal';
import { AppContext } from '../../AppContextProvider';
import { resendVerification, resetUserPasswordLink } from '../../data';

const auth = getAuth();



const LoginPage = ({ navigation, route }) => {
    const ButtonStyles = retButtonStyles()
    const styles = retStyles()
    const theme = useTheme()


    const [email,setEmail] = useState('');
    const [pass,setPass] =useState('');

    const [invalidLogin, setInvalidLogin] = useState(false)
    const [forgottenPass, setForgotPass] = useState(false)

    const [unverified, setUnverified] = useState(false)
    const [verifyEmail, setVerifyEmail] = useState('')

    const [emailSent, setEmailSent] = useState(false)
    const [emailIssue, setEmailIssue] = useState(false)

    const [loading, setLoading] = useState(false)

    const vpWidth = Dimensions.get('window').width;


    const loginAttempt = async() =>{
        setLoading(true)
        signInWithEmailAndPassword(auth,email,pass)
            .then((userCredential) => {
                const user = userCredential.user;
            })
            .catch((error) => {
                setLoading(false)
                const errorCode = error.code;
                const errorMessage = error.message;
                setInvalidLogin(true)

            });
    }

    const resetForgottenPass = async () =>{
        await resetUserPasswordLink(email)
        setEmailSent(true)
    }

    return (
        <View style={{alignContent:'center', justifyContent:'center', alignItems:'center'}}>
            <View style={[{height:'25vh', marginTop:'30vh'}, (vpWidth < 600) ? {width:'70vw'}:{width:'30vw'}]}>
                <Text style={[styles.headerLogo, (vpWidth < 600) ? {fontSize:'13vw',width:'70vw'}:null]}>{theme.name}</Text>
                <View>
                    <Text style={{fontWeight:'bold'}}>Email:</Text>
                    <TextInput
                        style={[{backgroundColor:'lightgrey', borderRadius:'10px', padding:'5px',paddingLeft:'10px', borderWidth:1}, (invalidLogin) ? ({borderColor:'red'}):({borderColor:'black'})]}
                        onChangeText={(e)=>{setEmail(e)}}
                    />

                </View>
                <View>
                    <Text style={{fontWeight:'bold'}}>Password:</Text>
                    <TextInput
                        style={[{backgroundColor:'lightgrey', borderRadius:'10px', padding:'5px',paddingLeft:'10px', borderWidth:1}, (invalidLogin) ? ({borderColor:'red'}):({borderColor:'black'})]}
                        secureTextEntry={true}
                        onChangeText={(e)=>{setPass(e)}}
                    />
                </View>

                <View style={{marginTop:'2vh', minHeight:'3vh', height:'3vh'}}>
                    <AppButton
                        title={"Login"}
                        containerStyle={ButtonStyles.appButtonContainer}
                        textStyle={ButtonStyles.appButtonText}
                        onPress={()=>loginAttempt()}
                    />
                </View>
                <View style={[{marginTop:'1vh'},(vpWidth < 600) ? {flexDirection:'column'}:{flexDirection:'row', justifyContent:'space-between'}]}>
                    <View style={{alignItems:'center'}}>
                        <TouchableHighlight
                            onPress={() => {setForgotPass(true)}}
                        >
                            <Text style={{color:'grey'}}>Forgot you Password?</Text>
                        </TouchableHighlight>
                    </View>
                    <View style={{alignItems:'center'}}>
                        <TouchableHighlight
                            onPress={() => Linking.openURL("https://www.skorebrd.com/CreateAccount")}
                        >
                            <Text style={{color:'grey'}}>Create an Account</Text>
                        </TouchableHighlight>
                    </View>
                </View>

            </View>
            <Popup
                trigger={forgottenPass}
                innerStyle={{
                    padding:10,
                    width:'30vw',
                    height:'auto',
                    backgroundColor:'white',
                    borderRadius:20,
                    borderColor:'Blue',
                    borderWidth:2,
                }}
                content={
                    <View>
                        {!emailSent ? (
                            <View>
                            <Text style={{marginBottom:'0.5vh', fontWeight:'bold'}}>Email Address:</Text>
                            <TextInput
                                style={[{backgroundColor:'lightgrey', borderRadius:'10px', padding:'5px',paddingLeft:'10px', borderWidth:1}, (emailIssue) ? ({borderColor:'red'}):({borderColor:'black'})]}
                                value={email}
                                onChangeText={(e) => setEmail(e)}
                            />
                            <View style={{flexDirection:'row', justifyContent:'space-around'}}>
                                <View style={{marginTop:'2vh', width:'20vw'}}>
                                    <AppButton
                                        title={'Send Password Reset'}
                                        containerStyle={ButtonStyles.appButtonContainer}
                                        textStyle={ButtonStyles.appButtonText}
                                        onPress={()=>resetForgottenPass()}
                                    />
                                </View>
                                <View style={{marginTop:'2vh', width:'7vw'}}>
                                    <AppButton
                                        title={"Cancel"}
                                        containerStyle={ButtonStyles.appButtonContainer}
                                        textStyle={ButtonStyles.appButtonText}
                                        onPress={() => setForgotPass(false)}
                                    />
                                </View>
                            </View>
                            </View>
                        ):(
                            <View>
                                <Text>Email Sent</Text>
                                <View style={{flexDirection:'row', justifyContent:'space-around'}}>
                                    <View style={{marginTop:'2vh', width:'7vw'}}>
                                        <AppButton
                                            title={"Ok"}
                                            containerStyle={ButtonStyles.appButtonContainer}
                                            textStyle={ButtonStyles.appButtonText}
                                            onPress={() => {setForgotPass(false); setEmailSent(false)}}
                                        />
                                    </View>
                                </View>
                            </View>
                        )}
                    </View>
                }
            />



            <LoadingModal
                trigger={loading}
            />
        </View>

    );
  };

export default LoginPage;