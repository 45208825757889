import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { Animated, TextInput, TouchableHighlight, Text } from 'react-native-web';
import retStyles from '../../styles/MainStyleSheet';


const LoadingModal = ({trigger, customMessage, theme}) =>{

    const styles = retStyles(((theme)?theme:null))


    //#region Loading Animations
    const fadeAnim = useRef(new Animated.Value(1)).current;

    useEffect(()=>{
        let isMounted = true;
        if(isMounted){
            animateLoading()
        }
        return()=>{isMounted=false}
    },[])

    const animateLoading = async() =>{
        Animated.loop(
            Animated.sequence([
                Animated.timing(fadeAnim, {
                    toValue: 0,
                    duration: 5000,
                    useNativeDriver: true
                }),
                Animated.timing(fadeAnim, {
                    toValue: 1,
                    duration: 3000,
                    useNativeDriver: true
                }),
            ])
        ).start();
    }
    //#endregion

    return(
        <Modal
                visible={trigger}
                transparent={false}
                style={{height:'100vh', width:'100vw'}}
            >
                <View style={{justifyContent:'center', alignContent:'center', alignItems:'center', flexDirection:'row', height:'100vh', width:'100vw', backgroundColor:'rgba(255,255,255,0.75)'}}>
                    <View style={{alignContent:'center', alignItems:'center',justifyContent:'center'}}>
                        <Animated.View
                            style={{opacity:fadeAnim}}

                        >
                            <Text style={[styles.H1,{fontSize:48}]}>Loading</Text>
                        </Animated.View>
                        <Text style={{marginTop:'2vh'}}>{customMessage}</Text>
                    </View>
                </View>
            </Modal>
    )
}

export default LoadingModal;