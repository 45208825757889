//#region Imports and Initialization
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, Button, Modal, Dimensions } from 'react-native';
import { Picker, ScrollView, TextInput, TouchableOpacity } from 'react-native-web';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as RootNavigation from '../../RootNavigation';
import QRCode from 'react-native-qrcode-svg';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { db } from '../../fbManage.js';
import { collection, getDocs, addDoc, setDoc ,doc, onSnapshot, updateDoc, query, orderBy, deleteDoc } from 'firebase/firestore';

import AppButton from '../parts/AppButton';
import Popup from '../parts/Popup'

import { isEmpty } from '@firebase/util';
import { useTheme } from 'react-native-paper';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import retStyles from '../../styles/MainStyleSheet';
import Header from '../parts/Header';
import DropDownPicker from 'react-native-dropdown-picker';
import { Entypo } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import LoadingModal from '../parts/LoadingModal'
import AlertPopup from '../parts/AlertPopup';
import { AppContext } from '../../AppContextProvider';
import LeaderboardPart from '../parts/LeaderboardPart';
import DivisionLeaderboards from '../use/DivisionLeaderboards';
import CustomLeaderboards from '../use/CustomLeaderboards';
const iconFile = require("../../assets/small_icon.png")

const Tab = createBottomTabNavigator();
//#endregion

const Finished = ({ navigation, route }) => {
    const useNavRef = React.createRef()
    const tempTheme = useTheme()
    const [theme, setTheme] = useState(tempTheme);
    const {globalOrganisationDets} = useContext(AppContext)


    const ButtonStyles = retButtonStyles()
    const styles = retStyles()
    const {permissionLevel} = useContext(AppContext);
    const [editable, setEditable] = useState(false)
    //#region Initiation of data
    const divisionsDir = "Competitions/"+route.params.compID+"/Divisions"
    const divisionsCollection = collection(db, divisionsDir)
    const teamsDir = "Competitions/"+route.params.compID+"/Teams"
    const teamsCollection = collection(db, teamsDir)
    const competitorsDir = "Competitions/"+route.params.compID+"/Competitors"
    const competitorsCollection = collection(db, competitorsDir)
    const eventDir = "Competitions/"+route.params.compID+"/Events"
    const eventCollection = collection(db, eventDir)

    const [loading, setLoading] = useState(true)
    const [firstLoad, setFirstLoad] = useState(true)
    //Alert trigger uses '' by design
    const [alertTrigger,setAlertTrigger] =useState('');

    const [compDets, setCompDets] = useState({})
    const [competitors, setCompetitors] = useState([])
    const [events, setEvents] = useState([])
    const [teams, setTeams] = useState([])
    const [divisions, setDivisions] = useState([])

    const [printTrigger, setPrintTrigger] = useState(false);
    const [createCustomLeaderboardTrigger, setCreateCustomLeaderboardTrigger] = useState(false);
    const [selectedBoard, setCustomboard] = useState('');
    const [qrTrigger, setQRTrigger] = useState(false)
    //#endregion


    const Scoring = ({route}) =>{
        //#region Initiation of scoring data
        const scoresDir = "Competitions/"+compDets.id+"/Scores"
        const scoresCollection = collection(db, scoresDir)

        const [loadingScoring, setLoadingScoring] = useState(true)

        const [scores, setScores] = useState([])
        const [scoreLen, setScoresLen] = useState()
        //#endregion

        useEffect(()=>{
            let isMounted = true
            onSnapshot(scoresCollection, snapshot=>{
                if(isMounted){
                    setScoresLen(snapshot.docs.length)
                    setScores(snapshot.docs.map(doc=>(
                        {
                            id: doc.id,
                            data:doc.data()
                        }
                    )))
                    if(permissionLevel <=3){
                        setEditable(true)
                    }
                }
            })
            return()=>{isMounted = false}
        },[])

        useEffect(()=>{
            let trigger = true;

            if(scores.length != scoreLen){
                trigger = false
            }

            if(trigger){
                setLoadingScoring(false)
            }
        },[scores])

        const formatScore = (i, t) =>{
            let v = i;

            if(t == 'value'){
                v = parseInt(i.replace(/[^0-9]/g, ''))
                if(isNaN(v)){
                    v=''
                }
            }else if(t == 'time'){
                v = i
            }

            return v
        }

        const convertMillisecondsToString = (ms)=>{
            let hh = Math.floor(ms / (1000*60*60))
            let mm = Math.floor((ms / (1000 * 60)) % 60)
            let ss = Math.floor((ms / 1000) % 60)
            let milliseconds = Math.floor((ms % 1000))

            //#region validate inputs
            //Validate hours
            if(String(hh).length < 2){
                hh = '0'+String(hh)
            }else{
                hh = String(hh)
            }
            //Validate Minutes
            if(String(mm).length < 2){
                mm = '0'+String(mm)
            }else{
                mm = String(mm)
            }
            //Validate Seconds
            if(String(ss).length < 2){
                ss = '0'+String(ss)
            }else{
                ss = String(ss)
            }
            //Validate Milliseconds
            if(String(milliseconds).length < 3){
                if(String(milliseconds).length <2){
                    milliseconds = '00'+String(milliseconds)
                }else{
                    milliseconds = '0'+String(milliseconds)
                }
            }else{
                milliseconds = String(milliseconds)
            }
            //#endregion

            let string =  hh +':'+mm+':'+ss+'.'+milliseconds

            return(string)
        }

        const convertStringToMilliseconds = (s) =>{
            let temp = s.split(':')
            let temp1 = temp[2].split('.')
            let hh = temp[0]
            let mm = temp[1]
            let ss = temp1[0]
            let ms = temp1[1]
            let v = (parseInt(hh.replace(/[^0-9]/g, '')) *60*60*1000) + (parseInt(mm.replace(/[^0-9]/g, ''))*60*1000)+(parseInt(ss.replace(/[^0-9]/g, ''))*1000)+(parseInt(ms.replace(/[^0-9]/g, '')))

            return v;
        }

        const renderScoreInput = (cID,scoreKey, data) => {
            let keys = scoreKey.split('_')
            let eventDets = events[keys[0]]
            let backgroundColourCode = 1;
            if((keys[0]%2)==1){
                //odd row
                backgroundColourCode = 0;
            }
            let SIDets = eventDets.data.ScoreInputs[keys[1]]

            if(SIDets.type == 'time'){
                let defaultValue = '00:00:00.000'
                if(data !='' && data != null){
                    defaultValue = convertMillisecondsToString(data[scoreKey])

                }

                return(
                    <TextInput
                        key={scoreKey}
style={[{width:'15vw',borderColor:'black', borderWidth:'1px', textAlign:'center'}, (backgroundColourCode != 0) ? {backgroundColor:theme.colors.mainColour+"50"} : null]}                        editable={false}
                        defaultValue={defaultValue}
                    />
                )

            }else if(SIDets.type == 'value'){
                let defaultValue = ''
                if(data !='' && data != null){
                    defaultValue = data[scoreKey]
                }
                return(
                    <TextInput
                        key={scoreKey}
                        style={[{width:'15vw',borderColor:'black', borderWidth:'1px', textAlign:'center'}, (backgroundColourCode != 0) ? {backgroundColor:theme.colors.mainColour+"50"} : null]}                        editable={false}
                        defaultValue={defaultValue}
                        onChangeText={(s)=> updateScores(cID,scoreKey,s, SIDets.type)}
                    />
                )
            }
        }

        const renderCompetitorRows = () =>{
            return(
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{maxHeight:'60vh'}}
                >
                        {competitors.map(c =>{
                            let compScores = scores.filter(x=>x.id===c.id)[0]

                            return(
                                <View key={c.id} style={{flexDirection:'row', textAlign:'center'}}>
                                    <Text style={{width:'15vw',borderColor:'black', borderWidth:'1px', paddingVertical:'0.5vh', fontWeight:'bold'}}>{c.data.LastName}, {c.data.FirstName}</Text>
                                    {events.map((e,i)=>{
                                        return(
                                            e.data.ScoreInputs.map((si,ii)=>{
                                                let scoreKey = i+"_"+ii
                                                if(compScores){
                                                    if(compScores.data[scoreKey]){
                                                        return(
                                                            renderScoreInput(c.id, scoreKey, compScores.data)

                                                        )
                                                    }else{
                                                        return(
                                                            renderScoreInput(c.id,scoreKey,'')
                                                        )
                                                    }
                                                }else{
                                                    return(
                                                        renderScoreInput(c.id,scoreKey,'')
                                                    )
                                                }
                                            })
                                        )
                                    })}
                                </View>
                            )
                        })}
                    </ScrollView>
            )
        }

        const renderTeamRows = () =>{
            return(
                <ScrollView
                    showVerticalScrollIndicator={false}
                    style={{maxHeight:'60vh'}}
                >
                        {teams.map(c =>{
                            let compScores = scores.filter(x=>x.id===c.id)[0]

                            return(
                                <View key={c.id} style={{flexDirection:'row', textAlign:'center'}}>
                                    <Text style={{width:'15vw',borderColor:'black', borderWidth:'1px', paddingVertical:'0.5vh'}}>{c.data.Name}</Text>
                                    {events.map((e,i)=>{
                                        return(
                                            e.data.ScoreInputs.map((si,ii)=>{
                                                let scoreKey = i+"_"+ii
                                                if(compScores){
                                                    if(compScores.data[scoreKey]){
                                                        return(
                                                            renderScoreInput(c.id, scoreKey, compScores.data)
                                                        )
                                                    }else{
                                                        return(
                                                            renderScoreInput(c.id,scoreKey,'')
                                                        )
                                                    }
                                                }else{
                                                    return(
                                                        renderScoreInput(c.id,scoreKey,'')
                                                    )
                                                }
                                            })
                                        )
                                    })}
                                </View>
                            )
                        })}
                    </ScrollView>
            )
        }

        const renderTeamWithCompRows = () =>{
            return(
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{maxHeight:'60vh'}}
                >
                        {teams.map(m =>{
                            return(
                            <View key={m.id}>
                                <Text style={{width:'15vw',borderColor:'black', borderWidth:'1px', width:'100%', backgroundColor:'rgba(130,130,130,0.6)', paddingLeft:'0.25vw'}}>{m.data.Name}</Text>
                                {m.data.TeamMembers.map(c=>{
                                    let compScores = scores.filter(x=>x.id===c)[0]
                                    let competitorDets = competitors.filter(x=>x.id===c)[0].data
                                    return(
                                        <View key={c} style={{flexDirection:'row', textAlign:'center'}}>
                                            <Text style={{width:'15vw',borderColor:'black', borderWidth:'1px', paddingVertical:'0.5vh'}}>{competitorDets.LastName}, {competitorDets.FirstName}</Text>
                                            {events.map((e,i)=>{
                                                return(
                                                    e.data.ScoreInputs.map((si,ii)=>{
                                                        let scoreKey = i+"_"+ii
                                                        if(compScores){
                                                            if(compScores.data[scoreKey]){
                                                                return(
                                                                    renderScoreInput(c, scoreKey, compScores.data)
                                                                )
                                                            }else{
                                                                return(
                                                                    renderScoreInput(c,scoreKey,'')
                                                                )
                                                            }
                                                        }else{
                                                            return(
                                                                renderScoreInput(c,scoreKey,'')
                                                            )
                                                        }
                                                    })
                                                )
                                            })}
                                        </View>
                                    )

                                })}

                            </View>
                                )
                        })}
                    </ScrollView>
            )
        }


        if(loadingScoring){
            return(
                <LoadingModal trigger={loadingScoring}/>
            )
        }else{
        return(
            <View
            >
                {/**TABLE */}
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    horizontal={true}
                    style={{marginHorizontal:'1vw'}}
                    contentContainerStyle={{justifyContent:'space-around', flexDirection:'row', minWidth:'100%'}}
                >
                <View style={{borderWidth:'0px', borderColor:'black', marginBottom:'3vh'}} >
                    {/* Header Row */}
                    <View style={{flexDirection:'row', marginLeft:'15vw', borderColor:'black', borderWidth:'0px'}}>
                        {events.map(e=>{
                            return(
                                <View key={e.id} style={{borderColor:'black', borderWidth:'0px',textAlign:'center'}}>
                                    <Text style={{fontWeight:'bold', color:theme.colors.mainColour}}>{e.data.Name}</Text>

                                    <View style={{flexDirection:'row',}}>
                                        {e.data.ScoreInputs.map((si, index) =>{
                                            return(
                                                <Text key={index}
                                                    style={{borderColor:'black', borderWidth:'0px', width:'15vw'}}
                                                >
                                                    {si.name}
                                                </Text>
                                            )
                                        })}
                                    </View>
                                </View>
                            )
                        })}
                    </View>

                    {/**Below will be dependant on TeamsEnabled/ScoringAsIndividual etc. */}
                    {!compDets.data.TeamsEnabled ?
                        (renderCompetitorRows())
                        :
                        (compDets.data.ScoreTeam ?
                                renderTeamRows()
                            :
                                renderTeamWithCompRows()
                        )

                    }
                </View>
                </ScrollView>
            </View>
        )}
    }

    const Leaderboard = ({route}) =>{
        const scoresDir = "Competitions/"+compDets.id+"/Scores"
        const scoresCollection = collection(db, scoresDir)

        const [loadingLeaderboard, setLoadingLeaderboard] = useState(true);
        const [LeaderboardFirstLoad, setLeaderboardFirstLoad] = useState(true);

        const [scores, setScores] = useState([]);
        const [scoreLen, setScoresLen] = useState();
        const [totals, setTotals] = useState({});
        const [teamTotals, setTeamTotals] = useState({});
        const [ranks, setRanks] = useState({});
        const [teamRanks, setTeamRanks] = useState({});

        const [customLeaderboards, setCustomLeaderboards] = useState([]);
        const vpWidth = Dimensions.get('window').width;


        useEffect(()=>{
            let isMounted = true;
            onSnapshot(scoresCollection, snapshot=>{
                if(isMounted){
                    setScoresLen(snapshot.docs.length)
                    setScores(snapshot.docs.map(doc=>(
                        {
                            id: doc.id,
                            data:doc.data()
                        }
                    )))
                }
            })
            onSnapshot(collection(db,"Competitions/"+compDets.id+"/CustomLeaderboards"),snap=>{
                if(isMounted){
                    setCustomLeaderboards(snap.docs.map(doc=>
                        ({id: doc.id,
                        data: doc.data()})
                    ))
                }
            })
            return()=>{isMounted = false}
        },[])

        //#region Checking Loading
        //Initial loading
        useEffect(()=>{
            let trigger = true;
            if(LeaderboardFirstLoad){
                setLeaderboardFirstLoad(false)
                trigger = false;
            }else{
                if(scores.length != scoreLen){
                    trigger = false
                }
                if(!totals){
                    trigger = false
                }
                if(!ranks){
                    trigger=false
                }
                if(!customLeaderboards){
                    trigger =false;
                }
                if(compDets.data.TeamsEnabled){
                    if(!teamTotals){
                        trigger=false;
                    }
                    if(!teamRanks){
                        trigger=false;
                    }
                }
            }

            if(trigger){
                setLoadingLeaderboard(false)
            }
        },[scores, totals, ranks, teamRanks, teamTotals, customLeaderboards])
        //#endregion


        if(!loadingLeaderboard){
            return(
                <View style={{height:'100%', width:'100%'}}>
                    {compDets.data.PublicLeaderboard ?
                        <View style={{width:'5%', height:'5%', marginLeft:'1%'}}>
                            <TouchableOpacity
                                onPress={()=>setQRTrigger(true)}
                            >
                                <AntDesign name="qrcode" size={12} color="black" />
                            </TouchableOpacity>
                        </View>
                    : null}
                    <ScrollView
                        horizontal={true}
                        showVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={(vpWidth > 600)}
                        style={{width:'95%', height:'95%'}}
                        contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}}
                    >
                        <View style={{flexDirection:'row'}}>
                            {!compDets.data.ScoreTeam?
                                <LeaderboardPart
                                    participants={competitors}
                                    compDets={compDets}
                                    events={events}
                                    scores={scores}
                                    name={"Competitors"}
                                    permissionLevel={permissionLevel}
                                    forViewer={false}
                                    competitorList={competitors}
                                    teamList={teams}
                                />
                            :null}
                            {compDets.data.TeamsEnabled ?
                                (compDets.data.ScoreTeam )?
                                (
                                    <LeaderboardPart
                                        participants={teams}
                                        compDets={compDets}
                                        events={events}
                                        scores={scores}
                                        name={"Teams"}
                                        permissionLevel={permissionLevel}
                                        forViewer={false}
                                        isTeamBoard={true}
                                        competitorList={competitors}
                                        teamList={teams}
                                    />
                                ):(
                                    <LeaderboardPart
                                        participants={competitors}
                                        compDets={compDets}
                                        events={events}
                                        scores={scores}
                                        name={"Teams"}
                                        permissionLevel={permissionLevel}
                                        forViewer={false}
                                        isTeamBoard={true}
                                        competitorList={competitors}
                                        teamList={teams}
                                    />
                                )
                            : null}
                            {compDets.data.DivisionsEnabled ?
                                <DivisionLeaderboards
                                competitors={competitors}
                                teams={teams}
                                Divisions={divisions}
                                compDets={compDets}
                                events={events}
                                scores={scores}
                                />
                            :null}
                            <CustomLeaderboards
                                CustomLeaderboards = {customLeaderboards}
                                active = {[customLeaderboards.length > 0]}
                                events={events}
                                scores={scores}
                                compDets={compDets}
                                competitors={competitors}
                                teams={teams}
                                permissionLevel={permissionLevel}
                                forViewer={false}
                                setCreateCustomLeaderboardTrigger={setCreateCustomLeaderboardTrigger}
                                setCustomboard={setCustomboard}
                            />
                            {permissionLevel <= 2 ?
                                <View
                                    style={{width:'25vw', marginLeft:'5vw'}}
                                >
                                    <Text style={{textAlign:'center', fontWeight:'bold', fontSize:'25px', marginBottom:'1vh'}}>Custom Leaderboard</Text>
                                    <View style={{flexDirection:'row', justifyContent:'center', marginBottom:'1vh'}}>
                                        <View style={{width:'20vw', height:'5vh'}}>
                                            <AppButton
                                            title={"Create Leaderboard"}
                                            containerStyle={ButtonStyles.appButtonContainer}
                                            textStyle={ButtonStyles.appButtonText}
                                            onPress={()=>setCreateCustomLeaderboardTrigger(true)}
                                            />
                                        </View>
                                    </View>
                                </View>
                            : null}
                        </View>
                    </ScrollView>
                </View>
            )
        }else{
            return(
                <LoadingModal trigger={loadingLeaderboard}/>
            )
        }
    }

    const Printing = () => {
        const defaultItemList = [
            {label: 'Nothing', value:''},
            {label: 'Competitor', value:'Competitor', selectable:false},
            {label: 'Name', value:'Competitor.Name', parent:'Competitor'},
            {label: 'Age', value:'Competitor.Age', parent:'Competitor'},
            {label: 'Weight', value:'Competitor.Weight', parent:'Competitor'},
            {label: 'Event', value:'Event', selectable:false},
            {label: 'Number', value:'Event.Number', parent:'Event'},
            {label: 'Name', value:'Event.Name', parent:'Event'},
            {label: "Competition", value:'Competition', selectable:false},
            {label: "Name", value:'Competition.Name', parent:'Competition'},
            {label: "Leaderboard", value:'Leaderboard', parent:'Competition'},
            {label: "QR Code", value: 'QR', parent:'Competition'}

        ]
        if(compDets.data.TeamsEnabled){
            defaultItemList.push(
                {label: 'Team Name', value:'Competitor.TeamName', parent:'Competitor'},
                {label: 'Team', value:'Team', selectable:false},
                {label: 'Name', value:'Team.Name', parent:'Team'},
                )
        }
        if(compDets.data.DivisionsEnabled){
            defaultItemList.push(
                {label: 'Division', value:'Competitor.Division', parent:'Competitor'},
                {label: 'Division', value:'Division', selectable:false},
                {label: 'Name', value:'Division.Name', parent:'Division'},

            )
        }
        if(compDets.data.DivisionsEnabled && compDets.data.TeamsEnabled){
            defaultItemList.push(
                {label: 'Division', value:'Team.Division', parent:'Team'}
            )
        }

        const [items, setItems] = useState(defaultItemList);

        const [catagory, setCatagory] = useState(null)

        const [value1, setValue1] = useState(null);
        const [open1, setOpen1] = useState(false);
        const [value2, setValue2] = useState(null);
        const [open2, setOpen2] = useState(false);
        const [value3, setValue3] = useState(null);
        const [open3, setOpen3] = useState(false);
        const [value4, setValue4] = useState(null);
        const [open4, setOpen4] = useState(false);
        const [value5, setValue5] = useState(null);
        const [open5, setOpen5] = useState(false);

        const [firstPrintLoad, setFirstPrintLoad] = useState(true)

        useEffect(()=>{
            if(firstPrintLoad){
                setFirstPrintLoad(false)
            }
        })

        useEffect(()=>{
            if(!firstPrintLoad){
                select(1, value1);
            }
        }, [value1])

        useEffect(()=>{
            if(!firstPrintLoad){
                select(2, value2);
            }
        }, [value2])

        useEffect(()=>{
            if(!firstPrintLoad){
                select(3, value3);
            }
        }, [value3])

        useEffect(()=>{
            if(!firstPrintLoad){
                select(4, value4);
            }
        }, [value4])

        useEffect(()=>{
            if(!firstPrintLoad){
                select(5, value5);
            }
        }, [value5])

        const select = (id,v) =>{
            if(!catagory){
                let cat = items.find(x=>x.value == v)
                let x = cat.parent
                setCatagory(x)
            }

            if(!value1 && !value2 && !value3 && !value4 && !value5){
                setCatagory('')
            }
        }

        const setCompetitorTemplate = () =>{
            setValue1('Competitor.Name');
            if(compDets.data.TeamsEnabled && compDets.data.DivisionsEnabled){
                setValue2('Competitor.TeamName')
                setValue3('Competitor.Division')
            }else if(compDets.data.TeamsEnabled){
                setValue2('Competitor.TeamName')
            }else if(compDets.data.DivisionsEnabled){
                setValue2('Competitor.Division')
            }
        }

        useEffect(async()=>{
            if(!firstPrintLoad){
                let arr = defaultItemList
                if(catagory != ''){
                    arr = arr.filter(x => (x.parent == catagory || x.value == catagory ||x.value == ''))
                }
                setItems(arr)
            }
        }, [catagory])


        return(
            <View>
                <View style={{flexDirection:'row'}}>
                    <Text style={styles.H2}>Templates:</Text>
                    <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                        <AppButton
                            textStyle={ButtonStyles.appButtonText}
                            containerStyle={ButtonStyles.appButtonContainer}
                            title={"Competitor List"}
                            onPress={()=> setCompetitorTemplate()}
                        />
                    </View>
                    {compDets.teamsEnabled ?
                        <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                            <AppButton
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle={ButtonStyles.appButtonContainer}
                                title={"Teams List"}
                                onPress={()=> setPrintTrigger(false)}
                            />
                        </View>
                    : null}
                    <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                        <AppButton
                            textStyle={ButtonStyles.appButtonText}
                            containerStyle={ButtonStyles.appButtonContainer}
                            title={"QR Code"}
                            onPress={()=> setPrintTrigger(false)}
                        />
                    </View>
                </View>
                <Text style={styles.H2}>Print Structure:</Text>
                <View>
                    <Text style={styles.H2}>Header</Text>
                </View>
                <View>
                    <Text style={styles.H2}>Body</Text>
                    <View style={{flexDirection:'row', justifyContent:'space-between', marginLeft:'5%', marginRight:'5%'}}>
                        <View>
                            <DropDownPicker
                                style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                                dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                                listItemContainerStyle={{flexDirection:'row'}}
                                open={open1}
                                value={value1}
                                items={items}
                                setOpen={setOpen1}
                                setValue={setValue1}
                                setItems={setItems}
                            />
                        </View>
                        <View>
                            <DropDownPicker
                                style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                                dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                                listItemContainerStyle={{flexDirection:'row'}}
                                open={open2}
                                value={value2}
                                items={items}
                                setOpen={setOpen2}
                                setValue={setValue2}
                                setItems={()=>setItems}
                            />
                        </View>
                        <View>
                            <DropDownPicker
                                style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                                dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                                listItemContainerStyle={{flexDirection:'row'}}
                                open={open3}
                                value={value3}
                                items={items}
                                setOpen={setOpen3}
                                setValue={setValue3}
                                setItems={()=>setItems}
                            />
                        </View>
                        <View>
                            <DropDownPicker
                                style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                                dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                                listItemContainerStyle={{flexDirection:'row'}}
                                open={open4}
                                value={value4}
                                items={items}
                                setOpen={setOpen4}
                                setValue={setValue4}
                                setItems={()=>setItems}
                            />
                        </View>
                        <View>
                            <DropDownPicker
                                style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                                dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                                listItemContainerStyle={{flexDirection:'row'}}
                                open={open5}
                                value={value5}
                                items={items}
                                setOpen={setOpen4}
                                setValue={setValue4}
                                setItems={()=>setItems}
                            />
                        </View>
                    </View>
                </View>
                <View style={{marginTop:'5vh', justifyContent:'center', width:'100%', flexDirection:'row'}}>
                    <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                        <AppButton
                            textStyle={ButtonStyles.appButtonText}
                            containerStyle={ButtonStyles.appButtonContainer}
                            title={"Print"}
                            onPress={()=> console.log("Print")}
                        />
                    </View>
                </View>

            </View>
        );
    }

    //#region Gathering data
    //Gather initial Data
    useEffect(()=>{
        let isMounted = true
        const docRef = doc(db, "Competitions",route.params.compID)
        onSnapshot((docRef), doc =>{
            setCompDets({id:doc.id,data:doc.data()})
            let curOrg = globalOrganisationDets.find(org => org.id == doc.data().organisation)
            setTheme(curOrg.data.Theme)
        })
        return()=>{isMounted = false}
    },[])

    //Gather the rest of the data on load
    useEffect(()=>{
        let isMounted = true
        if(!firstLoad){
            onSnapshot(competitorsCollection, snapshot=>{
                setCompetitors(snapshot.docs.map(doc=>(
                    {
                        id: doc.id,
                        data:doc.data()
                    }
                )))
            })

            let q = query(eventCollection, orderBy("EventNo"))
            onSnapshot(q, snapshot=>{
                setEvents(snapshot.docs.map(doc=>(
                    {
                        id: doc.id,
                        data:doc.data()
                    }
                )))
            })

            if(compDets.data.TeamsEnabled){
                onSnapshot(teamsCollection, snapshot=>{
                    setTeams(snapshot.docs.map(doc=>(
                        {
                            id: doc.id,
                            data:doc.data()
                        }
                    )))
                })
            }
            if(compDets.data.DivisionsEnabled){
                onSnapshot(divisionsCollection,(snapshot => {
                    setDivisions(snapshot.docs.map(doc=>(
                        {
                            id: doc.id,
                            data:doc.data()
                        }
                    )))
                }))
            }
        }else{
            setFirstLoad(false)
        }
        return()=>{isMounted = false}

    },[compDets])

    useEffect(()=>{
        competitors.sort((a, b) => {
            if(a.data.LastName < b.data.LastName) { return -1; }
            if(a.data.LastName > b.data.LastName) { return 1; }
            return 0;
        })
    },[competitors])

    //#endregion

    //Checking the data has loaded
    useEffect(()=>{
        let trigger = true;
        if(firstLoad){
            trigger = false;
        }else{
            if(compDets.length <1){
                trigger = false
            }
            if(competitors.length <1){
                trigger = false
            }
            if(events.length <1){
                trigger = false
            }
            if(compDets){
                if(compDets.data.TeamsEnabled){
                    if(teams.length <1){
                        trigger = false
                    }
                }
                if(compDets.data.DivisionsEnabled){
                    if(divisions.length <1){
                        trigger = false
                    }
                }
            }
        }
        if(trigger){
            setLoading(false)
        }
    },[compDets, competitors, events, teams, divisions])

    //Sorting events
    useEffect(()=>{
        events.sort((a,b)=>a.data.EventNo-b.data.EventNo)
    },[events])

    //Container for 'Use'
    if(!loading){
        return (
            <NavigationContainer independent={true} ref={useNavRef}>
                <Header
                    buttons={
                        <ScrollView horizontal={true} style={{flexDirection:'row'}} contentContainerStyle={{marginLeft:'auto'}} showsHorizontalScrollIndicator={false}>
                            <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    title={"Home"}
                                    onPress={()=> RootNavigation.navigate('Home')}
                                />
                            </View>

                            {/* <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    title={"Print"}
                                    onPress={() => useNavRef.current.navigate('Printing')}
                                />
                            </View> */}
                        </ScrollView>
                    }
                    name={compDets.data.name}
                />

            {/**Main Screen */}
                <Tab.Navigator screenOptions={{headerShown:false}}>
                    <Tab.Screen options={{unmountOnBlur: true, tabBarIcon:({color,size})=>(
                            <MaterialCommunityIcons name="table" size={size} color={color} />
                        )}} name="Scoring" component={Scoring}/>
                    <Tab.Screen options={{unmountOnBlur: true, tabBarIcon:({ color, size }) => (
                            <MaterialCommunityIcons name="scoreboard" size={size} color={color} />
                        )}} name="Leaderboard" component={Leaderboard}
                    />
                    <Tab.Screen options={{unmountOnBlur: true, tabBarButton: () => null, tabBarVisible: false}} name="Printing" component={Printing}/>
                </Tab.Navigator>

                <Popup
                    trigger={qrTrigger}
                    innerStyle={{
                        padding:10,
                        width:'40%',
                        height:'auto',
                        backgroundColor:'white',
                        borderRadius:20,
                        borderColor:'Blue',
                        borderWidth:2
                    }}
                    content={
                        <View style={{alignItems:'center'}}>
                            <View
                                style={{marginTop:'1vh'}}
                            >
                                <QRCode
                                value={"https://app.skorebrd.com/?viewer="+compDets.id}
                                size={256}
                                logo={iconFile}
                                logoBackgroundColor={"#ffffff"}
                                logoBorderRadius={20}
                                />
                            </View>
                            <View style={{width:'15vw', height:'5vh', marginTop:'2vh'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    title={"Close"}
                                    onPress={()=>setQRTrigger(false)}
                                />
                            </View>
                        </View>
                    }
                />

                <AlertPopup
                    trigger={(alertTrigger != '')}
                    setTrigger={setAlertTrigger}
                    title={alertTrigger.title}
                    alertText={alertTrigger.text}
                />

            </NavigationContainer>

        );
    }else{
        return(
            <LoadingModal
                trigger={loading}
            />
        )
    }
};

export default Finished;