import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, Button, Modal, Dimensions } from 'react-native';
import { CheckBox, Picker, ScrollView, TextInput, TouchableOpacity } from 'react-native-web';
import Checkbox from 'expo-checkbox';

import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as RootNavigation from '../../../RootNavigation';
import TimeField from '../../parts/TimeField.tsx';
import QRCode from 'react-native-qrcode-svg';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import LeaderboardPart from '../../parts/LeaderboardPart';
import EventRankingPart from '../../parts/EventRankingPart'
import CustomLeaderboards from '../CustomLeaderboards';
import DivisionLeaderboards from '../DivisionLeaderboards';
import CustomLeaderboardPopup from '../CustomLeaderboardPopup';
import AppButton from '../../parts/AppButton';

import { db } from '../../../fbManage.js';
import { collection, getDocs, addDoc, setDoc ,doc, onSnapshot, updateDoc, query, orderBy} from 'firebase/firestore';

import { useTheme } from 'react-native-paper';
import retButtonStyles from '../../../styles/ButtonStyleSheet';
import retStyles from '../../../styles/MainStyleSheet';
import LoadingModal from '../../parts/LoadingModal';

const Printing = ({compDets}) => {
    const defaultItemList = [
        {label: 'Nothing', value:''},
        {label: 'Competitor', value:'Competitor', selectable:false},
        {label: 'Name', value:'Competitor.Name', parent:'Competitor'},
        {label: 'Age', value:'Competitor.Age', parent:'Competitor'},
        {label: 'Weight', value:'Competitor.Weight', parent:'Competitor'},
        {label: 'Event', value:'Event', selectable:false},
        {label: 'Number', value:'Event.Number', parent:'Event'},
        {label: 'Name', value:'Event.Name', parent:'Event'},
        {label: "Competition", value:'Competition', selectable:false},
        {label: "Name", value:'Competition.Name', parent:'Competition'},
        {label: "Leaderboard", value:'Leaderboard', parent:'Competition'},
        {label: "QR Code", value: 'QR', parent:'Competition'}

    ]
    if(compDets.data.TeamsEnabled){
        defaultItemList.push(
            {label: 'Team Name', value:'Competitor.TeamName', parent:'Competitor'},
            {label: 'Team', value:'Team', selectable:false},
            {label: 'Name', value:'Team.Name', parent:'Team'},
            )
    }
    if(compDets.data.DivisionsEnabled){
        defaultItemList.push(
            {label: 'Division', value:'Competitor.Division', parent:'Competitor'},
            {label: 'Division', value:'Division', selectable:false},
            {label: 'Name', value:'Division.Name', parent:'Division'},

        )
    }
    if(compDets.data.DivisionsEnabled && compDets.data.TeamsEnabled){
        defaultItemList.push(
            {label: 'Division', value:'Team.Division', parent:'Team'}
        )
    }

    const [items, setItems] = useState(defaultItemList);

    const [catagory, setCatagory] = useState(null)

    const [value1, setValue1] = useState(null);
    const [open1, setOpen1] = useState(false);
    const [value2, setValue2] = useState(null);
    const [open2, setOpen2] = useState(false);
    const [value3, setValue3] = useState(null);
    const [open3, setOpen3] = useState(false);
    const [value4, setValue4] = useState(null);
    const [open4, setOpen4] = useState(false);
    const [value5, setValue5] = useState(null);
    const [open5, setOpen5] = useState(false);

    const [firstPrintLoad, setFirstPrintLoad] = useState(true)

    useEffect(()=>{
        if(firstPrintLoad){
            setFirstPrintLoad(false)
        }
    })

    useEffect(()=>{
        if(!firstPrintLoad){
            select(1, value1);
        }
    }, [value1])

    useEffect(()=>{
        if(!firstPrintLoad){
            select(2, value2);
        }
    }, [value2])

    useEffect(()=>{
        if(!firstPrintLoad){
            select(3, value3);
        }
    }, [value3])

    useEffect(()=>{
        if(!firstPrintLoad){
            select(4, value4);
        }
    }, [value4])

    useEffect(()=>{
        if(!firstPrintLoad){
            select(5, value5);
        }
    }, [value5])

    const select = (id,v) =>{
        if(!catagory){
            let cat = items.find(x=>x.value == v)
            let x = cat.parent
            setCatagory(x)
        }

        if(!value1 && !value2 && !value3 && !value4 && !value5){
            setCatagory('')
        }
    }

    const setCompetitorTemplate = () =>{
        setValue1('Competitor.Name');
        if(compDets.data.TeamsEnabled && compDets.data.DivisionsEnabled){
            setValue2('Competitor.TeamName')
            setValue3('Competitor.Division')
        }else if(compDets.data.TeamsEnabled){
            setValue2('Competitor.TeamName')
        }else if(compDets.data.DivisionsEnabled){
            setValue2('Competitor.Division')
        }
    }

    useEffect(async()=>{
        if(!firstPrintLoad){
            let arr = defaultItemList
            if(catagory != ''){
                arr = arr.filter(x => (x.parent == catagory || x.value == catagory ||x.value == ''))
            }
            setItems(arr)
        }
    }, [catagory])


    return(
        <View>
            <View style={{flexDirection:'row'}}>
                <Text style={styles.H2}>Templates:</Text>
                <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                    <AppButton
                        textStyle={ButtonStyles.appButtonText}
                        containerStyle={ButtonStyles.appButtonContainer}
                        title={"Competitor List"}
                        onPress={()=> setCompetitorTemplate()}
                    />
                </View>
                {compDets.teamsEnabled ?
                    <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                        <AppButton
                            textStyle={ButtonStyles.appButtonText}
                            containerStyle={ButtonStyles.appButtonContainer}
                            title={"Teams List"}
                            onPress={()=> setPrintTrigger(false)}
                        />
                    </View>
                : null}
                <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                    <AppButton
                        textStyle={ButtonStyles.appButtonText}
                        containerStyle={ButtonStyles.appButtonContainer}
                        title={"QR Code"}
                        onPress={()=> setPrintTrigger(false)}
                    />
                </View>
            </View>
            <Text style={styles.H2}>Print Structure:</Text>
            <View>
                <Text style={styles.H2}>Header</Text>
            </View>
            <View>
                <Text style={styles.H2}>Body</Text>
                <View style={{flexDirection:'row', justifyContent:'space-between', marginLeft:'5%', marginRight:'5%'}}>
                    <View>
                        <DropDownPicker
                            style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                            dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                            listItemContainerStyle={{flexDirection:'row'}}
                            open={open1}
                            value={value1}
                            items={items}
                            setOpen={setOpen1}
                            setValue={setValue1}
                            setItems={setItems}
                        />
                    </View>
                    <View>
                        <DropDownPicker
                            style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                            dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                            listItemContainerStyle={{flexDirection:'row'}}
                            open={open2}
                            value={value2}
                            items={items}
                            setOpen={setOpen2}
                            setValue={setValue2}
                            setItems={()=>setItems}
                        />
                    </View>
                    <View>
                        <DropDownPicker
                            style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                            dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                            listItemContainerStyle={{flexDirection:'row'}}
                            open={open3}
                            value={value3}
                            items={items}
                            setOpen={setOpen3}
                            setValue={setValue3}
                            setItems={()=>setItems}
                        />
                    </View>
                    <View>
                        <DropDownPicker
                            style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                            dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                            listItemContainerStyle={{flexDirection:'row'}}
                            open={open4}
                            value={value4}
                            items={items}
                            setOpen={setOpen4}
                            setValue={setValue4}
                            setItems={()=>setItems}
                        />
                    </View>
                    <View>
                        <DropDownPicker
                            style={{marginTop:'0.5vh',borderRadius:'10px', width:'15vw', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                            dropDownContainerStyle={{width:'15vw', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                            listItemContainerStyle={{flexDirection:'row'}}
                            open={open5}
                            value={value5}
                            items={items}
                            setOpen={setOpen4}
                            setValue={setValue4}
                            setItems={()=>setItems}
                        />
                    </View>
                </View>
            </View>
            <View style={{marginTop:'5vh', justifyContent:'center', width:'100%', flexDirection:'row'}}>
                <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                    <AppButton
                        textStyle={ButtonStyles.appButtonText}
                        containerStyle={ButtonStyles.appButtonContainer}
                        title={"Print"}
                        onPress={()=> console.log("Print")}
                    />
                </View>
            </View>

        </View>
    );
}

export default Printing;