import React from 'react';
import { StyleSheet, Text, View, Button } from 'react-native';
import { useTheme } from 'react-native-paper';

const retButtonStyles = (theme) =>{
    if(!theme){
        theme = useTheme()
    }
    const ButtonStyles = StyleSheet.create({
       generalButton:{
           borderRadius:25,
           width:'25%',
           maxWidth:'25%'
       },
       appButtonContainer:{
           elevation: 8,
           backgroundColor: theme.colors.mainColour,
           borderRadius: 30,
           justifyContent:'center',
           paddingHorizontal: 12,
           marginBottom:10,
           height:'100%'
       },

       inputButtonContainer:{
           elevation: 8,
           backgroundColor: theme.colors.mainColour,
           borderRadius: 30,
           paddingVertical: 7,
           paddingHorizontal: 12,
           marginTop:2,
           height:25
       },

       inputButtonText: {
           fontSize: 8,
           color: "rgba(0,0,0,1)",
           fontWeight: "bold",
           alignSelf: "center",
           textTransform: "uppercase"
       },

       appButtonText: {
           //fontSize: 18,
           //color: "rgba(0,0,0,1)",
           color:'white',
           fontWeight: "bold",
           alignSelf: "center",
           justifyContent:'center',
           textTransform: "uppercase"
       },

       headingButtonContainer:{
           elevation:8,
           backgroundColor: theme.colors.mainColour,
           borderRadius:20,
           paddingVertical:10,
           paddingHorizontal:12,

       }

    })
    return ButtonStyles
}


 export default retButtonStyles