import * as crypto from 'crypto';
import { httpsCallable } from 'firebase/functions';
import { cloudFunctions, db } from './fbManage';
import * as CryptoJS from 'crypto-js'

const publicKey =
`-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsvttKb70G+81e8/Zygzi
x2eAvuXWMeGQZ949mclJacl2N36v9zaiFTkfrFSX/AVZBYuaOkXLk+QR2fPWtJ3R
TsuZF5LbkvwN0asKlzzzS5yQWcDcLLgA+Fnk0Z4i9rOHbWFq6IYvzDZKOkOBVmQd
6qe0kQVty4YvE1MaRW+2D+7LOdx4bi82plzJEIu6gvjFB6qSCBsvFC+Cv3u/rbvN
+3WCKvAHxHN9yYwklX+CKnP5KT00t6MPQvlzF/9EB37p8c6EG7VuG9i7j8yG+cSY
ik53KaqIHAgYT64pbFeo6kR2rgJOukhTqvK8NilefQCu4Qn5AiNBULfluvS8K5md
PQIDAQAB
-----END PUBLIC KEY-----`;

export async function encrypt (value){
    const encrypted = crypto.publicEncrypt(publicKey, Buffer.from(value)).toString('base64');
    return(encrypted);
}

export async function createURLString(string){
    const notsosecretkey = 'notsosecretkey';
    const encrypted = CryptoJS.AES.encrypt(string, notsosecretkey).toString();
    const endString = encodeURIComponent(encrypted);
    return endString;
}

export async function deleteCompetition(compID){
    const call = httpsCallable(cloudFunctions, 'deleteCompetitionData')
    const res = await call({id:compID});
    return(res);
}

export async function updateUserLicence(userID, userOrgObj, licenceID, orgID, toggle){
    const obj ={
        userID: userID,
        userOrgObj, userOrgObj,
        licenceID: licenceID,
        orgID: orgID,
        toggle: toggle
    }
    const call =  httpsCallable(cloudFunctions, 'handleUserLicenceChange');
    const res = await call(obj);
    return res;
}

export async function getProduct(productID){
    let obj = {
        productID: productID
    }
    const call = httpsCallable(cloudFunctions, 'getStripeProduct');
    const res = await call(obj);
    return res;
}

export async function getPrice(priceID){
    let obj = {
        priceID: priceID
    }
    const call = httpsCallable(cloudFunctions, 'getStripePrice');
    const res = await call(obj);
    return res;
}

export async function checkout(newLicenceDets, mode){
    let obj = {
        newLicenceDets: {...newLicenceDets},
        mode: mode
    }
    const call = httpsCallable(cloudFunctions, 'checkout');
    const res = await call(obj);
    return res;
}

export async function cancelStripeSubscription(subscriptionID){
    let obj = {
        subscriptionID: subscriptionID
    }
    const call = httpsCallable(cloudFunctions, 'cancelSubscription');
    const res = await call(obj);
    return res;
}

export async function removeUserFromOrg(userID, orgID){
    const call = httpsCallable(cloudFunctions, 'removeUserOrg');
    const data = {userID:userID, orgID:orgID}
    const res = await call(data);
    return res;
}

export async function inviteUserToOrg(email, orgID){
    const call = httpsCallable(cloudFunctions, 'inviteUserToOrganisation');
    const res = await call({
        userEmail: email,
        orgID: orgID
    });
    return res;
}

export async function cancelOrganisationInvite(email, orgID){
    const call = httpsCallable(cloudFunctions, 'cancelUserOrgInvite');
    const res = await call({
        userEmail: email,
        orgID: orgID
    });
    return res;
}

export async function resetUserPasswordLink(email){
    let obj = {
        email: email
    };
    const call = httpsCallable(cloudFunctions, 'sendResetPwdLink');
    const res = await call(obj);
    return res;
}

export async function resendVerification(data){
    const encrypted = await encrypt(JSON.stringify(data));
    const resendEmailVerificaitonEmail = httpsCallable(cloudFunctions, 'resendVerificationEmail');
    const res = await resendEmailVerificaitonEmail(encrypted)
    return res;
}

export async function deleteOrg(data){
    const call = httpsCallable(cloudFunctions, 'deleteOrganisation');
    const res = await call(data);
    return res;
}

export async function testing(){
    const call = httpsCallable(cloudFunctions, 'testFunction');
    const res = await call({});
    return res;
}