import { Text, View, TextInput } from 'react-native';
import { Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import React, { useState, useEffect, useContext } from 'react';

import AppButton from '../../parts/AppButton';

import { Entypo } from '@expo/vector-icons';

import {
    addDoc,
    deleteDoc,
    doc,
    getDocs,
    updateDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
    } from 'firebase/firestore';
import { db } from '../../../fbManage.js';
import retStyles from '../../../styles/MainStyleSheet';
import retButtonStyles from '../../../styles/ButtonStyleSheet';
import { useTheme } from 'react-native-paper';
import LoadingModal from '../../parts/LoadingModal';
import AlertPopup from '../../parts/AlertPopup';
import { AppContext } from '../../../AppContextProvider';


const Events = ({theme, compID, compDets}) => {

    const styles = retStyles(theme)
    const ButtonStyles = retButtonStyles(theme);

    const eventDir = "Competitions/"+compID+"/Events"
    const eventCollection = collection(db, eventDir)

    const [loading, setLoading] = useState(false)
    const [alertTrigger, setAlertTrigger] = useState('')

    const [compEvents, setCompEvents] = useState([]);

    const {gVpWidth} = useContext(AppContext)

    const [eventID, setEventID] = useState('');
    const [selectName, setSelectName] = useState('');
    const [rankSys, setRankSys] = useState('');
    const [siPriority, setSIPriority] = useState('')
    const [globalSIRanking, setGlobalSIRanking] = useState('max')
    const [scoreSys, setScoreSys] = useState('');
    const [scoreInputs, setScoreInputs] = useState([{name:'',type:''}])
    const [numScoreInputs, setNumScoreInputs] = useState(1);
    const [pointsAllocation, setPointsAllocation] = useState([{position:'',value:''}])
    const [numPointsPositions, setNumPointsPosition] = useState(1)

    const [oldObj, setOldObj] = useState({})
    const [eventSaveHighlight, setEventSaveHighlight] = useState(true)

    const [arrowButtonSpace, setArrowButtonSpace] = useState(0)

    useEffect(()=>{
        let isMounted = true
        let q = query(eventCollection, orderBy("EventNo"))
        var unsub_events = onSnapshot(eventCollection, (snapshot)=>{
            let tArr = snapshot.docs.map(doc=>({id:doc.id, data:doc.data()}))
            tArr.sort((a,b)=> a.data.EventNo - b.data.EventNo)
            if(isMounted){
                setCompEvents(tArr)
            }
        })
        return()=>{isMounted = false; unsub_events();}
    },[])

    useEffect(()=>{
        compEvents.sort((a,b)=> a.data.EventNo - b.data.EventNo)
    }, [compEvents])

    useEffect(()=>{
        if(rankSys != 'Combi'){
            changeNumInputs('1')
        }
    },[rankSys])

    useEffect(()=>{
        let nobj = {
            selectName: selectName,
            rankSys: rankSys,
            scoreSys: scoreSys,
            scoreInputs: scoreInputs,
            numScoreInputs: numScoreInputs,
            numPointsPositions: numPointsPositions,
            pointsAllocation: pointsAllocation
        }
        if(JSON.stringify(nobj) == JSON.stringify(oldObj)){
            setEventSaveHighlight(false)
        }else{
            setEventSaveHighlight(true)
        }

    }, [selectName, rankSys, scoreSys,scoreSys,numScoreInputs, scoreInputs, numPointsPositions, pointsAllocation])

    const newEvent = () => {
        setEventID('')
        setSelectName('')
        setRankSys('')
        setScoreSys('')
        setNumScoreInputs(1)
        setScoreInputs([{name:'',type:'', rank:''}])
        setNumPointsPosition(1)
        setPointsAllocation([{position:'',value:''}])
        setSIPriority('')
        setGlobalSIRanking('max')
        setOldObj({})
    }

    const removeEvent = () =>{
        if(eventID){
            deleteDoc(doc(db,eventDir, eventID));
        }
    }

    const selectEvent = (id) =>{
        let data = compEvents.find(x =>x.id === id).data
        setEventID(id)
        setSelectName(data.Name)
        setRankSys(data.RankSys)
        setScoreSys(data.ScoreSys)
        setScoreInputs(data.ScoreInputs)
        setNumScoreInputs(data.ScoreInputs.length)
        setSIPriority(data.SIPriority)
        if(data.GlobalSIRanking){
            setGlobalSIRanking(data.GlobalSIRanking)
        }
        setNumPointsPosition(data.PointsAllocation.length)
        setPointsAllocation(data.PointsAllocation)

        let oobj = {
            selectName: data.Name,
            rankSys: data.RankSys,
            scoreSys: data.ScoreSys,
            scoreInputs: data.ScoreInputs,
            numScoreInputs: data.ScoreInputs.length,
            numPointsPositions: data.PointsAllocation.length,
            pointsAllocation: data.PointsAllocation,
            siPriority: data.SIPriority,
            globalSIRanking: data.GlobalSIRanking
        }
        setOldObj(oobj)

    }

    const saveEvent = async() =>{
        let missedTrigger = false
        let missedField = ''
        for(let i=0;i<scoreInputs.length;i++){
            if(!scoreInputs[i].name){
                missedTrigger = true
                missedField = "Missing a key field: Score Input " + (i+1) +": name"
            }else if(!scoreInputs[i].type){
                missedTrigger = true;
                missedField = "Missing a key field: Score Input " + (i+1) +": type"
            }
            if(siPriority == 'heirachy' || siPriority == 'avgRank' || rankSys == 'Simple'){
                if(!scoreInputs[i].rank){
                    missedTrigger = true
                    missedField = "Missing a key field: Score Input " + (i+1) +": rank"
                }
            }
        }

        if(scoreSys == "PointsPer"){
            for(let i=0;i< pointsAllocation.length;i++){
                if(!pointsAllocation[i].position){
                    missedTrigger = true
                    missedField = "Missing a key field: Points Allocation: " + (i+1) +" position"
                }else if(!pointsAllocation[i].value){
                    missedTrigger = true;
                    missedField = "Missing a key field: Points Allocation: " + (i+1) +" value"

                }
            }
        }

        if(siPriority == 'avg'){
            let typeArr =[]
            for(let x of scoreInputs){
                if(!typeArr.includes(x.type)){
                    typeArr.push(x.type)
                }
            }

            if(typeArr.length > 1){
                missedTrigger = true
                missedField = "When using 'Average' Score Input Priority all score inputs must be of the same type (Time etc.)"
            }
        }

        if(!selectName){
            missedTrigger = true
            missedField = "Missing a key field: Name"
        }
        if(!rankSys){
            missedTrigger = true
            missedField = "Missing a key field: Rank System"
        }
        if(!scoreSys){
            missedTrigger = true
            missedField = "Missing a key field: Score Type"
        }

        if(
            missedTrigger
        ){
            setAlertTrigger({title:'KEY FIELD ERROR', text:missedField})
        }else{
            setLoading(true)
            let obj = {
                Name:selectName,
                RankSys:rankSys,
                ScoreSys:scoreSys,
                ScoreInputs:scoreInputs,
                PointsAllocation:pointsAllocation,
                SIPriority: siPriority,
                GlobalSIRanking: globalSIRanking
            }

            if(eventID != ''){
                //Event is a selected one
                updateDoc(doc(db,eventDir,eventID),obj).then(async()=>{
                    let inputtedScores = await getDocs(collection(db,"Competitions",compID,"Scores"))
                    for(let x of inputtedScores.docs){
                        let keyset = Object.keys(x.data())
                        let resetobj = {};
                        for(let k of keyset){
                            if(k.split("_")[0] == compEvents.indexOf(compEvents.find(e=> e.id == eventID))){
                                resetobj[k] = "";
                            }
                        }
                        await updateDoc(doc(db,"Competitions",compID,"Scores",x.id),resetobj)
                    }
                    setLoading(false)
                })
            }else{
                //Event is new
                obj['EventNo'] = compEvents.length +1
                addDoc(eventCollection,obj).then((res)=>{
                    setEventID(res.id)
                    setLoading(false)
                })

            }
        }
    }

    const changeNumInputs = (e) =>{
        if(e != ''){
            let v = parseInt(e.replace(/[^0-9]/g, ''))
            let arr = scoreInputs;
            if(v!=0){
                while (arr.length < v){
                    arr.push({name:'',type:''})
                }
                while (arr.length > v){
                    arr.pop()
                }
                setScoreInputs(arr)
            }
            //This is for the input box
            setNumScoreInputs(v)

        }else{
            //This is for the input box
            setNumScoreInputs(e)
        }
    }

    const changeNumPointScoringPositions = (e) =>{
        if(e != ''){
            let v = parseInt(e.replace(/[^0-9]/g, ''))
             let arr = pointsAllocation;
            if(v!=0){
                while (arr.length < v){
                    arr.push({position:'',value:''})
                }
                while (arr.length > v){
                    arr.pop()
                }
                setPointsAllocation(arr)
            }
            //This is for the input box
            setNumPointsPosition(v)

        }else{
            //This is for the input box
            setNumPointsPosition(1)
        }
    }

    const changePAValue = (e,i) =>{
        if(e != ''){
            let v = parseInt(e.replace(/[^0-9]/g, ''))
            effectChangePAValue(v,i)

        }else{
            //This is for the input box
            effectChangePAValue(0,i)
        }
    }

    const effectChangePAValue = (e,i) =>{
        let arr = [...pointsAllocation]
        arr[i].position = i+1
        arr[i].value = e
        setPointsAllocation(arr)
    }

    const changeInputDets = (i,t,e) =>{
        let arr = [...scoreInputs]
        if(t == 'n'){
            arr[i].name = e
        }else if(t=='t'){
            arr[i].type = e
        }else if(t=='r'){
            arr[i].rank = e
        }
        setScoreInputs(arr)

    }

    const changeEventOrder = async(direction, amount) =>{
        if(eventID){
            let targetPos;
            let curPos = compEvents.find(x=>x.id==eventID).data.EventNo
            amount = parseInt(amount)
            let changed_events = []
            if(direction == 'U'){
                targetPos =  parseInt(curPos) - parseInt(amount)
            }else if(direction == 'D'){
                targetPos = curPos + amount
            }

            if(targetPos <= 0){
                targetPos = 1
            }else if(targetPos > compEvents.length){
                targetPos = compEvents.length
            }
            if(direction == 'U'){
                changed_events = compEvents.filter(function(x){
                    return(x.data.EventNo >= targetPos && x.data.EventNo <= curPos && x.id != eventID)
                })
            }else if(direction == 'D'){
                changed_events = compEvents.filter(function(x){
                    return(x.data.EventNo <= targetPos && x.data.EventNo >= curPos && x.id != eventID)
                })
            }

            for(let x of changed_events){
                let curEventNo = x.data.EventNo
                if(direction == 'U'){
                    let y = curEventNo +1
                    updateDoc(doc(db,eventDir,x.id),{
                        EventNo: y
                    })
                }else if(direction == 'D'){
                    let y = curEventNo -1
                    updateDoc(doc(db,eventDir,x.id),{
                        EventNo: y
                    })
                }
            }

            updateDoc(doc(db,eventDir,eventID),{
                EventNo: targetPos
            })

        }
    }


    return(
        <View style={[{width:'100%'},gVpWidth > 600 ? {flexDirection:'row', height:'90%'}:{height:'90%', flexDirection:'column'}]}>
            {/*Left side of the screen - List of Events to click and edit */}
            <View style={gVpWidth > 600 ? {width:'44%', marginLeft:'1%'}:{width:'100%', height:'30%', paddingLeft:arrowButtonSpace}}>
                {/** + and - button for adding and removing events */}
                <View style={{flexDirection:'row', justifyContent:'space-between', width:'95%'}}>
                    <Text>Number of Events: {compEvents.length}</Text>
                    <View style={{flexDirection:'row'}}>
                    <TouchableOpacity onPress={()=>{newEvent()}} >
                        <Entypo name="squared-plus" size={24} color="black" />
                    </TouchableOpacity >
                    <TouchableOpacity onPress={()=>{removeEvent()}} >
                        <Entypo name="squared-minus" size={24} color="black" />
                    </TouchableOpacity>
                    </View>
                </View>
                <View style={[{flexDirection:'row', minHeight:'70%', marginTop:'1vh'}, gVpWidth > 600 ?{height:'100%'}:null]}>
                    <ScrollView style={{borderColor:'Grey', borderWidth:1, height:'90%', width:'95%'}}>
                        {/**LIST OF COMPS */}
                        <View>
                            {compEvents.map((event,index) =>{
                                return(
                                    <TouchableOpacity
                                        key={event.id}
                                        onPress={() => selectEvent(event.id)}
                                        style={[(eventID==event.id)?{backgroundColor:theme.colors.mainColour }:{backgroundColor:'none',},styles.selectableList]}
                                    >
                                        <Text style={(eventID==event.id)?{color:'white' }:{color:'black'}}>{event.data.EventNo}. {event.data.Name}</Text>
                                    </TouchableOpacity>
                                )
                            })}
                        </View>
                    </ScrollView>
                    <View
                        onLayout={(e)=>{
                            const { height, width } = e.nativeEvent.layout;
                            setArrowButtonSpace(width)
                        }}
                    >
                        <TouchableOpacity
                            onPress={()=>changeEventOrder("U",1)}
                        >
                            <Entypo name="arrow-bold-up" size={24} color="black" />
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={()=>changeEventOrder("D",1)}
                        >
                            <Entypo name="arrow-bold-down" size={24} color="black" />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

            {/*Right Side of the screen - parameters per event clicked on left */}
            <View style={gVpWidth > 600 ? {width:'54%',paddingLeft:'3%', marginRight:'1%'} : {width:'100%', height:'70%', paddingHorizontal:'5%'}}>
                {eventID ? (
                    <Text style={{fontWeight:"900"}}>{selectName}</Text>)
                :(
                    <Text style={{fontWeight:'900'}}>New Event</Text>
                )
                }

                <ScrollView style={{width:'100%', height:'100%'}} showsVerticalScrollIndicator={false}>
                    <Text>Event Name:</Text>
                    <TextInput
                        value={selectName}
                        onChangeText={setSelectName}
                        style={styles.inputBox}
                    />
                    <Text>Ranking System:</Text>
                    <Picker
                        selectedValue={rankSys}
                        onValueChange={(itemValue) => setRankSys(itemValue)}
                    >
                        <Picker.Item label="" value=""/>
                        <Picker.Item label="Simple" value="Simple"/>
                        <Picker.Item label="Combination" value="Combi"/>
                        {/* <Picker.Item label="Sinclair Coefficient" value="Sinc"/> */}
                    </Picker>


                    <Text style={{fontWeight:"bold"}}>Scoring Input</Text>
                    {/**Only show if Combination scoring selected */}
                    { rankSys=='Combi' ?
                    <View>
                        <Text>Number of Scoring Inputs:</Text>
                        <TextInput
                            style={styles.inputBox}
                            onChangeText={(e)=>changeNumInputs(e)}
                            value={numScoreInputs}
                        />
                        {numScoreInputs > 1 ?
                            <View>
                                <Text>Score Input Priority:</Text>
                                <Picker
                                    selectedValue={siPriority}
                                    onValueChange={(valueChange)=> setSIPriority(valueChange)}
                                >
                                    <Picker.Item label="" value=""/>
                                    <Picker.Item label="Tiebreaker" value="heirachy"/>
                                    <Picker.Item label="Highest" value="high"/>
                                    <Picker.Item label="Lowest" value="low"/>
                                    <Picker.Item label="Average" value="avg"/>
                                    <Picker.Item label="Average Rank" value="avgRank"/>
                                </Picker>

                                {(siPriority != 'heirachy') ?
                                    <View>
                                        <Text>Input Rank System</Text>
                                        <Picker
                                            value={globalSIRanking}
                                            onValueChange={(value) => setGlobalSIRanking(value)}
                                        >
                                            <Picker.Item label='Max' value="max"/>
                                            <Picker.Item label="Min" value="min"/>
                                        </Picker>
                                    </View>
                                : null}
                            </View>
                        : null}
                    </View>
                    :null}
                    {/**END Hidden View */}
                    {/**For Each Scoring Input */}
                    <View style={(numScoreInputs > 1)?{marginLeft:'10%'}:{}}>
                        {scoreInputs.map((input,i) =>{
                            return(
                                <View
                                    key={i}
                                    style={(numScoreInputs > 1)?{marginTop:'2vh'}:{}}
                                >
                                    <Text style={{fontWeight:'200', color:theme.colors.mainColour, fontWeight:'bold'}}>Input {i+1}</Text>
                                    <Text>Name of Scoring Input:</Text>
                                    <TextInput
                                        style={styles.inputBox}
                                        value={input.name}
                                        onChangeText={(e)=>changeInputDets(i,"n",e)}
                                    />
                                    <Text>Type of Scoring Input</Text>
                                    <Picker
                                        selectedValue={input.type}
                                        onValueChange={(itemValue) => changeInputDets(i,"t",itemValue)}
                                    >
                                        <Picker.Item label="" value=""/>
                                        <Picker.Item label="Value" value="value"/>
                                        <Picker.Item label="Time" value="time"/>

                                    </Picker>
                                    {(siPriority == 'heirachy' || siPriority == 'avgRank' || rankSys == 'Simple') ?
                                        <View>
                                            <Text>Input Rank System:</Text>
                                            <Picker
                                                selectedValue={input.rank}
                                                onValueChange={(itemValue) => changeInputDets(i,"r",itemValue)}
                                            >
                                                <Picker.Item label="" value=""/>
                                                <Picker.Item label="Max" value="max"/>
                                                <Picker.Item label="Min" value="min"/>
                                            </Picker>
                                        </View>
                                    : null}
                                </View>
                            )
                        })}
                    </View>

                    {/**End For Each */}
                    <Text style={(numScoreInputs > 1)?{marginTop:'2vh'}:{}}>Scoring Type:</Text>
                    <Picker
                        selectedValue={scoreSys}
                        onValueChange={(itemValue) => setScoreSys(itemValue)}
                    >
                        <Picker.Item label="" value=""/>
                        <Picker.Item label="Points Per Position" value="PointsPer"/>
                        <Picker.Item label="Position" value="Pos"/>
                        <Picker.Item label="Position Inverted - Event" value="PosInvActive"/>
                        <Picker.Item label="Position Inverted - All" value="PosInvTotal"/>
                    </Picker>
                    {scoreSys=='PointsPer' ? (
                        <View>
                            <Text>Number of Scoring Positions:</Text>
                            <TextInput
                                style={styles.inputBox}
                                onChangeText={(e)=>changeNumPointScoringPositions(e)}
                                value={numPointsPositions}
                            />
                            {pointsAllocation.map((pa, i)=>{
                                return(
                                    <View
                                        key={i}
                                        style={{marginTop:'0.5vh', marginLeft:'10%'}}
                                    >
                                        <View style={{flexDirection:'row'}}>
                                            <Text>{i+1}:</Text>
                                            <TextInput
                                                style={[styles.inputBox, {marginLeft:'3%'}]}
                                                value={pa.value}
                                                onChangeText={(e) => changePAValue(e,i)}
                                            />
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    ):null}

                    <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:'2vh'}}>
                        <View style={{minWidth:'49%', height:'5vh'}}>
                            <AppButton
                                title={"Save"}
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle={[ButtonStyles.appButtonContainer, (!eventSaveHighlight) ? {backgroundColor:theme.colors.mainColour+"50"}:null]}
                                onPress={()=>saveEvent()}
                            />
                        </View>
                        <View style={{minWidth:'49%', height:'5vh'}}>
                            <AppButton
                                title={"New Event"}
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle={ButtonStyles.appButtonContainer}
                                onPress={()=>newEvent()}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>
            <LoadingModal
                trigger={loading}
                theme={theme}
            />
            <AlertPopup
                trigger={alertTrigger}
                setTrigger={setAlertTrigger}
                title={alertTrigger.title}
                alertText={alertTrigger.text}
                theme={theme}
            />
        </View>
    );
}

export default Events;