import Checkbox from 'expo-checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { useTheme } from 'react-native-paper';
import { Picker, ScrollView, Text, TextInput } from 'react-native-web';
import { AppContext } from '../../AppContextProvider';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import retStyles from '../../styles/MainStyleSheet';
import AppButton from '../parts/AppButton';



const AddCatagoryPopup = ({trigger, cancelTrigger, submitFunc, theme}) =>{
    if(!theme){
        theme = useTheme()
    }

    const {gVpWidth} = useContext(AppContext)

    const ButtonStyles = retButtonStyles(theme)

    const styles =  retStyles(theme)


    const [name, setName]= useState('')
    const [type, setType] = useState('text');
    const [required, setRequired] = useState(false)
    const [noOptions, setNoOptions] = useState(0);
    const [options, setOptions] = useState([])

    const changeNoOptions = async(e) =>{
        let t = e.replace(/[^0-9]/g, '')
        if(t != ''){
            let v = parseInt(t)
            let arr = options;
            if(v!=0){
                while (arr.length < v){
                    arr.push({name:''})
                }
                while (arr.length > v){
                    arr.pop()
                }
                setOptions(arr)
            }
            //This is for the input box
            setNoOptions(v)

        }else{
            //This is for the input box
            setNoOptions(t)
        }
    }

    const changeOptions = (e,i) =>{
        let arr = options;
        arr[i] = {name: e};
        setOptions(arr)
    }

    const submit = async() =>{
        let expobj = {
            label:name,
            value: name,
            type:type,
            options:options,
            required:required
        }
        submitFunc(expobj)
    }

    return(
        <Modal
            visible={trigger}
            transparent={true}
        >
            <View
                style={popupStyle.container}
            >
                <View
                    style={popupStyle.innerContainerStyle}
                >
                    <View style={[popupStyle.inner, {borderColor:theme.colors.mainColour}, gVpWidth <= 600 ? {width:'80%'}:null]}>
                        <Text style={styles.H1}>Add Attribute</Text>
                        <View style={{flexDirection:'row', marginTop:'1vh'}}>
                            <Text style={{marginRight:'0.5vw'}}>Name:</Text>
                            <TextInput style={styles.inputBox} value={name} onChangeText={(e)=>{setName(e)}}/>
                        </View>
                        <View style={{flexDirection:'row', marginTop:'1vh'}}>
                            <Text style={{marginRight:'0.5vw'}}>Is Required?</Text>
                            <Checkbox
                                value={required}
                                onValueChange={setRequired}
                            />
                        </View>
                        <View style={{flexDirection:'row', marginTop:'1vh'}}>
                            <Text style={{marginRight:'0.5vw'}}>Type:</Text>
                            <Picker
                                selectedValue={type}
                                onValueChange={(itemValue) => setType(itemValue)}
                            >
                                <Picker.Item label={"Text"} value={"text"}/>
                                <Picker.Item label={"Number"} value={"number"}/>
                                <Picker.Item label={"Selection"} value={"select"}/>
                            </Picker>
                        </View>
                        <View style={{height:'45%'}}>
                            {type == 'select' ?
                                <View style={{height:'100%'}}>
                                    <View style={{flexDirection:'row', marginTop:'1vh', marginBottom:'1vh'}}>
                                        <Text style={{marginRight:'0.5vw'}}>Number of Options</Text>
                                        <TextInput style={styles.inputBox} value={noOptions} onChangeText={(e)=>{changeNoOptions(e)}}/>
                                    </View>
                                    <ScrollView style={{height:'50%'}} >
                                        {options.map((x,i)=>{
                                            return(
                                                <View key={i} style={{flexDirection:'row', marginTop:'0.5vh'}}>
                                                    <Text style={{marginLeft:'1vw',marginRight:'0.5vw'}}>Option {i+1} Name:</Text>
                                                    <TextInput style={styles.inputBox} placeholder={x.name} onChangeText={(e)=>{changeOptions(e,i)}}/>
                                                </View>
                                            )
                                        })}
                                    </ScrollView>
                                </View>
                            :null}
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'2vh'}}>
                            <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                title={"Cancel"}
                                containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                                textStyle={ ButtonStyles.appButtonText}
                                onPress={()=>{cancelTrigger(false)}}
                                />
                            </View>
                            <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                title={"Add"}
                                containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                                textStyle={ ButtonStyles.appButtonText}
                                onPress={()=>{submit()}}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </View>

        </Modal>
    )
}

const popupStyle = StyleSheet.create({
    container:{
        width:'100%',
        height:'100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent:'center'
    },

    innerContainerStyle:{
        justifyContent:'center',
        flexDirection:'row'
    },

    inner:{
        padding:'5%',
        width: '60%',
        maxHeight:'50vh',
        height:'50vh',
        backgroundColor:'white',
        borderRadius:20,
        borderWidth:2
    }
})

export default AddCatagoryPopup;