import React, { createRef, useContext, useEffect, useState } from 'react';
import { CheckBox, Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import * as ImagePicker from 'expo-image-picker';

import { Text, View, TextInput, Image, Platform } from 'react-native';

import { Ionicons } from '@expo/vector-icons';

import { AppContext } from "../../../../../AppContextProvider";
import { useTheme } from 'react-native-paper';

import retButtonStyles from '../../../../../styles/ButtonStyleSheet';
import retStyles from '../../../../../styles/MainStyleSheet';
import LoadingModal from '../../../../parts/LoadingModal';
import Popup from '../../../../parts/Popup';
import AppButton from '../../../../parts/AppButton';
import AlertPopup from '../../../../parts/AlertPopup';
import Header from '../../../../parts/Header';
import { doc, onSnapshot, query, updateDoc, where, collection } from 'firebase/firestore';
import { db } from '../../../../../fbManage';
import { validateColour } from '../../../../../styles/colourManagement';
import Checkbox from 'expo-checkbox';
import LicenceRow from '../../../../parts/LicenceRow';
import { createURLString, resendVerification } from '../../../../../data';
import ConfirmPopup from '../../../../parts/confirmPopup';

function OrgLicenceScreen ({theme, orgDets, userOrgDets,setSaveButton}){
    const styles = retStyles(theme);
    const ButtonStyles = retButtonStyles(theme);

    const [licences, setLicences] = useState([])
    const [sort, setSort] = useState({field:'', direction:''})
    const {globalUserDets, userState, gVpWidth} = useContext(AppContext);
    const [unverified, setUnverified] = useState(false)

    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        let isMounted = true;
        if(isMounted){
            let q =  query(collection(db, "Licences"), where("organisationID", "==", orgDets.id));
            var unsub_licenceDets = onSnapshot(q, (snap)=>{
                if(!snap.empty){
                    setLicences([...snap.docs.map(doc=>({id:doc.id, data:doc.data()}))])
                }
            })
        }
        return ()=>{
            isMounted = false;
            unsub_licenceDets();
        }
    },[])

    useEffect(()=>{
        setSaveButton(null)
    },[])

    const sortPress = async (field) =>{
        let dir = 'asc'
        if(sort.field === field){
            dir = sort.direction === 'asc' ? 'desc' : 'asc'
        }
        setSort({field:field, direction:dir})
    }
    useEffect(()=>{
        if(sort.field !== ''){
            let sortedLicences = licences.sort((a,b)=>{
                if(sort.direction === 'asc'){
                    return a.data[sort.field] > b.data[sort.field] ? 1 : -1
                }else{
                    return a.data[sort.field] < b.data[sort.field] ? 1 : -1
                }
            })
            setLicences(sortedLicences)
        }
    }, [sort])

    const sendEmailVerificationEmail = async() =>{
        setLoading(true)
        const res = await resendVerification({
            toEmail:userState.email
        })
        setUnverified(false)
        setLoading(false)
    }

    const addLicence = async() =>{
        if(!userState.emailVerified){
            setUnverified(true);
        }else{
            let obj = {
                orgID: orgDets.id,
                orgName: orgDets.data.name,
                userID: globalUserDets.id,
                personal:false
            }
            let string = await createURLString(JSON.stringify(obj))
            let url = `http://www.skorebrd.com/PurchaseLicences/?data=${string}`;

            if(Platform.OS === 'web'){
                window.open(url, '_blank');
            }else{
                let result = await WebBrowser.openBrowserAsync(url)
            }
        }
    }

    return(
        <View style={gVpWidth > 600 ? {width:'100%'} : {width:"100%", marginTop:"1vh"}}>
            <View style={[{flexDirection:'row', justifyContent:'space-between'}, gVpWidth > 600 ? {width:'65vw'}:{width:'100%'}]}>
                <Text style={styles.H2}>Licences</Text>
                <View>
                    <AppButton
                        title={"Add Licence"}
                        containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                        textStyle={ ButtonStyles.appButtonText}
                        onPress={()=>addLicence()}
                    />
                </View>
            </View>
            <View style={{marginTop:'2.5vh', width:'100%'}}>
                <View style={ gVpWidth > 600 ? {flexDirection:'row'}: {flexDirection:'column'}}>
                    <View style={gVpWidth > 600 ? {width:'10%', height:'100%', alignItems:'center', justifyContent:'center'}:{width:"100%", alignItems:'center', justifyContent:'center'}}>
                        <Text style={{fontWeight:'bold'}}>Sort:</Text>
                    </View>
                    <View style={gVpWidth > 600 ? {width:'90%', flexDirection:'row', justifyContent:'space-evenly'} : {width:'100%'}}>
                        {gVpWidth > 600 ?
                        <>
                            <View>
                                <AppButton
                                    title={"Start Date"}
                                    containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                    textStyle={ ButtonStyles.appButtonText}
                                    onPress={()=>{sortPress('startDate')}}
                                />
                            </View>
                            <View>
                                <AppButton
                                    title={"End Date"}
                                    containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                    textStyle={ ButtonStyles.appButtonText}
                                    onPress={()=>{sortPress('endDate')}}
                                />
                            </View>
                            <View>
                                <AppButton
                                    title={"Count"}
                                    containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                    textStyle={ ButtonStyles.appButtonText}
                                    onPress={()=>{sortPress('count')}}
                                />
                            </View>
                            <View>
                                <AppButton
                                    title={"Applied"}
                                    containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                    textStyle={ ButtonStyles.appButtonText}
                                    onPress={()=>{sortPress('applied')}}
                                />
                            </View>
                            <View>
                                <AppButton
                                    title={"valid"}
                                    containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                    textStyle={ ButtonStyles.appButtonText}
                                    onPress={()=>{sortPress('valid')}}
                                />
                            </View>
                        </>
                        :
                        <>
                            <View style={{flexDirection:'row', justifyContent:'space-evenly'}}>
                                <View>
                                    <AppButton
                                        title={"Start Date"}
                                        containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                        textStyle={ ButtonStyles.appButtonText}
                                        onPress={()=>{sortPress('startDate')}}
                                    />
                                </View>
                                <View>
                                    <AppButton
                                        title={"End Date"}
                                        containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                        textStyle={ ButtonStyles.appButtonText}
                                        onPress={()=>{sortPress('endDate')}}
                                    />
                                </View>
                                <View>
                                    <AppButton
                                        title={"Count"}
                                        containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                        textStyle={ ButtonStyles.appButtonText}
                                        onPress={()=>{sortPress('count')}}
                                    />
                                </View>
                            </View>
                            <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'1vh'}}>
                                <View>
                                    <AppButton
                                        title={"Applied"}
                                        containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                        textStyle={ ButtonStyles.appButtonText}
                                        onPress={()=>{sortPress('applied')}}
                                    />
                                </View>
                                <View>
                                    <AppButton
                                        title={"valid"}
                                        containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                                        textStyle={ ButtonStyles.appButtonText}
                                        onPress={()=>{sortPress('valid')}}
                                    />
                                </View>
                            </View>
                        </>
                        }
                    </View>
                </View>
                {licences.length === 0 ?
                    <View style={{height:'100%', width:'100%', justifyContent:'center', alignItems:'center'}}>
                        <Text ><u>No Licences</u></Text>
                    </View>
                :
                    <ScrollView style={{marginTop:'2vh', width:'100%', height:'100%'}}>
                        {licences.map((licence)=>(
                            <View key={licence.id} style={{marginVertical:'1%'}}>
                                <LicenceRow theme={theme} licence={licence}/>
                            </View>
                        ))}
                    </ScrollView>
                }
            </View>

            <ConfirmPopup
                trigger={unverified}
                setTrigger={setUnverified}
                title={"Unverified Email"}
                alertText={"Please verify your email by clicking the link sent to you.\nIf you think this is an error, please refresh the app.\nWould you like us resend the link?"}
                theme={theme}
                confirmFunction={()=>{sendEmailVerificationEmail()}}
            />

            <LoadingModal
                trigger={loading}
            />
        </View>
    )
}

export default OrgLicenceScreen;