import { isEmpty } from '@firebase/util';
import { doc, getDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import {View, Text, Image, Dimensions} from "react-native";
import { DefaultTheme } from "react-native-paper";

import { useTheme } from 'react-native-paper';
import { db } from '../../fbManage';
import AppButton from '../parts/AppButton';



const ViewerHeader = ({buttons, navRef, theme}) =>{

    const vpWidth = Dimensions.get('window').width;
    const [fontSize, setFontSize] = useState((vpWidth/350)*50)

    const scale = () =>{
        const newWidth = Dimensions.get('window').width;
        setFontSize((newWidth/350)*50)
    }

    return(
        <View style={{
            flexDirection:'row',
            height:'10vh',
            marginBottom:'5vh',
            backgroundColor:theme.colors.headerBackground,
        }} onLayout={()=>scale()}>
            <View style={{flex:2}}>
                {theme.customImage == '' ?
                    <Text
                    style={{
                        textAlign:'left',
                        textAlignVertical:'center',
                        fontStyle:'normal',
                        fontFamily: 'BRUSH',
                        fontSize:(fontSize>60 ? 60 : fontSize),
                        paddingLeft:'2vw',
                        marginVertical:'auto',
                        color:theme.colors.mainColour,
                    }}

                    >{theme.name}</Text>
                :
                <View>
                    <Image
                        resizeMode={theme.customImageMode}
                        style={{height:'7vh', width:'28vw',maxHeight:'7vh', maxWidth:'28vw'}}
                        source={{
                            uri: theme.customImage
                        }}
                    />
                    <View style={{flexDirection:'row', marginBottom:'1vh'}}>
                        <Text style={{marginLeft:'14vw', color:theme.colors.mainColour, fontSize:13}}>Powered By </Text>
                        <Text style={[{marginLeft:'0', color:theme.colors.mainColour, fontFamily:'BRUSH', fontSize:13}, (vpWidth > 600 ? {marginTop:'0.25vh'}: null)]}>SKOREBRD</Text>
                    </View>
                </View>
                }
            </View>
            <View style={{minWidth:'15vw',flex:0.5, flexDirection:'row', justifyContent:'flex-end', marginRight:'2vw',marginTop:'2.5vh'}}>
                <View style={{flexDirection:'row'}}>
                  <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                      <AppButton
                          textStyle={{
                                color:'white',
                                fontWeight: "bold",
                                alignSelf: "center",
                                justifyContent:'center',
                                textTransform: "uppercase"
                            }}
                          containerStyle={{
                                elevation: 8,
                                backgroundColor: theme.colors.mainColour,
                                borderRadius: 30,
                                justifyContent:'center',
                                //paddingHorizontal: 12,
                                marginBottom:10,
                                height:'100%'
                            }}
                          title="Home"
                          onPress={()=>navRef.current.navigate("Input")}
                      />
                  </View>
                </View>
            </View>
        </View>
    )

}

export default ViewerHeader;