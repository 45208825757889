import React, { createRef, useContext, useEffect, useState } from 'react';
import { Text, View, Button, TextInput, Image } from 'react-native';
import { getDoc, doc, collection, getDocs, deleteDoc, setDoc, updateDoc, query, onSnapshot, where, addDoc } from 'firebase/firestore';
import { CheckBox, Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import { cloudFunctions, db } from '../../../../fbManage.js';
import * as RootNavigation from '../../../../RootNavigation';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import Popup from '../../../parts/Popup';
import AppButton from '../../../parts/AppButton';
import Header from '../../../parts/Header';
import AlertPopup from '../../../parts/AlertPopup';

import retButtonStyles from '../../../../styles/ButtonStyleSheet';
import retStyles from '../../../../styles/MainStyleSheet';
import { useTheme } from 'react-native-paper';
import { AppContext } from '../../../../AppContextProvider.js';
import LoadingModal from '../../../parts/LoadingModal.js';
import SelectedOrgComponent from './Organisations.Components/SelectedOrg.Comonent.js';

function OrganisationScreen({setSaveButton}) {
    const [theme, setTheme] = useState(useTheme())
    const {gVpWidth} = useContext(AppContext);
    const styles = retStyles(theme);
    const ButtonStyles = retButtonStyles(theme);
    const {globalUserDets} = useContext(AppContext);
    const {globalOrganisationDets} = useContext(AppContext);
    const [permissionLevel, setPermissionLevel] = useState(99);

    const [selectedOrganisation, setSelectedOrganisation] = useState('');
    const [alertPopupTrigger, setAlertPopupTrigger] = useState('');

    const [loading, setLoading] = useState(false);

    //#region Org Level
    const [movePersonalConf, setMovePersonalConf] = useState('');
    const [movePersonalConfCountdown, setMovePersonalConfCountdown] = useState(0);

    const [createOrgTrigger, setCreateOrgTrigger] = useState(false);
    const [createOrgName, setCreateOrgName] = useState('');
    //#endregion

    useEffect(()=>{
        if(movePersonalConf){
            setMovePersonalConfCountdown(5)
        }
    },[movePersonalConf])

    useEffect(()=>{
        if(movePersonalConf){
            if(movePersonalConfCountdown >0){
                setTimeout(()=>{
                    if(movePersonalConfCountdown > 0){
                        setMovePersonalConfCountdown(movePersonalConfCountdown-1)
                    }
                },1000)
            }
        }
    }, [movePersonalConfCountdown])

    useEffect(()=>{
        if(selectedOrganisation != '' && selectedOrganisation && selectedOrganisation != 'new'){
            if(!globalUserDets.data.Organisations[selectedOrganisation]){
                setPermissionLevel(99)
                setSelectedOrganisation('')
            }else{
                if(typeof globalUserDets?.data?.Organisations[selectedOrganisation]?.role != undefined){
                    setPermissionLevel(globalUserDets.data.Organisations[selectedOrganisation].role)
                }else{
                    setPermissionLevel(99)
                }
            }
        }
    }, [globalUserDets, selectedOrganisation])

    useEffect(()=>{
        setSaveButton(null)
    },[])

    useEffect(()=>{
        if(Object.keys(globalUserDets.data.Organisations).length <= 1){
            setSelectedOrganisation('new')
        }
    }, [globalUserDets])


    const validateNewOrg = () =>{
        if(!createOrgName || createOrgName == ''){
            setAlertPopupTrigger({title:'NAME?', text:'You must enter a name for your organisation'})
        }else if(createOrgName.toLowerCase() == 'new' || createOrgName.toLowerCase() == 'personal'){
            setAlertPopupTrigger({title:'Invalid Name', text:'Sorry, this name is reserved'})
        }else{
            setMovePersonalConf(true)
        }
    }

    const createOrg = async(copy) =>{
        setLoading(true)
        let newOrg ={};

        if(copy){
            newOrg = {
                name: "Personal",
                users: [globalUserDets.id],
                isPersonal: true,
                licenceCount:0,
                PendingInvites: [],
                Theme: {
                  colors: {
                    compNameColour: "#000000",
                    headerBackground: "#a9a9a9",
                    mainColour: "#0000ff",
                  },
                  customImage: "",
                  customImageMode: "contain",
                },
              }
        }else{
            newOrg = {
                name: createOrgName,
                users: [globalUserDets.id],
                isPersonal: false,
                licenceCount:0,
                PendingInvites: [],
                Theme: {
                  colors: {
                    compNameColour: "#000000",
                    headerBackground: "#a9a9a9",
                    mainColour: "#0000ff",
                  },
                  customImage: "",
                  customImageMode: "contain",
                },
              }
        }
        if(copy){
            let oldPersonal = globalOrganisationDets.find(x=> x.data.isPersonal == true)
            let docRef = doc(db, 'Organisations', oldPersonal.id)
            await updateDoc(docRef,{
                active: false,
                isPersonal: false,
                name: createOrgName
            })
        }
        let newDocID;
        await addDoc(collection(db, "Organisations"), newOrg).then(async (orgres)=>{
            let obj = {...globalUserDets.data.Organisations}
            let idArr = [...globalUserDets.data.OrganisationIDs]
            newDocID = orgres.id
            obj[orgres.id]={
                active:false,
                id: orgres.id,
                role:0
            }
            idArr.push(orgres.id)
            await updateDoc(doc(db, 'Users', globalUserDets.id),{
                Organisations:obj,
                OrganisationIDs:idArr
            })
        })

        setSelectedOrganisation(newDocID)
        setMovePersonalConf(false)
        setCreateOrgName('')
        setCreateOrgTrigger(false)
        setLoading(false)
    }




    return(
        <View style={{width:'100%', height: '100%',paddingLeft:'1vw', paddingRight:'1vw'}}>
            <View style={gVpWidth > 600 ?  {marginLeft:'2vw', flexDirection:'row', height:'4vh', alignItems:'center', height:'10%'} : {marginLeft:'2vw', flexDirection:'column', height:'15%'}}>
                <Text style={[styles.H1, {color:theme.colors.mainColour}]}>Organisation Settings</Text>
                {(Object.keys(globalUserDets.data.Organisations).length > 1) ?
                    <Picker style={gVpWidth >= 600 ? {width:'10vw', marginLeft:'5vw', height:'25px'}:{ marginTop:'1vh', width:'60%'}}
                        selectedValue={selectedOrganisation}
                        onValueChange={(itemValue) => setSelectedOrganisation(itemValue)}
                    >
                        <Picker.Item label="" value="" />
                        {globalUserDets.data.OrganisationIDs.map(o=>{
                            //let org = globalOrganisationDets.find(x=> (x.id == o && globalUserDets.data.Organisations[o].role <= 1))
                            let org = globalOrganisationDets.find(x=> (x.id == o))

                            if(org){
                                if(!org.data.isPersonal){
                                    return(
                                        <Picker.Item label={org.data.name} value={org.id} key={org.id}/>
                                    )
                                }
                            }
                        })}
                        <Picker.Item label={"New"} value={"new"}/>
                    </Picker>
                :null
               }
            </View>
            <View style={gVpWidth > 600 ? {height:'90%'}:{height:'80%'}}>
                {(selectedOrganisation == 'new') ?
                    (
                    <View style={{width:'100%', height:'100%', paddingLeft:'2vw', paddingRight:'2vw', alignItems:'center', justifyContent:'center'}}>
                        <View style={{alignContent:'center'}}>
                            <Text style={[styles.H2,{marginHorizontal:'auto', textAlign:'center'}]}>Want to create a new organisation?</Text>
                            <View style={{marginHorizontal:'auto'}}>
                                <View style={{minWidth:'25vw', height:'5vh', marginTop:'2vh'}}>
                                    <AppButton
                                        textStyle={ButtonStyles.appButtonText}
                                        containerStyle = {[ButtonStyles.appButtonContainer]}
                                        title="Create Organisation"
                                        onPress={()=>setCreateOrgTrigger(true)}
                                    />
                                </View>
                            </View>
                            <Text style={{marginTop:'2vh', marginHorizontal:'auto', textAlign:'center'}}>If you would like to join an existing organisation, you need an admin to invite you.</Text>
                        </View>
                    </View>
                    )
                : (selectedOrganisation!='') ?
                    <View>
                        <SelectedOrgComponent orgID={selectedOrganisation} orgDets={globalOrganisationDets.find(x=>x.id==selectedOrganisation)} setSaveButton={setSaveButton} permissionLevel={permissionLevel} setSelectedOrg={setSelectedOrganisation}/>
                    </View>
                :null }
            </View>
            <Popup
                trigger={createOrgTrigger}
                innerStyle={{
                    padding:10,
                    width:(gVpWidth > 600 ) ? '40%' : "90%",
                    height:'auto',
                    backgroundColor:'white',
                    borderRadius:20,
                    borderColor:theme.colors.mainColour,
                    borderWidth:2
                }}
                content={
                    <View>
                        <Text style={styles.H1}>NEW ORGANISATION</Text>
                        <View style={{flexDirection:'row', marginRight:'25%', maxHeight:'3vh', width:'50%', marginTop:'0.5vh'}}>
                            <Text>Name:</Text>
                            <TextInput
                                onChangeText={(e)=>{setCreateOrgName(e)}}
                                value={createOrgName}
                                style={{marginLeft:'1vw', borderColor:'grey', borderWidth:1, borderRadius:'5px', paddingLeft:'2%'}}
                            />
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-evenly', width:'100%'}}>
                            <View style={{minWidth:'10vw', height:'5vh', marginTop:'2vh'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle = {[ButtonStyles.appButtonContainer]}
                                    title="Create"
                                    onPress={()=>validateNewOrg()}
                                />
                            </View>
                            <View style={{minWidth:'10vw', height:'5vh', marginTop:'2vh'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle = {[ButtonStyles.appButtonContainer]}
                                    title="Cancel"
                                    onPress={()=>setCreateOrgTrigger(false)}
                                />
                            </View>
                        </View>
                    </View>
                }
            />
            <Popup
                trigger={(movePersonalConf)}
                innerStyle={{
                padding:10,
                width:(gVpWidth > 600 ) ? '40%' : "90%",
                height:'auto',
                backgroundColor:'white',
                borderRadius:20,
                borderColor:theme.colors.mainColour,
                borderWidth:2
                }}
                content={
                <View style={{justifyContent:'center', alignItems:'center'}}>
                    <Text style={styles.H1}>COPY PERSONAL DATA?</Text>
                    <Text>Would you like to copy all of your personal competitions and licences to this new organisation?</Text>
                    <Text>This is <b>CANNOT</b> be done later and <b>CANNOT</b> be undone!</Text>
                    <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3vh'}}>
                    <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                        <AppButton
                        title={(movePersonalConfCountdown > 0) ? `${movePersonalConfCountdown}` : "Yes"}
                        containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                        textStyle={ ButtonStyles.appButtonText}
                        onPress={()=>{if(movePersonalConfCountdown <= 0){createOrg(true)}}}
                        />
                    </View>
                    <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                        <AppButton
                        title={(movePersonalConfCountdown > 0) ? `${movePersonalConfCountdown}` : "No"}
                        containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                        textStyle={ ButtonStyles.appButtonText}
                        onPress={()=>{if(movePersonalConfCountdown <= 0){createOrg(false)}}}
                        />
                    </View>
                    </View>
                </View>
                }
            />
            <AlertPopup
                trigger={(alertPopupTrigger != '')}
                setTrigger={setAlertPopupTrigger}
                title={alertPopupTrigger.title}
                alertText={alertPopupTrigger.text}
            />
            <LoadingModal
                trigger={loading}
                theme={theme}
            />
        </View>
    )
}

export default OrganisationScreen;