import { StyleSheet, Text, View } from 'react-native';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {navigationRef} from './RootNavigation.js';
import { useTheme } from 'react-native-paper';

import HomeScreen from './components/HomeScreen/HomeScreen.component.js';
import CreateCompetition from './components/create/CreateCompetition.component.js';
import SettingsScreen from './components/HomeScreen/SettingsScreen.component.js';
import Viewer from './components/viewer/Viewer.component.js';
import Use from './components/use/Use.Component.js';
import LoginPage from './components/login/LoginPage.js';
const Stack = createNativeStackNavigator();
import { getAuth, onAuthStateChanged} from 'firebase/auth';

import AppContextProvider, { AppContext } from './AppContextProvider.js';
import LoadingModal from './components/parts/LoadingModal.js';
import * as Linking from 'expo-linking'
import { isEmpty } from '@firebase/util';
import Finished from './components/Finished/Finished.Component.js';

const auth = getAuth()

export default function App() {
  const [userState, setUserState] = useState(null)
  const [loading, setLoading] = useState(true)

  const [initParams, setInitParams] = useState({})
  const theme = useTheme()

  onAuthStateChanged(auth, (user)=>{
    if(user){
      setUserState(user);
    }else{
      setUserState(null);
      setLoading(false);
    }
  })

  useEffect(()=>{
    Linking.getInitialURL().then(x=>{
      let arr = x.split("?")
      if(arr.length > 1){
        let queries = arr[1].split("&")
        queries.map(q=>{
          let key = q.split("=")[0]
          let v = q.split("=")[1]
          if(v == undefined || v == null){
            v='true'
          }
          let currentObj = initParams;
          currentObj[key] = v;
          setInitParams(currentObj)
        })
      }
    })
  }, [])


  useEffect(()=>{
    if(userState){
      setLoading(false)
    }
  },[userState])

  return (
    <AppContextProvider>
      <NavigationContainer ref={navigationRef}>
        <Stack.Navigator
          initialRouteName={"Home"}
          screenOptions={{
            headerShown: false
          }}

        >
        {!loading ? (
          !userState ?(
            (isEmpty(initParams.viewer) && initParams.viewer!= 'true')?
            <>
              <Stack.Screen name="Login" component={LoginPage} />
            </>
            :(
              <Stack.Screen name="Viewer" component={Viewer} initialParams={{code:initParams.viewer}}/>
            )
          ):(
            isEmpty(initParams.viewer)?
              <>
              <Stack.Screen name="Home" component={HomeScreen} options={{unmountOnBlur:true}}/>
              <Stack.Screen name="create" component={CreateCompetition} options={{unmountOnBlur:true}}/>
              <Stack.Screen name="use"component={Use} options={{unmountOnBlur:true}}/>
              <Stack.Screen name="settings" component={SettingsScreen} options={{unmountOnBlur:true}}/>
              <Stack.Screen name="Finished"component={Finished} options={{unmountOnBlur:true}}/>
              </>
            :(
              <Stack.Screen name="Viewer" component={Viewer} initialParams={{code:initParams.viewer}}/>
            )
          )

        ):(
          <Stack.Screen name="Loading" component={LoadingModal}/>
        )}

        </Stack.Navigator>
      </NavigationContainer>
    </AppContextProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
