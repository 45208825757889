import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { useTheme } from 'react-native-paper';
import { Animated, TextInput, TouchableHighlight, Text } from 'react-native-web';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import retStyles from '../../styles/MainStyleSheet';
import AppButton from './AppButton';
import Popup from './Popup';


const NoInternetPopup = ({trigger,}) =>{
    const styles = retStyles()
    const ButtonStyles = retButtonStyles()
    const theme = useTheme()


    return(
        <Popup
            trigger={trigger}
            innerStyle={{
            padding:10,
            width:'40%',
            height:'auto',
            backgroundColor:'white',
            borderRadius:20,
            borderColor:theme.colors.mainColour,
            borderWidth:2
            }}
            content={
                <View style={{justifyContent:'center', alignItems:'center'}}>
                    <Text style={styles.H1}>Oh No! No Internet Connection</Text>
                    <Text>
                        You need to have an active internet connection to use this app.
                    </Text>
                </View>
            }
        />
    )
}

export default NoInternetPopup;