import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Modal, Dimensions } from "react-native";
import { Animated, TextInput, TouchableHighlight, Text, ScrollView, TouchableOpacity } from 'react-native-web';
import retStyles from '../../styles/MainStyleSheet';
import LoadingModal from './LoadingModal';
import { async, isEmpty } from '@firebase/util';
import { Entypo } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { useTheme } from 'react-native-paper';
import { db } from '../../fbManage.js';
import { deleteDoc, doc } from 'firebase/firestore';
import Popup from './Popup';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import AppButton from './AppButton';



const EventRankingPart = ({name, event, events, compDets, scores, competitorList, teamList, divisions}) =>{


    let theme = useTheme()
    let styles = retStyles()
    let ButtonStyles = retButtonStyles()

    const [loading, setLoading] = useState(true);
    const [rowdata, setRowData] = useState({})
    const [empty, setEmpty] = useState(false)

    const [leaderboardFontSize, setLeaderboardFontSize] = useState(25)
    const vpWidth = Dimensions.get('window').width;

    useEffect(async()=>{
        await calcScoreTotals()
        setLoading(false)
    }, [event, scores, competitorList,teamList])

    useEffect(async ()=>{
        let trigger = true
        if(rowdata){
            let keys =Object.keys(rowdata)
            if(keys.length < 1){
                //No Scores
                trigger = false
            }else{
                for(let x of keys){
                    if(Object.keys(rowdata[x]).length < 1){
                        trigger = false
                    }
                }
            }
        }else{
            //No Scores
            trigger = false
        }
        if(!trigger){
            //Empty
            setEmpty(true)
        }else{
            setEmpty(false)
        }
    },[rowdata])

    const calcScoreTotals = async()=>{
        let eventParticipantIDs = []
        let participants = []
        if(compDets.data.ScoreTeam){
            participants = teamList
            eventParticipantIDs = teamList.map(x=> x.id)
        }else{
            participants = competitorList
            eventParticipantIDs = competitorList.map(x=> x.id)
        }

        let tempTotals = {};
        let participantDivisions = [];

        if(compDets.data.IncludeNullDivs){
            participantDivisions.push('none')
        }

        if(compDets.data.DivisionsEnabled){
            for(let x of participants){
                if(!compDets.data.TeamDivs || compDets.data.ScoreTeam){
                    let id = x.data.Division
                    if(!participantDivisions.includes(id)){
                        participantDivisions.push(id)

                    }
                }else{
                    if(x.data.Team){
                        let team = teamList.find(t=>(t.id == x.data.Team))

                        if(team){
                            if(team.data.Division){
                                participantDivisions.push(team.data.Division)
                            }
                        }
                    }
                }
            }
        }

        participantDivisions = participantDivisions.filter(x=> (x))

        if(compDets.data.DivisionsEnabled){
            for(let x of participantDivisions){
                if(x){
                    let cList = [];
                    if(compDets.data.TeamDivs && !compDets.data.ScoreTeam){
                        cList = competitorList.filter(y =>{
                            if(y.data.Team){
                                let team = teamList.find(x=>(x.id == y.data.Team))
                                if(team.data.Division){
                                    if(team.data.Division == x){
                                        return(true)
                                    }else{
                                        return(false)
                                    }
                                }{
                                    if(x == 'none'){
                                        return(true)
                                    }else{
                                        return(false)
                                    }
                                }
                            }else{
                                return(false)
                            }
                        })
                    }else if(!compDets.data.TeamDivs && !compDets.data.ScoreTeam){
                        if(x == 'none'){
                            cList = competitorList.filter(y => {
                                if(!y.data.Division || y.data.Division == ''){
                                    return true;
                                }else{
                                    return false;
                                }
                            })
                        }else{
                            cList = competitorList.filter(y=>(y.data.Division == x))
                        }
                    }else if(compDets.data.ScoreTeam){
                        if(x == 'none'){
                            cList = teamList.filter(t=>{
                                if(!t.data.Division || t.data.Division == ''){
                                    return(true)
                                }else{
                                    return(false)
                                }
                            })
                        }else{
                            cList = teamList.filter(t => (t.data.Division == x))
                        }
                    }
                    let scores = await calcTotals(cList);


                    if(scores){
                        let s_keys = Object.keys(scores);
                        for(let i of s_keys){
                            if(eventParticipantIDs.includes(i)){
                                tempTotals[i] = scores[i];
                            }
                        }
                    }
                }
            }
        }else{
            if(!compDets.data.ScoreTeam){
                tempTotals = await calcTotals(competitorList)
            }else{
                tempTotals = await calcTotals(teamList)
            }
        }

        let TotalObject = {}
        if(compDets.data.DivisionsEnabled){
            for(let div of participantDivisions){
                if(!TotalObject[div]){
                    TotalObject[div] = [];
                }
                let divCompetitors;
                if(compDets.data.ScoreTeam){
                    divCompetitors = teamList.filter(t=>(t.data.Division == div || (div == 'none' && (t.data.Division == '' || !t.data.Division)) ))
                }else{
                    divCompetitors = competitorList.filter(c=>(c.data.Division == div || (div == 'none' && (c.data.Division == '' || !c.data.Division))))
                }
                let divPoints = []
                for(let c of divCompetitors){
                    if(tempTotals[c.id]){
                        divPoints.push(tempTotals[c.id])
                    }
                }
                if(compDets.data.LeaderboardRanking == 'Max'){
                    divPoints.sort((a,b)=>(b-a))
                }else{
                    divPoints.sort((a,b)=>(a-b))
                }
                //rank scores
                for(let c of divCompetitors){
                    if(tempTotals[c.id]){
                        let pos = divPoints.indexOf((tempTotals[c.id]))
                        pos += 1;
                        TotalObject[div].push({id: c.id ,points: tempTotals[c.id], rank: pos})
                    }
                }
            }
        }else{
            let participants = []
            if(compDets.data.ScoreTeam){
                participants = [...teamList]
            }else{
                participants = [...competitorList]
            }
            let points = []
            for(let c of participants){
                if(tempTotals[c.id]){
                    points.push(tempTotals[c.id])
                }
            }
            if(compDets.data.LeaderboardRanking == 'Max'){
                points.sort((a,b)=>(b-a))
            }else{
                points.sort((a,b)=>(a-b))
            }
            //rank scores
            TotalObject['All'] = []
            for(let c of participants){
                if(tempTotals[c.id]){
                    let pos = points.indexOf((tempTotals[c.id]))
                    pos += 1;
                    TotalObject['All'].push({id: c.id ,points: tempTotals[c.id], rank: pos})
                }
            }
        }

        for(let x in TotalObject){
            TotalObject[x] = TotalObject[x].sort((a,b)=>(a.rank -b.rank))
        }
        setRowData(TotalObject)
    }

    const calcTotals = async(eventParticipants) =>{
        let participantList = eventParticipants
        let eventCompetitor_ranks = {};
        let tempTotals ={};

        //build ranks object
        let eID = event.id;
        if(!eventCompetitor_ranks[eID]){
            eventCompetitor_ranks[eID] = {}
        }

        for(let t of participantList){
            let tID = t.id
            if(!eventCompetitor_ranks[eID][tID]){
                eventCompetitor_ranks[eID][tID] = ''
            }
            eventCompetitor_ranks[eID][tID] = '';
        }



        //figure out and input ranks into rank object
        let eventScores = [];

        //Get scores for current event
        let validKeys = [];
        for(let k of participantList){
            validKeys.push(k.id)
        }
        let filteredscores = scores.filter(s=>(validKeys.includes(s.id)))
        for(let c of filteredscores){
            //obj = {competitor.id:{c.data[s]}}
            let obj = {[c.id]:{}};
            for(let s in c.data){
                if(c.data[s]){
                    if(events.indexOf(event) == s.split("_")[0]){
                        obj[c.id][s.split("_")[1]] = c.data[s]
                    }
                }
            }
            //CompetitorID:{0:value,1:value}
            if(!isEmpty(obj[c.id])){
                eventScores.push(obj)
            }
        }

        //eventScore example
        //[...., {competitorID}:{scoreInput:Value}]
        //[..., {egjusbb143bhv34uv4:{0:12, 1:15}}]

        //Determine rankings for the current event
        for(let x = 0; x < eventScores.length; x++){
            let competitor1 = eventScores[x];
            let position = 1;
            let equalTrigger = false;
            let i = 0;
            while(i < eventScores.length && equalTrigger!=true){
                let competitorToJudge = eventScores[i]
                if(JSON.stringify(competitor1) != JSON.stringify(competitorToJudge)){
                    let res = await orderScores(competitor1,competitorToJudge, event, eventScores);
                    if(res ==-1){
                        position += 1;
                    }

                }
                i++;
            }
            let compScores = eventScores[x]
            let competitorID = Object.keys(compScores)[0]
            eventCompetitor_ranks[event.id][competitorID] = position
        }

        //At this point we have rankings for current event... need to apply scoring system
        //Apply totals to a competitor level
        for(let c of participantList){
            let counted_results = []
            for(let e in eventCompetitor_ranks){
                let eRank = eventCompetitor_ranks[e][c.id]
                if(compDets.data.CountTopScores){
                    if(compDets.data.CountTopScores == counted_results.length){
                        //Check if new rank is higher than any of counted_results... replace lowest one
                        let min = 0;
                        let loc = 0;
                        for(let y in counted_results){
                            let x = counted_results[y]
                            let key = Object.keys(x)[0]
                            if(x[key] > min || x[key]==''){
                                min = x[key]
                                loc = y
                            }
                        }
                        if(eRank != ''){
                            if(min > eRank){
                                counted_results.splice(loc,1)
                                counted_results.push({[e]:eRank})
                            }
                        }
                    }else{
                        if(eRank != ''){
                            counted_results.push({[e]:eRank})
                        }
                    }
                }else{
                    if(eRank != ''){
                        counted_results.push({[e]:eRank})
                    }
                }
            }

            for(let r in counted_results){
                let obj = counted_results[r];
                let eventKey = Object.keys(obj)[0];
                let eventDets = events.find(x=> x.id==eventKey);
                eventDets = eventDets.data;
                let v2Add = 0;
                if(eventDets.ScoreSys == 'Pos'){
                    v2Add = obj[eventKey]
                    if(tempTotals[c.id]){
                        tempTotals[c.id] = tempTotals[c.id] + obj[eventKey]
                    }else{
                        tempTotals[c.id] = obj[eventKey]
                    }
                }else if(eventDets.ScoreSys == 'PosInvActive'){
                    let len = 0;
                    for(let x in eventCompetitor_ranks[eventKey]){
                        if(eventCompetitor_ranks[eventKey][x] != ""){
                            len =len+ 1;
                        }
                    }
                    let score = len - (obj[eventKey]-1);
                    v2Add = score;
                    if(tempTotals[c.id]){
                        tempTotals[c.id] = tempTotals[c.id] + score
                    }else{
                        tempTotals[c.id] = score
                    }
                }else if(eventDets.ScoreSys == 'PosInvTotal'){
                    let len = eventParticipants.length;
                    let score = len - (obj[eventKey]-1)
                    v2Add = score;
                    if(tempTotals[c.id]){
                        tempTotals[c.id] = tempTotals[c.id] + score
                    }else{
                        tempTotals[c.id] = score
                    }
                }else if(eventDets.ScoreSys == 'PointsPer'){
                    let pos = obj[eventKey];
                    let pointsAllocation = eventDets.PointsAllocation.filter(x=> x.position == pos);
                    if(pointsAllocation[0]){
                        let v = parseInt(pointsAllocation[0].value)
                        v2Add = v
                        if(!tempTotals[c.id]){
                            tempTotals[c.id] = v
                        }else{
                            tempTotals[c.id] = tempTotals[c.id] + v
                        }
                    }
                }

                /* if(isTeamBoard){
                    if(!compDets.data.ScoreTeam && !compDets.data.TeamDivs){
                        addToTeamScore(c.data.Team,v2Add)
                    }
                } */
            }
        }
        return(tempTotals)
    }

    const compareValues = async(a,b,type) =>{
        if(type == "max"){
            if(a>b){
                return 1
            }else if(a<b){
                return -1
            }else{
                return 0
            }
        }else if(type=="min"){
            if(a<b){
                return 1
            }else if(a>b){
                return -1
            }else{
                return 0
            }
        }
    };

    const orderScores = async(a,b, ev, eventScores) => {
        //a,b are competitors being compared
        let outcome = ''
        let i = 0

        if(a == undefined || isEmpty(a) || a == ""){
            if(b!=undefined && !isEmpty(b)){
                //return b is higher
                outcome = -1
            }
        }
        if(b==undefined || isEmpty(b) || b == ""){
            //return a is higher
            outcome = 1
        }

        if(ev.data.SIPriority == 'heirachy' || !ev.data.SIPriority){
            while(outcome == '' && i < ev.data.ScoreInputs.length){

                let type = ev.data.ScoreInputs[i].rank
                let aKey = Object.keys(a)[0]
                let bKey = Object.keys(b)[0]
                let aScores = a[aKey][i];
                let bScores = b[bKey][i];

                let resp = await compareValues(aScores,bScores,type);
                if(resp == 0 && i < (ev.data.ScoreInputs.length-1)){
                    outcome = ''
                }else if(resp == 0 && i == (ev.data.ScoreInputs.length-1)){
                    outcome = 0
                }
                if(resp == 1){
                    outcome = 1;
                }else if(resp == -1){
                    outcome = -1
                }

                if(aScores == "" || !aScores){
                    if(bScores != "" && bScores){
                        outcome = -1
                    }
                }
                if(bScores == "" || !bScores){
                    if(aScores != "" && aScores){
                        outcome = 1

                    }
                }

                i = i+1;
            }
        }else if(ev.data.SIPriority == 'high'){
            if(outcome == ''){
                //type is the same across all inputs as part of the rules
                let type = ev.data.GlobalSIRanking
                let aHigh;
                let bHigh;
                let aKey = Object.keys(a)[0]
                let bKey = Object.keys(b)[0]
                for(let x in a[aKey]){
                    if(a[aKey][x] > aHigh || !aHigh){
                        aHigh = a[aKey][x];
                    }
                }
                for(let y in b[bKey]){
                    if(b[bKey][y] > bHigh || ! bHigh){
                        bHigh = b[bKey][y];
                    }
                }

                outcome = await compareValues(aHigh, bHigh, type)


            }
        }else if(ev.data.SIPriority == 'low'){
            if(outcome == ''){
                //type is the same across all inputs as part of the rules
                let type = ev.data.GlobalSIRanking
                let aLow;
                let bLow;
                let aKey = Object.keys(a)[0]
                let bKey = Object.keys(b)[0]
                for(let x in a[aKey]){
                    if(a[aKey][x] < aLow || !aLow){
                        aLow = a[aKey][x];
                    }
                }
                for(let y in b[bKey]){
                    if(b[bKey][y] < bLow || ! bLow){
                        bLow = b[bKey][y];
                    }
                }

                outcome = await compareValues(aLow, bLow, type)


            }
        }else if(ev.data.SIPriority == 'avg'){
            if(outcome == ''){
                let type = ev.data.GlobalSIRanking;
                let aAVG;
                let bAVG;
                let aKey = Object.keys(a)[0]
                let bKey = Object.keys(b)[0]

                //Find a's average score
                let total = 0;
                let len = 0;
                for(let i in a[aKey]){
                    total = total + a[aKey][i]
                    len = len +1
                }
                aAVG = total/len;

                //Find b's average score
                total = 0
                len = 0
                for(let i in b[bKey]){
                    total = total + b[bKey][i]
                    len = len +1
                }
                bAVG = total/len

                outcome = await compareValues(aAVG, bAVG, type)
            }
        }else if(ev.data.SIPriority == 'avgRank'){
            let evScorePerInput = [];
            //evScorePerInput = {0:[0,1,2,3,4,5,6],1:[0,1,2,3,4,5,6,7]}
            for(let x of eventScores){
               let k = Object.keys(x)[0]
               for(let y in x[k]){
                    if(!(evScorePerInput[y])){
                        evScorePerInput[y] = [x[k][y]]
                    }else{
                        evScorePerInput[y].push(x[k][y])
                    }
               }
            }
            for(let index in evScorePerInput){
                let sortType = ev.data.ScoreInputs[index].rank
                if(sortType == 'max'){
                    evScorePerInput[index] = evScorePerInput[index].sort((a,b)=>(b-a))
                }else if(sortType == 'min'){
                    evScorePerInput[index] = evScorePerInput[index].sort((a,b)=>(a-b))
                }
            }

            let aScores = a[Object.keys(a)[0]]
            let aTotal = 0
            let aCount = 0;
            for(let s in aScores){
                if(s && s != ''){
                    aTotal = aTotal + evScorePerInput[s].indexOf(aScores[s])+1
                    aCount = aCount + 1;
                }
            }
            let aAvg = aTotal/aCount;

            let bScores = b[Object.keys(b)[0]]
            let bTotal = 0
            let bCount = 0;
            for(let s in bScores){
                if(s && s != ''){
                    bTotal = bTotal + evScorePerInput[s].indexOf(bScores[s])+1
                    bCount = bCount + 1;
                }
            }
            let bAvg = bTotal/bCount;
            let type = ev.data.GlobalSIRanking;

            outcome = await compareValues(aAvg,bAvg,type)
            //rank competitor A scores for each input
            //rank competitor b ""
            //compare rankA to rankB
        }

        return outcome
    }


    return(

        <View
        >

            {loading?
                <Text>Loading</Text>
            :
            (
                <View
                style={ [{height:'60vh'},(vpWidth < 601) ? {width:'70vw', marginHorizontal:'15vw'}:{width:'25vw',marginLeft:'5vw'}]}

                >
                    <View style={{flexDirection:'row',alignItems:'center', justifyContent:'center', borderColor:'#c0c0c0', borderBottomWidth:1, height:'37.78px'}}>
                        <Text style={[{textAlign:'center', fontWeight:'bold', fontSize:leaderboardFontSize,width:'100%', marginBottom:'0.5vh', marginLeft:'1vw', marginRight:'1vw'}, (vpWidth > 600) ? {maxWidth:'17vw'}:{maxWidth:'60vw'}]}
                            onLayout={(e) => {
                                const { height } = e.nativeEvent.layout;
                                    if(height > 34){
                                        setLeaderboardFontSize(leaderboardFontSize - 1);
                                    }
                                }
                            }
                        >Event {event.data.EventNo}: {name}</Text>
                    </View>
                    <View style={{flexDirection:'row', textAlign:'center', marginBottom:'1vh'}}>
                        <Text style={[{textAlign:'left', fontWeight:'bold'}, (vpWidth > 600) ? {width:'11vw'}:{width:'30.8vw'}]}>Name</Text>
                        <Text style={[{fontWeight:'bold'}, (vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}]}>Rank</Text>
                        <Text style={[{fontWeight:'bold'}, (vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}]}>Points</Text>
                    </View>
                    <ScrollView showsVerticalScrollIndicator={false} >
                        {Object.keys(rowdata).map(d =>{
                            let div;
                            if(d != 'none' && d != 'All'){
                                div = divisions.find(x=>(x.id == d))
                            }else if(d != 'All'){
                                div = {id:'none', data:{Name:'No Division'}}
                            }else{
                                //Not actually divisions
                                div={id:'All'}
                            }
                            return(
                                <View key={d}>
                                    {compDets.data.DivisionsEnabled ?
                                        <Text style={{fontWeight:'bold', borderTopWidth:1, borderColor:'black', borderBottomWidth:1, textAlign:'center'}}>{div.data.Name}</Text>
                                    : null}
                                    {rowdata[div.id].map(member =>{
                                        if(!compDets.data.ScoreTeam){
                                            let competitor = competitorList.find(x=>(x.id == member.id))
                                            return(
                                                <View key={member.id} style={{flexDirection:'row', textAlign:'center',marginBottom:'0.5vh', paddingVertical:'0.25vh', alignItems:'center'}}>
                                                    <Text style={[{marginRight:'1vh', textAlign:'left'}, (vpWidth > 600) ? {width:'11vw'}:{width:'30.8vw'}]}>{competitor.data.LastName}, {competitor.data.FirstName}</Text>
                                                    <Text style={[{marginRight:'1vh'}, (vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}]}>{member.rank}</Text>
                                                    <Text style={(vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}}>{member.points}</Text>
                                                </View>
                                            )
                                        }else{
                                            let team = teamList.find(x=>(x.id == member.id))
                                            return(
                                                <View key={member.id} style={{flexDirection:'row', textAlign:'center',marginBottom:'0.5vh', paddingVertical:'0.25vh', alignItems:'center'}}>
                                                    <Text style={[{marginRight:'1vh', textAlign:'left'}, (vpWidth > 600) ? {width:'11vw'}:{width:'30.8vw'}]}>{team.data.Name}</Text>
                                                    <Text style={[{marginRight:'1vh'}, (vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}]}>{member.rank}</Text>
                                                    <Text style={(vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}}>{member.points}</Text>
                                                </View>
                                            )
                                        }
                                    })}
                                </View>


                            )
                        })}
                    </ScrollView>
                </View>
            )


            }

        </View>

    )
}

export default EventRankingPart;