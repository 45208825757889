import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { Animated, TextInput, TouchableHighlight, Text, ScrollView } from 'react-native-web';
import retStyles from '../../styles/MainStyleSheet';
import { isEmpty } from '@firebase/util';
import LeaderboardPart from '../parts/LeaderboardPart';



const DivisionLeaderboards = ({Divisions, competitors, teams, compDets, events, scores}) =>{
    const [divs, setDivs] = useState([]);

    useEffect(async ()=>{
        let d = [...Divisions]
        d = d.sort((a,b) => a.data.Name - b.data.Name)
        if(compDets.data.IncludeNullDivs){
            if(!d.includes({id:'none', data:{Name:'No Division'}})){
                d.push({id:'none', data:{Name:'No Division'}})
            }
        }
        setDivs(d)
    }, [Divisions])


    return(
        divs.map(div=>{
            //get div id
            //filter competitors by div
            let filteredParticipatList = [];

            if(!compDets.data.ScoreTeam){
                if(!compDets.data.TeamDivs){
                    //Competitors are in Divs
                    filteredParticipatList = competitors.filter(c=>(c.data.Division == div.id || (div.id == 'none' && (c.data.Division == '' || !c.data.Division))))
                    return(
                        <LeaderboardPart
                            key={div.id}
                            name={div.data.Name}
                            participants={filteredParticipatList}
                            competitorList={competitors}
                            teamList={teams}
                            events={events.sort((a,b)=>a.data.EventNo-b.data.EventNo)}
                            compDets={compDets}
                            scores={scores}

                        />
                    )
                }else{
                    filteredParticipatList = competitors.filter(y=>{
                        if(y.data.Team){
                            let team = teams.find(x=>(x.id == y.data.Team))
                            if(team.data.Division == div.id){
                                return(true)
                            }else if(div.id == 'none' && (!team.data.Division || team.data.Division == '')){
                                return(true)
                            }else{
                                return(false)
                            }
                        }else{
                            return(false)
                        }
                    })
                    return(
                        <LeaderboardPart
                            key={div.id}
                            name={div.data.Name}
                            participants={filteredParticipatList}
                            competitorList={competitors}
                            teamList={teams}
                            events={events.sort((a,b)=>a.data.EventNo-b.data.EventNo)}
                            compDets={compDets}
                            scores={scores}
                            isTeamBoard={true}

                        />
                    )
                }
            }else{
                filteredParticipatList = teams.filter(c=>(c.data.Division == div.id || (div.id == 'none' && (c.data.Division == '' || !c.data.Division))))
                return(
                    <LeaderboardPart
                        key={div.id}
                        name={div.data.Name}
                        participants={filteredParticipatList}
                        teamList={teams}
                        events={events.sort((a,b)=>a.data.EventNo-b.data.EventNo)}
                        compDets={compDets}
                        scores={scores}
                        isTeamBoard={true}
                    />
                )
            }

        })
    )


}

export default DivisionLeaderboards;