import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Modal, Dimensions } from "react-native";
import { Animated, TextInput, TouchableHighlight, Text, ScrollView, TouchableOpacity } from 'react-native-web';
import retStyles from '../../styles/MainStyleSheet';
import LoadingModal from './LoadingModal';
import { async, isEmpty } from '@firebase/util';
import { Entypo } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { useTheme } from 'react-native-paper';
import { db } from '../../fbManage.js';
import { deleteDoc, doc } from 'firebase/firestore';
import Popup from './Popup';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import AppButton from './AppButton';



const LeaderboardPart = ({name, participants, events, compDets, scores, custom, forViewer, permissionLevel, customID, setCustomboard, setCreateCustomLeaderboardTrigger, isTeamBoard, competitorList, teamList}) =>{
    if(!teamList){
        teamList = [];
    }
    if(!competitorList){
        competitorList = [];
    }

    if(permissionLevel == undefined || forViewer){
        permissionLevel = 99;
    }
    if(forViewer == undefined){
        forViewer = false;
    }

    let theme = useTheme()
    let styles = retStyles()
    let ButtonStyles = retButtonStyles()
    const [totals, setTotals] = useState({});
    const [eventCompetitorRanks, setEventCompetitorRanks] = useState({})
    const [ranks, setRanks] = useState({});
    const [loading, setLoading] = useState(true);
    const [leaderboardFirstLoad, setLeaderboardFirstLoad] = useState(true)
    const [leaderboardFontSize, setLeaderboardFontSize] = useState(25)
    const [deleteTrigger, setDeleteTrigger] = useState(false)
    const vpWidth = Dimensions.get('window').width;
    const [participantIDs, setParticipantIDs] = useState(participants.map(x=>x.id))

    useEffect(()=>{
        calcScoreTotals()
        setParticipantIDs(participants.map(x=>x.id))
    },[scores, participants])

    const calcScoreTotals = async()=>{
        let eventParticipantIDs = participants.map(x=> x.id)
        let tempTotals = {};

        let participantDivisions = [];

        if(compDets.data.IncludeNullDivs){
            participantDivisions.push('none')
        }

        if(compDets.data.DivisionsEnabled){
            for(let x of participants){
                if(!compDets.data.TeamDivs || compDets.data.ScoreTeam){
                    let id = x.data.Division
                    if(!participantDivisions.includes(id)){
                        participantDivisions.push(id)

                    }
                }else{
                    if(x.data.Team){
                        let team = teamList.find(t=>(t.id == x.data.Team))

                        if(team){
                            if(team.data.Division){
                                participantDivisions.push(team.data.Division)
                            }
                        }
                    }
                }
            }
        }

        participantDivisions = participantDivisions.filter(x=> (x))

        if(compDets.data.DivisionsEnabled){
            for(let x of participantDivisions){
                if(x){
                    let cList = [];
                    if(compDets.data.TeamDivs && !compDets.data.ScoreTeam){
                        cList = competitorList.filter(y =>{
                            if(y.data.Team){
                                let team = teamList.find(x=>(x.id == y.data.Team))
                                if(team.data.Division){
                                    if(team.data.Division == x){
                                        return(true)
                                    }else{
                                        return(false)
                                    }
                                }{
                                    if(x == 'none'){
                                        return(true)
                                    }else{
                                        return(false)
                                    }
                                }
                            }else{
                                return(false)
                            }
                        })
                    }else if(!compDets.data.TeamDivs && !compDets.data.ScoreTeam){
                        if(x == 'none'){
                            cList = competitorList.filter(y => {
                                if(!y.data.Division || y.data.Division == ''){
                                    return true;
                                }else{
                                    return false;
                                }
                            })
                        }else{
                            cList = competitorList.filter(y=>(y.data.Division == x))
                        }
                    }else if(compDets.data.ScoreTeam){
                        if(x == 'none'){
                            cList = teamList.filter(t=>{
                                if(!t.data.Division || t.data.Division == ''){
                                    return(true)
                                }else{
                                    return(false)
                                }
                            })
                        }else{
                            cList = teamList.filter(t => (t.data.Division == x))
                        }
                    }
                    let scores = await calcTotals(cList);


                    if(scores){
                        let s_keys = Object.keys(scores);
                        for(let i of s_keys){
                            if(eventParticipantIDs.includes(i)){
                                tempTotals[i] = scores[i];
                            }
                        }
                    }
                }
            }
        }else{
            if(!compDets.data.ScoreTeam){
                tempTotals = await calcTotals(competitorList)
            }else{
                tempTotals = await calcTotals(teamList)
            }
        }
        if(!isTeamBoard){
            setTotals(tempTotals)
        }else{
            let teamTots = {};
            if(!compDets.data.ScoreTeam){
                teamTots = await createTeamTotals(tempTotals)
            }else{
                teamTots = tempTotals
            }
            setTotals(teamTots)
        }
    }

    const compareValues = async(a,b,type) =>{
        if(type == "max"){
            if(a>b){
                return 1
            }else if(a<b){
                return -1
            }else{
                return 0
            }
        }else if(type=="min"){
            if(a<b){
                return 1
            }else if(a>b){
                return -1
            }else{
                return 0
            }
        }
    };

    const orderScores = async(a,b, ev, eventScores) => {
        //a,b are competitors being compared
        let outcome = ''
        let i = 0

        if(a == undefined || isEmpty(a) || a == ""){
            if(b!=undefined && !isEmpty(b)){
                //return b is higher
                outcome = -1
            }
        }
        if(b==undefined || isEmpty(b) || b == ""){
            //return a is higher
            outcome = 1
        }

        if(ev.data.SIPriority == 'heirachy' || !ev.data.SIPriority){
            while(outcome == '' && i < ev.data.ScoreInputs.length){

                let type = ev.data.ScoreInputs[i].rank
                let aKey = Object.keys(a)[0]
                let bKey = Object.keys(b)[0]
                let aScores = a[aKey][i];
                let bScores = b[bKey][i];

                let resp = await compareValues(aScores,bScores,type);
                if(resp == 0 && i < (ev.data.ScoreInputs.length-1)){
                    outcome = ''
                }else if(resp == 0 && i == (ev.data.ScoreInputs.length-1)){
                    outcome = 0
                }
                if(resp == 1){
                    outcome = 1;
                }else if(resp == -1){
                    outcome = -1
                }

                if(aScores == "" || !aScores){
                    if(bScores != "" && bScores){
                        outcome = -1
                    }
                }
                if(bScores == "" || !bScores){
                    if(aScores != "" && aScores){
                        outcome = 1

                    }
                }

                i = i+1;
            }
        }else if(ev.data.SIPriority == 'high'){
            if(outcome == ''){
                //type is the same across all inputs as part of the rules
                let type = ev.data.GlobalSIRanking
                let aHigh;
                let bHigh;
                let aKey = Object.keys(a)[0]
                let bKey = Object.keys(b)[0]
                for(let x in a[aKey]){
                    if(a[aKey][x] > aHigh || !aHigh){
                        aHigh = a[aKey][x];
                    }
                }
                for(let y in b[bKey]){
                    if(b[bKey][y] > bHigh || ! bHigh){
                        bHigh = b[bKey][y];
                    }
                }

                outcome = await compareValues(aHigh, bHigh, type)


            }
        }else if(ev.data.SIPriority == 'low'){
            if(outcome == ''){
                //type is the same across all inputs as part of the rules
                let type = ev.data.GlobalSIRanking
                let aLow;
                let bLow;
                let aKey = Object.keys(a)[0]
                let bKey = Object.keys(b)[0]
                for(let x in a[aKey]){
                    if(a[aKey][x] < aLow || !aLow){
                        aLow = a[aKey][x];
                    }
                }
                for(let y in b[bKey]){
                    if(b[bKey][y] < bLow || ! bLow){
                        bLow = b[bKey][y];
                    }
                }

                outcome = await compareValues(aLow, bLow, type)


            }
        }else if(ev.data.SIPriority == 'avg'){
            if(outcome == ''){
                let type = ev.data.GlobalSIRanking;
                let aAVG;
                let bAVG;
                let aKey = Object.keys(a)[0]
                let bKey = Object.keys(b)[0]

                //Find a's average score
                let total = 0;
                let len = 0;
                for(let i in a[aKey]){
                    total = total + a[aKey][i]
                    len = len +1
                }
                aAVG = total/len;

                //Find b's average score
                total = 0
                len = 0
                for(let i in b[bKey]){
                    total = total + b[bKey][i]
                    len = len +1
                }
                bAVG = total/len

                outcome = await compareValues(aAVG, bAVG, type)
            }
        }else if(ev.data.SIPriority == 'avgRank'){
            let evScorePerInput = [];
            //evScorePerInput = {0:[0,1,2,3,4,5,6],1:[0,1,2,3,4,5,6,7]}
            for(let x of eventScores){
               let k = Object.keys(x)[0]
               for(let y in x[k]){
                    if(!(evScorePerInput[y])){
                        evScorePerInput[y] = [x[k][y]]
                    }else{
                        evScorePerInput[y].push(x[k][y])
                    }
               }
            }
            for(let index in evScorePerInput){
                let sortType = ev.data.ScoreInputs[index].rank
                if(sortType == 'max'){
                    evScorePerInput[index] = evScorePerInput[index].sort((a,b)=>(b-a))
                }else if(sortType == 'min'){
                    evScorePerInput[index] = evScorePerInput[index].sort((a,b)=>(a-b))
                }
            }

            let aScores = a[Object.keys(a)[0]]
            let aTotal = 0
            let aCount = 0;
            for(let s in aScores){
                if(s && s != ''){
                    aTotal = aTotal + evScorePerInput[s].indexOf(aScores[s])+1
                    aCount = aCount + 1;
                }
            }
            let aAvg = aTotal/aCount;

            let bScores = b[Object.keys(b)[0]]
            let bTotal = 0
            let bCount = 0;
            for(let s in bScores){
                if(s && s != ''){
                    bTotal = bTotal + evScorePerInput[s].indexOf(bScores[s])+1
                    bCount = bCount + 1;
                }
            }
            let bAvg = bTotal/bCount;
            let type = ev.data.GlobalSIRanking;

            outcome = await compareValues(aAvg,bAvg,type)
            //rank competitor A scores for each input
            //rank competitor b ""
            //compare rankA to rankB
        }

        return outcome
    }

    const createTeamTotals = async(tempCompetitorTotals) => {
        let tempTeamTotals = {};
        if(!compDets.data.ScoreTeam){
            for(let c in tempCompetitorTotals){
                let cDets = competitorList.find(x=> (x.id == c))
                let teamID = cDets.data.Team;
                if(tempTeamTotals[teamID]){
                    tempTeamTotals[teamID] = tempTeamTotals[teamID] + tempCompetitorTotals[c]
                }else{
                    tempTeamTotals[teamID] = tempCompetitorTotals[c]
                }
            }
        }
        return(tempTeamTotals)
    }


    const calcTotals = async(eventParticipants) =>{
        let participantList = eventParticipants
        let eventCompetitor_ranks = {};
        let tempTotals ={};
        let tempTeamTotals={};

        //build ranks object
        for(let e of events){
            let eID = e.id;
            if(!eventCompetitor_ranks[eID]){
                eventCompetitor_ranks[eID] = {}
            }

            for(let t of participantList){
                let tID = t.id
                if(!eventCompetitor_ranks[eID][tID]){
                    eventCompetitor_ranks[eID][tID] = ''
                }
                eventCompetitor_ranks[eID][tID] = '';
            }

        }

        //figure out and input ranks into rank object
        for(let e of events){
            let eventScores = [];

            //Get scores for current event
            let validKeys = [];
            for(let k of participantList){
                validKeys.push(k.id)
            }

            let filteredscores = scores.filter(s=>(validKeys.includes(s.id)))
            for(let c of filteredscores){
                //obj = {competitor.id:{c.data[s]}}
                let obj = {[c.id]:{}};
                for(let s in c.data){
                    if(c.data[s]){
                        if(events.indexOf(e) == s.split("_")[0]){
                            obj[c.id][s.split("_")[1]] = c.data[s]
                        }
                    }
                }
                //CompetitorID:{0:value,1:value}
                if(!isEmpty(obj[c.id])){
                    eventScores.push(obj)
                }
            }

            //eventScore example
            //[...., {competitorID}:{scoreInput:Value}]
            //[..., {egjusbb143bhv34uv4:{0:12, 1:15}}]

            //Determine rankings for the current event
            for(let x = 0; x < eventScores.length; x++){
                let competitor1 = eventScores[x];
                let position = 1;
                let equalTrigger = false;
                let i = 0;
                while(i < eventScores.length && equalTrigger!=true){
                    let competitorToJudge = eventScores[i]
                    if(JSON.stringify(competitor1) != JSON.stringify(competitorToJudge)){
                        let res = await orderScores(competitor1,competitorToJudge, e, eventScores);
                        if(res ==-1){
                            position += 1;
                        }

                    }
                    i++;
                }
                let compScores = eventScores[x]
                let competitorID = Object.keys(compScores)[0]
                eventCompetitor_ranks[e.id][competitorID] = position
            }
        }

        setEventCompetitorRanks(eventCompetitor_ranks)
        //At this point we have rankings for current event... need to apply scoring system
        //Apply totals to a competitor level
        for(let c of participantList){
            let counted_results = []
            for(let e in eventCompetitor_ranks){
                let eRank = eventCompetitor_ranks[e][c.id]
                if(compDets.data.CountTopScores){
                    if(compDets.data.CountTopScores == counted_results.length){
                        //Check if new rank is higher than any of counted_results... replace lowest one
                        let min = 0;
                        let loc = 0;
                        for(let y in counted_results){
                            let x = counted_results[y]
                            let key = Object.keys(x)[0]
                            if(x[key] > min || x[key]==''){
                                min = x[key]
                                loc = y
                            }
                        }
                        if(eRank != ''){
                            if(min > eRank){
                                counted_results.splice(loc,1)
                                counted_results.push({[e]:eRank})
                            }
                        }
                    }else{
                        if(eRank != ''){
                            counted_results.push({[e]:eRank})
                        }
                    }
                }else{
                    if(eRank != ''){
                        counted_results.push({[e]:eRank})
                    }
                }
            }

            for(let r in counted_results){
                let obj = counted_results[r];
                let eventKey = Object.keys(obj)[0];
                let eventDets = events.find(x=> x.id==eventKey);
                eventDets = eventDets.data;
                let v2Add = 0;
                if(eventDets.ScoreSys == 'Pos'){
                    v2Add = obj[eventKey]
                    if(tempTotals[c.id]){
                        tempTotals[c.id] = tempTotals[c.id] + obj[eventKey]
                    }else{
                        tempTotals[c.id] = obj[eventKey]
                    }
                }else if(eventDets.ScoreSys == 'PosInvActive'){
                    let len = 0;
                    for(let x in eventCompetitor_ranks[eventKey]){
                        if(eventCompetitor_ranks[eventKey][x] != ""){
                            len =len+ 1;
                        }
                    }
                    let score = len - (obj[eventKey]-1);
                    v2Add = score;
                    if(tempTotals[c.id]){
                        tempTotals[c.id] = tempTotals[c.id] + score
                    }else{
                        tempTotals[c.id] = score
                    }
                }else if(eventDets.ScoreSys == 'PosInvTotal'){
                    let len = eventParticipants.length;
                    let score = len - (obj[eventKey]-1)
                    v2Add = score;
                    if(tempTotals[c.id]){
                        tempTotals[c.id] = tempTotals[c.id] + score
                    }else{
                        tempTotals[c.id] = score
                    }
                }else if(eventDets.ScoreSys == 'PointsPer'){
                    let pos = obj[eventKey];
                    let pointsAllocation = eventDets.PointsAllocation.filter(x=> x.position == pos);
                    if(pointsAllocation[0]){
                        let v = parseInt(pointsAllocation[0].value)
                        v2Add = v
                        if(!tempTotals[c.id]){
                            tempTotals[c.id] = v
                        }else{
                            tempTotals[c.id] = tempTotals[c.id] + v
                        }
                    }
                }

                /* if(isTeamBoard){
                    if(!compDets.data.ScoreTeam && !compDets.data.TeamDivs){
                        addToTeamScore(c.data.Team,v2Add)
                    }
                } */
            }
        }
        return(tempTotals)
    }

    const tieBreaker = async(tiedCompetitors,curCompetitorID) =>{
        let rankChange = 0;
        if(compDets.data.Tiebreaker == 'none' || !compDets.data.Tiebreaker){
            rankChange = 0
        }else if(compDets.data.Tiebreaker == 'avgResAll'){
            //Average result as compared to other competitors
            let avgRanks = {}
            let eventCount = events.length
            for(let e in eventCompetitorRanks){
                for(let c of tiedCompetitors){
                    if(eventCompetitorRanks[e][c] && eventCompetitorRanks[e][c] != ''){
                        if(!avgRanks[c]){
                            avgRanks[c] = eventCompetitorRanks[e][c];
                        }else{
                            avgRanks[c] = eventCompetitorRanks[e][c] + avgRanks[c];
                        }
                    }
                }
            }
            let rankArr = []
            for(let r in avgRanks){
                avgRanks[r] = avgRanks[r]/eventCount;
                rankArr.push(avgRanks[r])
            }

            rankChange = rankArr.indexOf(avgRanks[curCompetitorID])

        }else if(compDets.data.Tiebreaker == 'avgResActive'){
            //Average result as compared to other competitors
            let avgRanks = {}
            for(let e in eventCompetitorRanks){
                for(let c of tiedCompetitors){
                    if(eventCompetitorRanks[e][c] && eventCompetitorRanks[e][c] != ''){
                        if(!avgRanks[c]){
                            avgRanks[c] ={}
                            avgRanks[c].totalRanks = eventCompetitorRanks[e][c];
                            avgRanks[c].eventCount = 1
                        }else{
                            avgRanks[c].totalRanks = avgRanks[c].totalRanks + eventCompetitorRanks[e][c];
                            avgRanks[c].eventCount = avgRanks[c].eventCount + 1
                        }
                    }
                }
            }
            let rankArr = []
            for(let c in avgRanks){
                avgRanks[c].final = avgRanks[c].totalRanks/avgRanks[c].eventCount;
                rankArr.push(avgRanks[c].final)
            }

            rankChange = rankArr.indexOf(avgRanks[curCompetitorID].final)
        }else if(compDets.data.Tiebreaker == 'hrc'){
            //Count number of 1st places... all to the end to decide rank
            let rankToCount = 1;
            let outcome;
            let ranksAhead = 0;
            while (isNaN(outcome)){
                let countObject = {}
                let countArr = []
                for(let c of tiedCompetitors){
                    for(let e in eventCompetitorRanks){
                        if(eventCompetitorRanks[e][c]){
                            if(eventCompetitorRanks[e][c] == rankToCount){
                                if(countObject[c]){
                                    countObject[c] = countObject[c] + 1
                                }else{
                                    countObject[c] = 1
                                }
                            }
                        }
                    }
                }
                if(!countObject[curCompetitorID]){
                    countObject[curCompetitorID] = 0
                }

                for(let x in countObject){
                    countArr.push(countObject[x])
                }

                let stillTied = tiedCompetitors.filter(x=> (countObject[x] == countObject[curCompetitorID]))

                if(stillTied.length > 1){
                    //We have people still tied
                    if((rankToCount+1) > participants.length){
                        //no more ranks to count
                        outcome = countArr.indexOf(countObject[curCompetitorID]) + ranksAhead
                    }else{
                        //Still tied, need to rerun while loop with still tied competitors
                        rankToCount = rankToCount + 1;
                        ranksAhead = ranksAhead + (tiedCompetitors.length - stillTied.length)
                        tiedCompetitors = stillTied

                    }

                }else{
                    outcome = countArr.indexOf(countObject[curCompetitorID]) + ranksAhead
                }
            }

            rankChange = outcome;

        }

        return(rankChange)
    }

    //#region Calc Ranking
    useEffect(async()=>{
        //Calc Rankings
        if(!leaderboardFirstLoad){
            let scoreArr = []
            let rankingObj = []
            //Build arr
            for(let s in totals){
                scoreArr.push(totals[s])
            }
            //Rank competitors
            if(compDets.data.LeaderboardRanking == "Max"){
                scoreArr.sort((a,b)=> b-a)

            }else if(compDets.data.LeaderboardRanking == "Min"){
                scoreArr.sort((a,b)=> a-b)
            }
            for(let s in totals){
                let r = scoreArr.indexOf(totals[s])+1
                let e = r
                let tied = false
                if(scoreArr.filter(x => (x==totals[s])).length > 1){
                    //Tied
                    tied = true;
                    let tiedCompetitors = Object.keys(totals)
                    tiedCompetitors = tiedCompetitors.filter(x=> (totals[x] == totals[s]))
                    let change = await tieBreaker(tiedCompetitors,s, r)
                    if(change != -1){
                        e = change + r
                    }else{
                        e = "Err"
                    }

                }
                rankingObj.push({rank:r, eRank:e, id:s, tiebroken:tied})
                //rankingObj[s] = r
                //if count of total in scoreArr is greater than 1... calculate effective rank
            }

            //Points ranking and effective ranking
            //effective ranking should take into account tie-breaking
            rankingObj.sort((a,b) => (a.eRank - b.eRank))

            setRanks(rankingObj)

            setLoading(false)
        }else{
            setLeaderboardFirstLoad(false)
        }
    }, [totals])
    //#endregion

    const deleteBoard = () =>{
        setDeleteTrigger(false)
        deleteDoc(doc(db,"Competitions/"+compDets.id+"/CustomLeaderboards",customID))
    }

    return(
        <View
            style={ (vpWidth < 601) ? {width:'70vw', marginHorizontal:'15vw'}:{width:'25vw', marginLeft:"2.5vw",marginRight:"2.5vw"}}
        >
            <View style={{flexDirection:'row',alignItems:'center', justifyContent:'center', borderColor:'#c0c0c0', borderBottomWidth:1, height:'37.78px'}}>
                {(!forViewer && custom) && permissionLevel <= 2 ?
                    <TouchableOpacity
                        onPress={() => {
                            setCustomboard(customID)
                            setCreateCustomLeaderboardTrigger(true)
                        }}
                    >
                        <MaterialIcons name="edit" size={16} color={theme.colors.mainColour}/>
                    </TouchableOpacity>
                : null}
                <Text style={[{textAlign:'center', fontWeight:'bold', fontSize:leaderboardFontSize,width:'100%', marginBottom:'0.5vh', marginLeft:'1vw', marginRight:'1vw'}, (vpWidth > 600) ? {maxWidth:'17vw'}:{maxWidth:'60vw'}]}
                    onLayout={(e) => {
                        const { height } = e.nativeEvent.layout;
                            if(height > 34){
                                setLeaderboardFontSize(leaderboardFontSize - 1);
                            }
                        }
                    }
                >{name}</Text>
                {(!forViewer && custom) && permissionLevel <= 2 ?
                    <TouchableOpacity
                        onPress={()=>setDeleteTrigger(true)}
                    >
                        <Entypo name="trash" size={16} color={theme.colors.mainColour}/>
                    </TouchableOpacity>
                : null}
            </View>

            <View style={{flexDirection:'row', textAlign:'center', marginBottom:'1vh'}}>
                <Text style={[{textAlign:'left', fontWeight:'bold'}, (vpWidth > 600) ? {width:'11vw'}:{width:'30.8vw'}]}>Name</Text>
                <Text style={[{fontWeight:'bold'}, (vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}]}>Rank</Text>
                <Text style={[{fontWeight:'bold'}, (vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}]}>Score</Text>
            </View>
            {loading?
                <Text>Loading</Text>
            :

                (<ScrollView showsVerticalScrollIndicator={false} >
                    {ranks.filter(x=>(participantIDs.includes(x.id))).map(x=>{
                        if(!isTeamBoard){
                            let participantKey = x.id
                            let participant = participants.find(p=>(p.id==participantKey))

                            let ranking = ranks.find(r => (r.id==participantKey))
                            let rank = ranking.eRank
                            if(x.tiebroken){
                                rank = rank +"*"
                            }
                            let s = totals[participantKey]
                            return(
                                <View key={participantKey} style={{flexDirection:'row', textAlign:'center',marginBottom:'0.5vh', paddingVertical:'0.25vh', alignItems:'center'}}>
                                    {/**Name */}
                                    <Text style={[{marginRight:'1vh', textAlign:'left'}, (vpWidth > 600) ? {width:'11vw'}:{width:'30.8vw'}]}>{participant.data.LastName}, {participant.data.FirstName}</Text>
                                    {/**Rank */}
                                    <Text style={[{marginRight:'1vh'}, (vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}]}>{rank}</Text>
                                    {/**Score */}
                                    <Text style={(vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}}>{s}</Text>
                                </View>
                            )
                        }else{
                            let teamID = x.id
                            let team = teamList.find(t=>(t.id == teamID))
                            let ranking = ranks.find(r => (r.id==teamID))
                            let rank = ranking.rank
                            let s = totals[teamID]
                            return(
                                <View key={teamID} style={{flexDirection:'row', textAlign:'center',marginBottom:'0.5vh', paddingVertical:'0.25vh', alignItems:'center'}}>
                                    {/**Name */}
                                    <Text style={[{marginRight:'1vh', textAlign:'left'}, (vpWidth > 600) ? {width:'11vw'}:{width:'30.8vw'}]}>{team.data.Name}</Text>
                                    {/**Rank */}
                                    <Text style={[{marginRight:'1vh'}, (vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}]}>{rank}</Text>
                                    {/**Score */}
                                    <Text style={(vpWidth > 600) ? {width:'7vw'}:{width:'19.6vw'}}>{s}</Text>
                                </View>
                            )
                        }
                    })}
                </ScrollView>)


            }

            <Popup
                trigger={deleteTrigger}
                innerStyle={{
                padding:10,
                width:'40%',
                height:'auto',
                backgroundColor:'white',
                borderRadius:20,
                borderColor:theme.colors.mainColour,
                borderWidth:2
                }}
                content={
                    <View style={{justifyContent:'center', alignItems:'center'}}>
                        <Text style={styles.H1}>Delete?</Text>
                        <Text>Are you sure you would like to delete {name}?</Text>
                        <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3vh'}}>
                            <View style={{width:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                title={"No"}
                                containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                                textStyle={ ButtonStyles.appButtonText}
                                onPress={()=>{setDeleteTrigger(false)}}
                                />
                            </View>
                            <View style={{width:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                title={"Yes"}
                                containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                                textStyle={ ButtonStyles.appButtonText}
                                onPress={()=>{deleteBoard()}}
                                />
                            </View>
                        </View>
                    </View>
                }
            />
        </View>

    )
}

export default LeaderboardPart;