import { Text, View, TextInput } from 'react-native';
import { Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import React, { useState, useEffect, useContext } from 'react';

import AppButton from '../../parts/AppButton';

import { Entypo } from '@expo/vector-icons';

import {
    addDoc,
    deleteDoc,
    doc,
    getDoc,
    updateDoc,
    collection,
    onSnapshot,
    } from 'firebase/firestore';
import { db } from '../../../fbManage.js';
import retStyles from '../../../styles/MainStyleSheet';
import retButtonStyles from '../../../styles/ButtonStyleSheet';
import { useTheme } from 'react-native-paper';
import LoadingModal from '../../parts/LoadingModal';
import AddCatagoryPopup from '../AddCatagoryPopup';
import AlertPopup from '../../parts/AlertPopup';
import { AppContext } from '../../../AppContextProvider';


function Competitors({compID, compDets, theme}){
    const styles = retStyles(theme)
    const ButtonStyles = retButtonStyles(theme);

    const {gVpWidth} = useContext(AppContext)

    const competitorsDir = "Competitions/"+compID+"/Competitors"
    const competitorsCollection = collection(db, competitorsDir)
    const divisionsDir = "Competitions/"+compID+"/Divisions"
    const divisionsCollection = collection(db, divisionsDir)
    const teamsDir = "Competitions/"+compID+"/Teams"
    const teamsCollection = collection(db, teamsDir)

    const [competitorLoading, setCompetitorLoading] = useState(true)
    const [loadingTrigger1, setLoadingTrigger1] = useState(false)
    const [loadingTrigger2, setLoadingTrigger2] = useState(false)
    const [alertTrigger, setAlertTrigger] = useState('')

    const [competitors, setCompetitors] = useState([]);
    const [competitorsList, setCompetitorsList] = useState([]);

    const [competitorID, setCompetitorID] = useState('');
    const [competitorLastName, setCompetitorLastName] = useState('');
    const [competitorFirstName, setCompetitorsFirstName] = useState('')
    const [customAttributes, setCustomAttributes] = useState({})

    const [oldObj, setOldObj] = useState({});

    const [addCatagoryTrigger, setAddCatagoryTrigger] = useState(false)

    const [competitorSaveHighlight, setCompetitorSaveHighlight] = useState(true)

    useEffect(()=>{
        let isMounted = true
        let cusAttr = compDets.competitorCatagories;
        if(cusAttr){
            let obj = {};
            for(let x of cusAttr){
                obj[x.label] = "";
            }
            setCustomAttributes(obj)
        }
        setLoadingTrigger1(true)

        let unsubCompetitors = onSnapshot(competitorsCollection, (snapshot)=>{
            let tArr = snapshot.docs.map(d => ({id:d.id, data:d.data()}))
            tArr.sort((a, b) => {
                if(a.data.LastName < b.data.LastName) { return -1; }
                if(a.data.LastName > b.data.LastName) { return 1; }
                return 0;
            })
            if(isMounted){
                setCompetitors(tArr)
            }
        })
        return()=>{isMounted = false; unsubCompetitors();}
    },[])

    useEffect(()=>{
        setCompetitorsList(competitors)
    },[competitors])

    useEffect(()=>{
        competitorsList.sort((a, b) => {
            if(a.data.LastName < b.data.LastName) { return -1; }
            if(a.data.LastName > b.data.LastName) { return 1; }
            return 0;
        })
        setLoadingTrigger2(true)
    }, [competitorsList])

    useEffect(()=>{
        if(loadingTrigger1 && loadingTrigger2){
            setCompetitorLoading(false)
        }
    },[loadingTrigger1, loadingTrigger2])

    useEffect(()=>{
        let nobj = {
            LastName: competitorLastName,
            FirstName: competitorFirstName,
            customAttributes: customAttributes
        }
        if(JSON.stringify(nobj) == JSON.stringify(oldObj)){
            setCompetitorSaveHighlight(false)
        }else{
            setCompetitorSaveHighlight(true)
        }
    },[competitorLastName, competitorFirstName,oldObj, customAttributes])

    const newCompetitor = () =>{
        setCompetitorID('');
        setCompetitorLastName('');
        setCompetitorsFirstName('');
        resetCustomAttributes();

        setOldObj({})
    }

    const removeCompetitor = () =>{
        if(competitorID){
            deleteDoc(doc(db,competitorsDir, competitorID));
            //Need to check the competitor does not belong to a team or division
            let competitorDets = competitors.find(x=>x.id === competitorID).data
            let scoresRef = doc(db, "Competitions/"+compID+"/Scores",competitorID)
            if(getDoc(scoresRef).then((d)=>{return((d.exists()))})){
                deleteDoc(scoresRef)
            }
            if(competitorDets.Team){
                let dRef = doc(db,teamsDir,competitorDets.Team)
                getDoc(dRef).then((doc)=>{
                    let tm = doc.data().TeamMembers
                    tm = tm.filter(i => i!=competitorID)
                    updateDoc(dRef,{TeamMembers:tm})
                })
            }
            if(competitorDets.Division){
                let dRef = doc(db,divisionsDir,competitorDets.Division)
                getDoc(dRef).then((doc)=>{
                    let dm = doc.data().DivisionMembers
                    dm = dm.filter(i => i!=competitorID)
                    updateDoc(dRef,{DivisionMembers:dm})
                })
            }

        }
    }

    const selectCompetitor = (id) =>{
        let data = competitors.find(x =>x.id === id).data
        setCompetitorID(id)
        setCompetitorLastName(data.LastName)
        setCompetitorsFirstName(data.FirstName)
        resetCustomAttributes(data.customAttributes)

        let oobj = {
            LastName: data.LastName,
            FirstName: data.FirstName,
            customAttributes: data.customAttributes
        }
        setOldObj(oobj);
    }

    const saveCompetitor = () =>{
        if(!competitorLastName){
            setAlertTrigger({title:"Missing Last Name", text:'Please enter a last name for the competitor'})
        }
        else if(!competitorFirstName){
            setAlertTrigger({title:"Missing First Name", text:'Please enter a first name for the competitor'})
        }else{
            let missing = false;
            if(compDets.competitorCatagories){
                for(let x of compDets.competitorCatagories){
                    if(x.required){
                        if(!customAttributes[x.label]){
                            missing = true;
                        }
                    }
                }
                if(missing){
                    setAlertTrigger({title:"Missing Custom Attributes", text:'Please enter all required custom attributes for the competitor'})
                }
            }
            if(!missing){
                let obj = {
                    LastName:competitorLastName,
                    FirstName:competitorFirstName,
                    customAttributes: customAttributes

                }

                if(competitorID != ''){
                    //Competitor is a selected one
                    updateDoc(doc(db,competitorsDir,competitorID),obj)
                }else{
                    //Competitor is new
                    addDoc(competitorsCollection,obj).then((res)=>{
                        setCompetitorID(res.id)
                    })
                }
                setOldObj(obj)

            }
        }
    }

    const searchCompetitors =async(queryTerm)=>{
        let search_terms = queryTerm.split(" ");
        let list = [];
        for(let term of search_terms){
            list = competitors.filter(x=> {
                if(!list.includes(x)){
                    let lastName = x.data.LastName.toLowerCase()
                    let firstName = x.data.FirstName.toLowerCase()
                    let qTerm = term.toLowerCase()
                    if(lastName.includes(qTerm)){
                        return true
                    }
                    if(firstName.includes(qTerm)){
                        return true
                    }
                }
            })
        }
        setCompetitorsList(list)
    }

    const addCatagory = async(obj)=>{
        if(compDets.competitorCatagories && compDets.competitorCatagories.length > 9){
            setAlertTrigger({title:'Oh No!', text:'You have reached the maximum number of custom attributes (10)'})
        }else{
            let uploadObj = compDets.competitorCatagories;
            let searchObj;
            if(uploadObj){
                searchObj = uploadObj.find(x=> x.value == obj.value)
            }else{
                uploadObj = [];
            }
            if(searchObj){
                let index = uploadObj.indexOf(searchObj);
                uploadObj[index] = obj;
            }else{
                uploadObj.push(obj)
            }

            const compRef = doc(db,"Competitions", compID)
            updateDoc(compRef,{
                competitorCatagories:uploadObj
            }).then(()=>{
                setAddCatagoryTrigger(false);
            })
        }
    }

    const removeCatagory = async(obj) =>{
        setCompetitorLoading(true)
        //set confirmation
        let uploadObj = compDets.competitorCatagories;
        uploadObj = uploadObj.filter(x=> (x != obj))
        const compRef = doc(db,"Competitions", compID)
        updateDoc(compRef,{
            competitorCatagories:uploadObj
        }).then(async()=>{
            for(let x of competitorsList){
                let newobj = x.data.customAttributes;
                delete newobj[obj.label]
                updateDoc(doc(db,"Competitions",compID,"Competitors",x.id),{customAttributes: newobj}).then(()=>{
                    let x;
                })
            }
            setCompetitorLoading(false)
        })
    }

    const changecustomAttribute = async(e, id) =>{
        setCustomAttributes({
            ...customAttributes,
            [id]:e
        })
    }

    const resetCustomAttributes = async(newobj) => {
        let cusAttr = compDets.competitorCatagories
        let obj ={};
        if(cusAttr){
            for(let x of cusAttr){
                obj[x.label] = "";
            }
        }
        //This is used when selecting a competitor
        if(newobj){
            for(let x in newobj){
                obj[x] = newobj[x]
            }
        }
        setCustomAttributes(obj)

    }



    if(competitorLoading){
        return(
            <LoadingModal
                trigger={competitorLoading}
            />
        )
    }else{
        return(
            <View style={[{flexDirection:'row', height:'90%'}, gVpWidth <= 600 ? {flexDirection:'column'}:null]}>
                {/*Left side of the screen - List of Events to click and edit */}
                <View style={gVpWidth > 600 ? {width:'44%', marginLeft:'1%'}:{width:"100%", height:'30%', paddingHorizontal:'5%'}}>
                    {/** + and - button for adding and removing events */}
                    <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                        <Text style={{marginTop:'0.5vh'}}> { 800 < gVpWidth ? ("# of Competitors:"): ""} {competitors.length}</Text>
                        <View style={{flexDirection:'row'}}>
                        <TextInput
                            style={{backgroundColor:'white', borderRadius:'3px', marginRight:'0.5vw', paddingLeft:'0.5vw'}}
                            placeholder={"Search"}
                            onChangeText={(e)=>searchCompetitors(e)}
                        />
                        <TouchableOpacity onPress={()=>{newCompetitor()}} >
                            <Entypo name="squared-plus" size={24} color="black" />
                        </TouchableOpacity >
                        <TouchableOpacity onPress={()=>{removeCompetitor()}} >
                            <Entypo name="squared-minus" size={24} color="black" />
                        </TouchableOpacity>
                        </View>
                    </View>
                    <View style={[{borderColor:'Grey', borderWidth:1, marginTop:'1vh', height:'70%',paddingHorizontal:'2%'}, gVpWidth > 600 ?{minHeight:'90%', maxHeight:'90%',}:null]}>
                        {/**LIST OF COMPETITORS */}
                        <ScrollView

                        >
                             {competitorsList.map(competitor =>{
                                return(
                                    <TouchableOpacity
                                        key={competitor.id}
                                        onPress={() => selectCompetitor(competitor.id)}
                                        style={[(competitorID==competitor.id)?{backgroundColor:theme.colors.mainColour }:{backgroundColor:'none'},styles.selectableList]}
                                    >
                                        <Text style={(competitorID==competitor.id)?{color:'white' }:{color:'black'}}>{competitor.data.LastName}, {competitor.data.FirstName}</Text>
                                    </TouchableOpacity>
                                )
                            })}
                        </ScrollView>
                    </View>
                </View>

                {/*Right Side of the screen - parameters per event clicked on left */}
                <View style={[{minHeight:'70%', marginTop:'1vh'}, gVpWidth > 600 ?{width:'54%', height:'100%',paddingLeft:'5%',marginRight:'1%'}:{height:'70%', width:'100%', paddingHorizontal:'5%'}]}>
                {competitorID ? (
                    <Text style={{fontWeight:"900"}}>{competitorLastName}, {competitorFirstName}</Text>)
                :(
                    <Text style={{fontWeight:'900'}}>New Competitor</Text>
                )
                }
                <ScrollView style={{width:'100%', minHeight:'100%'}} showsVerticalScrollIndicator={false}>
                    <Text>Competitor's Last Name:</Text>
                    <TextInput
                        value={competitorLastName}
                        onChangeText={setCompetitorLastName}
                        style={styles.inputBox}
                    />
                    <Text>Competitor's First Name:</Text>
                    <TextInput
                        value={competitorFirstName}
                        onChangeText={setCompetitorsFirstName}
                        style={styles.inputBox}
                    />
                    <View style={{flexDirection:'row', justifyContent:'space-between', width:'100%', marginTop:'1vh'}}>
                        <Text>Attributes:</Text>
                        <View style={{flexDirection:'row'}}>
                            <TouchableOpacity onPress={()=>setAddCatagoryTrigger(true)} >
                                <Entypo name="squared-plus" size={24} color="black" />
                            </TouchableOpacity >
                        </View>
                    </View>
                    {compDets.competitorCatagories ?
                        compDets.competitorCatagories.map(cat =>{
                            if(cat){
                                let label = cat.label
                                return(
                                    <View key={cat.label} style={{marginTop:'0.25vh'}}>
                                        <View style={{flexDirection:'row', width:'50%'}}>
                                            {cat.required ?
                                                <Text style={{color:'red'}}>*</Text>
                                            : null}
                                            <Text style={{flex:1}}>{cat.label}:</Text>
                                            {cat.type == 'text' ?
                                                <TextInput value ={customAttributes[label]} onChangeText={(e) =>{changecustomAttribute(e, label)}} style={[styles.inputBox, {flex:1}]}/>
                                            :null}
                                            {cat.type == 'number' ?
                                                <TextInput value={customAttributes[label]} onChangeText={(e) => {changecustomAttribute(e.replace(/[^0-9]/g, ''),label)}} style={[styles.inputBox, {flex:1}]}/>
                                            :null}
                                            {cat.type == 'select' ?
                                                <Picker style={{flex:1}} selectedValue={customAttributes[label]} onValueChange={(e)=>{changecustomAttribute(e,label)}}>
                                                    <Picker.Item value={""} label=""/>
                                                    {cat.options.map(x=>{
                                                        return(
                                                            <Picker.Item key={x.name} value={x.name} label={x.name}/>
                                                        )
                                                    })}
                                                </Picker>
                                            :null}
                                            <TouchableOpacity onPress={()=>removeCatagory(cat)} >
                                                <Entypo name="squared-minus" size={24} color="black" />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )
                            }
                        })
                    :null}

                    <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:'2vh'}}>
                        <View style={{minWidth:'49%', height:'5vh'}}>
                            <AppButton
                                title={"Save"}
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle={[ButtonStyles.appButtonContainer, (!competitorSaveHighlight) ? {backgroundColor:theme.colors.mainColour+"50"}:null]}
                                onPress={()=>saveCompetitor()}
                            />
                        </View>
                        <View style={{minWidth:'49%', height:'5vh'}}>
                            <AppButton
                                title={"New Competitor"}
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle={ButtonStyles.appButtonContainer}
                                onPress={()=>newCompetitor()}
                            />
                        </View>
                    </View>
                </ScrollView>
                </View>
                <AddCatagoryPopup
                    trigger={addCatagoryTrigger}
                    cancelTrigger={setAddCatagoryTrigger}
                    submitFunc={(e)=>addCatagory(e)}
                    theme={theme}
                />
                <AlertPopup
                    trigger={alertTrigger}
                    setTrigger={setAlertTrigger}
                    title={alertTrigger.title}
                    alertText={alertTrigger.text}
                    theme={theme}
                />
            </View>
        );
    }
}

export default Competitors;