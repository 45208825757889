const colourList = require('../styles/colourList.json');


export async function validateColour(e) {
    e = e.toLowerCase()
    let colour = colourList.find(c=> (c.hex.toLowerCase() === e || c.name.toLowerCase() === e))
    if(colour){
        return colour.hex
    }else{
        return false
    }

}

