//#region Imports and Initialization
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, Button, Modal, Dimensions } from 'react-native';
import { CheckBox, Picker, ScrollView, TextInput, TouchableOpacity } from 'react-native-web';
import Checkbox from 'expo-checkbox';

import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as RootNavigation from '../../RootNavigation';
import TimeField from '../parts/TimeField.tsx';
import QRCode from 'react-native-qrcode-svg';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { db } from '../../fbManage.js';
import { collection, getDocs, addDoc, setDoc ,doc, onSnapshot, updateDoc, query, orderBy} from 'firebase/firestore';

import AppButton from '../parts/AppButton';
import Popup from '../parts/Popup'

import { useTheme } from 'react-native-paper';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import retStyles from '../../styles/MainStyleSheet';
import Header from '../parts/Header';
import LoadingModal from '../parts/LoadingModal'
import AlertPopup from '../parts/AlertPopup';
import { AppContext } from '../../AppContextProvider';

import Scoring from './pages/Scoring.Component';
import Leaderboard from './pages/Leaderboard.Component';
import Printing from './pages/Printing.Componenet';
const iconFile = require("../../assets/small_icon.png")

const Tab = createBottomTabNavigator();
//#endregion

const Use = ({ navigation, route }) => {
    const useNavRef = React.createRef()
    const {globalOrganisationDets, gVpWidth} = useContext(AppContext)
    const {globalUserDets} = useContext(AppContext)
    const tempTheme = useTheme()
    const [theme, setTheme] = useState(tempTheme);
    const ButtonStyles = retButtonStyles(theme)
    const styles = retStyles(theme)
    //#region Initiation of data
    const divisionsDir = "Competitions/"+route.params.compID+"/Divisions"
    const divisionsCollection = collection(db, divisionsDir)
    const teamsDir = "Competitions/"+route.params.compID+"/Teams"
    const teamsCollection = collection(db, teamsDir)
    const competitorsDir = "Competitions/"+route.params.compID+"/Competitors"
    const competitorsCollection = collection(db, competitorsDir)
    const eventDir = "Competitions/"+route.params.compID+"/Events"
    const eventCollection = collection(db, eventDir)

    const [loading, setLoading] = useState(true)
    const [firstLoad, setFirstLoad] = useState(true)
    //Alert trigger uses '' by design
    const [alertTrigger,setAlertTrigger] =useState('');
    const [reconfigureTrigger, setReconfigureTrigger] = useState(false)

    const [compDets, setCompDets] = useState({})
    const [permissionLevel, setPermissionLevel] = useState(99)
    const [competitors, setCompetitors] = useState([])
    const [events, setEvents] = useState([])
    const [teams, setTeams] = useState([])
    const [divisions, setDivisions] = useState([])

    const [printTrigger, setPrintTrigger] = useState(false);
    const [finaliseTrigger, setFinalliseTrigger] = useState(false);
    //#endregion

    //#region Gathering data
    //Gather initial Data
    useEffect(()=>{
        let isMounted = true
        if(isMounted){
            const docRef = doc(db, "Competitions",route.params.compID)
            var unsub_comp = onSnapshot((docRef), doc =>{
                setCompDets({id:doc.id,data:doc.data()})
                let curOrg = globalOrganisationDets.find(org => org.id == doc.data().organisation)
                setTheme(curOrg.data.Theme)
                let PL =  globalUserDets.data.Organisations[doc.data().organisation].role
                setPermissionLevel(PL)
            })
        }
        return()=>{
            unsub_comp()
            isMounted = false
        }
    },[])

    //Gather the rest of the data on load
    useEffect(()=>{
        let isMounted = true
        if(!firstLoad){
            onSnapshot(competitorsCollection, async(snapshot)=>{
                let docs = snapshot.docs
                setCompetitors(docs.map(doc=>(
                    {
                        id: doc.id,
                        data:doc.data()
                    }
                )))
            })

            let q = query(eventCollection, orderBy("EventNo"))
            onSnapshot(q, snapshot=>{
                setEvents(snapshot.docs.map(doc=>(
                    {
                        id: doc.id,
                        data:doc.data()
                    }
                )))
            })

            if(compDets.data.TeamsEnabled){
                onSnapshot(teamsCollection, snapshot=>{
                    setTeams(snapshot.docs.map(doc=>(
                        {
                            id: doc.id,
                            data:doc.data()
                        }
                    )))
                })
            }
            if(compDets.data.DivisionsEnabled){
                onSnapshot(divisionsCollection,(snapshot => {
                    setDivisions(snapshot.docs.map(doc=>(
                        {
                            id: doc.id,
                            data:doc.data()
                        }
                    )))
                }))
            }
        }else{
            setFirstLoad(false)
        }
        return()=>{isMounted = false}

    },[compDets])
    //#endregion

    useEffect(()=>{
        competitors.sort((a, b) => {
            if(a.data.LastName < b.data.LastName) { return -1; }
            if(a.data.LastName > b.data.LastName) { return 1; }
            return 0;
        })
    },[competitors])

    //Checking the data has loaded
    useEffect(()=>{
        let trigger = true;
        if(firstLoad){
            trigger = false;
        }else{
            if(compDets.length <1){
                trigger = false
            }
            if(competitors.length <1){
                trigger = false
            }
            if(events.length <1){
                trigger = false
            }
            if(compDets){
                if(compDets.data.TeamsEnabled){
                    if(teams.length <1){
                        trigger = false
                    }
                }
                if(compDets.data.DivisionsEnabled){
                    if(divisions.length <1){
                        trigger = false
                    }
                }
            }
        }
        if(trigger){
            setLoading(false)
        }
    },[compDets, competitors, events, teams, divisions])

    //Sorting events
    useEffect(()=>{
        events.sort((a,b)=>a.data.EventNo-b.data.EventNo)
    },[events])

    //Reconfigure(exit) function
    const reconfigure = async() =>{
        setReconfigureTrigger(false)
        let c = doc(db,"Competitions",route.params.compID)
        updateDoc(c, {stage: 'Setup'}).then((res)=>{
            RootNavigation.navigate('create',{compID:route.params.compID})
        })
    }

    const finaliseResults = async() =>{
        let trigger = true
        let msg = '';

        let c = doc(db,"Competitions",compDets.id)
        updateDoc(c, {stage: 'Finished'}).then(async(res)=>{
            setFinalliseTrigger(false);
            RootNavigation.navigate('Finished',{compID:compDets.id})
        })
    }

    //Container for 'Use'
    if(!loading){
        return (
            <NavigationContainer independent={true} ref={useNavRef}>
                <Header
                theme={theme}
                    buttons={
                        <ScrollView horizontal={(gVpWidth > 600)} style={gVpWidth > 600 ?{flexDirection:'row'}: {flexDirection:'column'}} contentContainerStyle={{marginLeft:'auto'}} showsHorizontalScrollIndicator={false}>
                            <View style={{minWidth:'15vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    title={"Home"}
                                    onPress={()=> RootNavigation.navigate('Home')}
                                />
                            </View>
                            {permissionLevel <=2 ?
                                <View style={{minWidth:'15vw', height:'5vh', marginLeft:'2vw'}}>
                                    <AppButton
                                        textStyle={ButtonStyles.appButtonText}
                                        containerStyle={ButtonStyles.appButtonContainer}
                                        title={"Reconfigure"}
                                        onPress={()=> setReconfigureTrigger(true)}
                                    />
                                </View>
                            :null}

                            {/* <View style={{width:'15vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    title={"Print"}
                                    onPress={() => useNavRef.current.navigate('Printing')}
                                />
                            </View> */}
                        </ScrollView>
                    }
                    name={compDets.data.name}
                />

            {/**Main Screen */}
                <Tab.Navigator screenOptions={{headerShown:false}}>
                    <Tab.Screen options={{unmountOnBlur: true, tabBarIcon:({color,size})=>(
                            <MaterialCommunityIcons name="table" size={size} color={color} />
                        )}} name="Scoring">
                            {()=> <Scoring compDets={compDets} competitors={competitors} teams={teams} divisions={divisions} permissionLevel={permissionLevel} events={events} theme={theme}/>}
                        </Tab.Screen>
                    <Tab.Screen options={{unmountOnBlur: true, tabBarIcon:({ color, size }) => (
                            <MaterialCommunityIcons name="scoreboard" size={size} color={color} />
                        )}} name="Leaderboard"
                    >
                        {()=> <Leaderboard compDets={compDets} competitors={competitors} teams={teams} divisions={divisions} permissionLevel={permissionLevel} events={events} theme={theme}/>}
                    </Tab.Screen>
                    <Tab.Screen options={{unmountOnBlur: true, tabBarButton: () => null, tabBarVisible: false}} name="Printing">
                        {()=> <Printing compDets={compDets}/>}
                    </Tab.Screen>
                </Tab.Navigator>


                <AlertPopup
                    trigger={(alertTrigger != '')}
                    setTrigger={setAlertTrigger}
                    title={alertTrigger.title}
                    alertText={alertTrigger.text}
                    theme={theme}
                />

                {/**Finalise Competitiion Popup */}
                <Popup
                    trigger={finaliseTrigger}
                    innerStyle={{
                    padding:10,
                    width:'40%',
                    height:'auto',
                    backgroundColor:'white',
                    borderRadius:20,
                    borderColor:theme.colors.mainColour,
                    borderWidth:2
                    }}
                    content={
                    <View style={{justifyContent:'center', alignItems:'center'}}>
                        <Text style={styles.H1}>Finalise</Text>
                        <Text>Are you sure you would like to finalise the results?</Text>
                        <Text>This CANNOT be undone</Text>
                        <Text>This will prevent any scores, making the results final.</Text>
                        {compDets.data.PublicLeaderboard}{
                            <Text>This will also update the public leaderboard with ALL results</Text>
                        }
                        <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3vh'}}>
                        <View style={{width:'10vw', height:'5vh', marginLeft:'2vw'}}>
                            <AppButton
                            title={"Yes"}
                            containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                            textStyle={ ButtonStyles.appButtonText}
                            onPress={()=>{finaliseResults()}}
                            />
                        </View>
                        <View style={{width:'10vw', height:'5vh', marginLeft:'2vw'}}>
                            <AppButton
                            title={"No"}
                            containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                            textStyle={ ButtonStyles.appButtonText}
                            onPress={()=>{setFinalliseTrigger}}
                            />
                        </View>
                        </View>
                    </View>
                    }
                />

                {/**Reconfigure Confirmation Popupd */}
                <Popup
                    trigger={reconfigureTrigger}
                    innerStyle={{
                    padding:10,
                    width:'40%',
                    height:'auto',
                    backgroundColor:'white',
                    borderRadius:20,
                    borderColor:theme.colors.mainColour,
                    borderWidth:2
                    }}
                    content={
                        <View style={{justifyContent:'center', alignItems:'center'}}>
                            <Text style={styles.H1}>Reconfigure?</Text>
                            <Text>Are you sure you would like to reconfigure this competition?</Text>
                            <Text>Depending on what is changed scores may be lost</Text>
                            <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3vh'}}>
                                <View style={{width:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                    <AppButton
                                    title={"No"}
                                    containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                                    textStyle={ ButtonStyles.appButtonText}
                                    onPress={()=>{setReconfigureTrigger(false)}}
                                    />
                                </View>
                                <View style={{width:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                    <AppButton
                                    title={"Yes"}
                                    containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                                    textStyle={ ButtonStyles.appButtonText}
                                    onPress={()=>{reconfigure()}}
                                    />
                                </View>
                            </View>
                        </View>
                    }
                />

            </NavigationContainer>

        );
    }else{
        return(
            <LoadingModal
                trigger={loading}
                theme={theme}
            />
        )
    }
};

export default Use;