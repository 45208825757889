import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { Animated, TextInput, TouchableHighlight, Text } from 'react-native-web';
import retStyles from '../../styles/MainStyleSheet';
import AppButton from './AppButton';


const NoLicenceAlert = ({theme, setAlertTrigger, trigger}) =>{
    const styles = retStyles(((theme)?theme:null))


    return(
        <Modal
            visible={true}
            transparent={false}
            style={{height:'100vh', width:'100vw'}}
        >
            <View style={{justifyContent:'center', alignContent:'center', alignItems:'center', flexDirection:'row', height:'100vh', width:'100vw', backgroundColor:'rgba(255,255,255,0.75)'}}>
                <View style={{alignContent:'center', alignItems:'center',justifyContent:'center'}}>
                    <Text style={[styles.H1,{fontSize:48}]}>Oh No!</Text>
                    <Text style={{marginTop:'2vh'}}>You do not have a valid licence applied at the moment! Without a valid licence, most features will be locked.</Text>
                    <Text style={{marginTop:'2vh'}}>You can apply licences in settings.</Text>
                    <View style={{height:'5vh', marginTop:'3vh', width:'10vw'}}>
                        <AppButton
                            title='Close'
                            onPress={()=>{setAlertTrigger(trigger+1)}}
                            theme={theme}
                        />
                    </View>
                </View>
            </View>
        </Modal>
    )
}

export default NoLicenceAlert;