import React, { useEffect, useRef, useContext, useState } from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { useTheme } from 'react-native-paper';
import { Animated, TextInput, TouchableHighlight, Text, TouchableOpacity } from 'react-native-web';
import retStyles from '../../styles/MainStyleSheet';
import Checkbox from 'expo-checkbox';
import { AppContext } from '../../AppContextProvider';
import Popup from './Popup';
import AppButton from './AppButton';
import ConfirmPopup from './confirmPopup';
import { cancelStripeSubscription } from '../../data';
import LoadingModal from './LoadingModal';


const LicenceDetsPopup = ({theme, licence, trigger, setTrigger, permissionLevel}) =>{
    if(!theme){
        theme = useTheme();
    }
    const styles = retStyles(((theme)?theme:null))
    const {gVpWidth} = useContext(AppContext)

    const {globalOrganisationDets} = useContext(AppContext);
    const org = globalOrganisationDets.find(o => (o.id== licence.data.organisationID))

    const [cancelSubscriptionTrigger, setCancelSubscriptionTrigger] = useState(false);
    const [loading, setLoading] = useState(false);

    const cancelSubscription = async() =>{
        setCancelSubscriptionTrigger(false)
        setLoading(true)
        await cancelStripeSubscription(licence.data.subscriptionID)
        setLoading(false)
    }

    function capitalizeFirstLetter(string) {
        if(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
      }

    return(
        <Popup
            trigger={trigger}
            title={`Licence Details`}
            content={
                <View>
                    <View style={gVpWidth > 600 ? {flexDirection:'row', marginTop:'1%'} : {marginTop:'1%', flexDirection:'column'}}>
                        <View style={gVpWidth > 600 ? {flexDirection:'row', width:'40%', marginLeft:'10%', marginRight:'10%'}: {flexDirection:'row', width:'80%', marginHorizontal:'10%', justifyContent:'center'}}>
                            <Text style={{fontWeight:'bold'}}>ID: </Text>
                            <Text>{licence.id}</Text>
                        </View>
                        <View style={gVpWidth > 600 ? {flexDirection:'row', width:'40%', marginLeft:'10%', marginRight:'10%'}: {flexDirection:'row', width:'80%', marginHorizontal:'10%', justifyContent:'center'}}>
                            <Text style={{fontWeight:'bold'}}>Organisation: </Text>
                            <Text>{org.data?.name}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'1%'}}>
                        <View style={{flexDirection:'row', width:'40%', marginLeft:'10%', marginRight:'10%'}}>
                            <Text style={{fontWeight:'bold'}}>Status: </Text>
                            <Text style={licence.data.status == 'active' ? {color:'green'} : {color:'red'}}>{capitalizeFirstLetter(licence.data.status)}</Text>
                        </View>
                        <View style={{flexDirection:'row', width:'40%'}}>
                            <Text style={{fontWeight:'bold'}}>Auto Renew?</Text>
                            <Checkbox
                                color={theme.colors.mainColour}
                                value={!licence.data.cancelAtEnd}
                            />
                        </View>
                    </View>
                    <View style={{flexDirection:'row', marginTop:'1%'}}>
                        <View style={{flexDirection:'row', width:'40%', marginLeft:'10%', marginRight:'10%'}}>
                            <Text style={{fontWeight:'bold'}}>Start: </Text>
                            <Text>{licence.data.startDate.toDate().toDateString()}</Text>
                        </View>
                        <View style={{flexDirection:'row', width:'40%'}}>
                            <Text style={{fontWeight:'bold'}}>End: </Text>
                            <Text>{licence.data.endDate.toDate().toDateString()}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'1%'}}>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{fontWeight:'bold'}}>{`Active: `}</Text>
                            <Checkbox value={licence.data.valid} color={theme.colors.mainColour}/>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{fontWeight:'bold'}}>Count: </Text>
                            <Text>{licence.data.count}</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{fontWeight:'bold'}}>Count: </Text>
                            <Text>{licence.data.userIDs.length}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3%'}}>
                        <View>
                            <AppButton
                                title={`Close`}
                                onPress={()=>setTrigger(false)}
                            />
                        </View>
                        {licence.data.valid && licence.data.status == 'active' && !licence.data.cancelAtEnd && permissionLevel == 0 ?
                            <View>
                                <AppButton
                                    title={'Cancel Subscription'}
                                    onPress={()=>setCancelSubscriptionTrigger(true)}
                                />
                            </View>
                        : null }
                    </View>
                    <ConfirmPopup
                        trigger={cancelSubscriptionTrigger}
                        setTrigger={setCancelSubscriptionTrigger}
                        title={`Cancel Subscription`}
                        alertText={`Are you sure you want to cancel your subscription?\nThis cannot be undone!`}
                        confirmFunction={()=>cancelSubscription()}
                    />
                    <LoadingModal
                        trigger={loading}
                    />
                </View>
            }
        />

    )
}

export default LicenceDetsPopup;