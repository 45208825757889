import React, { useContext, useEffect, useState } from 'react';
import { Text, View, Button, TextInput, Image } from 'react-native';
import { getDoc, doc, collection, getDocs, deleteDoc, setDoc, updateDoc, addDoc } from 'firebase/firestore';
import { Picker, TouchableOpacity } from 'react-native-web';
import { db } from '../../fbManage.js';

import Popup from '../parts/Popup';
import AppButton from '../parts/AppButton';

import retButtonStyles from '../../styles/ButtonStyleSheet';
import retStyles from '../../styles/MainStyleSheet';

import { AppContext } from '../../AppContextProvider';
import { useTheme } from 'react-native-paper';
import DropDownPicker from 'react-native-dropdown-picker';
import RNPickerSelect from 'react-native-picker-select';
import LoadingModal from '../parts/LoadingModal.js';
import AlertPopup from '../parts/AlertPopup.js';


const CustomLeaderboardPopup = ({trigger, setTrigger, compDets, divisions, teams, selectedBoard,setSelectedBoard, theme}) => {
    if(!theme){
        theme = useTheme()
    }

    const {gVpWidth} = useContext(AppContext)

    const styles = retStyles(theme)
    const ButtonStyles = retButtonStyles(theme)

    const [cusLoading, setCusLoading] = useState(true)
    const [alertTrigger, setAlertTrigger] = useState('');

    useEffect(async()=>{
        if(selectedBoard){
            setLeaderboardID(selectedBoard)
            getDoc(doc(db,"Competitions/"+compDets.id+"/CustomLeaderboards/"+selectedBoard)).then(async(d)=>{
                setCreateName(d.data().name);
                setCreateType(d.data().type);
                let arr = [];
                for(let z of d.data().filters){
                    arr.push(z.field)
                }
                setSelectedFilters(arr)
                setCreateFilters(d.data().filters)
                setCusLoading(false)
            })
        }else{
            setLeaderboardID('')
            setCreateName('')
            setCreateFilters([])
            setCreateType((compDets.data.ScoreTeam) ? 'Team' : 'Competitor')
            setFilterOpen(false)
            setSelectedFilters([])
            setCusLoading(false)
        }
    },[selectedBoard, trigger])

    const [leaderboardID, setLeaderboardID] = useState('');
    const [createType, setCreateType]= useState('Competitor');
    const [createName, setCreateName] = useState('');
    const [createFilters, setCreateFilters] = useState([]);

    const[filterOpen, setFilterOpen] = useState(false)
    const[selectedFilters, setSelectedFilters] = useState([])
    const[filterOptions, setFilterOptions] = useState([])

    useEffect(async ()=>{
        if(createType == 'Competitor'){
            setFilterOpen(false)
            setSelectedFilters([])
            let arr = [
                {label: 'Last Name', value:'LastName', type:'text'},
                {label: 'First Name', value: 'FirstName', type:'text'},
            ]
            if(compDets.data.competitorCatagories){
                for(let x of compDets.data.competitorCatagories){
                    arr.push({label: x.label, value: x.value, type: x.type, options: x.options, fcustom:true})
                }
            }


            if(compDets.data.DivisionsEnabled){
                arr.push({label: 'Division', value:'Division', type:'link'})
            }
            if(compDets.data.TeamsEnabled){
                arr.push({label: 'Team', value:'Team', type:'link'})
            }
            setFilterOptions(arr)
        }else if(createType == 'Team'){
            setFilterOpen(false)
            setSelectedFilters([])
            let arr =[
                {label:"Name", value:"Name", type:'text'},
                {label:"Number of Members", value:'NumMembers', type:'number'},
            ]
            if(compDets.data.DivisionsEnabled){
                arr.push({label:'Division', value:'Division', type:'link'})
            }
            setFilterOptions(arr)
        }
    }, [createType, leaderboardID])

    const changeFilter = async (v, qType, qString, custom) =>{
        let cur = createFilters.find(x=> x.field == v);
        let curObj = createFilters;
        let isCustom = false;
        if(custom){
            isCustom = true;
        }

        if(cur){
            let loc = curObj.indexOf(cur);
            if(loc!=-1){
                curObj[loc] = {
                    field:v,
                    queryType:qType,
                    queryString:qString,
                    isCustom: isCustom
                }
            }

        }else{
            curObj.push({
                field:v,
                queryType:qType,
                queryString:qString,
                isCustom: isCustom
            })
        }
        setCreateFilters(curObj);
    }

    const reset = async() =>{
        setLeaderboardID('');
        setCreateType('Competitor');
        setCreateName('');
        setCreateFilters([]);

        setFilterOpen(false)
        setSelectedFilters([])
    }
    const saveLeaderboard = async()=>{

        let errTrigger = true;
        if(!createName){
            //alert("Your leaderboard must include a name!")
            setAlertTrigger({title:'Name?', msg:'Your leaderboard must include a name!'})
            errTrigger = false
        }
        if(!createType){
            //alert("Your leaderboard must include a type!")
            setAlertTrigger({title:'Type?', msg:'Your leaderboard must include a type!'})
            errTrigger = false
        }
        if(!createFilters){
            //alert("Your leaderboard must filter in some way!")
            setAlertTrigger({title:'Filters?', msg:'Your leaderboard must filter in some way!'})
            errTrigger=false;
        }
        if(errTrigger){
            let obj2Save ={
                name: createName,
                type: createType,
                filters: createFilters
            };

            let customLeaderboardsDir = "Competitions/"+compDets.id+"/CustomLeaderboards"
            if(leaderboardID !=''){
                updateDoc(doc(db,customLeaderboardsDir+'/'+leaderboardID),obj2Save).then(
                    setSelectedBoard(''),
                    await reset(),
                    setTrigger(false)
                )
            }else{
                addDoc(collection(db,customLeaderboardsDir),obj2Save).then(
                    setSelectedBoard(''),
                    await reset(),
                    setTrigger(false)
                )
            }
        }
    }

    if(cusLoading){
        return(<LoadingModal trigger={cusLoading}/>)
    }else{
        return (
            <Popup
              trigger={trigger}
              content={
                <View>
                    <Text style={styles.H1}>Create Custom Leaderboard</Text>
                    <Text style={{fontWeight:'bold'}}>Custom Leaderboard Name</Text>
                    <TextInput
                        value={createName}
                        onChangeText={setCreateName}
                        style={styles.inputBox}
                    />
                    {(compDets.data.TeamsEnabled && !compDets.data.ScoreTeam) ?
                        <View style={{marginTop:'0.5vh'}}>
                        <Text style={{fontWeight:'bold'}}>Is this a team or competitor leaderboard?</Text>
                        <Picker
                            value={createType}
                            onValueChange={setCreateType}
                            style={{borderColor:'black', borderRadius:'5px', marginTop:'0.25vh'}}
                        >
                            <Picker.Item label="Competitor" value="Competitor"/>
                            <Picker.Item label="Team" value="Team"/>
                        </Picker>
                        </View>
                    :
                        null
                    }
                    {/**Filters View */}
                    <View style={{flexDirection:'row', justifyContent:'space-between', width:'100%', marginTop:'1.5vh',marginBottom:'1.5vh', zIndex:5}}>
                        <Text style={[styles.H2, gVpWidth > 600 ? {marginRight:'23%'} : {marginRight:'2%'}]}>Filters</Text>
                        <DropDownPicker
                            style={{marginTop:'0.5vh',borderRadius:'10px', width:'70%', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                            dropDownContainerStyle={{width:'70%', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                            listItemContainerStyle={{flexDirection:'row'}}
                            multiple={true}
                            open={filterOpen}
                            value={selectedFilters}
                            items={filterOptions}
                            setOpen={setFilterOpen}
                            setValue={setSelectedFilters}
                            setItems={setFilterOptions}
                        />
                    </View>
                    <View style={{alignContent:'center', justifyContent:'center', alignItems:'center', width:'100%'}}>
                        {selectedFilters.map((f)=>{
                            let obj = filterOptions.find(x=> x.value == f)
                            let currentState = createFilters.find(x=>x.field == f)
                            if(obj){
                                let display_name = obj.label;
                                if(obj.type == 'text'){
                                    let qType = 'equals';
                                    let qString = '';
                                    if(currentState){
                                        qType = currentState.queryType;
                                        qString = currentState.queryString;
                                    }
                                    return(
                                        <View
                                            key={f}
                                            style={ [{flexDirection:'row',justifyContent:'flex-end', marginBottom:'1.5vh'}, gVpWidth > 600 ? {width:'50%'}:{width:'100%'}]}
                                        >
                                            <Text style={{ marginTop:'3px', fontWeight:'bold'}}>{display_name} </Text>
                                            <View style={{width:'35%'}}>
                                                <RNPickerSelect
                                                    style={{inputWeb:{height:'3.5vh'}}}
                                                    onValueChange={(a)=>{
                                                        qType = a;
                                                        if(qString != ''){
                                                            changeFilter(f,a,qString,obj.fcustom)
                                                        }
                                                    }}
                                                    value={qType}
                                                    items={[
                                                        { label: 'Is', value: 'equals' },
                                                        { label: 'Is Not', value: '!equals' },
                                                        { label: 'Contains', value: 'contains' },
                                                        { label: 'Does Not Contain', value: '!contains' },
                                                    ]}
                                                />
                                            </View>


                                            <TextInput style={[styles.inputBox, {width:'44%', marginLeft:'1%'}]}
                                                defaultValue={qString}
                                                onChangeText={(a)=>{
                                                    qString = a
                                                    if(qType != ''){
                                                        changeFilter(f,qType,a, obj.fcustom)
                                                    }
                                                }}
                                            />
                                        </View>
                                    )
                                }else if(obj.type == 'number'){
                                    let qType = 'equals';
                                    let qString = '';
                                    if(currentState){
                                        qType = currentState.queryType;
                                        qString = currentState.queryString;
                                    }
                                    return(
                                        <View
                                            key={f}
                                            style={ [{flexDirection:'row',justifyContent:'flex-end', marginBottom:'1.5vh'}, gVpWidth > 600 ? {width:'50%'}:{width:'100%'}]}
                                        >
                                            <Text style={{ marginTop:'3px', fontWeight:'bold'}}>{display_name} </Text>
                                            <View style={{width:'35%'}}>
                                                <RNPickerSelect
                                                    style={{inputWeb:{height:'3.5vh'}}}
                                                    onValueChange={(a)=>{
                                                        qType = a;
                                                        if(qString != ''){
                                                            changeFilter(f,a,qString, obj.fcustom)
                                                        }
                                                    }}
                                                    value={qType}
                                                    items={[
                                                        { label: 'Is', value: 'equals' },
                                                        { label: 'Is Not', value: '!equals' },
                                                        { label: 'Larger Than', value: 'larger' },
                                                        { label: 'Smaller Than', value: 'smaller' },
                                                        { label: 'Larger/Equals', value:'larger_equals'},
                                                        { label: 'Smaller/Equals', value:'smaller_equals'}
                                                    ]}
                                                />
                                            </View>
                                            <TextInput style={[styles.inputBox, {width:'44%', marginLeft:'1%'}]}
                                                onChangeText={(a)=>{
                                                    qString = a
                                                    if(qType != ''){
                                                        changeFilter(f,qType,a, obj.fcustom)
                                                    }
                                                }}
                                            />
                                        </View>
                                    )
                                }else if(obj.type == 'select'){
                                    let qType = 'equals';
                                    let qString = '';
                                    let cusOpt = [{label:'', value:''}]
                                    cusOpt = obj.options.map(x=>{return({label: x.name, value:x.name})})
                                    if(currentState){
                                        qType = currentState.queryType;
                                        qString = currentState.queryString;
                                    }
                                    return(
                                        <View
                                            key={f}
                                            style={ [{flexDirection:'row',justifyContent:'flex-end', marginBottom:'1.5vh'}, gVpWidth > 600 ? {width:'50%'}:{width:'100%'}]}
                                        >
                                            <Text style={{ marginTop:'3px', fontWeight:'bold'}}>{display_name} </Text>
                                            <View style={{width:'35%'}}>
                                                <RNPickerSelect
                                                    style={{inputWeb:{height:'3.5vh'}}}
                                                    onValueChange={(a)=>{
                                                        qType = a;
                                                        if(qString != ''){
                                                            (f,a,qString, obj.fcustom)
                                                        }
                                                    }}
                                                    value={qType}
                                                    items={[
                                                        { label: 'Equals', value: 'equals' },
                                                        { label: 'Not Equals', value: '!equals' },
                                                    ]}
                                                />
                                            </View>
                                            <View style={{width:'44%', marginLeft:'1%'}}>
                                                <RNPickerSelect
                                                    style={{inputWeb:{height:'3.5vh'}}}
                                                    onValueChange={(a)=>{
                                                        qString = a;
                                                        if(qType != ''){
                                                            changeFilter(f,qType,a, obj.fcustom)
                                                        }
                                                    }}
                                                    value={qString}
                                                    items={cusOpt}
                                                />
                                            </View>
                                        </View>
                                    )

                                }else if(obj.type == 'link'){
                                    let qType = 'equals';
                                    let qString = '';
                                    if(currentState){
                                        qType = currentState.queryType;
                                        qString = currentState.queryString;
                                    }
                                    let optList = [];
                                    if(obj.value == 'Division'){
                                        for(let d of divisions){
                                            optList.push({label:d.data.Name,value:d.id})
                                        }
                                    }else if(obj.value == 'Team'){
                                        for(let t of teams){
                                            optList.push({label:t.data.Name,value:t.id})
                                        }
                                    }
                                    return(
                                        <View
                                            key={f}
                                            style={ [{flexDirection:'row',justifyContent:'flex-end', marginBottom:'1.5vh'}, gVpWidth > 600 ? {width:'50%'}:{width:'100%'}]}
                                        >
                                            <Text style={{ marginTop:'3px', fontWeight:'bold'}}>{display_name} </Text>
                                            <View style={{width:'35%'}}>
                                                <RNPickerSelect
                                                    style={{inputWeb:{height:'3.5vh'}}}
                                                    onValueChange={(a)=>{
                                                        qType = a;
                                                        if(qString != ''){
                                                            changeFilter(f,a,qString, obj.fcustom)
                                                        }
                                                    }}
                                                    value={qType}
                                                    items={[
                                                        { label: 'Is', value: 'equals' },
                                                        { label: 'Is Not', value: '!equals' }
                                                    ]}
                                                />
                                            </View>
                                            {qType=='equals' || qType=='!equals' ?
                                                <View style={{width:'35%'}}>
                                                    <RNPickerSelect
                                                        style={{inputWeb:{height:'3.5vh'}}}
                                                        onValueChange={(a)=>{
                                                            qString = a;
                                                            if(qType != ''){
                                                                changeFilter(f,qType,a, obj.fcustom)
                                                            }
                                                        }}
                                                        value={qString}
                                                        items={optList}
                                                    />
                                                </View>
                                            :null}
                                        </View>
                                    )
                                }

                            }


                        })}
                    </View>
                    <View style={{flexDirection:'row', padding:'1vw', justifyContent:'space-evenly',width:'100%', zIndex:-1}}>
                        <View style={{minWidth:'10vw', height:'5vh'}}>
                            <AppButton
                                title={"Save"}
                                containerStyle={ButtonStyles.appButtonContainer}
                                textStyle={ButtonStyles.appButtonText}
                                onPress={()=> saveLeaderboard()}
                            />
                        </View>
                        <View style={{minWidth:'10vw', height:'5vh'}}>
                            <AppButton
                                title={"Cancel"}
                                containerStyle={ButtonStyles.appButtonContainer}
                                textStyle={ButtonStyles.appButtonText}
                                onPress={()=> {setSelectedBoard(''); setTrigger(false)}}
                            />
                        </View>
                    </View>
                    <AlertPopup
                        trigger={(alertTrigger != '')}
                        setTrigger={setAlertTrigger}
                        title={alertTrigger.title}
                        alertText={alertTrigger.text}
                    />
                </View>
              }
            />
        );
    }
  };

export default CustomLeaderboardPopup;