import React from "react";
import { View, Button, StyleSheet, TouchableOpacity, Text, ActivityIndicator } from "react-native";
import { useTheme } from "react-native-paper";
import retButtonStyles from "../../styles/ButtonStyleSheet";
import Overlay from "./Overlay";


const LoadingOverlay = ({trigger, theme, size}) => {
    if(!theme){
        theme = useTheme()
    }
    if(!size){
        size = 'large'
    }

 if(trigger)
 {return(
    <Overlay
        trigger={trigger}
        style={{backgroundColor: 'rgba(255,255,255,0.5)'}}
        content={
            <ActivityIndicator
                size={size}
                color={theme.colors.mainColour}
            />
        }
    />
  )}else{
      return(null)
  }
};

export default LoadingOverlay;