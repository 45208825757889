import React, { useEffect, useState, useContext } from 'react';
import { Text, View, TextInput, Image, Platform } from 'react-native';
import { getDoc, doc, collection, getDocs, deleteDoc, setDoc, updateDoc, query, onSnapshot, where, addDoc } from 'firebase/firestore';
import { CheckBox, Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import * as WebBrowser from 'expo-web-browser';

import * as ImagePicker from 'expo-image-picker';
import * as crypto from 'crypto';


import Popup from '../../../parts/Popup';
import AppButton from '../../../parts/AppButton';
import Header from '../../../parts/Header';
import AlertPopup from '../../../parts/AlertPopup';

import { Ionicons } from '@expo/vector-icons';

import retButtonStyles from '../../../../styles/ButtonStyleSheet';
import retStyles from '../../../../styles/MainStyleSheet';
import { useTheme } from 'react-native-paper';
import { db } from '../../../../fbManage';
import { AppContext } from '../../../../AppContextProvider';
import Checkbox from 'expo-checkbox';
import LicenceRow from '../../../parts/LicenceRow';
import { isEmpty } from '@firebase/util';
import { createURLString, resendVerification, resetUserPasswordLink } from '../../../../data';
import ConfirmPopup from '../../../parts/confirmPopup';
import LoadingModal from '../../../parts/LoadingModal';


function AccountSettings({theme, setSaveButton, userDets, setLoading}) {
    if(!theme){
        theme= useTheme()
    }
    const styles = retStyles();
    const ButtonStyles = retButtonStyles();
    const {globalUserDets, globalOrganisationDets, userState} = useContext(AppContext);

    const [newPass1, setNewPass1] = useState('');
    const [newPass2, setNewPass2] = useState('');
    const [invalid, setInvalid] = useState(false);
    const [unverified, setUnverified] = useState(false);
    const [emailSent, setEmailSent] = useState(false)
    const [loading, setLoadingLocal] = useState(false)
    const [AlertPopupTrigger, setAlertPopupTrigger] = useState('')

    const [personalLicences, setPersonalLicences] = useState([]);
    const [orgLicences, setOrgLicences] = useState([]);

    const [personalOrgDets, setPersonalOrgDets] = useState({});

    const {gVpWidth} = useContext(AppContext)

    useEffect(()=>{
        if( newPass1 != '' && newPass2 != ''){
            if(newPass1 != newPass2){
                setInvalid(true)
            }else{
                setInvalid(false)
            }
        }

    },[newPass1,newPass2])

    useEffect(()=>{
        let isMounted = true;
        if(isMounted){
            setSaveButton(null)
            let personal_org = globalOrganisationDets.find(o => (o.data?.isPersonal))
            setPersonalOrgDets(personal_org)
            let q =  query(collection(db, "Licences"), where("userIDs", "array-contains", globalUserDets.id));
            var unsub_licenceDets = onSnapshot(q, (snap)=>{
                if(!snap.empty){
                    let arr = [...snap.docs.map(doc=>({id:doc.id, data:doc.data()}))];
                    let perArr = arr.filter(a => (a.data?.organisationID === personal_org.id))
                    let orgArr = arr.filter(a => (a.data?.organisationID != personal_org.id))
                    setPersonalLicences(perArr)
                    setOrgLicences(orgArr)
                }
            })
        }
        return ()=>{
            isMounted = false;
            unsub_licenceDets();
        }
    },[])

    const addPersonalLicence = async () =>{
        if(!isEmpty(personalOrgDets)){
            if(!userState.emailVerified){
                setUnverified(true);
            }else{
                if(personalLicences.length < 1 || personalLicences.filter(a => (a.data?.valid)).length < 1){
                    if(personalOrgDets.id && personalOrgDets.data.name){
                        let obj = {
                            orgID: personalOrgDets.id,
                            orgName: personalOrgDets.data.name,
                            userID: globalUserDets.id,
                            personal:true
                        }
                        let string = await createURLString(JSON.stringify(obj))
                        let url = `http://www.skorebrd.com/PurchaseLicences/?data=${string}`;
                        if(Platform.OS === 'web'){
                            window.open(url, '_blank');
                        }else{
                            let result = await WebBrowser.openBrowserAsync(url)
                        }
                    }
                }else{
                    setAlertPopupTrigger({title:"Wait Up", text:"We appreciate your support but you already have an active licence!"})
                }
            }
        }

    }

    const sendEmailVerificationEmail = async() =>{
        setLoadingLocal(true)
        const res = await resendVerification({
            toEmail:userState.email
        })
        setUnverified(false)
        setLoadingLocal(false)
    }

    const sendPassReset = async() =>{
        setLoadingLocal(true)
        const res = await resetUserPasswordLink(userState.email)
        setLoadingLocal(false)

    }

    return (

        <View style={{width:'100%',paddingLeft:'1vw',paddingRight:'1vw', height:'100%'}}>
            <Text style={styles.H1}>Account Settings</Text>
            <View style={{marginLeft:'1vw', height:'100%'}}>
                <View style={gVpWidth <= 600 ? {flexDirection:'row', marginVertical:'1vh'}:null}>
                    <Text style={[styles.H2, gVpWidth <= 600 ? {width:'50%'}:null]}>Reset Password:</Text>
                    <View style={[{marginLeft:'1vw',}, gVpWidth <= 600 ? {width:'50%'}: { marginTop:'1.5vh'}]}>
                        <View style={{width:'10vw', minWidth:'150px', height:'4vh'}}>
                            <AppButton
                                    title={"Send Reset Link"}
                                    onPress={()=>{sendPassReset()}}
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle = {[ButtonStyles.appButtonContainer]}
                            />
                        </View>
                    </View>
                </View>
                <View style={{flexDirection:'row', marginTop:'1.5vh', maxWidth:'100%', alignItems:'center'}}>
                    <Text style={styles.H2}>Licences</Text>
                    <View style={{minWidth: '15vw', marginLeft:'5%', height:'4vh'}}>
                        <AppButton
                            textStyle={ButtonStyles.appButtonText}
                            containerStyle = {[ButtonStyles.appButtonContainer]}
                            title="Add Personal Licence"
                            onPress={()=> addPersonalLicence()}
                        />
                    </View>
                </View>
                <View style={gVpWidth > 600 ? {width:'100%', flexDirection:'row', justifyContent:'space-evenly'}:{width:'100%', flexDirection:'column', height:'50vh'}}>
                    <View style={ gVpWidth > 600 ? {width:'40%'} : {width:"100%", height:'50%'}}>
                        <Text style={{fontWeight:'bold'}}>Personal Licence:</Text>
                        <ScrollView >
                            <View style={{paddingHorizontal:'3%', paddingTop:'1%'}}>
                                {personalLicences.length > 0 ?
                                    personalLicences.map((licence) => (
                                        <View key={licence.id} style={{marginVertical:'1%'}}>
                                            <LicenceRow theme={theme} licence={licence} key={licence.id}/>
                                        </View>
                                    ))
                                :
                                    <View style={{width:'100%', justifyContent:'center', alignItems:'center'}}>
                                        <Text>No Licences to show</Text>
                                    </View>
                                }
                            </View>
                        </ScrollView>
                    </View>
                    <View style={ gVpWidth > 600 ? {width:'40%'} : {width:"100%", height:'50%'}}>
                        <Text style={{fontWeight:'bold'}}>Organisational Licences:</Text>
                        <ScrollView >
                            <View style={{paddingHorizontal:'3%', paddingTop:'1%'}}>
                                {orgLicences.length > 0 ?
                                    orgLicences.map((licence) => (
                                        <View key={licence.id} style={{marginVertical:'1%'}}>
                                            <LicenceRow theme={theme} licence={licence} key={licence.id}/>
                                        </View>
                                    ))
                                :
                                    <View style={{width:'100%', justifyContent:'center', alignItems:'center'}}>
                                        <Text>No Licences to show</Text>
                                    </View>
                                }
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </View>

            <ConfirmPopup
                trigger={unverified}
                setTrigger={setUnverified}
                title={"Unverified Email"}
                alertText={"Please verify your email by clicking the link sent to you.\nIf you think this is an error, please refresh the app.\nWould you like us resend the link?"}
                theme={theme}
                confirmFunction={()=>{sendEmailVerificationEmail()}}
            />

            <AlertPopup
                trigger={(AlertPopupTrigger != '')}
                setTrigger={setAlertPopupTrigger}
                title={AlertPopupTrigger.title}
                alertText={AlertPopupTrigger.text}
                theme={theme}
            />

            <LoadingModal
                trigger={loading}
            />
        </View>

    );

}

export default AccountSettings;