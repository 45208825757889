import React from "react";
import { View, Button, StyleSheet, TouchableOpacity, Text } from "react-native";
import retButtonStyles from "../../styles/ButtonStyleSheet";


const AppButton = ({ onPress, onLongPress, title, containerStyle, textStyle }) => {
  const ButtonStyles = retButtonStyles();
  if(!containerStyle){
    containerStyle = ButtonStyles.appButtonContainer;
  }
  if(!textStyle){
    textStyle = ButtonStyles.appButtonText;
  }

 return(
 <TouchableOpacity onPress={onPress} onLongPress={onLongPress} style={containerStyle}>
      <Text style={textStyle}>{title}</Text>
    </TouchableOpacity>
  )
};

export default AppButton;