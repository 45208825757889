import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Modal, ScrollView, Text, TextInput } from "react-native";
import DropDownPicker from 'react-native-dropdown-picker';
import RNPickerSelect from 'react-native-picker-select';

import { useTheme } from 'react-native-paper';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import retStyles from '../../styles/MainStyleSheet';
import AppButton from '../parts/AppButton';
import Popup from '../parts/Popup';



const DivFilteredAddPopup = ({theme, trigger, setTrigger, teamDivs, compDets, competitors, teams, divID, addFunction}) =>{
    const styles = retStyles(theme);
    const ButtonStyles = retButtonStyles(theme);

    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filterOptions, setFilterOptions] = useState([])

    const [useFilters, setUseFilters] = useState([])
    const [competitorList, setCompetitorList] = useState([...competitors])
    const [teamList, setTeamsList] = useState(teams)

    const [alreadyAssignedList, setAlreadyAssignedList] = useState([])

    const [alreadyAssignedPopup, setAlreadyAssignedPopup] = useState(false)

    useEffect(async()=>{
        if(compDets){
            if(!teamDivs && !compDets.ScoreTeam){
                let optionArr = []

                optionArr.push(
                    {label: 'Last Name', value:'LastName', type:'text'},
                    {label: 'First Name', value:'FirstName', type:'text'},
                )
                if(compDets.competitorCatagories){
                    for(let x of compDets.competitorCatagories){
                        optionArr.push({label: x.label, value: x.value, type: x.type, fOptions:x.options, fcustom:true})
                    }
                }

                setFilterOptions(optionArr)
            }else{
                let optionArr = []

                optionArr.push(
                    {label:"Name", value:"Name", type:'text'},
                    {label:"Number of Members", value:'NumMembers', type:'number'}
                )
                if(compDets.teamCatagories){
                    for(let x of compDets.teamCatagories){
                        optionArr.push({label: x.label, value: x.value, type: x.type, fOptions:x.options, fcustom:true})
                    }
                }

                setFilterOptions(optionArr)
            }
        }
    }, [compDets, teamDivs])

    useEffect(()=>{
        setCompetitorList(competitors)
    }, [competitors])

    useEffect(()=>{
        setTeamsList(teams)
    }, [teams])

    const changeFilter = async (v, qType, qString, custom) =>{
        let cur = useFilters.find(x=> x.field == v);
        let curObj = [...useFilters];
        let isCustom = false;
        if(custom){
            isCustom = true;
        }

        if(cur){
            let loc = curObj.indexOf(cur);
            if(loc!=-1){
                curObj[loc] = {
                    field:v,
                    queryType:qType,
                    queryString:qString,
                    isCustom: isCustom
                }
            }

        }else{
            curObj.push({
                field:v,
                queryType:qType,
                queryString:qString,
                isCustom: isCustom
            })
        }

        setUseFilters(curObj);
    }

    useEffect(async()=>{
        let rowData;

        if(teamDivs){
            rowData = [...teams]

        }else{
            rowData = [...competitors]
        }

        for(let f of useFilters){
            if(f.field == 'NumMembers'){
                switch(f.queryType){
                    case 'equals':
                        rowData = rowData.filter(x=> x.data.TeamMembers.length == f.queryString)
                        break;
                    case '!equals':
                        rowData = rowData.filter(x=> x.data.TeamMembers.length != f.queryString)
                        break;
                    case 'larger':
                        rowData = rowData.filter(x=> x.data.TeamMembers.length > f.queryString);
                        break;
                    case 'smaller':
                        rowData = rowData.filter(x=> x.data.TeamMembers.length < f.queryString);
                        break;
                    case 'larger_equals':
                        rowData = rowData.filter(x=> x.data.TeamMembers.length >= f.queryString);
                        break;
                    case 'smaller_equals':
                        rowData = rowData.filter(x=> x.data.TeamMembers.length <= f.queryString);
                        break;
                }
            }else{
                if(f.isCustom){
                    switch(f.queryType){

                        case 'equals':
                            rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] == f.queryString){return(true)}}else{return(false)}})
                            break;
                        case '!equals':
                            rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] != f.queryString){return(true)}}else{return(false)}})
                            break;
                        case 'contains':
                            rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field].includes(f.queryString)){return(true)}}else{return(false)}})
                            break;
                        case '!contains':
                            rowData = rowData.filter(x=> {if(x.data.customAttributes){if(!x.data.customAttributes[f.field].includes(f.queryString)){return(true)}}else{return(false)}})
                            break;
                        case 'larger':
                            rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] > f.queryString){return(true)}}else{return(false)}})
                            break;
                        case 'smaller':
                            rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] < f.queryString){return(true)}}else{return(false)}})
                            break;
                        case 'larger_equals':
                            rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] >= f.queryString){return(true)}}else{return(false)}})
                            break;
                        case 'smaller_equals':
                            rowData = rowData.filter(x=> {if(x.data.customAttributes){if(x.data.customAttributes[f.field] <= f.queryString){return(true)}}else{return(false)}})
                            break;
                    }
                }else{
                    switch(f.queryType){
                        case 'equals':
                            rowData = rowData.filter(x=> x.data[f.field] == f.queryString)
                            break;
                        case '!equals':
                            rowData = rowData.filter(x=> x.data[f.field] != f.queryString)
                            break;
                        case 'contains':
                            rowData = rowData.filter(x => x.data[f.field].includes(f.queryString))
                            break;
                        case '!contains':
                            rowData = rowData.filter(x => !x.data[f.field].includes(f.queryString))
                            break;
                        case 'larger':
                            rowData = rowData.filter(x=> x.data[f.field] > f.queryString);
                            break;
                        case 'smaller':
                            rowData = rowData.filter(x=> x.data[f.field] < f.queryString);
                            break;
                        case 'larger_equals':
                            rowData = rowData.filter(x=> x.data[f.field] >= f.queryString);
                            break;
                        case 'smaller_equals':
                            rowData = rowData.filter(x=> x.data[f.field] <= f.queryString);
                            break;
                    }
                }
            }
        }

        if(teamDivs){
            setTeamsList(rowData)
        }else{
            setCompetitorList(rowData)
        }
    }, [useFilters])

    useEffect(async()=>{
        let use = [...useFilters]
        for(let x of useFilters){
            if(!selectedFilters.includes(x.field)){
                let i = use.indexOf(x);
                if(i > -1){
                    use.splice(i,1)
                }
            }
        }
        setUseFilters(use)
    }, [selectedFilters])

    const init = async() =>{
        setFilterOpen(false)
        setSelectedFilters([])
        setFilterOpen([])
        setUseFilters([])
        setCompetitorList([])
        setTeamsList([])
        setAlreadyAssignedList([])
        setAlreadyAssignedPopup(false)
    }

    const onLeave = async() => {
        const i = init();
        i.then(()=>{
            setTrigger(false)
        })
    }

    const addCompetitors = async() =>{

        let badCompetitorList = [];

        if(!teamDivs && !compDets.ScoreTeam){
            for(let x of competitorList){
                if(x.data.Division && x.data.Division != ''){
                    badCompetitorList.push(x.id)
                }
            }
            setAlreadyAssignedList(badCompetitorList)
            if(badCompetitorList.length > 0){
                setAlreadyAssignedPopup(true)

            }else{
                complete()
            }
        }else{
            for(let x of teamList){
                if(x.data.Division && x.data.Division != ''){
                    badCompetitorList.push(x.id)
                }
            }

            setAlreadyAssignedList(badCompetitorList)
            if(badCompetitorList.length > 0){
                setAlreadyAssignedPopup(true)
            }else{
                complete()
            }
        }
    }

    const complete = async() => {
        let arrToAdd = []

        if(!teamDivs && !compDets.ScoreTeam){
            for(let x of competitorList){
                if(!alreadyAssignedList.includes(x.id)){
                    arrToAdd.push(x.id)
                }
            }
        }else{
            for(let t of teamList){
                if(!alreadyAssignedList.includes(t.id)){
                    arrToAdd.push(t.id)
                }
            }
        }


        if(arrToAdd.length > 0){
            await addFunction(arrToAdd)
            onLeave();
        }

    }

    const refine = async() =>{
        setAlreadyAssignedPopup(false)
    }


    return(
        <Popup
            trigger={trigger}
            content={
            <View style={{width:'80vw', height:'60vh', justifyContent:'space-between'}}>
                <Text style={styles.H1}>Add By Filter</Text>
                <View style={{width:'100%'}}>
                    <View style={{width:'100%', flexDirection:'row'}}>
                        <View style={{width:'50%', justifyContent:'center', alignItems:'center'}}>
                            <Text style={{fontWeight:'bold'}}>Configure Filter</Text>
                            <DropDownPicker
                                style={{marginTop:'0.5vh',marginLeft:'12.5%',borderRadius:'10px', width:'75%', flexDirection:'row', borderColor:'black', borderWidth:'1px', borderRadius:'5px'}}
                                dropDownContainerStyle={{marginLeft:'12.5%',width:'75%', flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}
                                listItemContainerStyle={{flexDirection:'row'}}
                                multiple={true}
                                open={filterOpen}
                                value={selectedFilters}
                                items={filterOptions}
                                setOpen={setFilterOpen}
                                setValue={setSelectedFilters}
                                setItems={setFilterOptions}
                            />
                            <ScrollView
                                style={{height:'100%', marginTop:'2vh', width:'100%'}}
                            >
                                {selectedFilters.map(f =>{
                                    let obj = filterOptions.find(x=> x.value == f)
                                    let currentState = useFilters.find(x=>x.field == f)
                                    if(obj){
                                        let display_name = obj.label;
                                        if(obj.type == 'text'){
                                            let qType = 'equals';
                                            let qString = '';
                                            if(currentState){
                                                qType = currentState.queryType;
                                                qString = currentState.queryString;
                                            }

                                            return(
                                                <View
                                                    key={f}
                                                    style={{flexDirection:'row',justifyContent:'flex-end', width:'100%', marginBottom:'1.5vh'}}
                                                >
                                                    <Text style={{ marginTop:'3px', fontWeight:'bold'}}>{display_name} </Text>
                                                    <View style={{width:'35%'}}>
                                                        <RNPickerSelect
                                                            style={{inputWeb:{height:'3.5vh'}}}
                                                            onValueChange={(a)=>{
                                                                qType = a;
                                                                if(qString != ''){
                                                                    changeFilter(f,a,qString,obj.fcustom)
                                                                }
                                                            }}
                                                            value={qType}
                                                            items={[
                                                                { label: 'Is', value: 'equals' },
                                                                { label: 'Is Not', value: '!equals' },
                                                                { label: 'Contains', value: 'contains' },
                                                                { label: 'Does Not Contain', value: '!contains' },
                                                            ]}
                                                        />
                                                    </View>


                                                    <TextInput style={[styles.inputBox, {width:'44%', marginLeft:'1%'}]}
                                                        defaultValue={qString}
                                                        onChangeText={(a)=>{
                                                            qString = a
                                                            if(qType != ''){
                                                                changeFilter(f,qType,a, obj.fcustom)
                                                            }
                                                        }}
                                                    />
                                                </View>
                                            )
                                        }else if(obj.type == 'number'){
                                            let qType = 'equals';
                                            let qString = '';
                                            if(currentState){
                                                qType = currentState.queryType;
                                                qString = currentState.queryString;
                                            }
                                            return(
                                                <View
                                                    key={f}
                                                    style={{flexDirection:'row',justifyContent:'flex-end', width:'100%', marginBottom:'1.5vh'}}
                                                >
                                                    <Text style={{ marginTop:'3px', fontWeight:'bold'}}>{display_name} </Text>
                                                    <View style={{width:'35%'}}>
                                                        <RNPickerSelect
                                                            style={{inputWeb:{height:'3.5vh'}}}
                                                            onValueChange={(a)=>{
                                                                qType = a;
                                                                if(qString != ''){
                                                                    changeFilter(f,a,qString, obj.fcustom)
                                                                }
                                                            }}
                                                            value={qType}
                                                            items={[
                                                                { label: 'Is', value: 'equals' },
                                                                { label: 'Is Not', value: '!equals' },
                                                                { label: 'Larger Than', value: 'larger' },
                                                                { label: 'Smaller Than', value: 'smaller' },
                                                                { label: 'Larger/Equals', value:'larger_equals'},
                                                                { label: 'Smaller/Equals', value:'smaller_equals'}
                                                            ]}
                                                        />
                                                    </View>
                                                    <TextInput style={[styles.inputBox, {width:'44%', marginLeft:'1%'}]}
                                                        onChangeText={(a)=>{
                                                            qString = a
                                                            if(qType != ''){
                                                                changeFilter(f,qType,a, obj.fcustom)
                                                            }
                                                        }}
                                                    />
                                                </View>
                                            )
                                        }else if(obj.type == 'select'){
                                            let qType = 'equals';
                                            let qString = '';
                                            let cusOpt = [{label:'', value:''}]
                                            cusOpt = obj.fOptions.map(x=>{return({label: x.name, value:x.name})})
                                            if(currentState){
                                                qType = currentState.queryType;
                                                qString = currentState.queryString;
                                            }
                                            return(
                                                <View
                                                    key={f}
                                                    style={{flexDirection:'row',justifyContent:'flex-end', width:'100%', marginBottom:'1.5vh'}}
                                                >
                                                    <Text style={{ marginTop:'3px', fontWeight:'bold'}}>{display_name} </Text>
                                                    <View style={{width:'35%'}}>
                                                        <RNPickerSelect
                                                            style={{inputWeb:{height:'3.5vh'}}}
                                                            onValueChange={(a)=>{
                                                                qType = a;
                                                                if(qString != ''){
                                                                    (f,a,qString, obj.fcustom)
                                                                }
                                                            }}
                                                            value={qType}
                                                            items={[
                                                                { label: 'Equals', value: 'equals' },
                                                                { label: 'Not Equals', value: '!equals' },
                                                            ]}
                                                        />
                                                    </View>
                                                    <View style={{width:'44%', marginLeft:'1%'}}>
                                                        <RNPickerSelect
                                                            style={{inputWeb:{height:'3.5vh'}}}
                                                            onValueChange={(a)=>{
                                                                qString = a;
                                                                if(qType != ''){
                                                                    changeFilter(f,qType,a, obj.fcustom)
                                                                }
                                                            }}
                                                            value={qString}
                                                            items={cusOpt}
                                                        />
                                                    </View>
                                                </View>
                                            )

                                        }else if(obj.type == 'link'){
                                            let qType = 'equals';
                                            let qString = '';
                                            if(currentState){
                                                qType = currentState.queryType;
                                                qString = currentState.queryString;
                                            }
                                            let optList = [];
                                            if(obj.value == 'Division'){
                                                for(let d of divisions){
                                                    optList.push({label:d.data.Name,value:d.id})
                                                }
                                            }else if(obj.value == 'Team'){
                                                for(let t of teams){
                                                    optList.push({label:t.data.Name,value:t.id})
                                                }
                                            }
                                            return(
                                                <View
                                                    key={f}
                                                    style={{flexDirection:'row',justifyContent:'flex-end', width:'100%', marginBottom:'1.5vh'}}
                                                >
                                                    <Text style={{ marginTop:'3px', fontWeight:'bold'}}>{display_name} </Text>
                                                    <View style={{width:'35%'}}>
                                                        <RNPickerSelect
                                                            style={{inputWeb:{height:'3.5vh'}}}
                                                            onValueChange={(a)=>{
                                                                qType = a;
                                                                if(qString != ''){
                                                                    changeFilter(f,a,qString, obj.fcustom)
                                                                }
                                                            }}
                                                            value={qType}
                                                            items={[
                                                                { label: 'Is', value: 'equals' },
                                                                { label: 'Is Not', value: '!equals' }
                                                            ]}
                                                        />
                                                    </View>
                                                    {qType=='equals' || qType=='!equals' ?
                                                        <View style={{width:'35%'}}>
                                                            <RNPickerSelect
                                                                style={{inputWeb:{height:'3.5vh'}}}
                                                                onValueChange={(a)=>{
                                                                    qString = a;
                                                                    if(qType != ''){
                                                                        changeFilter(f,qType,a, obj.fcustom)
                                                                    }
                                                                }}
                                                                value={qString}
                                                                items={optList}
                                                            />
                                                        </View>
                                                    :null}
                                                </View>
                                            )
                                        }

                                    }
                                })}
                            </ScrollView>
                        </View>
                        <View style={{width:'50%', justifyContent:'center', alignItems:'center'}}>

                            {!teamDivs ? (
                                <Text style={{fontWeight:'bold'}}>Competitors to be Added</Text>
                            ):(
                                <Text style={{fontWeight:'bold'}}>Teams to be Added</Text>
                            )}
                            <ScrollView
                                style={{borderColor:'black', borderWidth:1 , maxHeight:'40vh', width:'75%', minHeight:'40vh', minWidth:'10vw'}}
                                contentContainerStyle={{maxHeight:'100%', paddingLeft:'0.5vw', paddingVertical:'0.5vh'}}

                            >
                                {!teamDivs ? (
                                    competitorList.map(c=>{
                                        return(
                                            <View key={c.id}>
                                                <Text style={[(c.data.Division && c.data.Division != divID) ? {color:'red'}:null]}>{c.data.LastName}, {c.data.FirstName}</Text>
                                            </View>
                                        )
                                    })
                                ):(
                                    teamList.map(t=>{
                                        return(
                                            <View key={t.id}>
                                                <Text style={[(t.data.Division && t.data.Division != divID) ? {color:'red'}:null]}>{t.data.Name}</Text>
                                            </View>
                                        )
                                    })
                                )}
                            </ScrollView>
                        </View>
                    </View>
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-evenly'}}>
                    <View style={{width:'25vw', height:'5vh'}}>
                        <AppButton
                            title={"Cancel"}
                            textStyle={ButtonStyles.appButtonText}
                            containerStyle={ButtonStyles.appButtonContainer}
                            onPress={()=>onLeave()}
                        />
                    </View>
                    <View style={{width:'25vw', height:'5vh'}}>
                        <AppButton
                            title={"Add To Division"}
                            textStyle={ButtonStyles.appButtonText}
                            containerStyle={ButtonStyles.appButtonContainer}
                            onPress={()=> addCompetitors()}
                        />
                    </View>
                </View>
                <Popup
                    trigger={alreadyAssignedPopup}
                    innerStyle={{
                        padding:10,
                        width:'40%',
                        minWidth:'40vw',
                        height:'auto',
                        backgroundColor:'white',
                        borderRadius:20,
                        borderWidth:2
                    }}
                    content={
                        <View>
                            <Text style={styles.H1}>Uh Oh... Hold Up</Text>
                            <View style={{marginTop:'2vh'}}>
                                <Text>Some of these competitors already belong to a Division (Marked in Red).</Text>
                                <Text>You can either cancel a refine your filter or add the other competitors.</Text>
                            </View>
                            <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3vh'}}>
                                <View style={{width:'10vw'}}>
                                    <AppButton
                                        title={"Refine"}
                                        textStyle={ButtonStyles.appButtonText}
                                        containerStyle={ButtonStyles.appButtonContainer}
                                        onPress={() => refine()}
                                    />
                                </View>
                                <View style={{width:'10vw'}}>
                                    <AppButton
                                        title={"Add Remaining"}
                                        textStyle={ButtonStyles.appButtonText}
                                        containerStyle={ButtonStyles.appButtonContainer}
                                        onPress={()=> complete()}
                                    />
                                </View>
                            </View>
                        </View>
                    }
                />
            </View>}
        />
    )
}



export default DivFilteredAddPopup;