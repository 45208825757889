import React, { createRef, useContext, useEffect, useState } from 'react';
import { CheckBox, Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import * as ImagePicker from 'expo-image-picker';

import { Text, View, TextInput, Image } from 'react-native';

import { Ionicons } from '@expo/vector-icons';

import { AppContext } from "../../../../../AppContextProvider";
import { useTheme } from 'react-native-paper';

import retButtonStyles from '../../../../../styles/ButtonStyleSheet';
import retStyles from '../../../../../styles/MainStyleSheet';
import LoadingModal from '../../../../parts/LoadingModal';
import Popup from '../../../../parts/Popup';
import AppButton from '../../../../parts/AppButton';
import AlertPopup from '../../../../parts/AlertPopup';
import Header from '../../../../parts/Header';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../fbManage';
import { validateColour } from '../../../../../styles/colourManagement';

function ThemeMenuComponent ({theme, orgDets, userOrgDets, setSaveButton}){
    const styles = retStyles(theme);
    const ButtonStyles = retButtonStyles(theme);
    const {gVpWidth} = useContext(AppContext)


    const [previewTheme, setPreviewTheme] = useState(orgDets.data.Theme);
    const [image, setImage] = useState(orgDets.data.Theme.customImage);
    const [imageMode, setCusImageMode] = useState(orgDets.data.Theme.customImageMode);

    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setSaveButton(
            <View style={{height:'5vh', marginLeft:'2vw', marginRight:'2vw'}}>
                <AppButton
                    //textStyle={ButtonStyles.appButtonText}
                   // containerStyle = {ButtonStyles.appButtonContainer}
                    title="Save Org Theme"
                    onPress={()=>saveTheme()}
                />
            </View>
        )

    }, [previewTheme])

    const changeColour = async (key, e) =>{
        let validated = await validateColour(e.toLowerCase())
        if(validated){
            let obj = {
                ...previewTheme,
                colors:{
                    ...previewTheme.colors,
                    [key]:validated
                }
            }
            setPreviewTheme(obj)
        }
    }

    useEffect(async ()=>{
        let obj = {
            ...previewTheme,
            customImage: image,
            customImageMode: imageMode
        }
        setPreviewTheme(obj)
    },[image, imageMode])

    const changeImage = async() => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            setImage(result.uri);
        }
    }

    const saveTheme = async() =>{
        setLoading(true)
        await updateDoc(doc(db, 'Organisations', orgDets.id), {Theme:previewTheme})
        setLoading(false)
    }

    return(
        <View>
            <View style={{flexDirection:'row', justifyContent:'space-between', width:'65vw'}}>
                <Text style={styles.H2}>Organisational Theme</Text>
            </View>
            <View style={{marginTop:'2.5vh'}}>
                <View style={gVpWidth > 600 ? {flexDirection:'row', justifyContent:'space-between'}: {flexDirection:'column'}}>
                    <View style={[{flexDirection:'row'}, gVpWidth >= 600 ? { justifyContent:'flex-end'}:{justifyContent:'space-between', marginTop:'1.vh'}]}>
                        <Text style={gVpWidth >= 600 ? {width:'14vw', fontWeight:'bold', textAlign:'right'}: {fontWeight:'bold', minWidth:"14vw"}}>Main:</Text>
                        <View style={{flexDirection:'row'}}>
                            <TextInput style={{borderColor:'black', borderWidth:1, marginLeft:'0.5vw'}}
                                placeholder={previewTheme.colors.mainColour}
                                onChangeText={(e)=>changeColour('mainColour',e)}
                            />
                            <View
                                style={{marginLeft:'1vw',width:'20px', height:'20px', backgroundColor:previewTheme.colors.mainColour, borderRadius:'5px'}}
                            />
                        </View>
                    </View>
                    <View style={[{flexDirection:'row'}, gVpWidth >= 600 ? { justifyContent:'flex-end'}:{justifyContent:'space-between', marginTop:'1.vh'}]}>
                        <Text style={{fontWeight:'bold'}}>Competition Name:</Text>
                        <View style={{flexDirection:'row'}}>
                            <TextInput style={{borderColor:'black', borderWidth:1, marginLeft:'0.5vw'}}
                                placeholder={previewTheme.colors.compNameColour}
                                onChangeText={(e)=>changeColour('compNameColour',e)}
                            />
                            <View
                                style={{marginLeft:'1vw',width:'20px', height:'20px', backgroundColor:previewTheme.colors.compNameColour, borderRadius:'5px'}}
                            />
                        </View>
                    </View>
                </View>
                <View style={gVpWidth >= 600 ? {flexDirection:'row', justifyContent:'space-between', marginTop:'1vh'}:{flexDirection:'row', justifyContent:'space-between', marginTop:'1.vh'}}>
                    <View style={[{flexDirection:'row'}, gVpWidth >= 600 ? { justifyContent:'flex-end'}:{justifyContent:'space-between', width:'100%'}]}>
                        <Text style={{minWidth:'14vw', textAlign:'right', fontWeight:'bold'}}>Header Background:</Text>
                        <View style={{flexDirection:'row'}}>
                            <TextInput style={{borderColor:'black', borderWidth:1, marginLeft:'0.5vw', maxHeight:'3vh'}}
                                placeholder={previewTheme.colors.headerBackground}
                                onChangeText={(e)=>changeColour('headerBackground',e)}
                            />
                            <View
                                style={{marginLeft:'1vw',width:'20px', height:'20px', backgroundColor:previewTheme.colors.headerBackground, borderRadius:'5px'}}
                            />
                        </View>
                    </View>
                </View>
                <View>
                    <View style={gVpWidth >= 600 ? {flexDirection:'row', width:'50%', marginTop:'1vh'} : {flexDirection:'column', width:'100%', marginTop:'1vh'}}>
                        <Text style={gVpWidth >= 600 ? {fontWeight:'bold', width:'14vw', textAlign:'right'}:{textAlign:'left', fontWeight:'bold'}}>Logo:</Text>
                        <View style={gVpWidth > 600 ? {width:'48%', maxWidth:'48%',  marginLeft:'0.5vw'}: {marginLeft:'0.5vw'}}>
                            {image == '' ?

                                <View style={gVpWidth > 600 ? {width:'10vw', height:'4vh'}: {maxWidth:"40%", height:'4vh'}}>
                                    <AppButton
                                        textStyle={ButtonStyles.appButtonText}
                                        containerStyle = {[ButtonStyles.appButtonContainer]}
                                        title="Add"
                                        onPress={()=>changeImage()}
                                    />
                                </View>
                            :
                                <View style={{flexDirection:'row'}}>
                                    <View style={{minWidth:'10vw', height:'4vh'}}>
                                        <AppButton
                                            title={"Remove"}
                                            containerStyle={ButtonStyles.appButtonContainer}
                                            textStyle={ButtonStyles.appButtonText}
                                            onPress={()=>setImage('')}
                                        />
                                    </View>

                                    <Picker
                                        selectedValue={imageMode}
                                        onValueChange={(itemValue) => setCusImageMode(itemValue)}
                                        style={{borderRadius:'5px', marginLeft:'1vw', minWidth:'7vw'}}
                                    >
                                        <Picker.Item label="Center" value="center"/>
                                        <Picker.Item label="Contain" value="contain"/>
                                        <Picker.Item label="Cover" value="cover"/>
                                        <Picker.Item label="Repeat" value="repeat"/>
                                        <Picker.Item label="Stretch" value="stretch"/>

                                    </Picker>

                                </View>
                            }
                        </View>
                    </View>
                </View>
                <View style={{marginTop:'2vh'}}>
                    <Text style={{fontWeight:'bold', marginBottom:'1vh'}}>Preview:</Text>
                    <View style={{width:'98%', height:'7.8vh', marginRight:'1%', marginLeft:'1%'}}>
                    <Header
                        preview={true}
                        name={"Preview"}
                        theme={previewTheme}
                        buttons={
                            <View style={{flexDirection:'row'}}>
                                <View style={{justifyContent:'center', alignItems:'center'}}>
                                    <TouchableOpacity>
                                    <Ionicons name="settings" size={24} color={previewTheme.colors.mainColour} />
                                    </TouchableOpacity>
                                </View>
                                <View style={{minWidth:'10vw', height:'3vh', marginLeft:'2vw'}}>
                                    <AppButton
                                        title={"Create"}
                                        containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:previewTheme.colors.mainColour}]}
                                        textStyle={ ButtonStyles.appButtonText}
                                    />
                                </View>
                                <View style={{ height:'3vh', minWidth:'10vw', marginLeft:'2vw'}}>
                                    <AppButton
                                        title={"Logout"}
                                        containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:previewTheme.colors.mainColour}]}
                                        textStyle={ ButtonStyles.appButtonText}
                                    />
                                </View>
                            </View>
                        }
                    />
                </View>
                </View>
            </View>
            <LoadingModal
                trigger={loading}
                theme={theme}
            />
        </View>
    )
}

export default ThemeMenuComponent;