import React from "react";
import { View, Button, StyleSheet, TouchableOpacity, Text } from "react-native";
import retButtonStyles from "../../styles/ButtonStyleSheet";


const Overlay = ({trigger, content, style}) => {

 if(trigger)
 {return(
    <View style={[style, {position: 'absolute', zIndex: 1, height:'100%', width:'100%', justifyContent:'center', alignItems:'center'}]} >
        {content}
    </View>
  )}else{
      return(null)
  }
};

export default Overlay;