import React from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { useTheme } from 'react-native-paper';



const Popup = ({content, trigger, containerStyle, innerStyle, innerContainerStyle}) =>{
    const theme = useTheme();

    /* if(!containerStyle){
        containerStyle = popupStyle.container
    } */

    /* if(!innerStyle){
        innerStyle = popupStyle.inner
        //innerStyle.color = theme.colors.mainColour;
    }

    if(!innerContainerStyle){
        innerContainerStyle = popupStyle.innerContainerStyle
    } */

    return(
        <Modal
            visible={trigger}
            transparent={true}
        >
            <View
                style={[popupStyle.dcontainer, containerStyle]}
            >
                <View
                    style={[popupStyle.dinnerContainerStyle, innerContainerStyle]}
                >
                    <View style={[popupStyle.dinner, innerStyle]}>
                        {content}
                    </View>
                </View>
            </View>

        </Modal>
    )
}

const popupStyle = StyleSheet.create({
    dcontainer:{
        width:'100%',
        height:'100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent:'center'
    },

    dinnerContainerStyle:{
        justifyContent:'center',
        flexDirection:'row'
    },

    dinner:{
        padding:10,
        width:'80%',
        height:'auto',
        backgroundColor:'white',
        borderRadius:20,
        borderWidth:2
    }
})

export default Popup;