import React, { createRef, useContext, useEffect, useState } from 'react';
import { Text, View, Button, TextInput, Image } from 'react-native';
import * as RootNavigation from '../../RootNavigation';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import AppButton from '../parts/AppButton';
import Header from '../parts/Header';
import AlertPopup from '../parts/AlertPopup';

import ThemeScreen from './Settings/Pages/ThemeScreen.js';

import retButtonStyles from '../../styles/ButtonStyleSheet';
import retStyles from '../../styles/MainStyleSheet';

import { AppContext } from '../../AppContextProvider';
import LoadingModal from '../parts/LoadingModal.js';
import OrganisationScreen from './Settings/Pages/OrganisationScreen.js';
import AccountSettings from './Settings/Pages/AccountSettings.js';
import { AntDesign } from '@expo/vector-icons';
import { useTheme } from 'react-native-paper';

const SettingsScreen = ({route, navigation}) => {
    const styles = retStyles();
    const ButtonStyles = retButtonStyles();
    const theme = useTheme()

    const {globalUserDets} = useContext(AppContext);

    const settingRef = createRef();
    const Tab = createBottomTabNavigator();

    const [loading,setLoading] = useState(false);
    const [alertTrigger, setAlertTrigger] = useState('')

    const [saveButton, setSaveButton] = useState();
    const {gVpWidth} = useContext(AppContext)
    const [popButtons, setPopButtons] = useState(false)

    if(loading){
        return(
            <LoadingModal
                trigger={loading}
            />
        )
    }else{
    return(
        <NavigationContainer independent={true} ref={settingRef}>
            <Header
                buttons={
                    <View style={{flexDirection:'row'}}>
                        {/* <Text style={{height:'100%', alignItems:'center', justifyContent:'center'}}>Example</Text> */}
                        <View style={{minWidth:'10vw', height:'5vh'}}>
                            <AppButton
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle = {ButtonStyles.appButtonContainer}
                                title="Home"
                                onPress={()=>RootNavigation.navigate('Home',{param:'refresh'})}
                            />
                        </View>
                    </View>
                }
            />
            <View style={{flexDirection:'row', marginBottom:'2vh', marginLeft:'2vw', justifyContent:'space-between'}}>
                {!(gVpWidth <= 600) ?
                    <>
                    <View style={{flexDirection:'row'}}>
                        <View style={{minWidth:'10vw', height:'5vh'}}>
                            <AppButton
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle = {ButtonStyles.appButtonContainer}
                                title="Theme"
                                onPress={()=>settingRef.current.navigate('theme')}
                            />
                        </View>

                        <View style={{minWidth:'15vw', height:'5vh', marginLeft:'2vw'}}>
                            <AppButton
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle = {ButtonStyles.appButtonContainer}
                                title="Organisations"
                                onPress={()=>settingRef.current.navigate('organisation')}
                            />
                        </View>

                        <View style={{minWidth:'15vw', height:'5vh', marginLeft:'2vw'}}>
                            <AppButton
                                textStyle={ButtonStyles.appButtonText}
                                containerStyle = {ButtonStyles.appButtonContainer}
                                title="Account"
                                onPress={()=>settingRef.current.navigate('account')}
                            />
                        </View>
                    </View>
                    {saveButton ?
                        saveButton
                    : null}
                    </>
                :
                    <>
                        {!popButtons ?
                            <>
                            <View style={{height:'5vh', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                                <AntDesign name="caretright" size={24} color={theme.colors.mainColour} onPress={() => setPopButtons(true)}/>
                                {saveButton ?
                                    saveButton
                                :null}
                            </View>
                            </>
                        :
                        <>
                        <View style={{flexDirection:'row'}}>
                            <View style={{minWidth:'10vw', height:'5vh'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle = {ButtonStyles.appButtonContainer}
                                    title="Theme"
                                    onPress={()=>settingRef.current.navigate('theme')}
                                />
                            </View>

                            <View style={{minWidth:'15vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle = {ButtonStyles.appButtonContainer}
                                    title="Organisations"
                                    onPress={()=>settingRef.current.navigate('organisation')}
                                />
                            </View>

                            <View style={{minWidth:'15vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle = {ButtonStyles.appButtonContainer}
                                    title="Account"
                                    onPress={()=>settingRef.current.navigate('account')}
                                />
                            </View>
                        </View>
                        <AntDesign name="caretleft" size={24} color={theme.colors.mainColour} onPress={() => setPopButtons(false)}/>
                        </>
                        }
                    </>
                }
            </View>
            {/**Main Screen */}
            <Tab.Navigator screenOptions={{headerShown:false, tabBarStyle:{display:'none'}}} >
                <Tab.Screen name="theme" options={{unmountOnBlur:true}}>
                    {()=> <ThemeScreen setSaveButton={setSaveButton} userDets={globalUserDets} setLoading={setLoading}/>}
                </Tab.Screen>
                <Tab.Screen name="organisation" options={{unmountOnBlur:true}}>
                    {()=> <OrganisationScreen setSaveButton={setSaveButton} />}
                </Tab.Screen>
                <Tab.Screen name="account">
                    {()=> <AccountSettings setSaveButton={setSaveButton}/>}
                </Tab.Screen>
            </Tab.Navigator>
        <LoadingModal trigger={loading}/>
        <AlertPopup
            trigger={(alertTrigger != '')}
            setTrigger={setAlertTrigger}
            title={alertTrigger.title}
            alertText={alertTrigger.text}
        />
        </NavigationContainer>

    )}
  };

export default SettingsScreen;