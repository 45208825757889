import React, { useEffect, useRef, useContext, useState } from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { useTheme } from 'react-native-paper';
import { Animated, TextInput, TouchableHighlight, Text, TouchableOpacity } from 'react-native-web';
import retStyles from '../../styles/MainStyleSheet';
import Checkbox from 'expo-checkbox';
import { AppContext } from '../../AppContextProvider';
import LicenceDetsPopup from './LicenceDetsPopup';


const LicenceRow = ({theme, licence}) =>{
    if(!theme){
        theme = useTheme();
    }

    const {gVpWidth} = useContext(AppContext);
    const styles = retStyles(((theme)?theme:null));
    const [licenceDetsPopup, setLicenceDetsPopup] = useState(false);

    const {globalOrganisationDets, globalUserDets} = useContext(AppContext);
    const org = globalOrganisationDets.find(o => (o.id== licence.data.organisationID))
    const userOrgDets = globalUserDets.data.Organisations[org.id]

    function capitalizeFirstLetter(string) {
        if(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
      }

    return(
        <View key={licence.id} style={{width:'100%', backgroundColor:'#a9a9a960', paddingHorizontal:'5%', paddingVertical:'1%', borderRadius:'15px'}}>
            <TouchableOpacity onPress={()=>setLicenceDetsPopup(true)}>
                {gVpWidth > 600 ?
                    <>
                        <View style={{flexDirection:'row', width:'100%', justifyContent:'space-between'}}>
                        <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Organisation: </Text>
                                <Text>{org.data?.name}</Text>
                            </View>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>ID: </Text>
                                <Text>{licence.id}</Text>
                            </View>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Status: </Text>
                                <Text style={licence.data.status === 'active' ? {color:'green'} : {color:'red'}}>{capitalizeFirstLetter(licence.data.status)}</Text>
                            </View>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Start Date: </Text>
                                <Text>{new Date(licence.data.startDate.toDate()).toLocaleDateString('en',{month:'short', day:'numeric', year:'numeric'})}</Text>
                            </View>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>End Date: </Text>
                                <Text>{new Date(licence.data.endDate.toDate()).toLocaleDateString('en',{month:'short', day:'numeric', year:'numeric'})}</Text>
                            </View>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Auto Renew?</Text>
                                <Checkbox
                                    color={theme.colors.mainColour}
                                    value={!licence.data.cancelAtEnd}
                                />
                            </View>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>{`Valid: `}</Text>
                                <Checkbox value={licence.data.valid} color={theme.colors.mainColour}/>
                            </View>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Licence Count: </Text>
                                <Text>{licence.data.count}</Text>
                            </View>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Applied: </Text>
                                <Text>{licence.data.userIDs.length}</Text>
                            </View>
                        </View>
                    </>
                :
                    <>
                        <View style={{flexDirection:'column', width:'100%', justifyContent:'space-between'}}>
                            <View style={{flexDirection:'row', width:'100%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Organisation: </Text>
                                <Text>{org.data?.name}</Text>
                            </View>
                            <View style={{flexDirection:'row', width:'100%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>ID: </Text>
                                <Text>{licence.id}</Text>
                            </View>
                        </View>
                        <View style={{flexDirection:'row', width:'100%', justifyContent:'space-evenly'}}>
                            <View style={{flexDirection:'row', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Status: </Text>
                                <Text style={licence.data.status === 'active' ? {color:'green'} : {color:'red'}}>{capitalizeFirstLetter(licence.data.status)}</Text>
                            </View>
                            <View style={{flexDirection:'row', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Auto Renew?</Text>
                                <Checkbox
                                    color={theme.colors.mainColour}
                                    value={!licence.data.cancelAtEnd}
                                />
                            </View>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                            <View style={{flexDirection:'row', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Start Date: </Text>
                                <Text>{new Date(licence.data.startDate.toDate()).toLocaleDateString('en',{month:'short', day:'numeric', year:'numeric'})}</Text>
                            </View>
                            <View style={{flexDirection:'row', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>End Date: </Text>
                                <Text>{new Date(licence.data.endDate.toDate()).toLocaleDateString('en',{month:'short', day:'numeric', year:'numeric'})}</Text>
                            </View>

                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>{`Valid: `}</Text>
                                <Checkbox value={licence.data.valid} color={theme.colors.mainColour}/>
                            </View>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Count: </Text>
                                <Text>{licence.data.count}</Text>
                            </View>
                            <View style={{flexDirection:'row', width:'30%', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold'}}>Applied: </Text>
                                <Text>{licence.data.userIDs.length}</Text>
                            </View>
                        </View>
                    </>
                }
            </TouchableOpacity>
            <LicenceDetsPopup
                licence={licence}
                trigger={licenceDetsPopup}
                setTrigger={setLicenceDetsPopup}
                permissionLevel={userOrgDets.role}

            />
        </View>
    )
}

export default LicenceRow;