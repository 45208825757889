import React from 'react';
import { StyleSheet, Text, View, Button } from 'react-native';
import { useTheme } from 'react-native-paper';

    const retStyles = (theme) =>{
        if(!theme){
            theme = useTheme()
        }

        const styles = StyleSheet.create({
           inputBox:{
               borderColor:'black',
               borderWidth:'0.5px',
               backgroundColor:'white',
               paddingLeft:'3px'
           },

           headerLogo:{
                textAlign:'center',
                fontStyle:'normal',
                fontFamily: 'BRUSH',
                color:theme.colors.mainColour,
                fontSize:'5vw',
                paddingLeft:'2VW',
                width:'28vw',
           },

           header:{
               flexDirection:'row',
               height:'10vh',
               marginBottom:'5vh',
               backgroundColor:theme.colors.headerBackground,
           },

           H1:{
               fontWeight:'800',
               fontSize:26,
               color:theme.colors.mainColour,

           },

           H2:{
               fontWeight:'700',
               fontSize:20,

           },

           selectableList:{
               height:'4vh',
               justifyContent:'center',
               paddingLeft:'0.5vw'
           },

           checkbox:{
                //marginVertical:'auto',
                //alignSelf:'center',
                marginLeft:'0.25vw'
           }


        })

        return(styles)
    }


 export default retStyles