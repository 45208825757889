import React from 'react';
import { StyleSheet, Text, View, Button } from 'react-native';
import { useTheme } from 'react-native-paper';

    const retLeaderboardStyle = (size) =>{
        const theme = useTheme()

        if(size=='large'){
            //Larger Screen
            const LeaderboardStyle = StyleSheet.create({
                container:{
                    width:'25vw',
                    marginLeft:'5vw'
                }
            })
            return(LeaderboardStyle)
        }else{
            //Small
            const LeaderboardStyle = StyleSheet.create({
                container:{
                    width:'65vw',
                    maxWidth:'65vw',
                    marginHorizontal: '15vw',
                },

            })
            return(LeaderboardStyle)
        }

    }


 export default retLeaderboardStyle