import React, { createRef, useContext, useEffect, useState} from 'react';
import { Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import * as ImagePicker from 'expo-image-picker';
import Checkbox from 'expo-checkbox';

import { Text, View, TextInput, Image, Modal } from 'react-native';

import { Ionicons } from '@expo/vector-icons';

import { AppContext } from "../../../../../AppContextProvider";
import { useTheme } from 'react-native-paper';

import retButtonStyles from '../../../../../styles/ButtonStyleSheet';
import retStyles from '../../../../../styles/MainStyleSheet';
import LoadingModal from '../../../../parts/LoadingModal';
import Popup from '../../../../parts/Popup';
import AppButton from '../../../../parts/AppButton';
import AlertPopup from '../../../../parts/AlertPopup';
import Header from '../../../../parts/Header';
import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { isEmpty } from '@firebase/util';
import { db } from '../../../../../fbManage';
import { validateColour } from '../../../../../styles/colourManagement';
import { firebase } from '@react-native-firebase/firestore';

import {cancelOrganisationInvite, inviteUserToOrg, removeUserFromOrg, updateUserLicence} from '../../../../../data'
import Overlay from '../../../../parts/Overlay';
import LoadingOverlay from '../../../../parts/LoadingOverlay';
import ConfirmPopup from '../../../../parts/confirmPopup';

function UsersMenuComponent ({theme, orgDets, userOrgDets,setSaveButton}){
    const styles = retStyles(theme);
    const ButtonStyles = retButtonStyles(theme);

    const [userLoading, setUserLoading] = useState(false);
    const [userLoadingPopup, setUserLoadingPopup] = useState(false);
    const [loadingTrigger, setLoadingTrigger] = useState(false);

    const [AlertPopupTrigger, setAlertPopupTrigger] = useState('');
    const [removeUserConfTrigger, setRemoveUserConfTrigger] = useState('');
    const [addUserTrigger, setAddUserTrigger] = useState(false);
    const [pendingUserList, setPendingUserList] = useState(false)

    const [userList, setUserList] = useState([]);
    const [pendingList, setPendingList] = useState([])
    const {globalLicencingDets, globalUserDets, gVpWidth} = useContext(AppContext);

    const [licencedUserCount, setLicencedUserCount] = useState(0);
    const [selectedUser, setSelectedUser] = useState({});
    const [selectedUserOrgDets, setSelectedUserOrgDets] = useState({});

    const [inviteEmail, setInviteEmail] = useState('');

    useEffect(async ()=>{
        setUserLoading(true)
        if(!isEmpty(selectedUser)){
            let userOrgDets = selectedUser.data.Organisations[orgDets.id]
            setSelectedUserOrgDets(userOrgDets)
        }else{
            setUserLoading(false)
        }
    }, [selectedUser])

    useEffect(()=>{
        if(!isEmpty(selectedUser) && !isEmpty(selectedUserOrgDets)){
            setUserLoading(false)
        }
    }, [selectedUserOrgDets, selectedUser])

    useEffect(()=>{
        let isMounted = true;
        if(isMounted){
            setSelectedUser({})
            let q = query(collection(db, "Users"), where("OrganisationIDs", "array-contains", orgDets.id));
            setPendingList(orgDets.data?.PendingInvites?.sort())
            var unsub_Userlist = onSnapshot(q,(snapshot)=>{
                let arr = [...snapshot.docs.map(doc=>({id:doc.id, data:doc.data()}))]
                setUserList(arr)
                if(!isEmpty(selectedUser)){
                    let newSelectedUserDets = arr.find(x=> x.id == selectedUser.id)
                    setSelectedUser(newSelectedUserDets)
                }
            })
        }
        return ()=>{
            isMounted = false
            unsub_Userlist()
        }
    }, [orgDets])

    useEffect(async ()=>{
        //Figure out licenced users
        let count = 0
        let filteredLicences = globalLicencingDets.filter(l=>(l.data.organisationID === orgDets.id))
        for(let l of filteredLicences){
            count = count + l.data.userIDs.length
        }
        setLicencedUserCount(count)
    },[userList, globalLicencingDets])

    useEffect(()=>{
        setSaveButton(null)

    }, [selectedUser, selectedUserOrgDets ])

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const updateLicence = async (toggle) =>{
        setUserLoadingPopup(true)
        if(toggle){
            const res = await updateUserLicence(selectedUser.id, selectedUser.data.Organisations,null, orgDets.id,toggle)
            if(res.data.code == 201){
                //no licence to apply to
                setAlertPopupTrigger({title:'No Valid Licence', text:'There are no valid licences to apply this user to.'})
            }else if(res.data.code == 202){
                //licence full
                setAlertPopupTrigger({title:'Licences are full', text:'All licences for this organisation are full. Please purchase another one or deativate another user.'})
            }else if(res.data.code == 200){
                let od = selectedUserOrgDets;
                od.active = true;
                setSelectedUserOrgDets(od)
            }
        }else{
            let licenceID = globalLicencingDets.find(x=> (x.data.organisationID === orgDets.id && x.data.userIDs.includes(selectedUser.id) && x.data.valid)).id
            const res = await updateUserLicence(selectedUser.id, selectedUser.data.Organisations,licenceID, orgDets.id,toggle)
            if(res.data.code == 200){
                let od = selectedUserOrgDets;
                od.active = false;
                setSelectedUserOrgDets(od)
            }
        }
        setUserLoadingPopup(false)
    }
    const changeValue = async (field, value)=>{
        let id = selectedUser
        setUserLoadingPopup(true)
        let userOrgDets = {...selectedUserOrgDets}
        let userAllOrgsDets = {...selectedUser.data.Organisations}

        if(field == 'role'){
            let numberOfAdmins = 0
            let i = 0
            while(numberOfAdmins < 1 && i < userList.length){
                if(userList[i].data.Organisations[orgDets.id].role <= 0 && userList[i].id != selectedUser.id){
                    numberOfAdmins++
                }
                i++
            }
            if(numberOfAdmins > 0){
                await updateDoc(doc(db, "Users", selectedUser.id), {Organisations:{...userAllOrgsDets, [orgDets.id]:{...userOrgDets, [field]:value}}})
            }else{
                setAlertPopupTrigger({title:'No Managers', text:'There must be at least one manager remaining in the organisation.'})
            }
        }

        let ogd = selectedUser.data.Organisations[orgDets.id]
        ogd[field]= value
        setSelectedUserOrgDets(ogd)
        setUserLoadingPopup(false)
    }

    const removeUser = async ()=>{
        setLoadingTrigger(true)
        if(selectedUser.data.Organisations[orgDets.id].role < 3){
            setAlertPopupTrigger({title:'Cannot remove user', text:'You cannot remove a user with a role of Admin or Manager.'})
        }else{
            let res = await removeUserFromOrg(selectedUser.id, orgDets.id)
            if(res.data.code == 200){
                setAlertPopupTrigger({title:'User Removed', text:'User was removed from the organisation.'})
            }else{
                setAlertPopupTrigger({title:'Oh No', text:'Something went wrong removing the user. Please try again'})
            }
            setRemoveUserConfTrigger('')
        }
        setLoadingTrigger(false)
    }

    const addUser = async () =>{
        if(validateEmail(inviteEmail)){
            if(pendingList?.includes(inviteEmail.toUpperCase())){
                setAlertPopupTrigger({title:'Invite already sent', text:'You can resend the invite in the "Pending Invites" menu.'})
            }else{
                if(!isEmpty(userList.find(x=> x.data.Details.email == inviteEmail.toLowerCase()))){
                    setAlertPopupTrigger({title:'User already exists', text:'This user already exists in the organisation.'})
                }else{
                    setLoadingTrigger(true);
                    let res = await inviteUserToOrg(inviteEmail, orgDets.id)
                    if(res.data.code == 200){
                        setAddUserTrigger(false)
                        setInviteEmail('')
                        setAlertPopupTrigger({title:'Invite Sent', text:`An invite has been sent to ${inviteEmail}`})
                    }else{
                        setAlertPopupTrigger({title:'Oh No!', text:'Something went wrong. Please try again'})
                    }
                    setLoadingTrigger(false);
                }
            }
        }else{
            setAlertPopupTrigger({title:'Invalid Email', text:'Please enter a valid email address.'})
        }
    }

    const cancelInvite = async (email) => {
        setLoadingTrigger(true)
        let res = await cancelOrganisationInvite(email, orgDets.id)
        if(res.code != 200){
            setAlertPopupTrigger({title:'Oh No!', text:'Something went wrong! Please try again.'})
        }
        setLoadingTrigger(false)
    }

    const resendInvite = async(email) => {
        setLoadingTrigger(true)
        if(!isEmpty(userList.find(x=> x.data.Details.email == inviteEmail.toLowerCase()))){
            setAlertPopupTrigger({title:'User already exists', text:'This user already exists in the organisation.'})
        }else{
            let res = await inviteUserToOrg(email, orgDets.id)
            if(res.data.code == 200){
                setAlertPopupTrigger({title:'Invite Sent', text:`An invite has been sent to ${email}`})
            }else{
                setAlertPopupTrigger({title:'Oh No!', text:'Something went wrong. Please try again'})
            }
        }
        setLoadingTrigger(false)
    }

    useEffect(()=>{
        setInviteEmail(inviteEmail.trim())
    },[inviteEmail])


    const retUserList = () => {
        return(
            <View style={{justifyContent:'space-between', height:'100%'}}>
                <View style={{height:'90%'}}>

                    <View style={[{flexDirection:'row', justifyContent:'space-between', marginBottom:'2vh'},gVpWidth <= 600 ? {marginTop:'1vh'}:null]}>
                        <View style={{flex:2, marginRight:'3vw'}}>
                            <Text style={styles.H2}>Users</Text>

                        </View>
                        <View style={{marginRight:'1vw', width:'5vw', height:'5vh', flex:1}}>
                            <View style={{height:'3vh'}}>
                                <AppButton
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    textStyle={ButtonStyles.appButtonText}
                                    title={"Add"}
                                    onPress={()=>{setAddUserTrigger(true)}}
                                />
                            </View>
                            <View style={{width:'100%', justifyContent:'center', alignItems:'center'}}>
                                <Text style={{fontSize:9, textDecorationLine:'underline'}} onPress={() => setPendingUserList(true)}>Pending Invites</Text>
                            </View>
                        </View>
                    </View>
                    <ScrollView style={{width:'30vw', marginBottom:'0.5vh'}} showVerticalScrollIndicator={false}>
                        {userList.map(u =>{
                            return(
                                <View style={{height:'5vh', marginTop:'0.5vh'}} key={u.id}>
                                    <TouchableOpacity onPress={() =>{setSelectedUser(u)}}>
                                        <View style={{flexDirection:'row', alignItems:'center'}}>
                                            <Text style={{marginRight:'0.5vw'}}>{u.data?.Details?.LastName}, {u.data?.Details?.FirstName}</Text>
                                            {u.data?.Organisations[orgDets.id]?.active ?
                                                <Ionicons name="checkmark-sharp" size={16} color="black" />
                                            :null}
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            )
                        })}
                    </ScrollView>
                </View>
                {userOrgDets.role <= 1 ?
                    <View style={{flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                        <Text style={{width:'30%', fontSize:'80%', textAlign:'center'}}>Count: {userList.length}</Text>
                        <Text style={{width:'30%', fontSize:'80%', textAlign:'center'}}>Licenced: {licencedUserCount}</Text>
                        <Text style={{width:'30%', fontSize:'80%', textAlign:'center'}}>Licences: {orgDets.data.licenceCount}</Text>
                    </View>
                :null}
            </View>
        )
    }

    const retUserDets = () => {
        return(
            <View style={{height:'100%', widht:'100%'}}>
                <LoadingOverlay
                    trigger={userLoadingPopup}
                    theme={theme}
                />
                <View style={{width:'100%', justifyContent:'space-between', flexDirection:'row'}}>
                    <Text style={styles.H2}>{selectedUser.data.Details.LastName}, {selectedUser.data.Details.FirstName}</Text>
                    <View style={{minWidth:'10vw', height:'5vh'}}>

                        <AppButton
                            containerStyle={ButtonStyles.appButtonContainer}
                            textStyle={ButtonStyles.appButtonText}
                            title={"Back"}
                            onPress={()=>{setSelectedUser({}); setSelectedUserOrgDets({})}}
                        />

                    </View>
                </View>
                <View style={{marginTop:'2.5vh'}}>
                    <View style={{flexDirection:'row', marginBottom:'1vh'}}>
                        <Text style={{fontWeight:'bold', marginRight:'1vw'}}>Email:</Text>
                        <Text>{selectedUser.data.Details.email}</Text>
                    </View>
                    <View style={{flexDirection:'row', alignItems:'center', marginBottom:'1vh'}}>
                        <Text style={{marginRight:'1vw'}}>Licenced:</Text>
                        <Checkbox
                            onValueChange={(e)=>updateLicence(e)}
                            style={{marginLeft:'0.2vw', marginTop:'0.4vh'}}
                            color={theme.colors.mainColour}
                            value={selectedUserOrgDets.active}
                        />
                    </View>
                    <View style={gVpWidth > 600 ? {flexDirection:'row', justifyContent:'center', alignItems:'center'} : {flexDirection:'row'}}>
                        <Text style={{marginRight:'0.5vw'}}>Role:</Text>
                        <Picker
                            style={{minWidth:'20vw', maxWidth:'50vw'}}
                            selectedValue={selectedUserOrgDets.role}
                            onValueChange={(e)=>changeValue('role', parseInt(e))}
                        >
                            <Picker.Item label={""} value={""}/>
                            {(globalUserDets.data.Organisations[orgDets.id].role == 0) ?
                                <Picker.Item label={"Manager"} value={0}/>
                            : null}
                            <Picker.Item label={"Admin"} value={1}/>
                            <Picker.Item label={"Configurer"} value={2}/>
                            <Picker.Item label={"User"} value={3}/>
                        </Picker>
                    </View>
                    <View style={{width:'100%', justifyContent:'center',alignContent:'center', marginTop:'2vh'}}>
                        <View style={{minWidth:'10vw', height:'5vh', margin:'auto'}}>
                            {selectedUser.id != globalUserDets.id ?
                                <AppButton
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    textStyle={ButtonStyles.appButtonText}
                                    title={"Remove"}
                                    onPress={()=>{setRemoveUserConfTrigger({title:'Remove User', text:'Are you sure you want to remove this user from this orgnaisation?'})}}
                                />
                            :
                                <AppButton
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    textStyle={ButtonStyles.appButtonText}
                                    title={gVpWidth > 600 ? "Leave" : "Leave Organisation"}
                                    onPress={()=>{setRemoveUserConfTrigger({title:'LEAVE?', text:'Are you sure you want to leave this organisation?'})}}
                                />
                            }
                        </View>
                    </View>
                </View>


            </View>
        )
    }


    return(
        <View style={(gVpWidth > 600) ? {flexDirection:'row'}:null}>
            {gVpWidth > 600 ?
            <>
                <View style={{borderRightWidth:1, borderColor:'grey', borderStyle:'dashed', height:'50vh'}}>
                    {retUserList()}
                </View>
                <View style={{marginLeft:'2vw'}}>
                    {!isEmpty(selectedUser) && !isEmpty(selectedUserOrgDets)?
                        !userLoading?
                            (retUserDets())
                        :null
                    :null}
                </View>
            </>
            :
                !isEmpty(selectedUser) &&  !isEmpty(selectedUserOrgDets) ?
                    <View style={{marginTop:'1.5vh'}}>
                        {retUserDets()}
                    </View>
                :
                    <View style={{height:'60vh'}}>
                        {retUserList()}
                    </View>
            }
            <Popup
                trigger={addUserTrigger}
                innerStyle={{
                    padding:10,
                    width:(gVpWidth > 600 ) ? '40%' : "90%",
                    height:'auto',
                    backgroundColor:'white',
                    borderRadius:20,
                    borderColor:theme.colors.mainColour,
                    borderWidth:2
                  }}
                content={
                    <View>
                        <Text style={styles.H1}>Add User</Text>
                        <View style={{marginLeft:'1vw', marginTop:'3vh'}}>
                            <View style={{flexDirection:'row', marginBottom:'1vh'}}>
                                <Text style={{marginRight:'1vw'}}>User's Email:</Text>
                                <TextInput
                                    value={inviteEmail}
                                    onChangeText={(e)=>setInviteEmail(e)}
                                    style={styles.inputBox}
                                />
                            </View>
                            <View>
                                <Text>Once the user has accepted the invite you can apply licences and change their role.</Text>
                            </View>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3vh'}}>
                            <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    textStyle={ButtonStyles.appButtonText}
                                    onPress={() => addUser()}
                                    title={'Add'}
                                />
                            </View>
                            <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    textStyle={ButtonStyles.appButtonText}
                                    onPress={() => setAddUserTrigger(false)}
                                    title={'Cancel'}
                                />
                            </View>
                        </View>
                    </View>
                }

            />

            <Popup
                trigger={pendingUserList}
                innerStyle={{
                    padding:10,
                    width:(gVpWidth > 600 ) ? '40%' : "90%",
                    height:'auto',
                    backgroundColor:'white',
                    borderRadius:20,
                    borderColor:theme.colors.mainColour,
                    borderWidth:2
                }}
                content={
                    <View style={{height:'80vh'}}>
                        <View style={{height:'15%', width:'100%', alignItems:'center'}}>
                            <Text style={styles.H1}>Pending Invites</Text>
                        </View>
                        <View style={{height:'75%'}}>
                            {pendingList?.length > 0 ?
                            pendingList?.map((e,i) =>(

                                <View style={{flexDirection:'row', width:'80%', marginLeft:'10%', justifyContent:'space-between'}} key={e}>
                                    <Text style={{width:'60%'}}>{e}</Text>
                                    <View style={{width:'40%', justifyContent:'space-evenly', flexDirection:'row'}}>
                                        <View>
                                            <AppButton
                                                containerStyle={ButtonStyles.appButtonContainer}
                                                textStyle={ButtonStyles.appButtonText}
                                                onPress={()=>{resendInvite(e)}}
                                                title={'Resend'}
                                            />
                                        </View>
                                        <View>
                                            <AppButton
                                                containerStyle={ButtonStyles.appButtonContainer}
                                                textStyle={ButtonStyles.appButtonText}
                                                onPress={()=>{cancelInvite(e)}}
                                                title={'Cancel'}
                                            />
                                        </View>
                                    </View>
                                </View>
                            ))
                            :
                            <View>
                                <Text>No Pending Invites</Text>
                            </View>
                            }
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', height:'10%', width:'100%'}}>
                            <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                                <AppButton
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    textStyle={ButtonStyles.appButtonText}
                                    onPress={() => setPendingUserList(false)}
                                    title={'Done'}
                                />
                            </View>
                        </View>
                    </View>
                }
            />

            <ConfirmPopup
                trigger={(removeUserConfTrigger != '')}
                setTrigger={setRemoveUserConfTrigger}
                title={removeUserConfTrigger.title}
                alertText={removeUserConfTrigger.text}
                theme={theme}
                confirmFunction={()=>{removeUser()}}
            />
            <AlertPopup
                trigger={(AlertPopupTrigger != '')}
                setTrigger={setAlertPopupTrigger}
                title={AlertPopupTrigger.title}
                alertText={AlertPopupTrigger.text}
                theme={theme}
            />
            <LoadingModal
                trigger={loadingTrigger}
                customMessage={''}
            />
        </View>
    )
}

export default UsersMenuComponent;