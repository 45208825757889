import React, { useContext, useEffect, useRef } from 'react';
import { View, StyleSheet, Modal } from "react-native";
import { useTheme } from 'react-native-paper';
import { Animated, TextInput, TouchableHighlight, Text } from 'react-native-web';
import { AppContext } from '../../AppContextProvider';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import retStyles from '../../styles/MainStyleSheet';
import AppButton from './AppButton';
import Popup from './Popup';


const AlertPopup = ({trigger, title,alertText, setTrigger, theme}) =>{
    if(!theme){
        theme = useTheme()
    }
    const styles = retStyles(theme)
    const ButtonStyles = retButtonStyles(theme)
    const {gVpWidth} = useContext(AppContext)


    return(
        <Popup
            trigger={trigger}
            innerStyle={{
            padding:10,
            width: (gVpWidth > 600) ? '40%' : '80%',
            height:'auto',
            backgroundColor:'white',
            borderRadius:20,
            borderColor:theme.colors.mainColour,
            borderWidth:2
            }}
            content={
                <View style={{justifyContent:'center', alignItems:'center'}}>
                    <Text style={[styles.H1, {color:theme.colors.mainColour}]}>{title}</Text>
                    <Text>{alertText}</Text>
                    <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3vh'}}>
                        <View style={{minWidth:'10vw', height:'5vh', marginLeft:'2vw'}}>
                            <AppButton
                            title={"OK"}
                            containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                            textStyle={ ButtonStyles.appButtonText}
                            onPress={()=>{setTrigger('')}}
                            />
                        </View>
                    </View>
                </View>
            }
        />
    )
}

export default AlertPopup;