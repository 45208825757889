import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import React, {useEffect, useState} from "react";
import { Provider as PaperProvider } from "react-native-paper";
import { DefaultTheme } from "react-native-paper";

import NetInfo from "@react-native-community/netinfo";

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db } from "./fbManage.js";
import LoadingModal from "./components/parts/LoadingModal.js";
import { View } from "react-native-web";
import { isEmpty } from '@firebase/util';

import { useFonts } from 'expo-font';
import NoInternetPopup from "./components/parts/NoInternetModal.js";
import NoLicenceAlert from "./components/parts/NoLicenceAlert.js";
import { Dimensions } from "react-native";


export const AppContext = React.createContext(null)

export default ({children}) =>{
    const auth = getAuth();

    const [fonts] = useFonts({
        BRUSHKURSIVE: require('./assets/fonts/Brushkursive.ttf'),
        BRUSH: require('./assets/fonts/BRUSHSCI.ttf')
    })

    const themeObj ={
        ...DefaultTheme,
        colors:{
            ...DefaultTheme.colors,
            mainColour: "#0000ff",
            accentColour: '#808080',
            headerBackground: '#a9a9a9'
        },
        logoFont:'Brush Script MT',
        //logoFont:fonts.BrushKursive,
        name: "SKOREBRD",
        customImage:'',
        customImageMode:'contain'
    }
    const [theme, changeTheme] = useState(themeObj)

    const [userState, setUserState] = useState()

    const [globalUserDets, setGlobalUserDets] =useState({});
    const [globalOrganisationDets, setGlobalOrganisationDets] = useState([]);
    const [organisationsIDList, setOrganisationsIDList] = useState([]);
    const [globalLicencingDets, setGlobalLicencingDets] = useState([]);
    const [licenceAlertTrigger, setLicenceAlertTrigger] = useState(0);
    const [loading, setLoading] = useState(true)
    const [firstRun, setFirstRun] = useState(true)

    const [internetStatus, setInternetStatus] = useState()

    const [gVpWidth, setVPWidth] = useState(Dimensions.get('screen').width)
    const [gVpHeight, setVPHeight] = useState(Dimensions.get('screen').height)

    Dimensions.addEventListener('change', (e)=>{
        const {width, height} = e.screen;
        setVPWidth(width)
        setVPHeight(height)
    })

    onAuthStateChanged(auth, (user)=>{
        if(user){
            setUserState(user)

        }else{
            setUserState(null)
        }

    })

    useEffect(()=>{
        let unsubscribe = NetInfo.addEventListener(state => {
            setInternetStatus(state.isConnected)
        });
        return () => {
            unsubscribe();
        };
    },[])

    useEffect(()=>{
        if(!isEmpty(globalUserDets) && !globalOrganisationDets.length == 0){
            setLoading(false)
        }else{
            setLoading(false)
        }
    }, [userState, globalUserDets, globalOrganisationDets])


    useEffect(()=>{
        if(firstRun){setFirstRun(false)}
        if(!firstRun){
            if(userState){
                var unsub_user = onSnapshot(doc(db, "Users", userState.uid),onSnap=>{
                    if(onSnap.exists){
                        setGlobalUserDets({id:onSnap.id, data:onSnap.data()})
                        changeTheme({...theme, ...onSnap.data().Settings.Theme})
                    }
                })

            }else{
                setGlobalUserDets({})
                setLoading(false)
            }
        }

        return()=>{
            if(userState){
                unsub_user();
            }
        }
    },[userState])

    useEffect(async ()=>{
        var unsub_org = ()=>{};
        let arr = []
        if(!isEmpty(globalUserDets)){
            if(globalUserDets.data.Organisations){
                let q = query(collection(db, "Organisations"), where("users", "array-contains", globalUserDets.id));
                unsub_org = onSnapshot(q, async (onSnap)=>{
                    arr =[...onSnap.docs.map(doc=>({id:doc.id, data:doc.data()}))]
                    setGlobalOrganisationDets(arr)
                    setOrganisationsIDList(arr.map(org=>org.id))
                })
            }
        }
        return()=>{
            unsub_org();
        }
    },[globalUserDets])


    useEffect(async ()=>{
        var unsub_Licencing = () =>{}
        if(!isEmpty(globalOrganisationDets) && !isEmpty(globalUserDets) && organisationsIDList.length > 0){
            let q = query(collection(db, "Licences"), where("organisationID", "in", organisationsIDList), where('endDate', '>=', new Date()));
            unsub_Licencing = onSnapshot(q,async (snap)=>{
                setGlobalLicencingDets(snap.docs.map(doc=>({id:doc.id, data:doc.data()})))
            })
        }
        return()=>{
            unsub_Licencing();
        }
    }, [globalOrganisationDets, globalUserDets, organisationsIDList])

    useEffect(()=>{
        if(!isEmpty(globalOrganisationDets) && !isEmpty(globalUserDets) && organisationsIDList.length > 0){
            if(globalLicencingDets.filter(l => l.data.userIDs.includes(globalUserDets.id)).length == 0 && !loading){
                setLicenceAlertTrigger(licenceAlertTrigger + 1);
            }else{
                setLicenceAlertTrigger(2)
            }
        }
    }, [globalLicencingDets, loading])

    return(
        <AppContext.Provider value={{theme:theme, globalUserDets, globalOrganisationDets, globalLicencingDets, userState, gVpHeight, gVpWidth}}>
            <PaperProvider theme={theme}>
                {loading ?
                    <LoadingModal trigger={loading}/>
                :licenceAlertTrigger == 1 ?
                <NoLicenceAlert theme={theme} setAlertTrigger={setLicenceAlertTrigger} trigger={licenceAlertTrigger}/>
                :
                    (children)
                }

                {!internetStatus ? (
                    <NoInternetPopup/>
                ):null}
            </PaperProvider>
        </AppContext.Provider>
    )
}