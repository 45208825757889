import { Text, View, Button, TextInput } from 'react-native';
import Checkbox from 'expo-checkbox';
import { ScrollView, TouchableOpacity } from 'react-native-web';
import React, { useState, useEffect, useContext } from 'react';

import AppButton from '../../parts/AppButton';

import { Entypo } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import {
    addDoc,
    deleteDoc,
    doc,
    getDocs,
    setDoc,
    updateDoc,
    collection,
    onSnapshot,
    } from 'firebase/firestore';
import { db } from '../../../fbManage.js';
import retStyles from '../../../styles/MainStyleSheet';
import retButtonStyles from '../../../styles/ButtonStyleSheet';
import { useTheme } from 'react-native-paper';
import DivFilteredAddPopup from '../divFilteredAddPopup';
import AlertPopup from '../../parts/AlertPopup';
import { AppContext } from '../../../AppContextProvider';


function Divisions({theme, compID, compDets}){
    const styles = retStyles(theme)
    const ButtonStyles = retButtonStyles(theme);

    const {gVpWidth} = useContext(AppContext)

    const divisionsDir = "Competitions/"+compID+"/Divisions"
    const divisionsCollection = collection(db, divisionsDir)
    const teamsDir = "Competitions/"+compID+"/Teams"
    const teamsCollection = collection(db, teamsDir)
    const competitorsDir = "Competitions/"+compID+"/Competitors"
    const competitorsCollection = collection(db, competitorsDir)

    const [enabled, setEnabled] = useState(false)
    const [teamDivs, setTeamDivs] = useState(false)
    const [noneDiv, setNoneDiv] = useState(false)
    const [scoreTeamState, setScoreTeamState] = useState();
    const [removedMembers, setRemovedMembers] = useState([])
    const [filteredAddPopupTrigger, setFilteredAddPopupTrigger] = useState(false);
    const [alertTrigger, setAlertTrigger] = useState('');

    const [divisions, setDivisions] = useState([])
    const [competitors, setCompetitors] = useState([])
    const [competitorList, setCompetitorList] = useState([])
    const [teams,setTeams] = useState([])
    const [teamList, setTeamList] = useState([]);
    const [selectEntryID,setSelectEntryID] = useState('')
    const [selectMemberID, setSelectMemberID] = useState('')

    const [divisionID, setDivisionID] = useState('')
    const [divisionName, setDivisionName] = useState('')
    const [divisionMembers, setDivisionMembers] = useState([])
    const [divisionMemberIDs, setDivisionMemberIDs] = useState([])

    const [oldObj, setOldObj] = useState({})
    const [divSaveHighlight, setDivSaveHighlight] = useState(true)

    useEffect(()=>{
        let isMounted = true
        if(Object.keys(compDets).length > 0){

            if(compDets.DivisionsEnabled){
                setEnabled(compDets.DivisionsEnabled)
            }else{
                setEnabled(false)
            }
            if(compDets.TeamDivs || compDets.ScoreTeam){
                setTeamDivs(true)
            }else{
                setTeamDivs(false)
            }
            if(compDets.ScoreTeam){
                setScoreTeamState(true)
            }else{
                setScoreTeamState(false)
            }
            if(compDets.IncludeNullDivs){
                setNoneDiv(true)
            }else{
                setNoneDiv(false)
            }

            var unsub_team = onSnapshot(teamsCollection, (snapshot)=>{

                setTeams(snapshot.docs.map(doc => ({id:doc.id, data:doc.data()})).sort((a,b)=> a.data.Name - b.data.Name))
            })

            var unsub_competitor = onSnapshot(competitorsCollection, (snap)=>{
                let tArr = snap.docs.map(d => ({id:d.id, data:d.data()}))
                tArr.sort((a, b) => {
                    if(a.data.LastName < b.data.LastName) { return -1; }
                    if(a.data.LastName > b.data.LastName) { return 1; }
                    return 0;
                })
                if(isMounted){
                    setCompetitors(tArr)
                }
            })

            var unsub_divisions = onSnapshot(divisionsCollection, (snapshot)=>{
                if(isMounted){
                    setDivisions(snapshot.docs.map(doc => ({id:doc.id, data:doc.data()})))
                }
            })
        }

        return()=>{
            isMounted = false
            unsub_competitor()
            unsub_divisions()
            unsub_team()
        }

    },[])

    useEffect(()=>{
        let nobj = {
            Name: divisionName,
            DivisionMembers: divisionMemberIDs,
        }
        if(JSON.stringify(nobj) == JSON.stringify(oldObj)){
            setDivSaveHighlight(false)
        }else{
            setDivSaveHighlight(true)
        }
    },[divisionMemberIDs, divisionName, oldObj])

    useEffect(()=>{
        setCompetitorList(competitors)
    }, [competitors])

    useEffect(()=>{
        const arr = teams.sort((a,b) => (a.data.Name - b.data.Name))
        setTeamList(arr)
    }, [teams])

    const changeEnabled =() =>{
        if(enabled == false){
            //Make True
            updateDoc(doc(db, 'Competitions', compID),{DivisionsEnabled: true})
                .then(()=>{
                    setEnabled(true)
                })
        }else if(enabled == true){
            //Make false
            updateDoc(doc(db, 'Competitions',compID),{DivisionsEnabled: false})
                .then(()=>{
                    setEnabled(false)
                })
        }
    }

    const changeTeamDivs = () =>{
        if(!teamDivs){
            setTeamDivs(true)
            cleanupCompetitorDiv()
            updateDoc(doc(db, 'Competitions', compID), {TeamDivs: true})
            .catch(()=>{
                setTeamDivs(false)
                setAlertTrigger({title:'Oh No!', text:'There was an error updating your competition details.'})
            })
        }else{
            setTeamDivs(false)
            cleanupTeamDiv()
            updateDoc(doc(db, 'Competitions', compID),{TeamDivs: false})
            .catch(()=>{
                setTeamDivs(true)
                setAlertTrigger({title:'Oh No!', text:'There was an error updating your competition details.'})
            })
        }
    }

    const changeNoDivDiv = () =>{
        if(!noneDiv){
            setNoneDiv(true)
            updateDoc(doc(db, 'Competitions', compID), {IncludeNullDivs: true})
            .catch(()=>{
                setNoneDiv(false)
                setAlertTrigger({title:'Oh No!', text:'There was an error updating your competition details.'})
            })
        }else{
            setNoneDiv(false)
            updateDoc(doc(db, 'Competitions', compID),{IncludeNullDivs: false})
            .catch(()=>{
                setNoneDiv(true)
                setAlertTrigger({title:'Oh No!', text:'There was an error updating your competition details.'})
            })
        }
    }

    const cleanupTeamDiv = () =>{
        let divisions = getDocs(divisionsCollection)
        divisions.then((data)=>{
            for(let d of data.docs){
                if(d.data().DivisionMembers){
                    for(let m of d.data().DivisionMembers){
                        let mr = doc(db,teamsDir,m)
                        updateDoc(mr,{Division:'',DivisionName:''})

                    }
                }
                let dr = doc(db,divisionsDir,d.id)
                deleteDoc(dr)
            }
        })
    }

    const cleanupCompetitorDiv = () => {
        let divisions = getDocs(divisionsCollection)
        divisions.then((data)=>{
            for(let d of data.docs){
                if(d.data().DivisionMembers){
                    for(let m of d.data().DivisionMembers){
                        let mr = doc(db,competitorsDir,m)
                        updateDoc(mr,{Division:'',DivisionName:''})

                    }
                }
                let dr = doc(db,divisionsDir,d.id)
                deleteDoc(dr)
            }
        })
    }

    const newDivision = () =>{
        setDivisionID('');
        setDivisionName('');
        setDivisionMembers([]);
        setDivisionMemberIDs([])
        setOldObj({})
    }

    const removeDivision = () =>{
        if(divisionID){
            if(compDets.ScoreTeam || teamDivs){
                for(let member of divisionMembers){
                    updateDoc(doc(teamsCollection, member.id), {Division:'', DivisionName:''})
                }
            }else{
                for(let member of divisionMembers){
                    updateDoc(doc(competitorsCollection, member.id), {Division:'', DivisionName:''})
                }
            }
            deleteDoc(doc(db,divisionsDir, divisionID));
        }
    }

    const selectDivision = (id) =>{
        let data = divisions.find(x =>x.id === id).data
        setDivisionID(id)
        setDivisionName(data.Name)
        setDivisionMemberIDs(data.DivisionMembers)
        if(data.DivisionMembers){
            if(compDets.ScoreTeam || teamDivs){
                let dm = teams.filter(i=> data.DivisionMembers.includes(i.id))
                setDivisionMembers(dm)
            }else{
                let dm = competitors.filter(i=> data.DivisionMembers.includes(i.id))
                setDivisionMembers(dm)
            }
        }
        let oobj = {
            Name: data.Name,
            DivisionMembers: data.DivisionMembers
        }
        setOldObj(oobj)
    }

    const selectEntry = (id) =>{
        setSelectEntryID(id);
    }

    const addMember = () =>{
        if(selectEntryID){
            if(teamDivs || compDets.ScoreTeam){
                let t = teams.find(x=>x.id === selectEntryID)
                let x = divisionMembers.find(x=>x.id === selectEntryID)
                if(!t.data.Division){
                    if(!x){
                        setDivisionMemberIDs([...divisionMemberIDs, selectEntryID])
                        setDivisionMembers([...divisionMembers, t])
                    }else{
                        setAlertTrigger({title:'ALREADY ASSIGNED', text:'This team is already in a division.'})
                    }
                }else{
                    setAlertTrigger({title:'ALREADY ASSIGNED', text:'This team is already in a division.'})
                }

            }else{
                let c = competitors.find(x=>x.id === selectEntryID)
                let x = divisionMembers.find(x=>x.id === selectEntryID)

                if(!c.data.Division){
                    if(!x){
                        setDivisionMemberIDs([...divisionMemberIDs, selectEntryID])
                        setDivisionMembers([...divisionMembers, c])
                    }else{
                        setAlertTrigger({title:'ALREADY ASSIGNED', text:'This competitor is already in a division.'})
                    }
                }else{
                    setAlertTrigger({title:'ALREADY ASSIGNED', text:'This competitor is already in a division.'})
                }
            }
        }
    }

    const addMemberGroup = async(arrToAdd)=>{
        if(arrToAdd.length > 0){
            let finishedIDArr = [...divisionMemberIDs];
            let finishedMemberArr = [...divisionMembers];

            if(!teamDivs && !compDets.ScoreTeam){
                for(let c of arrToAdd){
                    let x = competitors.find(y=>(y.id == c))
                    if(x){
                        if(!finishedIDArr.includes(c)){
                            finishedIDArr.push(c)
                            finishedMemberArr.push(x)
                        }
                    }
                }
            }else{
                for(let t of arrToAdd){
                    let x = teams.find(y => (y.id == t))
                    if(x){
                        if(!finishedIDArr.includes(t)){
                            finishedIDArr.push(t)
                            finishedMemberArr.push(x)
                        }
                    }
                }
            }

            setDivisionMemberIDs(finishedIDArr);
            setDivisionMembers(finishedMemberArr);


        }
        return("Done")
    }

    const removeMember = () =>{
        if(selectMemberID){
            let m = divisionMemberIDs.find(x => x===selectMemberID)
            let arr = divisionMemberIDs
            let arr2 = divisionMembers.filter(i => i.id!=m)
            arr = arr.filter(i => i!=m)
            setRemovedMembers([...removedMembers, m])
            setDivisionMemberIDs(arr);
            setDivisionMembers(arr2)
        }
    }

    const saveDivision = async() =>{
        if(
            !divisionName
        ){
            setAlertTrigger({title:'MISSING KEY FIELD', text:'Missing a Key Field'})
        }else{
            let obj = {
                Name:divisionName,
                DivisionMembers:divisionMemberIDs,
            }

            if(divisionID != ''){
                //Division is a selected one
                setDoc(doc(db,divisionsDir,divisionID),obj)
                .then(()=>{
                    if(compDets.ScoreTeam || teamDivs){
                        for(let i=0; i < removedMembers.length; i++){
                            if(!divisionMembers.includes(removedMembers[i])){
                                updateDoc(doc(teamsCollection,removedMembers[i]), {Division:'', DivisionName:''})
                            }
                        }
                    }else{
                        for(let i=0; i < removedMembers.length; i++){
                            if(!divisionMembers.includes(removedMembers[i])){
                                updateDoc(doc(competitorsCollection,removedMembers[i]), {Division:'', DivisionName:''})
                            }
                        }
                    }
                    for(let i=0; i < divisionMembers.length; i++){
                        //Go through and add divisionID on each member
                        if(compDets.ScoreTeam || teamDivs){
                            updateDoc(doc(teamsCollection,divisionMembers[i].id), {Division:divisionID, DivisionName:divisionName})
                        }else{
                            updateDoc(doc(competitorsCollection,divisionMembers[i].id), {Division:divisionID, DivisionName:divisionName})
                        }

                    }

                })

            }else{
                //Event is new
                addDoc(divisionsCollection,obj).then((res)=>{
                    setDivisionID(res.id)
                    for(let i=0; i < divisionMembers.length; i++){
                        //Go through and add divisionID on each member
                        if(compDets.ScoreTeam || teamDivs){
                            updateDoc(doc(teamsCollection,divisionMembers[i].id), {Division:res.id, DivisionName:divisionName})
                        }else{
                            updateDoc(doc(competitorsCollection,divisionMembers[i].id), {Division:res.id, DivisionName:divisionName})
                        }

                    }
                    if(compDets.ScoreTeam || teamDivs){
                        for(let i=0; i < removedMembers.length; i++){
                            if(!divisionMembers.includes(removedMembers[i])){
                                updateDoc(doc(teamsCollection,removedMembers[i]), {Division:'', DivisionName:''})
                            }
                        }
                    }else{
                        for(let i=0; i < removedMembers.length; i++){
                            if(!divisionMembers.includes(removedMembers[i])){
                                updateDoc(doc(competitorsCollection,removedMembers[i]), {Division:'', DivisionName:''})
                            }
                        }
                    }
                })
            }

            setOldObj(obj)
        }
    }

    const searchCompetitors =async(queryTerm)=>{
        let search_terms = queryTerm.split(" ");
        let list = [];
        for(let term of search_terms){
            list = competitors.filter(x=> {
                if(!list.includes(x)){
                    let lastName = x.data.LastName.toLowerCase()
                    let firstName = x.data.FirstName.toLowerCase()
                    let qTerm = term.toLowerCase()
                    if(lastName.includes(qTerm)){
                        return true
                    }
                    if(firstName.includes(qTerm)){
                        return true
                    }
                }
            })
        }
        setCompetitorList(list)
    }

    const searchTeams =async(queryTerm)=>{
        let search_terms = queryTerm.split(" ");
        let list = [];
        for(let term of search_terms){
            list = teams.filter(x=> {
                if(!list.includes(x)){
                    let Name = x.data.Name.toLowerCase()
                    let qTerm = term.toLowerCase()
                    if(Name.includes(qTerm)){
                        return true
                    }
                }
            })
        }
        setTeamList(list)
    }

    return(
        <View style={{height:'100%', width:'100%'}}>
            <ScrollView style={[{flexDirection:'row', marginLeft:'1vw', marginRight:'1vw', width:'100%'}]} horizontal={(gVpWidth > 600)} showsHorizontalScrollIndicator={false}>
                <View style={gVpWidth > 600 ? {flexDirection:'row', maxHeight:'5vh'}:{flexDirection:'column'}}>
                    <View style={{flexDirection:'row', alignItems:'center'}}>
                        <Text>Are divisions enabled? (Leaderboard)</Text>
                        <Checkbox
                            value={enabled}
                            onValueChange={() => changeEnabled()}
                            style={styles.checkbox}
                            color={enabled ? theme.colors.mainColour : null}
                        />
                    </View>
                    {enabled ?
                        <View style={gVpWidth > 600 ? {flexDirection:'row', marginLeft:'1.5vw', alignItems:'center'}:{flexDirection:'row'}}>
                            <Text>Do you want to treat 'no-division' as it's own division?</Text>
                            <Checkbox
                                value={noneDiv}
                                onValueChange={()=>changeNoDivDiv()}
                                style={styles.checkbox}
                                color={noneDiv ? theme.colors.mainColour : null}
                            />
                        </View>
                    :null}
                    {(enabled && !compDets.ScoreTeam && compDets.TeamsEnabled) ?(
                        <View style={gVpWidth > 600 ? {flexDirection:'row', marginLeft:'1.5vw', alignItems:'center'}:{flexDirection:'row'}}>
                            <Text>Are the divisions at a team level?</Text>
                            <Checkbox
                                value={teamDivs}
                                style={styles.checkbox}
                                onValueChange={()=>changeTeamDivs()}
                                color={teamDivs ? theme.colors.mainColour : null}
                            />
                        </View>
                    ):null}
                </View>
            </ScrollView>
            {enabled ?(
                    <View style={gVpWidth > 600 ? {flexDirection:'row', height:'85%', width:'100%'}:{flexDirection:'column', height:'85%', width:'100%'}}>
                        {/*Left side of the screen - List of Events to click and edit */}
                        <View style={gVpWidth > 600 ? {width:'44%', marginLeft:'1%', height:'90%'}:{width:'100%', paddingHorizontal:'5%', height:'20%'}}>
                            {/** + and - button for adding and removing events */}
                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                <Text>Number of Divisions: {divisions.length}</Text>
                                <View style={{flexDirection:'row'}}>
                                <TouchableOpacity onPress={()=>{newDivision()}} >
                                    <Entypo name="squared-plus" size={24} color="black" />
                                </TouchableOpacity >
                                <TouchableOpacity onPress={()=>{removeDivision()}} >
                                    <Entypo name="squared-minus" size={24} color="black" />
                                </TouchableOpacity>
                                </View>
                            </View>
                            <ScrollView style={{borderColor:'Grey', borderWidth:1, height:'80%'}}>
                                {/**LIST OF COMPS */}
                                <View style={{height:'100%'}}>
                                     {divisions.sort((a,b) => a.data.Name.localeCompare(b.data.Name)).map(d =>{
                                        return(
                                            <TouchableOpacity
                                                key={d.id}
                                                onPress={() => selectDivision(d.id)}
                                                style={[(divisionID==d.id)?{backgroundColor:theme.colors.mainColour }: {backgroundColor:'none',},,styles.selectableList]}
                                            >
                                                <Text style={(divisionID==d.id)?{color:'white' }:{color:'black'}}>{d.data.Name}</Text>
                                            </TouchableOpacity>
                                        )
                                    })}
                                </View>
                            </ScrollView>
                        </View>

                        {/*Right Side of the screen - parameters per event clicked on left */}
                        <View style={gVpWidth > 600 ? {width:'54%',paddingLeft:'5%', marginRight:'1%', height:'90%'}:{width:'100%', paddingHorizontal:'5%', height:'80%'}}>
                            {divisionID ? (
                                <Text style={{fontWeight:"900"}}>{divisionName}</Text>)
                            :(
                                <Text style={{fontWeight:'900'}}>New Division</Text>
                            )
                            }
                            <ScrollView showsVerticalScrollIndicator={false} style={{height:'90%'}} contentContainerStyle={{height:'60%'}}>

                                <View>
                                    <Text>Division Name:</Text>
                                    <TextInput
                                        value={divisionName}
                                        onChangeText={setDivisionName}
                                        style={styles.inputBox}
                                    />
                                </View>


                                <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:'2vh', height:'90%'}}>
                                    {/**Left Side */}
                                    <View style={{width:'45%', height:'100%'}}>

                                        {/***** COMPETITORS OR TEAMS */}
                                        {/**List of Competitors */}
                                        {teamDivs ? (
                                            <View style={{height:'100%'}}>
                                            <TextInput
                                                style={{backgroundColor:'white', borderRadius:'3px', marginRight:'0.5vw', paddingLeft:'0.5vw'}}
                                                placeholder={"Search"}
                                                onChangeText={(e)=>searchTeams(e)}
                                            />
                                            <ScrollView
                                                style={[{borderColor:'Grey', borderWidth:1,}, gVpWidth > 600 ? {  height:'30vh'}:{height:'50%'}]}
                                            >
                                                {teamList.sort((a,b) => a.data.Name.localeCompare(b.data.Name)).map(t => {
                                                    return(
                                                        <TouchableOpacity
                                                            key={t.id}
                                                            //Below style - also need to be red if competitor.data.teamID is not null - current red doesn't work either
                                                            style={[(selectEntryID==t.id)?{backgroundColor:theme.colors.mainColour }: {backgroundColor:'none',},styles.selectableList]}
                                                            onPress={()=>{selectEntry(t.id)}}
                                                        >
                                                            <Text style={[(!t.data.Division)?null:{color:theme.colors.mainColour},(selectEntryID==t.id)?{color:'white' }:null]}>{t.data.Name}</Text>
                                                        </TouchableOpacity>
                                                    )
                                                })}
                                            </ScrollView>
                                            </View>
                                        ):(
                                            <View style={{height:'100%'}}>
                                                <TextInput
                                                    style={{backgroundColor:'white', borderRadius:'3px', marginRight:'0.5vw', paddingLeft:'0.5vw'}}
                                                    placeholder={"Search"}
                                                    onChangeText={(e)=>searchCompetitors(e)}
                                                />
                                                <ScrollView
                                                    style={[{borderColor:'Grey', borderWidth:1,}, gVpWidth > 600 ? {  height:'30vh'}:{height:'50%'}]}
                                                >
                                                    {competitorList.sort((a,b) => a.data.LastName.localeCompare(b.data.LastName)).map(c => {
                                                        return(
                                                            <TouchableOpacity
                                                                key={c.id}
                                                                //Below style - also need to be red if competitor.data.teamID is not null - current red doesn't work either
                                                                style={[(selectEntryID==c.id)?{backgroundColor:theme.colors.mainColour }: {backgroundColor:'none',},styles.selectableList]}
                                                                onPress={()=>{selectEntry(c.id)}}
                                                            >
                                                                <Text style={[(!c.data.Division)?null:{color:theme.colors.mainColour},(selectEntryID==c.id)?{color:'white' }:null]}>{c.data.LastName}, {c.data.FirstName}</Text>
                                                            </TouchableOpacity>
                                                        )
                                                    })}
                                                </ScrollView>
                                            </View>
                                        )}

                                    </View>
                                    <View style={{justifyContent:'center'}}>
                                        <TouchableOpacity
                                            onPress={()=>addMember()}
                                            >
                                            <Entypo name="arrow-bold-right" size={24} color="black" />
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                            onPress={()=>removeMember()}
                                            >
                                            <Entypo name="arrow-bold-left" size={24} color="black" />
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                            onPress={()=>{setFilteredAddPopupTrigger(true)}}
                                        >
                                            <MaterialCommunityIcons name="filter-variant-plus" size={24} color="black" />
                                        </TouchableOpacity>
                                    </View>
                                    {/**Members of Division */}
                                    <View style={{width:'45%'}}>
                                        <Text>Members:</Text>
                                        <ScrollView
                                            style={[{borderColor:'Grey', borderWidth:1,}, gVpWidth > 600 ? {  height:'30vh'}:{height:'50%'}]}
                                        >
                                            {(compDets.ScoreTeam || teamDivs) ? (
                                                divisionMembers.map(member => {
                                                    return(
                                                        <TouchableOpacity
                                                            key={member.id}
                                                            onPress={() => setSelectMemberID(member.id)}
                                                            style={[(selectMemberID==member.id)?{backgroundColor:theme.colors.mainColour }: {backgroundColor:'none',},styles.selectableList]}
                                                        >
                                                            <Text style={(selectMemberID==member.id)?{color:'white' }:{color:'black'}}>{member.data.Name}</Text>
                                                        </TouchableOpacity>
                                                    )
                                                })
                                            ):(
                                                divisionMembers.map(member => {
                                                    return(
                                                        <TouchableOpacity
                                                            key={member.id}
                                                            onPress={() => setSelectMemberID(member.id)}
                                                            style={[(selectMemberID==member.id)?{backgroundColor:theme.colors.mainColour }: {backgroundColor:'none',},styles.selectableList]}
                                                        >
                                                            <Text style={(selectMemberID==member.id)?{color:'white' }:{color:'black'}}>{member.data.LastName}, {member.data.FirstName}</Text>
                                                        </TouchableOpacity>
                                                    )
                                                })
                                            )}
                                        </ScrollView>
                                    </View>

                                </View>


                                <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:'5%'}}>
                                    <View style={{minWidth:'25%', height:'5vh'}}>
                                        <AppButton
                                            title={"Save"}
                                            textStyle={ButtonStyles.appButtonText}
                                            containerStyle={[ButtonStyles.appButtonContainer, (!divSaveHighlight) ? {backgroundColor:theme.colors.mainColour+"50"}:null]}
                                            onPress={()=>saveDivision()}
                                        />
                                    </View>
                                    <View style={{minWidth:'25%', height:'5vh'}}>
                                        <AppButton
                                            title={"New Division"}
                                            textStyle={ButtonStyles.appButtonText}
                                            containerStyle={ButtonStyles.appButtonContainer}
                                            onPress={()=>newDivision()}
                                        />
                                    </View>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
            ):null}
            {compDets ?
                <DivFilteredAddPopup
                    trigger={filteredAddPopupTrigger}
                    setTrigger={setFilteredAddPopupTrigger}
                    teamDivs={teamDivs}
                    compDets = {compDets}
                    competitors={competitors}
                    teams={teams}
                    addFunction={(arr)=>addMemberGroup(arr)}
                    theme={theme}
                />
            : null}
            <AlertPopup
                trigger={alertTrigger}
                setTrigger={setAlertTrigger}
                title={alertTrigger.title}
                alertText={alertTrigger.text}
                theme={theme}
            />
        </View>
    );
}

export default Divisions;