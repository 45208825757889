import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';
import { getAuth, connectAuthEmulator } from "firebase/auth";

const config = {
    apiKey: "AIzaSyCHFGh3L9sIrx5Wt1Sapnt5tlV3p_g4f2k",
    authDomain: "comp-340916.firebaseapp.com",
    projectId: "comp-340916",
    storageBucket: "comp-340916.appspot.com",
    messagingSenderId: "833982796924",
    appId: "1:833982796924:web:df432a888bdbdb17c4e5f9"
};

const firebase = initializeApp(config);
const db = getFirestore()
const auth = getAuth();
const cloudFunctions = getFunctions(firebase,'us-central1')

//connectFunctionsEmulator(cloudFunctions,'localhost',5001)
//connectFirestoreEmulator(db,'localhost',8080)
//connectAuthEmulator(auth,'http://localhost:9099')

export {firebase, db, cloudFunctions}


