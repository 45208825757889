import { StyleSheet, Text, View, Button, TextInput } from 'react-native';
import Checkbox from 'expo-checkbox';
import { Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import React, { useState, useEffect, useContext } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import * as RootNavigation from '../../../RootNavigation';
import AppButton from '../../parts/AppButton';

import { Entypo } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import {
    addDoc,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
    } from 'firebase/firestore';
import { db } from '../../../fbManage.js';
import retStyles from '../../../styles/MainStyleSheet';
import retButtonStyles from '../../../styles/ButtonStyleSheet';
import { useTheme } from 'react-native-paper';
import LoadingModal from '../../parts/LoadingModal';
import AlertPopup from '../../parts/AlertPopup';
import { AppContext } from '../../../AppContextProvider';


function Teams({compID, compDets, theme}){
    const styles = retStyles(theme)
    const ButtonStyles = retButtonStyles(theme);

    const teamsDir = "Competitions/"+compID+"/Teams"
    const teamsCollection = collection(db, teamsDir)
    const competitorsDir = "Competitions/"+compID+"/Competitors"
    const competitorsCollection = collection(db, competitorsDir)
    const divisionsDir = "Competitions/"+compID+"/Divisions"
    const divisionsCollection = collection(db, divisionsDir)

    const {gVpWidth} = useContext(AppContext)

    const [enabled, setEnabled] = useState(false)
    const [scoreTeam, setScoreTeam] = useState(false)
    const [alertTrigger, setAlertTrigger] = useState('')

    const [competitors,setCompetitors] = useState([]);
    const [competitorList, setCompetitorList] = useState([]);
    const [teams, setTeams] = useState([]);

    const [teamID,setSelectTeamID] = useState('');
    const [teamName, setTeamName] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamMemberIDs, setTeamMemberIDs] = useState([]);
    const [removedMembers, setRemovedMembers] = useState([]);
    const [cusName, setCusName] = useState(false)

    const [selectCompID, setSelectCompID] = useState('');
    const [selectMemberID, setSelectMemberID] = useState('');

    const [oldObj, setOldObj] = useState({})
    const [teamSaveHighlight, setTeamSaveHighlight] = useState(true)

    useEffect(()=>{
        let isMounted = true;

        if(compDets.TeamsEnabled){
            setEnabled(compDets.TeamsEnabled)
            if(compDets.ScoreTeam){
                setScoreTeam(true)
            }else{
                setScoreTeam(false)
            }
        }else{
            setEnabled(false)
            setScoreTeam(false)
        }

        var unsub_teams = onSnapshot(teamsCollection, (snapshot)=>{
            let tArr = snapshot.docs.map(d => ({id:d.id, data:d.data()}))
            tArr.sort((a, b) => {
                if(a.data.Name < b.data.Name) { return -1; }
                if(a.data.Name > b.data.Name) { return 1; }
                return 0;
            })
            if(isMounted){
                setTeams(tArr)
            }
        })
        var unsub_competitors = onSnapshot(competitorsCollection, (snap)=>{
            let tArr = snap.docs.map(d => ({id:d.id, data:d.data()}))
            tArr.sort((a, b) => {
                if(a.data.LastName < b.data.LastName) { return -1; }
                if(a.data.LastName > b.data.LastName) { return 1; }
                return 0;
            })
            if(isMounted){
                setCompetitorList(tArr)
                setCompetitors(tArr)
            }
        })
        return()=>{isMounted = false
            unsub_teams();
            unsub_competitors();
        }
    },[])

    useEffect(()=>{updateName()},[teamMembers])
    useEffect(()=>{
        if(cusName == false){
            updateName()
        }
    },[cusName])

    useEffect(()=>{
        let nobj = {
            Name: teamName,
            TeamMembers: teamMemberIDs,
            cusName: cusName
        }
        if(JSON.stringify(nobj) == JSON.stringify(oldObj)){
            setTeamSaveHighlight(false)
        }else{
            setTeamSaveHighlight(true)
        }
    },[teamName, teamMemberIDs, cusName, oldObj])

    const changeEnabled = () =>{

        if(enabled == false){
            //Make True
            updateDoc(doc(db, 'Competitions', compID),{TeamsEnabled: true})
                .then(()=>{
                    setEnabled(true)
                })
        }else if(enabled == true){
            //Make false
            updateDoc(doc(db, 'Competitions', compID),{TeamsEnabled: false})
                .then(()=>{
                    setEnabled(false)
                    changeScoreTeam(true)
                })
        }

    }

    const changeScoreTeam = (trigger) =>{
        if(!trigger){
            if(!scoreTeam){
                updateDoc(doc(db, 'Competitions', compID),{ScoreTeam: true})
                    .then(()=>{
                        cleanupCompetitorDiv()
                        setScoreTeam(true)
                    })
            }else if(scoreTeam){
                updateDoc(doc(db, 'Competitions', compID),{ScoreTeam: false})
                    .then(()=>{
                        cleanupTeamDiv()
                        setScoreTeam(false)
                    })
            }
        }else{
            updateDoc(doc(db, 'Competitions', compID),{ScoreTeam: false})
                .then(()=>{
                    cleanupTeamDiv()
                    setScoreTeam(false)
                })
        }
    }

    const cleanupTeamDiv = () =>{
        let divisions = getDocs(divisionsCollection)
        divisions.then((data)=>{
            for(let d of data.docs){
                if(d.data().DivisionMembers){
                    for(let m of d.data().DivisionMembers){
                        let mr = doc(db,teamsDir,m)
                        updateDoc(mr,{Division:'',DivisionName:''})

                    }
                }
                let dr = doc(db,divisionsDir,d.id)
                deleteDoc(dr)
            }
        })
    }

    const cleanupCompetitorDiv = () => {
        let divisions = getDocs(divisionsCollection)
        divisions.then((data)=>{
            for(let d of data.docs){
                if(d.data().DivisionMembers){
                    for(let m of d.data().DivisionMembers){
                        let mr = doc(db,competitorsDir,m)
                        updateDoc(mr,{Division:'',DivisionName:''})

                    }
                }
                let dr = doc(db,divisionsDir,d.id)
                deleteDoc(dr)
            }
        })
    }

    const newTeam = () => {
        setSelectTeamID('')
        setTeamName('')
        setTeamMemberIDs([])
        setTeamMembers([])
        setCusName(false)

        setOldObj({})
    }

    const removeTeam = () =>{
        if(teamID){
            for(let member of teamMembers){
                updateDoc(doc(competitorsCollection, member.id), {Team:'', TeamName:''})
            }
            deleteDoc(doc(db,teamsDir, teamID));
            let teaminfo = teams.find(x=>x.id === teamID)
            let teamDets;
            if(teaminfo){
                teamDets = teaminfo.data
            }
            if(teamDets.Division){
                let dRef = doc(db,divisionsDir,teamDets.Division)
                getDoc(dRef).then((doc)=>{
                    let dm = doc.data().DivisionMembers
                    dm = dm.filter(i => i!=teamID)
                    updateDoc(dRef,{DivisionMembers:dm})
                })
            }
        }
    }

    const selectTeam = (id) => {
        let data = teams.find(x =>x.id === id).data
        setSelectTeamID(id)
        setTeamName(data.Name)
        setTeamMemberIDs(data.TeamMembers)
        if(data.TeamMembers){
            let tm = competitors.filter(i=> data.TeamMembers.includes(i.id))
            updateTeamMembers(tm)
        }
        setCusName(data.CustomName)

        let oobj = {
            Name: data.Name,
            TeamMembers: data.TeamMembers,
            cusName: data.CustomName
        }
        setOldObj(oobj);
    }

    const selectComp = (id)=> {
        let data = competitors.find(x=>x.id === id).data
        setSelectCompID(id)
    }

    const addMember = () =>{
        if(selectCompID){
            let c = competitors.find(x=>x.id === selectCompID)
            let x = teamMembers.find(x=>x.id === selectCompID)
            if(!c.data.Team){
                if(!x){
                    setTeamMemberIDs([...teamMemberIDs,selectCompID])
                    updateTeamMembers([...teamMembers, c])
                    if(removedMembers.filter(x=>x===selectCompID)[0]){
                        let newRmv = removedMembers.filter(x=>x!=selectCompID)
                        setRemovedMembers(newRmv)
                    }
                }else{
                    setAlertTrigger({title:'ALREADY ASSIGNED', text:'This competitor is already in a team.'})
                }
            }else{
                setAlertTrigger({title:'ALREADY ASSIGNED', text:'This competitor is already in a team: '+c.data.Team})
            }
            //Gets added to competitor on save
        }
    }

    const removeMember = () =>{
        if(selectMemberID){
            let m = teamMemberIDs.find(x=>x === selectMemberID)
            let arr = teamMemberIDs
            let arr2 = teamMembers.filter(i=>i.id!=m)
            arr = arr.filter(i => i!=m)
            setRemovedMembers([...removedMembers, m])
            setTeamMemberIDs(arr);
            updateTeamMembers(arr2)
            //Handling of competitor/Teamdetails gets done on save
        }
    }

    const updateTeamMembers = (array) =>{
        array.sort((a, b) => {
            if(a.data.LastName < b.data.LastName) { return -1; }
            if(a.data.LastName > b.data.LastName) { return 1; }
            return 0;
        })
        setTeamMembers(array)
    }

    const updateName = () => {
        if(!cusName){
            let n = ''
            if(teamMembers.length == 0){
                n = ''
            }else if(teamMembers.length < 3){
                for(let i =0;i<teamMembers.length;i++){
                    if(i == 0){
                        n = teamMembers[i].data.FirstName
                    }else{
                        n = n + ' & ' + teamMembers[i].data.FirstName
                    }
                }
            }else{
                for(let i = 0; i<teamMembers.length;i++){
                    if(i==0){
                        n = teamMembers[i].data.FirstName
                    }else if(teamMembers.length-1 == i){
                        n = n + ' & ' + teamMembers[i].data.FirstName
                    }else{
                        n = n + ', ' + teamMembers[i].data.FirstName
                    }
                }
            }
            setTeamName(n)
        }
    }
    const saveTeam = async() => {
        if(
            !teamName
        ){
            setAlertTrigger({title:'MISSING KEY FIELD', text:'Missing a key field'})
        }else{
            let obj = {
                Name:teamName,
                TeamMembers:teamMemberIDs,
                CustomName: cusName
            }

            if(teamID != ''){
                //Team is a selected one
                setDoc(doc(db,teamsDir,teamID),obj)
                for(let i=0; i < teamMembers.length; i++){
                    //Go through and add teamID on competitor
                    updateDoc(doc(competitorsCollection,teamMembers[i].id), {Team:teamID, TeamName:teamName})
                }
                for(let i=0; i < removedMembers.length; i++){
                    if(!teamMembers.includes(removedMembers[i])){
                        updateDoc(doc(competitorsCollection,removedMembers[i]), {Team:'', TeamName:''})
                    }
                }
            }else{
                //Event is new
                addDoc(teamsCollection,obj).then((res)=>{
                    setSelectTeamID(res.id)
                    for(let i=0; i < teamMembers.length; i++){
                        //Go through and add teamID on competitor
                        updateDoc(doc(competitorsCollection,teamMembers[i].id), {Team:res.id, TeamName:teamName})
                    }
                    for(let i=0; i < removedMembers.length; i++){
                        if(!teamMembers.includes(removedMembers[i])){
                            updateDoc(doc(competitorsCollection,removedMembers[i]), {Team:'', TeamName:''})
                        }
                    }
                })
            }

            let oobj = {
            Name: teamName,
            TeamMembers: teamMemberIDs,
            cusName: cusName
            }
            setOldObj(oobj);
        }
    }

    const searchCompetitors = (queryTerm) =>{
        let search_terms = queryTerm.split(" ");
        let list = [];
        for(let term of search_terms){
            list = competitors.filter(x=> {
                if(!list.includes(x)){
                    let lastName = x.data.LastName.toLowerCase()
                    let firstName = x.data.FirstName.toLowerCase()
                    let qTerm = term.toLowerCase()
                    if(lastName.includes(qTerm)){
                        return true
                    }
                    if(firstName.includes(qTerm)){
                        return true
                    }
                }
            })
        }
        setCompetitorList(list)

    }

    return(
        <View style={{height:'100%', width:'100%'}}>
            <View style={{height:'10%', width:'100%'}}>
                <ScrollView style={{flexDirection:'row', marginLeft:'1vw', marginRight:'1vw'}} horizontal={true} showsHorizontalScrollIndicator={false}>

                    <View style={ gVpWidth > 600 ? {flexDirection:'row', maxHeight:'5vh'}:null}>
                        <View style={{flexDirection:'row', alignItems:'center'}}>
                            <Text>Are the competitors in teams?</Text>
                            <Checkbox
                                value={enabled}
                                onValueChange={() => changeEnabled()}
                                color={enabled ? theme.colors.mainColour : null}
                                style={styles.checkbox}
                            />
                        </View>
                        {enabled ?(
                                <View style={[{flexDirection:'row', alignItems:'center'},gVpWidth > 600 ? { marginLeft:'1.5vw'}:{marginTop:'0.25vh'}]}>
                                <Text>Are the scores input at the team level?</Text>
                                <Checkbox
                                    value={scoreTeam}
                                    onValueChange={()=>changeScoreTeam()}
                                    color={scoreTeam ? theme.colors.mainColour : null}
                                    style={styles.checkbox}
                                />
                            </View>
                        ):null}
                    </View>
                </ScrollView>
            </View>
            {enabled ?(
                        <View style={[{height:'90%', width:'100%'}, gVpWidth <= 600 ? {flexDirection:'column'}:{flexDirection:'row'}]}>

                        <View style={gVpWidth > 600 ? {width:'44%', marginLeft:'1%', height:'80%'}:{width:'100%', height:'25%',  paddingHorizontal:'5%'}}>
                            {/** + and - button for adding and removing events */}
                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                <Text>Number of Teams: {teams.length}</Text>
                                <View style={{flexDirection:'row'}}>
                                <TouchableOpacity onPress={()=>{newTeam()}} >
                                    <Entypo name="squared-plus" size={24} color="black" />
                                </TouchableOpacity >
                                <TouchableOpacity onPress={()=>{removeTeam()}} >
                                    <Entypo name="squared-minus" size={24} color="black" />
                                </TouchableOpacity>
                                </View>
                            </View>
                            <View style={[{borderColor:'Grey', borderWidth:1, marginTop:'1vh'}, gVpWidth > 600 ? {minWidth:'99%', maxWidth:'99%', height:'100%'}:{minHeight:'70%', maxHeight:'70%'}]}>
                                {/**LIST OF COMPS */}
                                <ScrollView>
                                     {teams.sort().map(t =>{
                                        return(
                                            <TouchableOpacity
                                                key={t.id}
                                                onPress={() => selectTeam(t.id)}
                                                style={[(teamID==t.id)?{backgroundColor:theme.colors.mainColour }: {backgroundColor:'none',},styles.selectableList]}
                                            >
                                                <Text style={(teamID==t.id)?{color:'white' }:{color:'black'}}>{t.data.Name}</Text>
                                            </TouchableOpacity>
                                        )
                                    })}
                                </ScrollView>
                            </View>
                        </View>

                        {/*Right Side of the screen - parameters per event clicked on left */}
                        <View style={gVpWidth > 600 ? {width:'54%',marginRight:'1%',paddingLeft:'5%', height:'100%'} : {width:'100%', height:'100%', paddingHorizontal:'5%', height:'70%'}}>
                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                {teamID ? (
                                    <Text style={{fontWeight:"900"}}>{teamName}</Text>)
                                :(
                                    <Text style={{fontWeight:'900'}}>New Team</Text>
                                )
                                }
                                <View style={{flexDirection:'row'}}>
                                    <Text>Custom Team Name?</Text>
                                    <Checkbox
                                        value={cusName}
                                        onValueChange={setCusName}
                                        style={{marginLeft:'0.2vw', marginTop:'0.4vh'}}
                                        color={cusName ? theme.colors.mainColour : null}
                                    />
                                </View>
                            </View>
                            <ScrollView showsVerticalScrollIndicator={false} style={{height:'90%', width:'100%'}} contentContainerStyle={{height:'70%'}}>
                                {cusName ?(
                                    <View>
                                        <Text>Team Name:</Text>
                                        <TextInput
                                            value={teamName}
                                            onChangeText={setTeamName}
                                            style={styles.inputBox}
                                        />
                                    </View>

                                ):
                                (
                                    //No Custom Names
                                    <View>
                                        <Text>Team Name:</Text>
                                        <TextInput
                                            value={teamName}
                                            onChangeText={setTeamName}
                                            style={styles.inputBox}
                                            editable={false}
                                        />
                                    </View>
                                )}

                                <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:'2vh', height:'85%'}}>
                                    {/**Left Side */}
                                    <View style={{width:'45%', height:'100%'}}>
                                        <TextInput
                                            style={styles.inputBox}
                                            placeholder="Search"
                                            onChangeText={(e) => searchCompetitors(e)}
                                        />
                                        {/**List of Competitors */}
                                        <ScrollView
                                            style={[{borderColor:'Grey', borderWidth:1, }, gVpWidth > 600 ? {height:'80%'}:{height:'80%'}]}
                                        >
                                            {competitorList.map(competitor => {
                                                return(
                                                    <TouchableOpacity
                                                        key={competitor.id}
                                                        //Below style - also need to be red if competitor.data.teamID is not null - current red doesn't work either
                                                        style={[(selectCompID==competitor.id)?{backgroundColor:theme.colors.mainColour }: teamMembers.includes(competitor.id)? {backgroundColor:'red'}: {backgroundColor:'none',},,styles.selectableList]}
                                                        onPress={()=>{selectComp(competitor.id)}}
                                                    >
                                                        <Text style={[(!competitor.data.Team)?null:{color:theme.colors.mainColour},(selectCompID==competitor.id)?{color:'white' }:null]}>{competitor.data.LastName}, {competitor.data.FirstName}</Text>
                                                    </TouchableOpacity>
                                                )
                                            })}
                                        </ScrollView>
                                    </View>
                                    <View style={{justifyContent:'center'}}>
                                        <TouchableOpacity
                                            onPress={()=>addMember()}
                                        >
                                            <Entypo name="arrow-bold-right" size={24} color="black" />
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                            onPress={()=>removeMember()}
                                        >
                                            <Entypo name="arrow-bold-left" size={24} color="black" />
                                        </TouchableOpacity>
                                    </View>
                                    {/**Members of Team */}
                                    <View style={{width:'45%', height:'100%'}}>
                                        <Text>Members:</Text>
                                        <ScrollView
                                            style={[{borderColor:'Grey', borderWidth:1, }, gVpWidth > 600 ? { height:'80%'}:{height:'80%'}]}
                                        >
                                            {teamMembers.map(member => {
                                                return(
                                                    <TouchableOpacity
                                                        key={member.id}
                                                        onPress={() => setSelectMemberID(member.id)}
                                                        style={[(selectMemberID==member.id)?{backgroundColor:theme.colors.mainColour}: {backgroundColor:'none',},,styles.selectableList]}
                                                    >
                                                        <Text style={(selectMemberID==member.id)?{color:'white' }:{color:'black'}}>{member.data.LastName}, {member.data.FirstName}</Text>
                                                    </TouchableOpacity>
                                                )
                                            })}
                                        </ScrollView>
                                    </View>

                                </View>

                                <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:'5vh'}}>
                                    <View style={{minWidth:'25%', height:'5vh'}}>
                                        <AppButton
                                            title={"Save"}
                                            textStyle={ButtonStyles.appButtonText}
                                            containerStyle={[ButtonStyles.appButtonContainer, (!teamSaveHighlight) ? {backgroundColor:theme.colors.mainColour+"50"}:null]}
                                            onPress={()=>saveTeam()}
                                        />
                                    </View>
                                    <View style={{minWidth:'25%', height:'5vh'}}>
                                        <AppButton
                                            title={"New Team"}
                                            textStyle={ButtonStyles.appButtonText}
                                            containerStyle={ButtonStyles.appButtonContainer}
                                            onPress={()=>newTeam()}
                                        />
                                    </View>
                                </View>

                            </ScrollView>
                        </View>
                    </View>
            ):null}
            <AlertPopup
                trigger={alertTrigger}
                setTrigger={setAlertTrigger}
                title={alertTrigger.title}
                alertText={alertTrigger.text}

            />
        </View>
    );
}

export default Teams;