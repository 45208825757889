import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, Button, Modal, Dimensions } from 'react-native';
import { CheckBox, Picker, ScrollView, TextInput, TouchableOpacity } from 'react-native-web';
import Checkbox from 'expo-checkbox';

import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as RootNavigation from '../../../RootNavigation';
import TimeField from '../../parts/TimeField.tsx';
import QRCode from 'react-native-qrcode-svg';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import LeaderboardPart from '../../parts/LeaderboardPart';
import EventRankingPart from '../../parts/EventRankingPart'
import CustomLeaderboards from '../CustomLeaderboards';
import DivisionLeaderboards from '../DivisionLeaderboards';
import CustomLeaderboardPopup from '../CustomLeaderboardPopup';
import AppButton from '../../parts/AppButton';
import Popup from '../../parts/Popup'


import { db } from '../../../fbManage.js';
import { collection, getDocs, addDoc, setDoc ,doc, onSnapshot, updateDoc, query, orderBy} from 'firebase/firestore';

import { useTheme } from 'react-native-paper';
import retButtonStyles from '../../../styles/ButtonStyleSheet';
import retStyles from '../../../styles/MainStyleSheet';
import LoadingModal from '../../parts/LoadingModal';

import { AntDesign } from '@expo/vector-icons';
import { AppContext } from '../../../AppContextProvider';
const iconFile = require("../../../assets/small_icon.png")

const Leaderboard = ({theme, compDets, competitors, teams, divisions, permissionLevel, events}) =>{
    if(!theme){
        theme = useTheme()
    }
    const styles = retStyles(theme)
    const ButtonStyles = retButtonStyles(theme);
    const vpWidth = Dimensions.get('window').width;
    const {gVpWidth} = useContext(AppContext)

    const scoresDir = "Competitions/"+compDets.id+"/Scores"
    const scoresCollection = collection(db, scoresDir)

    const [loadingLeaderboard, setLoadingLeaderboard] = useState(true);
    const [LeaderboardFirstLoad, setLeaderboardFirstLoad] = useState(true);

    const [createCustomLeaderboardTrigger, setCreateCustomLeaderboardTrigger] = useState(false);
    const [selectedBoard, setCustomboard] = useState('');


    const [scores, setScores] = useState([]);
    const [scoreLen, setScoresLen] = useState();
    const [totals, setTotals] = useState({});
    const [teamTotals, setTeamTotals] = useState({});
    const [ranks, setRanks] = useState({});
    const [teamRanks, setTeamRanks] = useState({});

    const [customLeaderboards, setCustomLeaderboards] = useState([]);
    const [viewEventRanks, setViewEventRanks] = useState(false)

    const [qrTrigger, setQRTrigger] = useState(false);


    useEffect(()=>{
        let isMounted = true;
        var unsub_scores = onSnapshot(scoresCollection, snapshot=>{
            if(isMounted){
                setScoresLen(snapshot.docs.length)
                setScores(snapshot.docs.map(doc=>(
                    {
                        id: doc.id,
                        data:doc.data()
                    }
                )))
            }
        })
        var unsub_customboards = onSnapshot(collection(db,"Competitions/"+compDets.id+"/CustomLeaderboards"),snap=>{
            if(isMounted){
                setCustomLeaderboards(snap.docs.map(doc=>
                    ({id: doc.id,
                    data: doc.data()})
                ))
            }
        })
        return()=>{isMounted = false
            unsub_scores();
            unsub_customboards();
        }
    },[])

    //#region Checking Loading
    //Initial loading
    useEffect(()=>{
        let trigger = true;
        if(LeaderboardFirstLoad){
            setLeaderboardFirstLoad(false)
            trigger = false;
        }else{
            if(scores.length != scoreLen){
                trigger = false
            }
            if(!totals){
                trigger = false
            }
            if(!ranks){
                trigger=false
            }
            if(!customLeaderboards){
                trigger =false;
            }
            if(compDets.data.TeamsEnabled){
                if(!teamTotals){
                    trigger=false;
                }
                if(!teamRanks){
                    trigger=false;
                }
            }
        }

        if(trigger){
            setLoadingLeaderboard(false)
        }
    },[scores, totals, ranks, teamRanks, teamTotals, customLeaderboards])
    //#endregion

    if(!loadingLeaderboard){
        return(
            <View style={{height:'100%', width:'100%'}}>
                <View style={{flexDirection:'row'}}>
                    {compDets.data.PublicLeaderboard ?
                        <View style={{height:'5%', marginLeft:'1%'}}>
                            <TouchableOpacity
                                onPress={()=>setQRTrigger(true)}
                            >
                                <AntDesign name="qrcode" size={12} color="black" />
                            </TouchableOpacity>
                        </View>
                    : null}
                    <View style={{maxHeight:'5vh', flexDirection:'row', marginLeft:'1%'}}>
                            <Text>Show Event Rankings?</Text>
                            <Checkbox
                                value={viewEventRanks}
                                onValueChange={setViewEventRanks}
                                color={viewEventRanks? theme.colors.mainColour : null}
                                style={styles.checkbox}

                            />
                    </View>
                </View>
                <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={(vpWidth > 600)}
                    style={{minWidth:'95%', height:'95%'}}
                    contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}}
                >
                    {!viewEventRanks ? (
                        <View style={{flexDirection:'row', maxHeight:'60vh'}}>
                            {!compDets.data.ScoreTeam?
                                <LeaderboardPart
                                    participants={competitors}
                                    compDets={compDets}
                                    events={events}
                                    scores={scores}
                                    name={"Competitors"}
                                    permissionLevel={permissionLevel}
                                    forViewer={false}
                                    competitorList={competitors}
                                    teamList={teams}
                                />
                            :null}
                            {compDets.data.TeamsEnabled ?
                                (compDets.data.ScoreTeam )?
                                (
                                    <LeaderboardPart
                                        participants={teams}
                                        compDets={compDets}
                                        events={events}
                                        scores={scores}
                                        name={"Teams"}
                                        permissionLevel={permissionLevel}
                                        forViewer={false}
                                        isTeamBoard={true}
                                        competitorList={competitors}
                                        teamList={teams}
                                    />
                                ):(
                                    <LeaderboardPart
                                        participants={competitors}
                                        compDets={compDets}
                                        events={events}
                                        scores={scores}
                                        name={"Teams"}
                                        permissionLevel={permissionLevel}
                                        forViewer={false}
                                        isTeamBoard={true}
                                        competitorList={competitors}
                                        teamList={teams}
                                    />
                                )
                            : null}
                            {compDets.data.DivisionsEnabled ?

                                <DivisionLeaderboards
                                    competitors={competitors}
                                    teams={teams}
                                    Divisions={divisions}
                                    compDets={compDets}
                                    events={events}
                                    scores={scores}
                                />
                            :null}
                            <CustomLeaderboards
                                CustomLeaderboards = {customLeaderboards}
                                active = {[customLeaderboards.length > 0]}
                                events={events}
                                scores={scores}
                                compDets={compDets}
                                competitors={competitors}
                                teams={teams}
                                permissionLevel={permissionLevel}
                                forViewer={false}
                                setCreateCustomLeaderboardTrigger={setCreateCustomLeaderboardTrigger}
                                setCustomboard={setCustomboard}
                            />
                            {permissionLevel <= 2 ?
                                <View
                                    style={gVpWidth > 600 ? {width:'25vw', marginLeft:'5vw'}:{width:'70vw', marginHorizontal:'15vw'}}
                                >
                                    <Text style={{textAlign:'center', fontWeight:'bold', fontSize:'25px', marginBottom:'1vh'}}>Custom Leaderboard</Text>
                                    <View style={{flexDirection:'row', justifyContent:'center', marginBottom:'1vh'}}>
                                        <View style={{minWidth:'20vw', height:'5vh'}}>
                                            <AppButton
                                            title={"Create Leaderboard"}
                                            containerStyle={ButtonStyles.appButtonContainer}
                                            textStyle={ButtonStyles.appButtonText}
                                            onPress={()=>setCreateCustomLeaderboardTrigger(true)}
                                            />
                                        </View>
                                    </View>
                                </View>
                            : null}
                        </View>
                    ) : (
                        <View style={{flexDirection:'row', maxHeight:'60vh'}}>
                            {events.map(e=>{
                                return(
                                    <View key={e.id} style={{maxHeight:'60vh', height:'60vh'}}>
                                        <EventRankingPart
                                            name={e.data.Name}
                                            competitorList={competitors}
                                            teamList={teams}
                                            compDets={compDets}
                                            event={e}
                                            scores={scores}
                                            events={events}
                                            divisions={divisions}
                                        />
                                    </View>
                                )
                            })}
                        </View>
                    )}
                </ScrollView>
                {(compDets.data.Tiebreaker != 'none' && compDets.data.Tiebreaker) ?
                    <View style={{marginLeft:'1vw'}}>
                        <Text>*Indicates it went down to a tiebreaker</Text>
                    </View>
                : null}
                <CustomLeaderboardPopup
                    trigger={createCustomLeaderboardTrigger}
                    setTrigger={setCreateCustomLeaderboardTrigger}
                    compDets={compDets}
                    divisions={divisions}
                    teams={teams}
                    selectedBoard={selectedBoard}
                    setSelectedBoard={setCustomboard}
                    theme={theme}
                />

                <Popup
                    trigger={qrTrigger}
                    innerStyle={{
                        padding:10,
                        width: gVpWidth > 600 ? '40%' : '100%',
                        height:'auto',
                        backgroundColor:'white',
                        borderRadius:20,
                        borderColor:'Blue',
                        borderWidth:2
                    }}
                    content={
                        <View style={{alignItems:'center'}}>
                            <View
                                style={{marginTop:'1vh'}}
                            >
                                <QRCode
                                value={"https://app.skorebrd.com/?viewer="+compDets.id}
                                size={256}
                                logo={iconFile}
                                logoBackgroundColor={"#ffffff"}
                                logoBorderRadius={20}
                                />
                            </View>
                            <View style={{width:'15vw', height:'5vh', marginTop:'2vh'}}>
                                <AppButton
                                    textStyle={ButtonStyles.appButtonText}
                                    containerStyle={ButtonStyles.appButtonContainer}
                                    title={"Close"}
                                    onPress={()=>setQRTrigger(false)}
                                />
                            </View>
                        </View>
                    }
                />
            </View>
        )
    }else{
        return(
            <LoadingModal trigger={loadingLeaderboard}
                theme={theme}
            />
        )
    }
}

export default Leaderboard;