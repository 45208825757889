import React, { createRef, useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, Button,Dimensions } from 'react-native';
import { Animated, ScrollView, TextInput, TouchableHighlight, TouchableOpacity } from 'react-native-web';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { DefaultTheme } from "react-native-paper";

import * as Linking from 'expo-linking';
const Stack = createNativeStackNavigator();
const navRef = createRef()
//Custom Parts
import AppButton from '../parts/AppButton';
import Popup from '../parts/Popup'
import { AntDesign } from '@expo/vector-icons';
//Custom Style sheets
import retButtonStyles from '../../styles/ButtonStyleSheet';
import { Modal, useTheme } from 'react-native-paper';
import retStyles from '../../styles/MainStyleSheet';
import LoadingModal from '../parts/LoadingModal';
import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../fbManage';
import ViewerHeader from './ViewerHeader.component';
import retLeaderboardStyle from '../../styles/LeaderboardStyleSheet';
import LeaderboardPart from '../parts/LeaderboardPart';
import CustomLeaderboards from '../use/CustomLeaderboards';
import DivisionLeaderboards from '../use/DivisionLeaderboards';
import EventRankingPart from '../parts/EventRankingPart';
import AlertPopup from '../parts/AlertPopup';

const Viewer = ({ navigation, route }) => {
    const ButtonStyles = retButtonStyles()
    const styles = retStyles()
    const lrgLeaderboardStyle = retLeaderboardStyle('large')
    const smlLeaderboardStyle = retLeaderboardStyle('small')
    const theme = useTheme()

    const [alertTrigger, setAlertTrigger] = useState('');

    const [loading, setLoading] = useState(true)
    const [code, setCode] = useState('');

    const [compDets, setGlobalCompDets] = useState();

    const vpWidth = Dimensions.get('window').width;

    function ViewLeaderboard(){
      const scoresDir = "Competitions/"+compDets.id+"/Scores"
      const scoresCollection = collection(db, scoresDir)

      const [orgtheme, setOrgTheme] = useState({
        ...DefaultTheme,
        colors:{
            ...DefaultTheme.colors,
            mainColour: "#0000ff",
            accentColour: '#808080',
            headerBackground: '#a9a9a9'
        },
        logoFont:'Brush Script MT',
        //logoFont:fonts.BrushKursive,
        name: "SKOREBRD",
        customImage: '',
        customImageMode:'stretch'
      })

      const [loadingLeaderboard, setLoadingLeaderboard] = useState(true);
      const [LeaderboardFirstLoad, setLeaderboardFirstLoad] = useState(true);

      const [leftScrollArrow, setLeftScrollArrow] = useState(false);
      const [rightScrollArrow, setRightScrollArrow] = useState(false);

      const [localCompDets, setLocalCompDets] = useState({})
      const [events, setEvents] = useState([]);
      const [totalEventCount, setTotalEventCount] = useState(0);
      const [competitors, setCompetitors] = useState([]);
      const [divisions, setDivisions] = useState([]);
      const [teams, setTeams] = useState([]);
      const [scores, setScores] = useState([]);
      const [scoreLen, setScoresLen] = useState();
      const [totals, setTotals] = useState({});
      const [teamTotals, setTeamTotals] = useState({});
      const [ranks, setRanks] = useState({});
      const [teamRanks, setTeamRanks] = useState({});

      const [noScores, setNoScores] = useState(true);
      const [customLeaderboards, setCustomLeaderboards] = useState([]);
      const [showEventRankings, setShowEventRankings] = useState(false)

      useEffect(()=>{
        var isMounted = true;
        if(isMounted){
          var unsub_localCompDets = onSnapshot(doc(db, "Competitions", compDets.id), async (snap) => {
            setLocalCompDets({id: snap.id, data: snap.data()})
          })
        }

        return()=>{
          isMounted = false,
          unsub_localCompDets()

        }
      },[])

      useEffect(()=>{
        let isMounted = true;
        if(isMounted){


          if(compDets.data.organisation){
            getDoc(doc(db,"Organisations",compDets.data.organisation))
              .then(d=>{
                if(d.data().Theme){
                  const obj ={
                    ...theme,
                    colors:d.data().Theme.colors,
                    customImage: d.data().Theme.customImage,
                    customImageMode: d.data().Theme.customImageMode,
                  }
                  setOrgTheme(obj)
                }

              })
          }

          var unsub_events = onSnapshot(collection(db,"Competitions/"+code+"/Events"),async (snap)=>{
            let eventArr = [];
            let useEvents = [];
            eventArr = snap.docs.map(x=>({id:x.id,data:x.data()}));
            setTotalEventCount(eventArr.length)
            useEvents = eventArr;

            if(localCompDets.data?.publishedEvents){
              useEvents = eventArr.filter(x=> (localCompDets.data.publishedEvents.includes(x.id)))
            }

            setEvents(useEvents)

          })

          var unsub_scores = onSnapshot(scoresCollection, snapshot=>{
            if(isMounted){
                setScoresLen(snapshot.docs.length)
                setScores(snapshot.docs.map(doc=>(
                    {
                        id: doc.id,
                        data:doc.data()
                    }
                )))
            }
          })

          var unsub_customboards = onSnapshot(collection(db,"Competitions/"+compDets.id+"/CustomLeaderboards"),snap=>{
              if(isMounted){
                  setCustomLeaderboards(snap.docs.map(doc=>
                      ({id: doc.id,
                      data: doc.data()})
                  ))
              }
          })

          var unsub_compDets = onSnapshot(collection(db, "Competitions/"+compDets.id+"/Competitors"), s =>{
            if(isMounted){
              setCompetitors(s.docs.map(c =>(
                {
                  id:c.id,
                  data:c.data()
                }
              )))
            }
          })
          var unsub_teams = () => {};
          if(compDets.data.TeamsEnabled){
            unsub_teams = onSnapshot(collection(db, "Competitions/"+compDets.id+"/Teams"), s =>{
              if(isMounted){
                setTeams(s.docs.map(t =>(
                  {
                    id:t.id,
                    data:t.data()
                  }
                )))
              }
            })
          }

          var unsub_divs = () => {};
          if(compDets.data.DivisionsEnabled){
            unsub_divs = onSnapshot(collection(db, "Competitions/"+compDets.id+"/Divisions"), s =>{
              if(isMounted){
                setDivisions(s.docs.map(d =>(
                  {
                    id:d.id,
                    data:d.data()
                  }
                )))
              }
            })
          }
        }
        return()=>{isMounted=false
          unsub_scores();
          unsub_events();
          unsub_customboards();
          unsub_compDets();
          unsub_teams();
          unsub_divs();
        }
      },[localCompDets])

      useEffect(async()=>{
        let trigger = true;
        if(LeaderboardFirstLoad){
            setLeaderboardFirstLoad(false)
            trigger = false;
        }else{
            if(scores.length != scoreLen){
                trigger = false
            }
            if(!totals){
              trigger = false
            }
            if(!ranks){
              trigger=false
            }
            if(!competitors.length > 0){
              trigger = false
            }
            if(!customLeaderboards){
                trigger =false;
            }
            if(compDets.data.TeamsEnabled){
                if(!teamTotals){
                    trigger=false;
                }
                if(!teamRanks){
                    trigger=false;
                }
            }
        }

        if(trigger){
          setLoadingLeaderboard(false)
        }
      },[scores, totals, ranks, teamRanks, teamTotals, customLeaderboards, competitors])

    useEffect(()=>{
        let scoreArr = []
        for(let s in totals){
            scoreArr.push(totals[s])
        }

        if(scoreArr.length > 0){
        }
        setNoScores(false)
        //iflength
    }, [totals])

      const scrolled = (e) =>{
        if(e.contentOffset.x != 0){
            setLeftScrollArrow(true);
        }else{
            setLeftScrollArrow(false)
        }

        if(e.contentOffset.x + e.layoutMeasurement.width != e.contentSize.width){
            setRightScrollArrow(true)
        }else{
            setRightScrollArrow(false)
        }
      }

      const initScrollview = (w) => {
          if(w > vpWidth){
              setRightScrollArrow(true)
          }
      }

      return(
        <View style={{height:'75%'}}>
          <ViewerHeader
            compDets={compDets}
            theme={orgtheme}
            navRef={navRef}
          />
          <View style={{paddingHorizontal:'2%', height:'100%'}}>

            <View>
              <Text style={{fontWeight:'800', fontSize:25, textAlign:'center'}}>{compDets.data.name}</Text>
              {(events.length != totalEventCount)?(
                <View>
                  <Text style={{textAlign:'center'}}>Some events are yet to have published results.</Text>
                </View>
              ):null}
              <Text style={{textAlign:'center'}}>Standings are subject to change as scores are collected.</Text>
            </View>
            <View style={{marginTop:'2vh', height:'90%'}}>
              {loadingLeaderboard ?
                <LoadingModal
                  trigger={loadingLeaderboard}
                />
              : noScores ?
                (
                    <View>
                        <Text style={{textAlign:'center', fontWeight:'bold'}}>Awaiting Scores to be recorded.</Text>
                    </View>
                )
              :
              <>

              <ScrollView
                horizontal={true}
                showVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{width:'100%', height:'95%'}}
                contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}}
                onScroll={(e) => {scrolled(e.nativeEvent)}}

                onContentSizeChange={(width) => {
                    initScrollview(width)
                }}

              >
                {!showEventRankings ?
                  <View style={{flexDirection:'row'}}>
                    {!compDets.data.hideMainLeaderboard ?
                      !compDets.data.ScoreTeam?
                          <LeaderboardPart
                            participants={competitors}
                            competitorList={competitors}
                            compDets={compDets}
                            events={events}
                            scores={scores}
                            name={"Competitors"}
                            permissionLevel={99}
                            forViewer={true}
                          />
                      :
                      compDets.data.TeamsEnabled ?
                        (compDets.data.ScoreTeam )?
                        (
                            <LeaderboardPart
                                participants={teams}
                                compDets={compDets}
                                events={events}
                                scores={scores}
                                name={"Teams"}
                                permissionLevel={99}
                                forViewer={true}
                                isTeamBoard={true}
                                competitorList={competitors}
                                teamList={teams}
                            />
                        ):(
                            <LeaderboardPart
                                participants={competitors}
                                compDets={compDets}
                                events={events}
                                scores={scores}
                                name={"Teams"}
                                permissionLevel={99}
                                forViewer={true}
                                isTeamBoard={true}
                                competitorList={competitors}
                                teamList={teams}
                            />
                        )
                    : null

                    :null}
                    {compDets.data.DivisionsEnabled ?
                      <DivisionLeaderboards
                          competitors={competitors}
                          teams={teams}
                          Divisions={divisions}
                          compDets={compDets}
                          events={events}
                          scores={scores}
                      />
                      :null}

                      <CustomLeaderboards
                          CustomLeaderboards = {customLeaderboards}
                          active = {[customLeaderboards.length > 0]}
                          events={events}
                          scores={scores}
                          compDets={compDets}
                          competitors={competitors}
                          teams={teams}
                          forViewer={true}

                      />
                  </View>
                :
                <View style={{flexDirection:'column'}}>
                  <View style={{flexDirection:'row', width:'100%'}}>
                    {events.map(e=>{
                      return(
                        <View key={e.id} style={{maxHeight:'60vh', height:'60vh'}}>
                          <EventRankingPart
                            name={e.data.Name}
                            competitorList={competitors}
                            teamList={teams}
                            compDets={compDets}
                            event={e}
                            scores={scores}
                            events={events}
                            divisions={divisions}
                          />
                        </View>
                      )
                    })}
                  </View>
                </View>
                }
              </ScrollView>
              <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <View>
                        {leftScrollArrow ?
                            <AntDesign name="arrowleft" size={24} color="grey" />
                        : null}
                    </View>
                    <View>
                        {rightScrollArrow ?
                            <AntDesign name="arrowright" size={24} color="grey" />
                        : null}
                    </View>
              </View>
              {compDets.data.ShowEventResults ?
                <View style={{width:'100%', flexDirection:'row',justifyContent:'center'}}>
                  {!showEventRankings ?
                    <View style={[(vpWidth < 601) ? {width:'50%'}:{width:'20vw'},{marginTop:'2vh'}]}>
                      <AppButton
                        title={"Event Results"}
                        containerStyle={{
                          elevation: 8,
                          backgroundColor: orgtheme.colors.mainColour,
                          borderRadius: 30,
                          justifyContent:'center',
                          //paddingHorizontal: 12,
                          marginBottom:10,
                          height:'100%'
                        }}
                        textStyle={ButtonStyles.appButtonText}
                        onPress={()=>setShowEventRankings(true)}
                      />
                    </View>

                  :
                  <View style={[(vpWidth < 601) ? {width:'50%'}:{width:'20vw'},{marginTop:'2vh'}]}>
                    <AppButton
                      title={"Leaderboards"}
                      containerStyle={{
                        elevation: 8,
                        backgroundColor: orgtheme.colors.mainColour,
                        borderRadius: 30,
                        justifyContent:'center',
                        //paddingHorizontal: 12,
                        marginBottom:10,
                        height:'100%'
                      }}                      textStyle={ButtonStyles.appButtonText}
                      onPress={()=>setShowEventRankings(false)}
                    />
                  </View>
                  }
                </View>
              : null}
              </>
              }
            </View>
          </View>
        </View>
      )

    }

    function InputScreen(){
      return (
        <View style={{alignContent:'center', justifyContent:'center', alignItems:'center'}}>
          <View style={[{height:'25vh', marginTop:'30vh'}, (vpWidth < 600) ? {width:'70vw'}:{width:'30vw'}]}>
            <Text style={[styles.headerLogo, (vpWidth < 600) ? {fontSize:'13vw',width:'70vw'}:null]}>{theme.name}</Text>
            <View style={{alignItems:'center'}}>
              <Text style={{fontWeight:'bold', fontSize:26}}>VIEWER</Text>
            </View>
            <View>
              <Text style={{fontWeight:'bold'}}>Code:</Text>
              <TextInput
                style={[{backgroundColor:'lightgrey', borderRadius:'10px', padding:'5px',paddingLeft:'10px', borderWidth:1}, {borderColor:'black'}]}
                value={code}
                onChangeText={(e)=>{setCode(e)}}
              />
            </View>

            <View style={{marginTop:'2vh'}}>
              <AppButton
                title={"Enter"}
                containerStyle={ButtonStyles.appButtonContainer}
                textStyle={ButtonStyles.appButtonText}
                onPress={()=>findCompetition()}
              />
            </View>
            <View style={{marginTop:'2vh'}}>
              <AppButton
                title={"Main App"}
                containerStyle={ButtonStyles.appButtonContainer}
                textStyle={ButtonStyles.appButtonText}
                onPress={()=>Linking.openURL("http://app.skorebrd.com")}
              />
            </View>
            <View style={{marginTop:'2vh'}}>
              <AppButton
                title={"Website"}
                containerStyle={ButtonStyles.appButtonContainer}
                textStyle={ButtonStyles.appButtonText}
                onPress={()=>Linking.openURL("http://www.skorebrd.com")}
              />
            </View>
          </View>

        </View>
      );
    }

    function NotPublicView(){
      return(
        <View>
          <ViewerHeader
            compDets={compDets}
            theme={theme}
            navRef={navRef}
          />
          <View style={{paddingHorizontal:'2%', height:'100%'}}>
            <Text>Sorry but the organisers do not allow access to the leaderboard through the Viewer.</Text>
          </View>
        </View>
      )
    }

    useEffect(()=>{
      let isMounted = true;
      if(isMounted){
        if(route.params.code){
          if(route.params.code != 'true'){
            setCode(route.params.code)
            findCompetition(route.params.code)
          }else{
            setLoading(false)
          }
        }
      }
      return()=>{isMounted=false}
    },[])

    const findCompetition = async(e) =>{
      setLoading(true)
      let inCode = code;
      if(e){
        inCode = e
      }

      let docRef = getDoc(doc(db,"Competitions",inCode))
      docRef.then(doc=>{
        if(doc.exists()){
          setGlobalCompDets({id:doc.id, data:doc.data()})
          setLoading(false)
          if(doc.data().PublicLeaderboard){
            navRef.current.navigate("Viewer")
          }else{
            navRef.current.navigate("NotPublic")
          }
        }else{
          //Set alert that it doesn't exist
          setAlertTrigger({title:'Code', text:`Sorry, but that code doesn't seem quite right`})
          setLoading(false)
        }
      })
    }

    return(
      <NavigationContainer independent ref={navRef}>
        <Stack.Navigator
          initialRouteName={"Input"}
          screenOptions={{
            headerShown: false
          }}
        >
          <Stack.Screen name="Input" component={InputScreen}/>
          <Stack.Screen name="Viewer" component={ViewLeaderboard}/>
          <Stack.Screen name="NotPublic" component={NotPublicView}/>
        </Stack.Navigator>
        <LoadingModal
          trigger={loading}
        />
        <AlertPopup
          trigger={(alertTrigger != '')}
          setTrigger={setAlertTrigger}
          title={alertTrigger.title}
          alertText={alertTrigger.text}
        />
      </NavigationContainer>
    )
  };

export default Viewer;