import React, { createRef, useContext, useEffect, useState } from 'react';
import { CheckBox, Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import * as ImagePicker from 'expo-image-picker';

import { Text, View, TextInput, Image } from 'react-native';

import { Ionicons } from '@expo/vector-icons';

import { AppContext } from "../../../../../AppContextProvider";
import { useTheme } from 'react-native-paper';

import retButtonStyles from '../../../../../styles/ButtonStyleSheet';
import retStyles from '../../../../../styles/MainStyleSheet';
import LoadingModal from '../../../../parts/LoadingModal';
import Popup from '../../../../parts/Popup';
import AppButton from '../../../../parts/AppButton';
import AlertPopup from '../../../../parts/AlertPopup';
import Header from '../../../../parts/Header';
import ThemeMenuComponent from './ThemeMenu.Component';
import UsersMenuComponent from './UsersMenu.component';
import OrganisationMenu from './OrganisationMenu.component';
import OrgLicenceScreen from './OrgLicenceScreen';
import ConfirmPopup from '../../../../parts/confirmPopup';
import { removeUserFromOrg } from '../../../../../data';

import { AntDesign } from '@expo/vector-icons';

function SelectedOrgComponent ({orgID, orgDets, setSaveButton, permissionLevel, setSelectedOrg}) {
    const [theme, setTheme] = useState(orgDets.data.Theme)
    const styles = retStyles(theme);
    const ButtonStyles = retButtonStyles(theme);

    const [loading, setLoading] = useState(false);
    const [currentCatagory, setCurrentCatagory] = useState();

    const { globalUserDets, gVpWidth } = useContext(AppContext);

    const [userOrgDets, setUserOrgDets] = useState(globalUserDets.data.Organisations[orgDets.id]);

    const [addUserEmail, setAddUserEmail] = useState('');
    const [addUserTrigger, setAddUserTrigger] = useState(false);

    const [AlertPopupTrigger, setAlertPopupTrigger] = useState('');

    const [removeUserConf, setRemoveUserConf] = useState(false);
    //#endregion

    useEffect(()=>{
        setTheme(orgDets.data.Theme)
    },[orgDets])

    const retSettingsMenu = () =>{
        let options =[
            {id:2,label:'Organisation Theme'},
            {id:3,label:'Users'},
            {id:4,label:'Licences'}
        ]
        if(!orgDets.data.isPersonal){
            options.push({id:1,label:'Organisation'})
        }
        return(
            <View style={gVpWidth > 600 ? {width:'30vw', height:'50vh', paddingVertical:'2%'}:{height:"80vh", paddingVertical:'2%', width:'100%', alignItems:'center'}}>
                {options.sort((a,b) => (a.id - b.id)).map(o=>{
                    return(
                        <View key={o.id} style={{paddingLeft:'5vw'}}>
                            <TouchableOpacity style={{paddingVertical:'2%'}} onPress={()=> setCurrentCatagory(o.label)}>
                                <Text style={{fontWeight:'bold', fontSize:18}}>{o.label}</Text>
                            </TouchableOpacity>
                        </View>
                    )
                })}
            </View>
        )
    }

    const removeUser = async ()=>{
        setLoading(true)
        let res = await removeUserFromOrg(globalUserDets.id, orgDets.id)
        if(res.data.code == 200){
            setSelectedOrg('')
            setAlertPopupTrigger({title:'User Removed', text:'User was removed from the organisation.'})
        }else{
            setAlertPopupTrigger({title:'Oh No', text:'Something went wrong removing the user. Please try again'})
            setRemoveUserConf(false);
            setLoading(false);
        }
    }


    return(
        <View style={{ width:'100%',paddingLeft:'1vw',paddingRight:'1vw', marginTop:'0.75vh'}}>
            {permissionLevel < 2 ?
                <View>
                    {gVpWidth > 600 ?
                        <Text style={[styles.H2, {marginLeft:'2vw', color:theme.colors.mainColour}]}>{orgDets.data.name}</Text>
                    :
                        <View style={{width:'100%', flexDirection:'row', justifyContent:'space-between'}}>
                            <Text style={[styles.H2, {marginLeft:'2vw', color:theme.colors.mainColour}]}>{orgDets.data.name}</Text>
                            {currentCatagory ?
                                <AntDesign name="leftcircle" size={24} color={theme.colors.mainColour} onPress={()=>setCurrentCatagory(null)}/>
                            :null}
                        </View>
                    }
                    {gVpWidth > 600 ?
                    <View style={{flexDirection:'row'}}>
                        <View style={{height:'50vh', marginTop:'6vh', marginRight:'2vw'}}>
                            <ScrollView showVerticalScrollIndicator={false} style={{borderRightWidth:1,borderColor:'grey',borderStyle:'dashed'}}>
                                {retSettingsMenu()}
                            </ScrollView>
                        </View>
                        <View style={{height:'50vh', marginTop:'6vh', marginRight:'2vw'}}>
                            {currentCatagory == "Organisation" ?(
                                <OrganisationMenu theme={theme} orgDets={orgDets} userOrgDets={userOrgDets} setSaveButton={setSaveButton} setSelectedOrg={setSelectedOrg}/>
                            ):null}
                            {currentCatagory == 'Organisation Theme' ?(
                                <ThemeMenuComponent theme={theme} orgDets={orgDets} userOrgDets={userOrgDets} setSaveButton={setSaveButton}/>
                            ):null}
                            {currentCatagory == 'Users' ? (
                                <UsersMenuComponent theme={theme} orgDets={orgDets} userOrgDets={userOrgDets} setSaveButton={setSaveButton}/>
                            ):null}
                            {currentCatagory == 'Licences' ? (
                                <OrgLicenceScreen theme={theme} orgDets={orgDets} userOrgDets={userOrgDets} setSaveButton={setSaveButton}/>
                            ):null}

                        </View>
                    </View>
                    :
                    <>
                        {currentCatagory == 'Organisation' ?
                            <OrganisationMenu theme={theme} orgDets={orgDets} userOrgDets={userOrgDets} setSaveButton={setSaveButton} setSelectedOrg={setSelectedOrg}/>
                        :currentCatagory == 'Organisation Theme' ?(
                            <ThemeMenuComponent theme={theme} orgDets={orgDets} userOrgDets={userOrgDets} setSaveButton={setSaveButton}/>
                        ):currentCatagory == 'Users' ? (
                            <UsersMenuComponent theme={theme} orgDets={orgDets} userOrgDets={userOrgDets} setSaveButton={setSaveButton}/>
                        ):currentCatagory == 'Licences' ? (
                            <OrgLicenceScreen theme={theme} orgDets={orgDets} userOrgDets={userOrgDets} setSaveButton={setSaveButton}/>
                        ):
                            retSettingsMenu()
                        }
                    </>
                    }
                    <Popup
                        trigger={addUserTrigger}
                        innerStyle={{
                            padding:10,
                            width:'40%',
                            height:'auto',
                            backgroundColor:'white',
                            borderRadius:20,
                            borderColor:theme.colors.mainColour,
                            borderWidth:2
                        }}
                        content={
                            <View>
                                <Text style={styles.H1}>Add User</Text>
                                <View style={{width:'100%'}}>
                                    <View style={{flexDirection:'row', width:'70%', marginBottom:'2vh', marginTop:'1vh'}}>
                                        <Text style={{fontWeight:'bold', marginRight:'1vw'}}>User Email:</Text>
                                        <TextInput
                                            style={styles.inputBox}
                                            value={addUserEmail}
                                            onChangeText={(e)=>{setAddUserEmail(e)}}
                                        />
                                    </View>
                                </View>
                                <View style={{flexDirection:'row', width:'100%', justifyContent:'space-evenly'}}>
                                    <View style={{marginRight:'1vw', minWidth:'10vw'}}>
                                        <AppButton
                                            containerStyle={ButtonStyles.appButtonContainer}
                                            textStyle={ButtonStyles.appButtonText}
                                            title={"Add"}
                                            onPress={()=>{addOrgUser()}}
                                        />
                                    </View>
                                    <View style={{marginRight:'1vw', minWidth:'10vw'}}>
                                        <AppButton
                                            containerStyle={ButtonStyles.appButtonContainer}
                                            textStyle={ButtonStyles.appButtonText}
                                            title={"Cancel"}
                                            onPress={()=>{setAddUserTrigger(false)}}
                                        />
                                    </View>
                                </View>
                            </View>
                        }
                    />
                </View>
            :
                <View style={{height:'100%', width:'100%', justifyContent:'center', alignItems:'center', paddingTop:'10%'}}>
                    <Text>You don't have permissions to edit the settings for this organisation.</Text>
                    <Text>If you don't think this is right, get a organisation admin to change your role.</Text>
                    <View style={{marginRight:'1vw', minWidth:'20vw', marginTop:'5%'}}>
                        <AppButton
                            containerStyle={ButtonStyles.appButtonContainer}
                            textStyle={ButtonStyles.appButtonText}
                            title={"Leave Organisation"}
                            onPress={()=>{setRemoveUserConf(true)}}
                        />
                    </View>
                </View>
            }

            <ConfirmPopup
                trigger={removeUserConf}
                setTrigger={setRemoveUserConf}
                title={"Leave?"}
                alertText={"Are you sure you want to leave this organisation?"}
                theme={theme}
                confirmFunction={()=>{removeUser()}}
            />

            <AlertPopup
                trigger={(AlertPopupTrigger != '')}
                setTrigger={setAlertPopupTrigger}
                title={AlertPopupTrigger.title}
                alertText={AlertPopupTrigger.text}
                theme={theme}
            />

            <LoadingModal
                trigger={loading}
                theme={theme}
            />
        </View>
    )
}

export default SelectedOrgComponent;

