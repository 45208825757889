import React, { useContext, useEffect, useState } from 'react';
import {View, Text, Image, Dimensions} from "react-native";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

import { useTheme } from 'react-native-paper';
import retStyles from '../../styles/MainStyleSheet';
import { Modal, ScrollView, TouchableOpacity } from 'react-native-web';
import { Entypo } from '@expo/vector-icons';
import Popup from './Popup';
import AppButton from './AppButton';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import { AppContext } from '../../AppContextProvider';


function DynamicText ({ children, style,VWPercentage,VHPercentage, originalSize, originalWidth, originalHeight,verticalCenter,minFontSize,maxFontSize, ...props}) {

    const {gVpHeight, gVpWidth} = useContext(AppContext)

    if(originalHeight && isNaN(originalHeight)){
        let unit = originalHeight.replace(/[^a-zA-Z]+/g, '');
        if(unit == 'vh'){
            let num = originalHeight.replace(/[a-z]/g, '');
            originalHeight = (num/100) * 1041;
        }
    }

    if(originalWidth && isNaN(originalWidth)){
        let unit = originalWidth.replace(/[^a-zA-Z]+/g, '');
        if(unit == 'vw'){
            let num = originalWidth.replace(/[a-z]/g, '');
            originalWidth = (num/100) * 1854;
        }
    }
    const [fontSize, setFontSize] = useState('5vw')
    const [vPadding, setVPadding] = useState(0)

    const setSize = (size, height)=>{

        if(minFontSize && size < minFontSize){
            size = minFontSize
        }
        if(maxFontSize && size > maxFontSize){
            size = maxFontSize;
        }
        setFontSize(size)

        if(verticalCenter){
            let x = ((height-size)/2)
            setVPadding(x)
        }
    }

    return(

        <Text {...props} style={[style,  {fontSize:fontSize, paddingVertical:vPadding}]}
        onLayout={async (e) => {
                const { height, width } = e.nativeEvent.layout;
                let ratio;
                let size;
                if(!originalSize && VWPercentage){
                    originalSize = Math.floor(gVpWidth*(VWPercentage/100));
                }else if(!originalSize && VHPercentage){
                    originalSize = Math.floor(gVpHeight*(VWPercentage/100));
                }

                let sizeH;
                let sizeW;
                if(originalHeight){
                    ratio = originalHeight/originalSize;
                    sizeH= Math.floor(height/ratio)
                }
                if(originalWidth){
                    ratio = originalWidth/originalSize;
                    sizeW = Math.floor(width/ratio)
                }


                if(sizeW <= sizeH && sizeW != 0 && originalWidth){
                    setSize(sizeW, height)
                }else if(sizeH <= sizeW && sizeH != 0 && originalHeight){
                    setSize(sizeH, height)
                }
            }
        }
        >{children}</Text>
    )
}

export default DynamicText