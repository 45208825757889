//#region Imports
import { View } from 'react-native';

import React, { useState, useEffect, useContext } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import * as RootNavigation from '../../RootNavigation';
import AppButton from '../parts/AppButton';

import { Entypo } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import {
    doc,
    getDocs,
    updateDoc,
    collection,
    onSnapshot,
    } from 'firebase/firestore';
import { db } from '../../fbManage.js';
import retStyles from '../../styles/MainStyleSheet';
import retButtonStyles from '../../styles/ButtonStyleSheet';
import Header from '../parts/Header';
import { useTheme } from 'react-native-paper';
import AlertPopup from '../parts/AlertPopup';
import LoadingModal from '../parts/LoadingModal'
import Events from './Pages/Events.Component';
import Competitors from './Pages/Competitors.Component';
import Teams from './Pages/Teams.Componenet';
import Divisions from './Pages/Divisions.Component';
import Leaderboard from './Pages/Leaderboard.Component';
import { AppContext } from '../../AppContextProvider';

const Tab = createBottomTabNavigator();
//#endregion

const CreateCompetition = ({ navigation, route}) => {

    const {globalOrganisationDets, gVpWidth} = useContext(AppContext)

    const tempTheme = useTheme()
    const [theme, setTheme] = useState(tempTheme);
    const [alertTrigger, setAlertTrigger] = useState('');
    const [loading, setLoading] = useState(true)

    const styles = retStyles()
    const ButtonStyles = retButtonStyles();

    const compDir = "Competitions/" + route.params.compID
    const [compDets, setCompDets] = useState({})

    useEffect(()=>{
        let isMounted = true
        const docRef = doc(db, "Competitions",route.params.compID)
        var unsub = onSnapshot((docRef), doc =>{
                if (isMounted){
                setCompDets(doc.data())
                let curOrg = globalOrganisationDets.find(org => org.id == doc.data().organisation)
                setTheme(curOrg.data.Theme)
            }
            })
        return()=>{
            isMounted = false;
            unsub();
        }
    },[])

    useEffect(()=>{
        if(compDets){
            setLoading(false)
        }
    },[compDets])

    const finishSetup = async() => {
        let trigger = true;
        let msg = ''

        if(trigger){
            let e = getDocs(collection(db,compDir+"/Events"))
            await e.then(docs =>{
                if(docs.docs.length < 1){
                    trigger = false;
                    msg = "The competition must include at least 1 Event before finishing configuration."
                }
            })
        }

        if(trigger){
            let cList = getDocs(collection(db,compDir+"/Competitors"))
            await cList.then(docs =>{
                if(docs.docs.length < 1){
                    trigger = false;
                    msg = "The competition must include at least 1 competitor before finishing configuration."
                }

                if( compDets.competitorCatagories){
                    for(let CA of compDets.competitorCatagories){
                        let check;
                        if(CA.required){
                            check = CA.label;
                        }

                        if(check){
                            for(let c of docs.docs){
                                if(!c.data().customAttributes[CA.label]){
                                    trigger = false;
                                    msg = "There are some competitors missing required attributes."
                                    break;
                                }
                            }
                        }
                        if(!trigger){
                            break;
                        }
                    }
                }

            })
        }
        if(trigger){
            if(compDets.TeamsEnabled){
                let t = getDocs(collection(db,compDir+"/Teams"))
                await t.then(docs =>{
                    if(docs.docs.length < 1){
                        trigger = false;
                        msg = "The competition must include at least 1 team before finishing configuration.\n\nAleternatively you can disable teams."
                    }
                })
            }
        }
        if(trigger){
            if(compDets.DivisionsEnabled){
                let d = getDocs(collection(db,compDir+"/Divisions"))
                await d.then(docs =>{
                    if(docs.docs.length < 1){
                        trigger = false;
                        msg = "The competition must include at least 1 division before finishing configuration.\n\nAleternatively you can disable divisions."
                    }
                })
            }
        }

        if(trigger){
            //Leaderboard Level alerts
            if(!compDets.LeaderboardRanking || !compDets.Tiebreaker){
                trigger=false;
                msg = "The Leaderboard configuration must be complete"
            }

        }

        if(trigger){
            let c = doc(db,"Competitions",route.params.compID)
            updateDoc(c, {stage: 'use'}).then((res)=>{
                RootNavigation.navigate('use',{compID:route.params.compID})
            })
        }else{
            setAlertTrigger({title:'HOLD ON', text:msg})
        }
    }

    if(compDets){
    return (
            <NavigationContainer independent={true}>
                <Header
                    theme={theme}
                    buttons={
                            <>
                            <View style={{minWidth:'10vw', height:'5vh'}}>
                                <AppButton
                                    textStyle={{
                                        color:'white',
                                        fontWeight: "bold",
                                        alignSelf: "center",
                                        justifyContent:'center',
                                        textTransform: "uppercase"
                                    }}
                                    containerStyle = {{
                                        elevation: 8,
                                        backgroundColor: theme.colors.mainColour,
                                        borderRadius: 30,
                                        justifyContent:'center',
                                        paddingHorizontal: 12,
                                        marginBottom:10,
                                        height:'100%'
                                    }}
                                    title="Home"
                                    onPress={()=>RootNavigation.navigate('Home',{param:'refresh'})}
                                />
                            </View>
                            <View style={[{minWidth:'15vw', height:'5vh'}, gVpWidth < 600 ?{marginTop:'1vh'}:{marginLeft:'2vw'}]}>
                                <AppButton
                                    textStyle={{
                                        color:'white',
                                        fontWeight: "bold",
                                        alignSelf: "center",
                                        justifyContent:'center',
                                        textTransform: "uppercase"
                                    }}
                                    containerStyle = {{
                                        elevation: 8,
                                        backgroundColor: theme.colors.mainColour,
                                        borderRadius: 30,
                                        justifyContent:'center',
                                        paddingHorizontal: 12,
                                        marginBottom:10,
                                        height:'100%'
                                    }}
                                    title="Finished Setup"
                                    onPress={()=>finishSetup()}
                                />
                            </View>
                            </>
                        }
                    name={compDets.name}
                />
                <Tab.Navigator screenOptions={{headerShown:false}}>
                    <Tab.Screen name="Events" options={{unmountOnBlur: true, tabBarIcon:({ color, size }) => (
                            <MaterialCommunityIcons name="dumbbell" size={size} color={color} />
                        )}}>
                           {()=> <Events compDets={compDets} compID={route.params.compID} theme={theme}/>}
                    </Tab.Screen>
                    <Tab.Screen name="Competitors" options={{unmountOnBlur: true, tabBarIcon:({ color, size }) => (
                            <Ionicons name="person" size={size} color={color}/>
                        )}} >
                        {()=> <Competitors compDets={compDets} compID={route.params.compID} theme={theme}/>}
                    </Tab.Screen>
                    <Tab.Screen name="Teams" options={{unmountOnBlur: true, tabBarIcon:({ color, size }) => (
                            <FontAwesome name="group" size={size} color={color} />
                        )}}>
                        {()=> <Teams compDets={compDets} compID={route.params.compID} theme={theme}/>}
                    </Tab.Screen>
                    <Tab.Screen name="Divisions" options={{unmountOnBlur: true, test:'test',tabBarIcon:({ color, size }) => (
                            <Ionicons name="people-sharp" size={size} color={color} />
                        )}}
                        >
                        {() => <Divisions compDets={compDets} compID={route.params.compID} theme={theme}/>}
                    </Tab.Screen>
                    <Tab.Screen name="Leaderboard" options={{tabBarIcon:({ color, size }) => (
                            <Entypo name="trophy" size={size} color={color} />
                        )}}component={Leaderboard} initialParams={{compID:route.params.compID}}/>
                </Tab.Navigator>
                <AlertPopup
                    trigger={(alertTrigger != '')}
                    setTrigger={setAlertTrigger}
                    title={alertTrigger.title}
                    alertText={alertTrigger.text}
                    theme={theme}
                />
                <LoadingModal
                    trigger={loading}
                />
            </NavigationContainer>

        );
    }
};

export default CreateCompetition;