import { StyleSheet, Text, View, Button, TextInput } from 'react-native';
import Checkbox from 'expo-checkbox';
import { Picker, ScrollView, TouchableOpacity } from 'react-native-web';
import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import * as RootNavigation from '../../../RootNavigation';
import AppButton from '../../parts/AppButton';

import { Entypo } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import {
    addDoc,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
    } from 'firebase/firestore';
import { db } from '../../../fbManage.js';
import retStyles from '../../../styles/MainStyleSheet';
import retButtonStyles from '../../../styles/ButtonStyleSheet';
import { useTheme } from 'react-native-paper';
import LoadingModal from '../../parts/LoadingModal';
import AddCatagoryPopup from '../AddCatagoryPopup';
import AlertPopup from '../../parts/AlertPopup';



function Leaderboard({route}){
    const theme = useTheme();
    const styles = retStyles()
    const ButtonStyles = retButtonStyles();

    const [alertTrigger, setAlertTrigger] = useState('');

    const [rankType, setRankType] = useState('')
    const [tiebreaker, setTiebreaker] = useState('')
    const [ScoreCount, setScoreCount] = useState('')
    const [publicLeaderboard, setPublicLeaderboard] = useState()
    const [leaderboardUpdateLevel, setLeaderboardUpdateLevel] = useState()
    const [hideMainLeaderboard, sethideMainLeaderboard] = useState(false);
    const [showEventResults, setShowEventResults] = useState(false)

    const [eventList, setEventList] = useState([]);

    useEffect(()=>{
        let isMounted = true;
        const docRef = doc(db, "Competitions",route.params.compID)
        var unsub_dets = onSnapshot((docRef), doc =>{
            if(isMounted){
                setRankType(doc.data().LeaderboardRanking)
                if(doc.data().PublicLeaderboard){
                    setPublicLeaderboard(doc.data().PublicLeaderboard)
                }
                if(doc.data().CountTopScores){
                    setScoreCount(doc.data().CountTopScores)
                }
                if(doc.data().LeaderboardLevel != undefined || doc.data().LeaderboardLevel != null){
                    setLeaderboardUpdateLevel(doc.data().LeaderboardLevel)
                }
                if(doc.data().Tiebreaker){
                    setTiebreaker(doc.data().Tiebreaker)
                }
                if(doc.data().ShowEventResults){
                    setShowEventResults(true)
                }else{
                    setShowEventResults(false)
                }
            }
        })
        getDocs(collection(db,"Competitions/"+route.params.compID+"/Events")).then(res=>{
            let arr = res.docs.map(doc=>({id:doc.id, data:doc.data()}))
            arr.sort((a,b)=> a.data.EventNo - b.data.EventNo)
            setEventList(arr)
        })
        return()=>{isMounted = false; unsub_dets()};
    },[])

    const updateRankType = (x) =>{
        const docRef = doc(db, "Competitions",route.params.compID)
        updateDoc(docRef,{
            LeaderboardRanking: x
        })
    }

    const updateScoreCount = (x) =>{
        let v = '';
        if(x != ''){
            v = parseInt(x.target.value.replace(/[^0-9]/g, ''))
        }
        const docRef = doc(db, "Competitions",route.params.compID)
        updateDoc(docRef,{
            CountTopScores: v
        }).then(()=>{
            setScoreCount(v)
        })
    }

    const updatePublicLeaderboard = (x) =>{
        let boolX = (x === 'true')
        const docRef = doc(db, "Competitions",route.params.compID)
        updateDoc(docRef,{
            PublicLeaderboard: boolX
        }).then(()=>{
            setPublicLeaderboard(boolX)
        })
    }

    const updateLeaderboardLevel = (x) =>{
        const docRef = doc(db, "Competitions",route.params.compID)
        updateDoc(docRef,{
            LeaderboardLevel: x
        }).then(()=>{
            setLeaderboardUpdateLevel(x)
        })
    }

    const updateMainLeaderboard = (x) =>{
        const docRef = doc(db, "Competitions",route.params.compID)
        updateDoc(docRef,{
            hideMainLeaderboard: x
        }).then(()=>{
            sethideMainLeaderboard(x)
        })
    }

    const updateShowEventRes = (value) =>{
        const docRef = doc(db, "Competitions",route.params.compID);
        updateDoc(docRef,{
            ShowEventResults: value
        }).then(
            setShowEventResults(value)
        )
    }

    const updateTiebreaker = (value) =>{
        const docRef = doc(db, "Competitions",route.params.compID);
        updateDoc(docRef,{
            Tiebreaker:value
        }).then(
            setTiebreaker(value)
        )
    }

    return(
            <View style={{marginTop:'2vh', marginLeft:'2vw', paddingRight:'2vw'}}>
                <Text>Ranking Type:</Text>
                <Picker
                    onValueChange={(itemValue) => updateRankType(itemValue)}
                    style={{marginTop:'0.5vh'}}
                    selectedValue={rankType}
                >
                    <Picker.Item label="" value=""/>
                    <Picker.Item label="Most Points" value="Max"/>
                    <Picker.Item label="Fewest Points" value="Min"/>
                    <Picker.Item label="Points Per Event" value="PPE"/>
                </Picker>
                <Text>Tie Breaking</Text>
                <Picker
                    style={{marginTop:'0.5vh'}}
                    value={tiebreaker}
                    onValueChange={(value) => updateTiebreaker(value)}
                >
                    <Picker.Item label="" value=""/>
                    <Picker.Item label="None" value="none"/>
                    <Picker.Item label="Average Result - All Events" value="avgResAll"/>
                    <Picker.Item label="Average Result - Participated" value="avgResActive"/>
                    <Picker.Item label="Highest Result Count" value="hrc"/>
                </Picker>
                <Text>Only count top <i>X</i> scores (<i>Optional</i>)</Text>
                <TextInput
                    style={styles.inputBox}
                    onChange={(e)=> updateScoreCount(e)}
                    value={ScoreCount}
                />
                <Text>Public Leaderboard Site?</Text>
                <Picker
                    onValueChange={(itemValue) => {updatePublicLeaderboard(itemValue)}}
                    selectedValue={publicLeaderboard}
                >
                    <Picker.Item label="No" value='false'/>
                    <Picker.Item label="Yes" value='true'/>
                </Picker>
                {publicLeaderboard ?
                    <View>

                        <Text>Hide main (Competitor/Team) public leaderboard(s)?</Text>
                        <Picker
                            selectedValue={hideMainLeaderboard}
                            onValueChange={(value) =>{updateMainLeaderboard(value)}}
                        >
                            <Picker.Item label={'Yes'} value={true}/>
                            <Picker.Item label={"No"} value={false}/>
                        </Picker>
                        <View style={{alignItems:'center', flexDirection:'row'}}>
                            <Text>Allow public to view event results?</Text>
                            <Checkbox
                                value={showEventResults}
                                onValueChange={(value) => {updateShowEventRes(value)}}
                                color={showEventResults ? theme.colors.mainColour : null}
                                style={styles.checkbox}

                            />
                        </View>
                    </View>
                :null}
                <AlertPopup
                    trigger={alertTrigger}
                    setTrigger={setAlertTrigger}
                    title={alertTrigger.title}
                    alertText={alertTrigger.text}

                />
            </View>
    );
}

export default Leaderboard;