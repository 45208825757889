import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, Button, Modal, Dimensions } from 'react-native';
import { CheckBox, Picker, ScrollView, TextInput, TouchableOpacity } from 'react-native-web';
import Checkbox from 'expo-checkbox';

import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as RootNavigation from '../../../RootNavigation';
import TimeField from '../../parts/TimeField.tsx';
import QRCode from 'react-native-qrcode-svg';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { db } from '../../../fbManage.js';
import { collection, getDocs, addDoc, setDoc ,doc, onSnapshot, updateDoc, query, orderBy} from 'firebase/firestore';

import { useTheme } from 'react-native-paper';
import retButtonStyles from '../../../styles/ButtonStyleSheet';
import retStyles from '../../../styles/MainStyleSheet';
import { AppContext } from '../../../AppContextProvider';


const Scoring = ({theme, events, competitors, teams, divisions, compDets, permissionLevel}) =>{
    if(!theme){
        theme = useTheme();
    }
    const styles = retStyles(theme)
    const ButtonStyles = retButtonStyles(theme);

    const {gVpWidth} = useContext(AppContext)

    //#region Initiation of scoring data
    const scoresDir = "Competitions/"+compDets.id+"/Scores"
    const scoresCollection = collection(db, scoresDir)

    const [loadingScoring, setLoadingScoring] = useState(true);
    const [viewDivisions, setViewDivisions] = useState(false);
    const [editable, setEditable] = useState(false)

    const [viewTeams, setViewTeams] = useState([...teams])
    const [viewCompetitors, setViewCompetitors] = useState([...competitors])
    const [validDivisions, setValidDivivions] = useState([...divisions])


    const [scores, setScores] = useState([]);
    const [scoreLen, setScoresLen] = useState();
    const [publishedEvents, setPublishedEvents] = useState([])

    const [searchedIDs, setSearchedIDs] = useState([]);
    //#endregion

    useEffect(()=>{
        let isMounted = true

        if(isMounted){
            if(compDets.data.publishedEvents){
                setPublishedEvents(compDets.data.publishedEvents)
            }else{
                setPublishedEvents([])
            }
            var unsub_scores = onSnapshot(scoresCollection, snapshot=>{
                setScoresLen(snapshot.docs.length)
                setScores(snapshot.docs.map(doc=>(
                    {
                        id: doc.id,
                        data:doc.data()
                    }
                )))
                if(permissionLevel <=3){
                    setEditable(true)
                }
            })
        }
        return()=>{isMounted = false
            unsub_scores();
        }
    },[])

    useEffect(()=>{
        let trigger = true;

        if(scores.length != scoreLen){
            trigger = false
        }

        if(trigger){
            setLoadingScoring(false)
        }
    },[scores])

    useEffect(()=>{
        if(!loadingScoring){
            if(!compDets.data.ScoreTeam){
                setViewCompetitors([...competitors.filter(c=> (searchedIDs.includes(c.id)))])
            }
            if(compDets.data.TeamsEnabled){
                let teamsToShow = [...teams.filter(t=> (searchedIDs.includes(t.id) || searchedIDs.includes(t.data.Division) || findIfArraysHaveCommonElements(searchedIDs, t.data.TeamMembers)))];
                setViewTeams(teamsToShow)
            }
            if(compDets.data.DivisionsEnabled && viewDivisions){
                let divisionsToShow = [...divisions.filter(d=> (searchedIDs.includes(d.id) || findIfArraysHaveCommonElements(searchedIDs, d.data.DivisionMembers)))];
                setValidDivivions(divisionsToShow)
            }
        }
    }, [searchedIDs])

    const findIfArraysHaveCommonElements = (arr1, arr2) => {
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.includes(arr1[i])) {
                return true;
            }
        }
        return false;
    }

    const formatScore = (i, t) =>{
        let v = i;

        if(t == 'value'){
            v = parseFloat(i.replace(/[^0-9.]/g, ''))
            if(isNaN(v)){
                v=''
            }
        }else if(t == 'time'){
            v = i
        }

        return v
    }

    const convertMillisecondsToString = (ms)=>{
        let hh = Math.floor(ms / (1000*60*60))
        let mm = Math.floor((ms / (1000 * 60)) % 60)
        let ss = Math.floor((ms / 1000) % 60)
        let milliseconds = Math.floor((ms % 1000))

        //#region validate inputs
        //Validate hours
        if(String(hh).length < 2){
            hh = '0'+String(hh)
        }else{
            hh = String(hh)
        }
        //Validate Minutes
        if(String(mm).length < 2){
            mm = '0'+String(mm)
        }else{
            mm = String(mm)
        }
        //Validate Seconds
        if(String(ss).length < 2){
            ss = '0'+String(ss)
        }else{
            ss = String(ss)
        }
        //Validate Milliseconds
        if(String(milliseconds).length < 3){
            if(String(milliseconds).length <2){
                milliseconds = '00'+String(milliseconds)
            }else{
                milliseconds = '0'+String(milliseconds)
            }
        }else{
            milliseconds = String(milliseconds)
        }
        //#endregion

        let string =  hh +':'+mm+':'+ss+'.'+milliseconds

        return(string)
    }

    const convertStringToMilliseconds = (s) =>{
        let temp = s.split(':')
        let temp1 = temp[2].split('.')
        let hh = temp[0]
        let mm = temp[1]
        let ss = temp1[0]
        let ms = temp1[1]
        let v = (parseInt(hh.replace(/[^0-9]/g, '')) *60*60*1000) + (parseInt(mm.replace(/[^0-9]/g, ''))*60*1000)+(parseInt(ss.replace(/[^0-9]/g, ''))*1000)+(parseInt(ms.replace(/[^0-9]/g, '')))

        return v;
    }

    const updateScores = (r,c,s, t) => {
        /**
         * R = Row
         * C = Column
         * S = Score
         * T = Type
         */
        let v = formatScore(s,t)

        //convert time to milliseconds
        if(t == 'time'){
            v = convertStringToMilliseconds(s)
            if(v==0){
                v='';
            }
        }

        let curObj = scores.filter(x=>x.id===r)[0]
        if(curObj){
            curObj.data[c] = v
        }else{
            //New obj
            curObj = {
                data:{
                    [c]:v
                }
            }
        }
        try{
            setDoc(doc(db, scoresDir,r),curObj.data)
        }catch(e){
            setAlertTrigger({title:'ISSUE', text:'Issue Saving Score: ' + e})
        }
    }

    const renderScoreInput = (cID,scoreKey, data) => {
        let keys = scoreKey.split('_')
        let eventDets = events[keys[0]]
        let backgroundColourCode = 1;
        if((keys[0]%2)==1){
            //odd row
            backgroundColourCode = 0;
        }
        let SIDets = eventDets.data.ScoreInputs[keys[1]]

        if(SIDets.type == 'time'){
            let defaultValue = '00:00:00.000'
            if(data !='' && data != null){
                defaultValue = convertMillisecondsToString(data[scoreKey])

            }
            if(editable){
                let bg = '#f2f2f2'
                if(backgroundColourCode != 0){
                    bg = theme.colors.mainColour + '50'
                }
                return(
                    <View
                        key={scoreKey}
                    style={{borderColor:'black', borderWidth:1, width:150}}>
                        <TimeField
                            style={{width:'100%', height:'100%', padding:0, margin:0, borderColor:'black', borderWidth:0, textAlign:'center', backgroundColor:bg}}
                            showFull={true}
                            value={defaultValue}
                            onChange={(e)=>updateScores(cID,scoreKey,e.target.value, SIDets.type)}
                        />
                    </View>
                )
            }else{
                return(
                    <TextInput
                        key={scoreKey}
                        style={[{width:150,borderColor:'black', borderWidth:'1px', textAlign:'center'}, (backgroundColourCode != 0) ? {backgroundColor:theme.colors.mainColour+"50"} : null]}
                        editable={false}
                        defaultValue={defaultValue}
                    />
                )
            }
        }else if(SIDets.type == 'value'){
            let defaultValue = ''
            if(data !='' && data != null){
                defaultValue = data[scoreKey]
            }
            return(
                <TextInput
                    key={scoreKey}
                    style={[{width:150,borderColor:'black', borderWidth:'1px', textAlign:'center'}, (backgroundColourCode != 0) ? {backgroundColor:theme.colors.mainColour+"50"} : null]}
                    editable={editable}
                    defaultValue={defaultValue}
                    onChangeText={(s)=> updateScores(cID,scoreKey,s, SIDets.type)}
                />
            )
        }
    }

    const renderCompetitorRows = () =>{
        return(
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{maxHeight:'60vh'}}
            >
                    {viewCompetitors.map(c =>{
                        let compScores = scores.filter(x=>x.id===c.id)[0]

                        return(
                            <View key={c.id} style={{flexDirection:'row', textAlign:'center'}}>
                                <Text style={{width:150,borderColor:'black', borderWidth:'1px', paddingVertical:'0.5vh', fontWeight:'bold'}}>{c.data.LastName}, {c.data.FirstName}</Text>
                                {events.map((e,i)=>{
                                    return(
                                        e.data.ScoreInputs.map((si,ii)=>{
                                            let scoreKey = i+"_"+ii
                                            if(compScores){
                                                if(compScores.data[scoreKey]){
                                                    return(
                                                        renderScoreInput(c.id, scoreKey, compScores.data)

                                                    )
                                                }else{
                                                    return(
                                                        renderScoreInput(c.id,scoreKey,'')
                                                    )
                                                }
                                            }else{
                                                return(
                                                    renderScoreInput(c.id,scoreKey,'')
                                                )
                                            }
                                        })
                                    )
                                })}
                            </View>
                        )
                    })}
            </ScrollView>
        )
    }

    const renderTeamRows = () =>{
        return(
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{maxHeight:'60vh'}}

            >
                    {viewTeams.map(c =>{
                        let compScores = scores.filter(x=>x.id===c.id)[0]

                        return(
                            <View key={c.id} style={{flexDirection:'row', textAlign:'center'}}>
                                <Text style={{width:150,borderColor:'black', borderWidth:'1px', paddingVertical:'0.5vh'}}>{c.data.Name}</Text>
                                {events.map((e,i)=>{
                                    return(
                                        e.data.ScoreInputs.map((si,ii)=>{
                                            let scoreKey = i+"_"+ii
                                            if(compScores){
                                                if(compScores.data[scoreKey]){
                                                    return(
                                                        renderScoreInput(c.id, scoreKey, compScores.data)
                                                    )
                                                }else{
                                                    return(
                                                        renderScoreInput(c.id,scoreKey,'')
                                                    )
                                                }
                                            }else{
                                                return(
                                                    renderScoreInput(c.id,scoreKey,'')
                                                )
                                            }
                                        })
                                    )
                                })}
                            </View>
                        )
                    })}
                </ScrollView>
        )
    }

    const renderTeamWithCompRows = () =>{
        return(
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{maxHeight:'60vh'}}
            >
                    {viewTeams.map(m =>{
                        return(
                        <View key={m.id}>
                            <Text style={{width:150,borderColor:'black', borderWidth:'1px', width:'100%', backgroundColor:'rgba(130,130,130,0.6)', paddingLeft:'0.25vw'}}>{m.data.Name}</Text>
                            {m.data.TeamMembers.map(c=>{
                                let compScores = scores.filter(x=>x.id===c)[0]
                                let competitorDets = competitors.filter(x=>x.id===c)[0].data
                                return(
                                    <View key={c} style={{flexDirection:'row', textAlign:'center'}}>
                                        <Text style={{width:150,borderColor:'black', borderWidth:'1px', paddingVertical:'0.5vh'}}>{competitorDets.LastName}, {competitorDets.FirstName}</Text>
                                        {events.map((e,i)=>{
                                            return(
                                                e.data.ScoreInputs.map((si,ii)=>{
                                                    let scoreKey = i+"_"+ii
                                                    if(compScores){
                                                        if(compScores.data[scoreKey]){
                                                            return(
                                                                renderScoreInput(c, scoreKey, compScores.data)
                                                            )
                                                        }else{
                                                            return(
                                                                renderScoreInput(c,scoreKey,'')
                                                            )
                                                        }
                                                    }else{
                                                        return(
                                                            renderScoreInput(c,scoreKey,'')
                                                        )
                                                    }
                                                })
                                            )
                                        })}
                                    </View>
                                )

                            })}

                        </View>
                            )
                    })}
                </ScrollView>
        )
    }

    const renderCompetitorDivRows = () => {
        let divs = [...validDivisions];
        if(compDets.data.IncludeNullDivs){
            divs.push({id:'none', data:{Name:'No Division', DivisionMembers:[]}})
        }
        return(
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{maxHeight:'60vh'}}
            >
                    {divs.map(m =>{
                        let members = []

                        if(m.id == 'none'){
                            let arr = competitors.filter(x=>(!x.data.Division || x.data.Division == ''))
                            for(let y of arr){
                                if(y){
                                    members.push(y.id);
                                }
                            }
                        }else{
                            members = m.data.DivisionMembers
                        }
                        return(
                        <View key={m.id}>
                            <Text style={{width:150,borderColor:'black', borderWidth:'1px', width:'100%', backgroundColor:'rgba(130,130,130,0.6)', paddingLeft:'0.25vw'}}>{m.data.Name}</Text>
                            {members.map(c=>{
                                let compScores = scores.filter(x=>x.id===c)[0]
                                let competitorDets = competitors.filter(x=>x.id===c)[0].data
                                return(
                                    <View key={c} style={{flexDirection:'row', textAlign:'center'}}>
                                        <Text style={{width:150,borderColor:'black', borderWidth:'1px', paddingVertical:'0.5vh'}}>{competitorDets.LastName}, {competitorDets.FirstName}</Text>
                                        {events.map((e,i)=>{
                                            return(
                                                e.data.ScoreInputs.map((si,ii)=>{
                                                    let scoreKey = i+"_"+ii
                                                    if(compScores){
                                                        if(compScores.data[scoreKey]){
                                                            return(
                                                                renderScoreInput(c, scoreKey, compScores.data)
                                                            )
                                                        }else{
                                                            return(
                                                                renderScoreInput(c,scoreKey,'')
                                                            )
                                                        }
                                                    }else{
                                                        return(
                                                            renderScoreInput(c,scoreKey,'')
                                                        )
                                                    }
                                                })
                                            )
                                        })}
                                    </View>
                                )

                            })}

                        </View>
                            )
                    })}
                </ScrollView>
        )
    }

    const renderTeamDivRows = () => {
        let divs = [...validDivisions];
        if(compDets.data.IncludeNullDivs){
            divs.push({id:'none', data:{Name:'No Division', DivisionMembers:[]}})
        }
        return(
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{maxHeight:'60vh'}}
            >
                    {divs.map(m =>{
                        let members = []

                        if(m.id == 'none'){
                            let arr = teams.filter(x=>(!x.data.Division || x.data.Division == ''))
                            for(let y of arr){
                                if(y){
                                    members.push(y.id);
                                }
                            }
                        }else{
                            members = m.data.DivisionMembers
                        }
                        return(
                        <View key={m.id}>
                            <Text style={{width:150,borderColor:'black', borderWidth:'1px', width:'100%', backgroundColor:'rgba(130,130,130,0.6)', paddingLeft:'0.25vw'}}>{m.data.Name}</Text>
                            {members.map(c=>{
                                let compScores = scores.filter(x=>x.id===c)[0]
                                let competitorDets = teams.filter(x=>x.id===c)[0].data
                                return(
                                    <View key={c} style={{flexDirection:'row', textAlign:'center'}}>
                                        <Text style={{width:150,borderColor:'black', borderWidth:'1px', paddingVertical:'0.5vh'}}>{competitorDets.Name}</Text>
                                        {events.map((e,i)=>{
                                            return(
                                                e.data.ScoreInputs.map((si,ii)=>{
                                                    let scoreKey = i+"_"+ii
                                                    if(compScores){
                                                        if(compScores.data[scoreKey]){
                                                            return(
                                                                renderScoreInput(c, scoreKey, compScores.data)
                                                            )
                                                        }else{
                                                            return(
                                                                renderScoreInput(c,scoreKey,'')
                                                            )
                                                        }
                                                    }else{
                                                        return(
                                                            renderScoreInput(c,scoreKey,'')
                                                        )
                                                    }
                                                })
                                            )
                                        })}
                                    </View>
                                )

                            })}

                        </View>
                            )
                    })}
                </ScrollView>
        )
    }

    const renderTeamCompDivRows = () =>{
        let divs = [...validDivisions];
        if(compDets.data.IncludeNullDivs){
            divs.push({id:'none', data:{Name:'No Division', DivisionMembers:[]}})
        }

        return(
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{maxHeight:'60vh'}}
            >
                {divs.map(d =>{
                    let members = []

                    if(d.id == 'none'){
                        let arr = teams.filter(x=>(!x.data.Division || x.data.Division == ''))
                        for(let y of arr){
                            if(y){
                                members.push(y.id);
                            }
                        }
                    }else{
                        members = d.data.DivisionMembers
                    }

                    return(
                        <View key={d.id}>
                            <Text style={{width:150,borderColor:'black', borderWidth:'1px', width:'100%', backgroundColor:'rgba(130,130,130,0.6)', paddingLeft:'0.25vw', fontWeight:'bold'}}>{d.data.Name}</Text>
                            {members.map(m =>{
                                let team = teams.find(t=> (t.id == m))
                                return(
                                    <View key={m}>
                                        <Text style={{width:150,borderColor:'black', borderWidth:'1px', width:'100%', backgroundColor:'rgba(130,130,130,0.3)', paddingLeft:'0.25vw'}}>{team.data.Name}</Text>
                                        {team.data.TeamMembers.map(c=>{
                                            let compScores = scores.filter(x=>x.id===c)[0]
                                            let competitorDets = competitors.filter(x=>x.id===c)[0].data
                                            return(
                                                <View key={c} style={{flexDirection:'row', textAlign:'center'}}>
                                                    <Text style={{width:150,borderColor:'black', borderWidth:'1px', paddingVertical:'0.5vh'}}>{competitorDets.LastName}, {competitorDets.FirstName}</Text>
                                                    {events.map((e,i)=>{
                                                        return(
                                                            e.data.ScoreInputs.map((si,ii)=>{
                                                                let scoreKey = i+"_"+ii
                                                                if(compScores){
                                                                    if(compScores.data[scoreKey]){
                                                                        return(
                                                                            renderScoreInput(c, scoreKey, compScores.data)
                                                                        )
                                                                    }else{
                                                                        return(
                                                                            renderScoreInput(c,scoreKey,'')
                                                                        )
                                                                    }
                                                                }else{
                                                                    return(
                                                                        renderScoreInput(c,scoreKey,'')
                                                                    )
                                                                }
                                                            })
                                                        )
                                                    })}
                                                </View>
                                            )

                                        })}

                                    </View>
                                )
                            })}
                        </View>
                    )
                })}

            </ScrollView>
        )
    }

    const searchParticipants = async(term) =>{
        term = term.toLowerCase()
        let returnedIDs = []
        if(!compDets.data.ScoreTeam){
            let Cs = competitors.filter(c=>((c.data.LastName.toLowerCase().includes(term) || c.data.FirstName.toLowerCase().includes(term) ))).map(x=>{
                returnedIDs = [...returnedIDs, x.id]
            })
        }
        if(compDets.data.TeamsEnabled){
            teams.filter(t=>(t.data.Name.toLowerCase().includes(term))).map(x=>{
                returnedIDs = [...returnedIDs, x.id]
            })
        }
        if(compDets.data.DivisionsEnabled){
            divisions.filter(d=>(d.data.Name.toLowerCase().includes(term))).map(x=>{
                returnedIDs = [...returnedIDs, x.id]
            })
        }
        setSearchedIDs(returnedIDs)
    }

    const changePublishEvent = async(value, event) => {
        let curList = [...publishedEvents]
        if(value){
            if(!curList.includes(event.id)){
                curList.push(event.id)
            }
        }else{
            if(curList.includes(event.id)){
                curList.splice(curList.indexOf(event.id),1)
            }
        }


        const docRef = doc(db,"Competitions", compDets.id)
        updateDoc(docRef,{
            publishedEvents: curList
        }).then(()=>{
            setPublishedEvents(curList)
        })
    }

    const publishAllEvents = async(value) =>{
        let curList = [...publishedEvents]
        if(value){
            for(let e of events){
                if(!curList.includes(e.id)){
                    curList.push(e.id)
                }
            }
        }else{
            curList = []
        }

        const docRef = doc(db,"Competitions", compDets.id)
        updateDoc(docRef,{
            publishedEvents: curList
        }).then(()=>{
            setPublishedEvents(curList)
        })

    }

    if(loadingScoring){
        return(
            <View
                style={{justifyContent:'center'}}
            >
                <View
                    style={{justifyContent:'center', flexDirection:'row'}}
                >
                    <Text style={{fontWeight:'bold'}}>Loading Scoring</Text>
                </View>
            </View>
        )
    }else{
    return(
        <View style={{height:'100%', width:'100%', paddingBottom:'3%'}}>
            <View style={ gVpWidth > 600 ? {maxHeight:'5vh', flexDirection:'row', marginLeft:'1vw', marginBottom:'2vh'}:{flexDirection:'column', width:'100%', marginBottom:'1vh'}}>
                <View>
                    <TextInput
                        style={styles.inputBox}
                        placeholder={"Search"}
                        onChangeText={(e) => searchParticipants(e)}
                    />
                </View>
                {(compDets.data.DivisionsEnabled && (!compDets.data.TeamsEnabled || (compDets.data.TeamDivs || compDets.data.ScoreTeam)))?
                    <View style={{maxHeight:'5vh', flexDirection:'row', marginLeft:'1vw'}}>
                        <Text style={{alignContent:'center', alignSelf:'center'}}>Show Divisions?</Text>
                        <Checkbox
                            value={viewDivisions}
                            onValueChange={setViewDivisions}
                            style={styles.checkbox}
                            color={viewDivisions? theme.colors.mainColour : null}
                        />
                    </View>
                :null}
                {compDets.data.PublicLeaderboard && permissionLevel < 2?
                    <View style={{maxHeight:'5vh', flexDirection:'row', marginLeft:'1vw', alignItems:'center'}}>
                        <Text>Publish all Events?</Text>
                        <Checkbox
                            value={(publishedEvents.length == events.length)}
                            style={styles.checkbox}
                            onValueChange={(value) => publishAllEvents(value)}
                            color={(publishedEvents.length == events.length) ? theme.colors.mainColour : null}
                        />
                    </View>
                : null}
            </View>
            {/**TABLE */}
            <ScrollView
                showsHorizontalScrollIndicator={true}
                horizontal={true}
                style={{marginHorizontal:'1vw', height:'100%'}}
                contentContainerStyle={{justifyContent:'space-around', flexDirection:'row', minWidth:'100%', height:'100%'}}
            >
            <View style={{borderWidth:'0px', borderColor:'black'}} >
                {/* Header Row */}
                <View style={{flexDirection:'row', marginLeft:150, borderColor:'black', borderWidth:'0px'}}>
                    {events.map(e=>{
                        return(
                            <View key={e.id} style={{width:(e.data.ScoreInputs.length*150),borderColor:'black', borderWidth:'0px',textAlign:'center', borderTopWidth:1, borderColor:'black', borderLeftWidth:1, borderRightWidth:1}}>
                                <View style={{flexDirection:'row', textAlign:'center', justifyContent:'space-evenly', marginTop:'0.5vh'}}>
                                    <Text style={{fontWeight:'bold', color:theme.colors.mainColour}}>{e.data.Name}</Text>
                                    {(compDets.data.PublicLeaderboard && permissionLevel < 2) ?
                                        <View style={{flexDirection:'row', alignItems:'center', alignContent:'center'}}>
                                            <Text>Publish?</Text>
                                            <Checkbox
                                                value={(publishedEvents.includes(e.id))}
                                                onValueChange={(value) => changePublishEvent(value, e)}
                                                style={styles.checkbox}
                                                color={(publishedEvents.includes(e.id))? theme.colors.mainColour : null}
                                            />
                                        </View>
                                    : null}
                                </View>

                                <View style={{flexDirection:'row',}}>
                                    {e.data.ScoreInputs.map((si, index) =>{
                                        return(
                                            <Text key={index}
                                                style={{borderColor:'black', borderWidth:'0px', width:150}}
                                            >
                                                {si.name}
                                            </Text>
                                        )
                                    })}
                                </View>
                            </View>
                        )
                    })}
                </View>

                {/**Below will be dependant on TeamsEnabled/ScoringAsIndividual etc. */}
                {
                    !compDets.data.TeamsEnabled ?
                        (compDets.data.DivisionsEnabled && viewDivisions)?
                            renderCompetitorDivRows()
                        :
                        (
                            (renderCompetitorRows())
                        )
                    :
                    (compDets.data.ScoreTeam ?
                        (compDets.data.DivisionsEnabled && viewDivisions)?
                            renderTeamDivRows()
                        :
                            renderTeamRows()
                    :
                        (compDets.data.DivisionsEnabled && viewDivisions)?
                            renderTeamCompDivRows()
                        :
                            renderTeamWithCompRows()

                    )

                }
            </View>
            </ScrollView>
        </View>
    )}
}

export default Scoring;