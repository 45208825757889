import React, { useContext, useEffect, useState } from 'react';
import { Text, View} from 'react-native';
import { TouchableOpacity } from 'react-native-web';

import { Feather } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import { useTheme } from 'react-native-paper';
import { AppContext } from '../../AppContextProvider.js';

const CompetitionRow = ({name, date, id, status, copy, setDeleteTrigger, organisation}) => {


    const theme = useTheme()
    const [permissionLevel, setPermissionLevel] = useState(99)
    const {globalUserDets, gVpWidth} = useContext(AppContext)
    let d = new Date(date.seconds*1000+date.nanoseconds)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let stage;
    if(status == 'Setup'){
        stage = "Configuration"
    }else if(status == 'use'){
        stage = "Active"
    }else if(status == 'Finished'){
        stage = "Finished"
    }

    useEffect(()=>{
        if(organisation){
            let orgUser = globalUserDets.data.Organisations[organisation.id]
            if(orgUser){
                if(typeof orgUser.role !== undefined){
                    setPermissionLevel(orgUser.role)
                }else{
                    setPermissionLevel(100)
                }
            }else{
                setPermissionLevel(100)
            }
        }
    },[organisation])

    if(permissionLevel < 100){
        return (
            <View style={[{marginVertical:'1%', flexDirection:'row', justifyContent:'space-between'}, gVpWidth < 600 ? {paddingLeft:"2%"}:null]}>
                <View>
                    <Text style={{fontWeight:'bold', color:theme.colors.mainColour, paddingLeft:'0.25vw'}}>{name}</Text>
                    <View style={gVpWidth > 600 ? {flexDirection:'row', marginTop:'0.5vh'} : {flexDirection:'column', marginTop:'0.5vh'}}>
                        <View style={{flexDirection:'row', marginRight:'1.5vw'}}>
                            <Text style={{marginRight:'0.5vw', fontWeight:'bold'}}>Date:</Text>
                            <Text>{d.getDate()} {monthNames[d.getMonth()]} {d.getFullYear()}</Text>
                        </View>
                        <View style={{flexDirection:'row', marginRight:'1.5vw'}}>
                            <Text style={{marginRight:'0.5vw',fontWeight:'bold'}}>Status:</Text>
                            <Text>{stage}</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{marginRight:'0.5vw', fontWeight:'bold'}}>Organisation:</Text>
                            <Text>{organisation.data.name}</Text>
                        </View>
                    </View>
                </View>
                <View style={{justifyContent:'center'}}>
                    {permissionLevel <= 2?
                    <View
                        style={{flexDirection:'row'}}
                    >
                        {/* <TouchableOpacity
                            style={{marginRight:'2vw'}}
                            onPress={()=>copy()}
                        >
                            <Feather name="copy" size={24} color={theme.colors.mainColour} />
                        </TouchableOpacity> */}
                        <TouchableOpacity
                            style={{marginRight:'2vw'}}
                            //onPress={() => deleteComp(id)}
                            onPress={()=> setDeleteTrigger({id:id, name:name})}
                        >
                            <Entypo name="trash" size={24} color={theme.colors.mainColour}/>
                        </TouchableOpacity>
                    </View>

                    :null}
                </View>

            </View>

        );
    }else{
        return(null)
    }
  };

export default CompetitionRow;