import React, { useContext, useEffect, useState } from 'react';
import {  Text, View} from 'react-native';
import Popup from '../parts/Popup';
import * as RootNavigation from '../../RootNavigation';
import { CheckBox, Modal, Picker, ScrollView, TextInput, TouchableOpacity } from 'react-native-web';
import { useTheme } from 'react-native-paper';
import { AppContext } from '../../AppContextProvider';

import AppButton from '../parts/AppButton';
import CompetitionRow from './CompetitionRow';

import { Ionicons } from '@expo/vector-icons';

import retButtonStyles from '../../styles/ButtonStyleSheet';

import { getAuth, signOut } from 'firebase/auth';
import { db } from '../../fbManage.js';
import { collection, getDocs, addDoc ,doc, query,where, onSnapshot, setDoc, getDoc} from 'firebase/firestore';

import CalendarPicker from 'react-native-calendar-picker/CalendarPicker';
import retStyles from '../../styles/MainStyleSheet';
import Header from '../parts/Header';
import LoadingModal from '../parts/LoadingModal';
import AlertPopup from '../parts/AlertPopup';
import { deleteCompetition, testing } from '../../data';

import * as CryptoJS from 'crypto-js'

const auth = getAuth();

const HomeScreen = ({ navigation, route }) => {
  const ButtonStyles =retButtonStyles()
  const styles = retStyles()
  const theme = useTheme()

  const [loading, setLoading] = useState(true);
  const [loadingTrigger, setLoadingTrigger] = useState(false);

  const [trigger, setTrigger] = useState(false);
  const [copyTrigger, setCopyTrigger] = useState(false);
  //Delete/Alert trigger works differently... it uses '' instead of false by design
  const [deleteTrigger, setDeleteTrigger] = useState('');
  const [alertTrigger, setAlertTrigger] = useState('');

  const [createName, setCreateName] = useState('');
  const [createDate, setCreateDate] = useState();
  const [createOrg, setCreateOrg] = useState('');

  const [compList, setCompList] =useState([]);

  const [viewCompList, setViewCompList] = useState([]);

  const [origID, setOrigID] = useState('')
  const [copyName, setCopyName] = useState('')
  const [copyDate, setCopyDate] = useState()
  const [copyEvents, setCopyEvents] = useState(false)
  const [copyCompetitors, setCopyCompetitors] = useState(false)
  const [copyTeams, setCopyTeams] = useState(false)
  const [copyDivisions, setCopyDivisions] = useState(false)
  const [copyLeaderboard, setCopyLeaderboard] = useState(false)
  const [copyCusLeaderboards, setCopyCusLeaderboards] = useState(false)
  const [copyPhase, setCopyPhase] = useState('Complete')
  const [copyLoading, setCopyLoading] = useState(false);

  const {globalUserDets} = useContext(AppContext)
  const {globalOrganisationDets} = useContext(AppContext)
  const {globalLicencingDets} = useContext(AppContext)
  const {gVpWidth, gVpHeight} = useContext(AppContext)

  useEffect(()=>{
    let isMounted = true
    var unsub_compList = () =>{};
    if(isMounted){
      let arr = [...globalOrganisationDets.map(x=>x.id)]
      if(arr.length > 0){
        const q = query(collection(db,'Competitions'), where('organisation', 'in', arr))
        unsub_compList = onSnapshot(q, async(snapshot)=>{
          setCompList([...snapshot.docs.map(doc=>({id:doc.id, data:doc.data()}))])
          setLoadingTrigger(true)
        })
      }
    }
    return()=>{
      unsub_compList()
      isMounted = false
    }

  },[globalUserDets, globalOrganisationDets])

  useEffect(()=>{
    if(globalOrganisationDets && globalUserDets && loadingTrigger){
      setLoading(false)
    }
  },[globalOrganisationDets, globalUserDets, loadingTrigger])

  const createComp = async() =>{
    if(createName === ''){
      setAlertTrigger({title:'NAME?', text:'Please enter a name for the competition'})
    }else if(!createDate){
      setAlertTrigger({title:'DATE?', text:'You forgot to specify a date for you competition'})
    }else{
      setLoading(true)
      let orgID = globalOrganisationDets.find(x=>x.data.name === 'Personal').id
      if(createOrg != ''){
        orgID = createOrg
      }
      if(!globalUserDets.data.Organisations[orgID].active){
        setLoading(false)
        setAlertTrigger({title:'LICENCE', text:'Sorry, but you are not licensed for this organisation.\nLicences are managed in settings'})
      }else{
        let initCompetitionObj = {
          name:createName,
          stage:'Setup',
          TeamsEnabled:false,
          date: createDate,
          organisation:orgID,
          publishedEvents:[],
        }

        const docRef = addDoc(collection(db,"Competitions"),initCompetitionObj)
        .then((t)=>{
          setTrigger(false)
          setLoading(false)
          RootNavigation.navigate("create", {compID: t.id})
        })
      }
      }
  }

  useEffect(()=>{
    setViewCompList(compList)
  },[compList])

  const selectComp = (comp) => {
    if(globalUserDets.data.Organisations[comp.data.organisation].active){
      let permissionLevel = globalUserDets.data.Organisations[comp.data.organisation].role
      if(comp.data.stage == 'Setup'){
        if(permissionLevel <= 2){
          RootNavigation.navigate('create', {compID:comp.id})
        }
      }else if(comp.data.stage == 'use'){
        if(permissionLevel <= 3){
          RootNavigation.navigate('use', {compID:comp.id})
        }
      }else if(comp.data.stage == 'Finished'){
        RootNavigation.navigate('Finished', {compID: comp.id})
      }
    }else{
      setAlertTrigger({title:'LICENCE', text:'Sorry, but you are not licensed for this organisation.\nLicences are managed in settings'})
    }
  }

  const selectDate = (d) =>{
    let date = new Date(d);
    let utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
    date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
    let utc = new Date(utcDate)
    setCreateDate(utc)
  }

  const initCopy = (oldID) =>{
    setOrigID(oldID)
    setCopyTrigger(true)
  }

  const copy = async() =>{
    if(copyName==''){
      setAlertTrigger({title:'MISSING NAME', text:'You must provide a name for your new competition'})
    }else if(!copyEvents && !copyCompetitors && !copyTeams && !copyDivisions && !copyLeaderboard){
      setAlertTrigger({title:'HOLD ON', text:'You must select at least one configuration group to copy'})
    }else{
      setCopyLoading(true)
      let newID =''
      let oldDetsRef = doc(db,'Competitions',origID)
      let oldDetsSnap = await getDoc(oldDetsRef);
      let oldDetsData = oldDetsSnap.data()
      setCopyPhase("Setting up New Competition")
      let newOBJ = {
        name:copyName,
        date: copyDate,
        stage:'Setup',
      };
      if(oldDetsData.admins){
        newOBJ.admins = oldDetsData.admins;
      }
      if(oldDetsData.TeamsEnabled){
        newOBJ.TeamsEnabled = oldDetsData.TeamsEnabled
      }
      if(oldDetsData.ScoreTeam){
        newOBJ.ScoreTeam = oldDetsData.ScoreTeam;
      }
      if(oldDetsData.organisation){
        newOBJ.organisation = oldDetsData.organisation;
      }

      if(copyCompetitors){
        newOBJ.competitorCatagories = oldDetsData.competitorCatagories;
      }
      if(copyLeaderboard){
        newOBJ.hideMainLeaderboard = oldDetsData.hideMainLeaderboard;
        newOBJ.LeaderboardRanking = oldDetsData.LeaderboardRanking;
        newOBJ.CountTopScores = oldDetsData.CountTopScores;
        newOBJ.LeaderboardLevel = oldDetsData.LeaderboardLevel;
        newOBJ.PublicLeaderboard = oldDetsData.PublicLeaderboard;
      }
      const docRef = addDoc(collection(db,"Competitions"),newOBJ).then(async(t)=>{
        newID=t.id;
        let events =[]
        let competitors =[]
        let teams = []
        let divisions = []
        let Leaderboard =[]
        let CustomLeaderboards = []
        if(copyEvents){
          setCopyPhase("Copying Events")
          let d = getDocs(collection(db,'Competitions/' + origID +'/Events'))
          d.then((res)=>{
            for(let doc of res.docs){
              events.push({id:doc.id, data:doc.data()})
            }

            for(let e of events){
              setDoc(doc(db,"Competitions/"+newID+"/Events",e.id),e.data)
            }
          })

        }
        if(copyCompetitors){
          setCopyPhase("Copying Competitors")
          let d = getDocs(collection(db,'Competitions/' + origID +'/Competitors'))
          d.then((res)=>{
            for(let doc of res.docs){
              competitors.push({id:doc.id, data:doc.data()})
            }

            for(let c of competitors){
              setDoc(doc(db,"Competitions/"+newID+"/Competitors",c.id),c.data)
            }
          })
        }
        if(copyTeams){
          setCopyPhase("Copying Teams")
          let d = getDocs(collection(db,'Competitions/' + origID +'/Teams'))
          d.then((res)=>{
            for(let doc of res.docs){
              teams.push({id:doc.id, data:doc.data()})
            }

            for(let t of teams){
              setDoc(doc(db,"Competitions/"+newID+"/Teams",t.id),t.data)
            }
          })

        }
        if(copyDivisions){
          setCopyPhase("Copying Divisions")
          let d = getDocs(collection(db,'Competitions/' + origID +'/Divisions'))
          d.then((res)=>{
            for(let doc of res.docs){
              divisions.push({id:doc.id, data:doc.data()})
            }

            for(let div of divisions){
              setDoc(doc(db,"Competitions/"+newID+"/Divisions",div.id),div.data)
            }
          })

        }
        if(copyCusLeaderboards){
          setCopyPhase("Copying Custom Leaderboards")
          let d = getDocs(collection(db,'Competitions/' + origID +'/CustomLeaderboards'))
          d.then((res)=>{
            for(let doc of res.docs){
              CustomLeaderboards.push({id:doc.id, data:doc.data()})
            }

            for(let CL of CustomLeaderboards){
              setDoc(doc(db,"Competitions/"+newID+"/CustomLeaderboards",CL.id),CL.data)
            }
          })
        }

        setCopyPhase("Completed")
        setCopyTrigger(false)
        setCopyLoading(false)
      })

    }
  }

  const deleteComp = async (compID) =>{
    setLoading(true)
    let res = await deleteCompetition(compID)
    if(res){
      setDeleteTrigger(false)
      setLoading(false)
    }
  }

  const filter = (e) =>{
    let arr = [...compList]
    arr = arr.filter((comp)=>(comp.data.name.toLowerCase().includes(e.toLowerCase())))
    setViewCompList(arr);
  }

  const test = async () =>{
  }

  return (
    <View>
      <Header
        theme={theme}
        buttons={
          <View style={(gVpWidth > 600) ? {flexDirection:'row'} : {flexDirection:'column'}}>
              <View style={[{justifyContent:'center', alignItems:'center'}, gVpWidth <= 600 ? {marginVertical:'1.5vh'}:null]}>
                <TouchableOpacity onPress={()=>RootNavigation.navigate('settings')}>
                  <Ionicons name="settings" size={24} color={theme.colors.mainColour} />
                </TouchableOpacity>
              </View>

              <View style={[{minWidth:'10vw', height:'5vh'}, gVpWidth > 600 ? {marginLeft:'2vw', marginVertical:'2.5vh'}:{marginBottom:'1.5vh'}]}>
                  <AppButton
                  title={"Create"}
                  containerStyle={[ButtonStyles.appButtonContainer, {backgroundColor:theme.colors.mainColour}]}
                  textStyle={ ButtonStyles.appButtonText}
                  onPress={()=>{setTrigger(true);}}
                  />
              </View>
              <View style={[{minWidth:'10vw', height:'5vh'}, gVpWidth > 600 ? {marginLeft:'2vw',  marginVertical:'2.5vh'}:{marginBottom:'1.5vh'}]}>
                  <AppButton
                    title={"Logout"}
                    containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                    textStyle={ ButtonStyles.appButtonText}
                    onPress={()=>{signOut(auth)}}
                  />
              </View>
          </View>
        }
      />
      {/**LIST OF COMPS */}
      <View style={{paddingLeft:'1%', paddingRight:'1%'}}>
        <View style={[{marginBottom:'3vh', marginLeft:'3vw'}, gVpWidth <= 600 ?{marginRight:'3vw'}:null]}>
          <View style={ gVpWidth > 600 ? {width:'30vw'}:{width:'100%'}}>
            <TextInput style={[styles.inputBox, {borderRadius:'5px'}]}
              placeholder = {"Search"}
              onChangeText={(text)=>{filter(text)}}
            />
          </View>
        </View>
        <ScrollView>
          {viewCompList.length < 1 ?
            <View style={{justifyContent:'center', width:'100%', minHeight:'50vh'}}>
              <View style={{justifyContent:'center', flexDirection:'row'}}>
                <Text>No Competitions to Show</Text>
              </View>
            </View>

          :
          viewCompList.map(comp => {
            return(
              <TouchableOpacity
                key={comp.id}
                onPress={()=> selectComp(comp)}
                style={{backgroundColor:'white', borderRadius:'30px', paddingLeft:'3vw', marginBottom:'2vh'}}
              >
                <CompetitionRow
                  name={comp.data.name}
                  date={comp.data.date}
                  status={comp.data.stage}
                  organisation={globalOrganisationDets.find(x=>x.id===comp.data.organisation)}
                  id={comp.id}
                  copy={() => initCopy(comp.id)}
                  setDeleteTrigger={setDeleteTrigger}
                />
              </TouchableOpacity>
            )
          })
          }
        </ScrollView>
      </View>

      {/**CREATE COMPETITION POPUP */}
      <Popup
        trigger={trigger}
        innerStyle={{padding:10,
          width:(gVpWidth > 600 ) ? '60%' : '100%',
          maxWidth:676,
          height:'auto',
          backgroundColor:'white',
          borderRadius:20,
          borderColor:theme.colors.mainColour,
          borderWidth:2}}
        content={
          <View>
            <Text style={[styles.H1, {marginBottom:'1vh'}, gVpWidth <= 600 ? {width:'100%', textAlign:'center'}:null]}>Create Competition</Text>
            <View style={[{flexDirection:'row', maxHeight:'4vh'}, gVpWidth < 600 ? {flexDirection:'column', width:'100%', alignItems:'center'}:null]}>
              <Text style={gVpWidth > 600 ? {width:'20%', marginRight:'1%', fontWeight:'bold'}:{fontWeight:'bold'}}>Name:</Text>
              <TextInput style={{width:'75%', borderWidth:1, borderColor:'black', paddingLeft:'1%'}} onChangeText={(e) => setCreateName(e)} ></TextInput>
            </View>
            <View style={[{flexDirection:'row', maxHeight:'4vh', marginTop:'2.25vh'}, gVpWidth < 600 ? {flexDirection:'column', width:'100%', alignItems:'center'}:null]}>
              <Text style={gVpWidth > 600 ? {width:'20%', marginRight:'1%', fontWeight:'bold'}:{fontWeight:'bold'}}>Organisation:</Text>
              <Picker style={{width:'75%'}}
                selectedValue={createOrg}
                onValueChange={(value) => setCreateOrg(value)}
              >
                {globalOrganisationDets.map((org)=>{
                  if(org){
                    let curOrgUser = globalUserDets.data.Organisations[org.id]
                    if(curOrgUser){
                      if(curOrgUser.role < 2){
                        return(
                          <Picker.Item key={org.id} label={org.data.name} value={(org.data.name == 'Personal')? '':org.id} />
                        )
                      }
                    }
                  }
                })}
              </Picker>
            </View>
            <View style={[{flexDirection:'row', marginTop:'2.25vh', height:'auto'}, gVpWidth < 600 ? {flexDirection:'column', width:'100%', alignItems:'center'}:null]}>
              <Text style={gVpWidth > 600 ? {width:'20%', marginRight:'1%', fontWeight:'bold'}:{fontWeight:'bold'}}>Date:</Text>
              <View style={gVpWidth > 600 ? {marginLeft:'7%'}:{marginTop:'0.5vh', flex:1, maxWidth:'100%', width:'100%'}}>
                <CalendarPicker
                  onDateChange={(d) => selectDate(d._d)}
                  width={350}
                  selectedDayColor={theme.colors.mainColour}
                  selectedDayTextColor={'white'}
                  minDate={new Date()}
                />
              </View>
            </View>
            <View style={{flexDirection:'row', padding:'1vw', justifyContent:'space-evenly', marginTop:'1.5vhS'}}>
              <View style={{minWidth:'10vw', height:'5vh'}}>
                <AppButton
                  title={"Create"}
                  containerStyle={ButtonStyles.appButtonContainer}
                  textStyle={ ButtonStyles.appButtonText}
                  onPress={()=>createComp()}
                />
              </View>
              <View style={{minWidth:'10vw', height:'5vh'}}>
                <AppButton
                  title={"Cancel"}
                  containerStyle={ButtonStyles.appButtonContainer}
                  textStyle={ButtonStyles.appButtonText}
                  onPress={()=>setTrigger(false)}
                />
              </View>
            </View>
          </View>
        }
      />

      {/**Copy Competition Popup */}
      <Popup
        trigger={copyTrigger}
        innerStyle={{
          padding:10,
          width:'70%',
          height:'auto',
          backgroundColor:'white',
          borderRadius:20,
          borderColor:theme.colors.mainColour,
          borderWidth:2
        }}
        content={
          <View>
            <Text style={styles.H1}>Copy</Text>
            <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3vh'}}>
              {/**Left Side*/}
              <View>
                <View style={{flexDirection:'row', marginTop:'1vh', marginBottom:'0.5vh'}}>
                  <Text>New Competition Name:</Text>
                  <TextInput
                    style={[styles.inputBox, {marginLeft:'0.5vw'}]}
                    value={copyName}
                    onChangeText={(e)=>{setCopyName(e)}}
                  />
                </View>
                <View>
                  <Text>New Competition Date:</Text>
                  <CalendarPicker
                    onDateChange={(d) => setCopyDate(d._d)}
                    width={350}
                    selectedDayColor={theme.colors.mainColour}
                    selectedDayTextColor={'white'}
                    minDate={new Date()}
                  />
                </View>
              </View>
              {/**Right side */}
              <View style={{ justifyContent:'center'}}>
                <Text  style={{fontWeight:'bold'}}>Select configuration to copy:</Text>
                <View style={{marginLeft:'3vw', marginRight:'3vw'}}>
                  <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text>Events:</Text>
                    <CheckBox
                      style={{marginLeft:'0.2vw', marginTop:'0.4vh'}}
                      value={copyEvents}
                      onValueChange={setCopyEvents}
                    />
                  </View>
                  <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text>Competitors:</Text>
                    <CheckBox
                      style={{marginLeft:'0.2vw', marginTop:'0.4vh'}}
                      value={copyCompetitors}
                      onValueChange={setCopyCompetitors}
                    />
                  </View>
                  <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text>Teams:</Text>
                    <CheckBox
                      style={{marginLeft:'0.2vw', marginTop:'0.4vh'}}
                      value={copyTeams}
                      onValueChange={setCopyTeams}
                    />
                  </View>
                  <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text>Divisions:</Text>
                    <CheckBox
                      style={{marginLeft:'0.2vw', marginTop:'0.4vh'}}
                      value={copyDivisions}
                      onValueChange={setCopyDivisions}
                    />
                  </View>
                  <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text>Leaderboard:</Text>
                    <CheckBox
                      style={{marginLeft:'0.2vw', marginTop:'0.4vh'}}
                      value={copyLeaderboard}
                      onValueChange={setCopyLeaderboard}
                    />
                  </View>
                  <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text>Custom Leaderboards:</Text>
                    <CheckBox
                      style={{marginLeft:'0.2vw', marginTop:'0.4vh'}}
                      value={copyCusLeaderboards}
                      onValueChange={setCopyCusLeaderboards}
                    />
                  </View>
                </View>
              </View>

            </View>
              <View style={{flexDirection:'row', padding:'1vw', justifyContent:'space-evenly', marginTop:'1vh'}}>
                <View style={{width:'10vw', height:'5vh'}}>
                  <AppButton
                    title={"Copy"}
                    containerStyle={ButtonStyles.appButtonContainer}
                    textStyle={ButtonStyles.appButtonText}
                    onPress={()=>copy()}
                  />
                </View>
                <View style={{width:'10vw', height:'5vh'}}>
                  <AppButton
                    title={"Cancel"}
                    containerStyle={ButtonStyles.appButtonContainer}
                    textStyle={ButtonStyles.appButtonText}
                    onPress={()=>setCopyTrigger(false)}
                  />
                </View>
              </View>

              <Modal
                visible={copyLoading}
                transparent={true}
              >
                <View
                  style={{
                    width:'100%',
                    height:'100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    justifyContent:'center'
                  }}
                >
                  <View
                    style={{flexDirection:'row', justifyContent:'center'}}
                  >
                    <View
                      style={{
                      padding:10,
                      width:'20vw',
                      height:'10vh',
                      backgroundColor:'white',
                      borderRadius:20,
                      borderColor:'Blue',
                      borderWidth:2}}
                    >
                      <Text style={{fontWeight:'bold', marginBottom:'0.5vh'}}>Copying Competition</Text>
                      <Text>Stage: {copyPhase}</Text>
                    </View>
                  </View>
                </View>
              </Modal>
          </View>
        }
      />

      {/**Delete Competitiion Popup */}
      <Popup
        trigger={(deleteTrigger !='')}
        innerStyle={{
          padding:10,
          width:'40%',
          height:'auto',
          backgroundColor:'white',
          borderRadius:20,
          borderColor:theme.colors.mainColour,
          borderWidth:2
        }}
        content={
          <View style={{justifyContent:'center', alignItems:'center'}}>
            <Text style={styles.H1}>DELETE</Text>
            <Text style={{textAlign:'center'}}>Are you sure you would like to delete this competition? <b>{deleteTrigger.name}</b></Text>
            <Text style={{textAlign:'center'}}>This CANNOT be undone</Text>
            <View style={{flexDirection:'row', justifyContent:'space-evenly', marginTop:'3vh'}}>
              <View style={{width:'10vw', height:'5vh', marginLeft:'2vw'}}>
                <AppButton
                  title={"Yes"}
                  containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                  textStyle={ ButtonStyles.appButtonText}
                  onPress={()=>{deleteComp(deleteTrigger.id)}}
                />
              </View>
              <View style={{width:'10vw', height:'5vh', marginLeft:'2vw'}}>
                <AppButton
                  title={"No"}
                  containerStyle={[ButtonStyles.appButtonContainer,{backgroundColor:theme.colors.mainColour}]}
                  textStyle={ ButtonStyles.appButtonText}
                  onPress={()=>{setDeleteTrigger('')}}
                />
              </View>
            </View>
          </View>
        }
      />

      {/**Alert Popup */}
      <AlertPopup
        trigger={(alertTrigger != '')}
        setTrigger={setAlertTrigger}
        title={alertTrigger.title}
        alertText={alertTrigger.text}
      />
      {/**Loading */}
      <LoadingModal
        trigger={loading}
        customMessage={deleteTrigger!=''?("Deleting Competition"):null}
      />

    </View>
  );
};

export default HomeScreen;